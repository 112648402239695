import React from "react";
import { Col } from "react-bootstrap";
import Empty from "../global/Empty";
import Notification from "../../Notification";

function Notifications() {
  return (
    <Col className="mainarea">
      <div className="pagetitle">
        <h1 className="title">Notifications</h1>
      </div>
      <Notification />
    </Col>
  );
}

export default Notifications;
