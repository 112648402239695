import React, { useEffect, useState } from "react";
import {
  Col,
  Card,
  Stack,
  Spinner,
  Toast,
  ToastContainer,
} from "react-bootstrap";
import { useNavigate, useLocation } from "react-router-dom";
import { BiArrowBack } from "react-icons/bi";
import * as yup from "yup";
import { useFormik } from "formik";
import axios from "axios";
import { CKEditor } from "@ckeditor/ckeditor5-react";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";

function EditBid() {
  const [state, setState] = useState({
    loading: false,
    disable: false,
    notification: null,
  });
  const location = useLocation();

  const bid = JSON.parse(localStorage.getItem("bid"));
  const [showToast, setShowToast] = useState(false);

  console.log(bid);

  const navigate = useNavigate();
  const maxamount = bid.project.bidhigh;
  const minamount = bid.project.bidlow;

  const placebid = yup.object().shape({
    bidamount: yup
      .number()
      .max(
        bid.project.employer.country === "Kenya" ? maxamount : maxamount * 100,
        "The Bid amount Should not exceed the Max Project Budget"
      )
      .min(
        bid.project.employer.country === "Kenya" ? minamount : minamount * 100,
        "The Bid amount should not be lower than the Min Project Budget"
      )
      .required("Bid amount is a required field"),
    biddescription: yup
      .string()
      .max(700, "Number of Characters should not exceed 700")
      .required("Give a reason why you are suited to handle this bid"),
  });

  const edit_bid = async (values) => {
    const url = `${process.env.REACT_APP_API_URL}/accounts/edit_bid`;
    const config = {
      headers: {
        Authorization: `JWT ${localStorage.getItem("access")}`,
        "Content-Type": "application/json",
      },
    };
    const body = JSON.stringify({
      bidid: bid.id,
      amount: values.bidamount,
      description: values.biddescription,
    });
    try {
      const res = await axios.patch(url, body, config);
      console.log(res.data);
      return res.data;
    } catch (err) {
      console.log(err);
    }
  };

  const onSubmit = (values) => {
    setState({ ...state, loading: true, disable: true });
    edit_bid(values).then((res) => {
      setState({
        loading: false,
        disable: true,
        notification: "Bid Successfully Edited",
      });
      setTimeout(() => {
        setState({
          ...state,
          notification: "Bid Could Not be Edited",
          disable: true,
        });
        navigate("/writer/bids");
      }, 6500);
    });
  };
  const initialValues = {
    bidamount: bid.amount,
    biddescription: bid.description,
  };

  console.log(initialValues);

  const {
    values,
    errors,
    touched,
    handleChange,
    handleSubmit,
    handleBlur,
    setFieldValue,
  } = useFormik({
    initialValues: initialValues,
    validationSchema: placebid,
    onSubmit,
  });
  useEffect(() => {
    if (state.notification) {
      setShowToast(true);
    }
  }, [state.notification]);

  return (
    <Col className="mainarea">
      <div className="pagetitle mt-2 mb-2">
        <h1 className="title">
          <BiArrowBack onClick={() => navigate(-1)} className="pagenavbutton" />
          Edit Bid On Project ID: <strong>#{bid.project.id}</strong>
        </h1>
      </div>
      <div className="itemscontainer d-flex align-items-center justify-content-center">
        <ToastContainer
          position="top-end"
          className="p-3"
          style={{ zIndex: 99999 }}>
          <Toast
            delay={3000}
            autohide
            className={
              state.notification === "Bid Successfully Edited"
                ? "bg-success text-white"
                : "bg-danger text-white"
            }
            show={showToast}
            onClose={() => {
              setShowToast(false);
            }}>
            <Toast.Body>{state.notification}</Toast.Body>
          </Toast>
        </ToastContainer>
        <Card style={{height: "100%", width:"100%"}}>
          <Card.Body style={{ margin: "1vh" }}>
            <h6>
              Project Budget: Min <span className="inlineitem">Kshs</span>{" "}
              {bid.project.employer.country === "Kenya"? (<>{bid.project.bidlow} Max Kshs {bid?.project.bidhigh}</>):(<>{bid.project.bidlow *100} Max Kshs {bid?.project.bidhigh *100}</>)}
            </h6>
            <h6>Subject: {bid.project.subject}</h6>
            <form onSubmit={handleSubmit}>
              <p>
                This amount should not be lower or exceed the project budget
              </p>
              <Stack gap={2}>
                <label htmlFor="bidamount" style={{ color: "green" }}>
                  Amount
                </label>
                <input
                  type="number"
                  id="bidamount"
                  onChange={handleChange}
                  onBlur={handleBlur}
                  value={values.bidamount}
                  disabled={state.disable}
                  className={
                    errors.bidamount && touched.bidamount ? "input_error" : ""
                  }
                />
                {errors.bidamount && touched.bidamount ? (
                  <p className="error">{errors.bidamount}</p>
                ) : (
                  ""
                )}
                <label htmlFor="biddescription" style={{ color: "green" }}>
                  Give a brief description of why you are suited to handle this
                  project
                </label>

                <CKEditor
                  id="biddescription"
                  editor={ClassicEditor}
                  data={values.biddescription}
                  onChange={(event, editor) => {
                    const data = editor.getData();
                    console.log({ event, editor, data });
                    setFieldValue("biddescription", data);
                  }}
                  className={
                    errors.biddescription && touched.biddescription
                      ? "input_error"
                      : ""
                  }
                />

                {errors.biddescription && touched.biddescription ? (
                  <p className="error">{errors.biddescription}</p>
                ) : (
                  ""
                )}
                <div className="d-flex justify-content-center">
                  <button
                    type="submit"
                    className="btn btn-sm btn-success"
                    disabled={state.disable}>
                    {console.log(state.disable)}
                    {state.loading ? (
                      <span>
                        <Spinner as="span" animation="border" size="sm" />
                        Editing Bid...
                      </span>
                    ) : state.disable ? (
                      <span>Edited</span>
                    ) : (
                      <span>Edit</span>
                    )}
                  </button>
                </div>
              </Stack>
            </form>
          </Card.Body>
        </Card>
      </div>
    </Col>
  );
}

export default EditBid;
