import { useEffect } from "react";

import axios from 'axios'




const patchproject = async(url, formdata) => {
    const config = {
      headers: {
        "Authorization": `JWT ${localStorage.getItem("access")}`
      },
    };

    try {
      
       const res = await axios.patch(url, formdata, config)
       return res.data
    } catch (err) {
      
      console.log(err)
      
    }
  }

  const usePatch = () => async (url,formdata)=> {
     try{
        const res = await patchproject(url, formdata)
        return res.data
     }
     catch(err){
        console.log(err)
     }
}

export default usePatch