import React, { useEffect, useState } from "react";
import { Col, Row, Card } from "react-bootstrap";
import rating from "../../images/rating.png";
import file from "../../images/file.png";
import agreement from "../../images/agreement.png";
import document from "../../images/document.png";
import disputed from "../../images/disputed.png";
import review from "../../images/review.png";
import revision from "../../images/revision.png";
import wallet from "../../images/wallet.png";
import wallet2 from "../../images/wallet2.png";
import { display_projects } from "../../actions/writeractions";
import { connect } from "react-redux";
import { Link } from "react-router-dom";

const WriterDashboard = ({ writer }) => {
  console.log(writer);
  const [state, setState] = useState({
    availableprojects: 0,
    loading: true,
  });

  const [socket, setSocket] = useState(null);
  const [messages, setMessages] = useState([]);

  useEffect(() => {
    const newSocket = new WebSocket(
      `${process.env.REACT_APP_WEBSOCKET_URL}/ws/project_add/${writer?.role}`
    );

    setSocket(newSocket);

    // Clean up the WebSocket connection when the component unmounts

    newSocket.onopen = () => console.log("WebSocket connected");
    newSocket.onclose = () => console.log("WebSocket disconnected");
  }, []);

  useEffect(() => {
    if (socket) {
      socket.onmessage = (event) => {
        const data = JSON.parse(event.data);
        display_projects();
        setMessages((prevMessages) => [...prevMessages, data]);
      };
    }
  }, [socket]);

  useEffect(() => {
    display_projects().then(
      (res) => {
        
        setState({ loading: false, availableprojects: res.length });
      },
      (err) => {
        console.log(err);
        setState({ loading: false, availableprojects: 0 });
      }
    );
  }, []);

  return (
  
      <Row className="cardcontainer">
        <Col md={4} sm={6} xs={6} className="m-0 p-0">
          <Card className="mycard">
            <Link to={"/writer/projects"}>
              <h5 className="card_text">Available</h5>

              <h4 className="d-flex justify-content-around align-items-center cardvalues">
                <span>
                  <img src={file} className="cardicon" alt="available" />
                </span>
                {state.availableprojects}
              </h4>
            </Link>
          </Card>
        </Col>
        <Col md={4} sm={6} xs={6} className="m-0 p-0">
          <Card className="mycard">
            <Link to={"/writer/my_projects"}>
              <h5 className="card_text">In Progress</h5>
              <h4 className="d-flex justify-content-around align-items-center cardvalues">
                <img src={agreement} className="cardicon" alt="inprogress" />
                {writer.writer_profile.inprogress}
              </h4>
            </Link>
          </Card>
        </Col>
        <Col md={4} sm={6} xs={6} className="m-0 p-0">
          <Card className="mycard">
            <Link to={"/writer/completed"}>
              <h5 className="card_text">Completed</h5>
              <h4 className="d-flex justify-content-around align-items-center cardvalues">
                <img src={document} className="cardicon" alt="document" />
                {writer.writer_profile.completedcount}
              </h4>
            </Link>
          </Card>
        </Col>
        <Col md={4} sm={6} xs={6} className="m-0 p-0">
          <Card className="mycard">
            <Link to={"/writer/disputed"}>
              <h5 className="card_text">In Dispute</h5>

              <h4 className="d-flex justify-content-around align-items-center cardvalues">
                <img src={disputed} className="cardicon" />
                {writer.writer_profile.indispute}
              </h4>
            </Link>
          </Card>
        </Col>
        <Col md={4} sm={6} xs={6} className="m-0 p-0">
          <Card className="mycard">
            <Link to={"/writer/profile"}>
              <h5 className="card_text">All Disputes</h5>

              <h4 className="d-flex justify-content-around align-items-center cardvalues">
                <img src={revision} className="cardicon" alt="revision" />
                {writer.writer_profile.disputecount}
              </h4>
            </Link>
          </Card>
        </Col>

        <Col md={4} sm={6} xs={6} className="m-0 p-0">
          <Card className="mycard">
            <Link to={"/writer/forrevision"}>
              <h5 className="card_text">In Revision</h5>

              <h4 className="d-flex justify-content-around align-items-center cardvalues">
                <img src={review} className="cardicon" alt="review" />
                {writer.writer_profile.inrevision}
              </h4>
            </Link>
          </Card>
        </Col>

        <Col md={4} sm={6} xs={6} className="m-0 p-0">
          <Card className="mycard">
            <Link to={"/writer/profile"}>
              <h5 className="card_text">Level</h5>

              <h4 className="d-flex justify-content-around align-items-center cardvalues">
                <img src={rating} className="cardicon" />
                {writer.writer_profile.level}
              </h4>
            </Link>
          </Card>
        </Col>
        <Col md={4} sm={6} xs={6} className="m-0 p-0">
          <Card className="mycard">
            <Link to={"/writer/transactions"}>
              <h5 className="card_text">In Escrow</h5>

              <h4 className="d-flex justify-content-around align-items-center cardvalues">
                <img src={wallet} className="cardicon" alt="wallet" />
                Kshs {writer.writer_profile.cash}.00
              </h4>
            </Link>
          </Card>
        </Col>
        <Col md={4} sm={6} xs={6} className="m-0 p-0">
          <Card className="mycard">
            <Link to={"/writer/transactions"}>
              <h5 className="card_text">All Earnings</h5>

              <h4 className="d-flex justify-content-around align-items-center cardvalues">
                <img src={wallet2} className="cardicon" alt="wallet2" />
                Kshs {writer.total_amount}.00
              </h4>
            </Link>
          </Card>
        </Col>
      </Row>

  );
};

const mapStateToProps = (state) => ({
  writer: state.current_user.user,
});

export default connect(mapStateToProps)(WriterDashboard);
