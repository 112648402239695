import React, { useEffect, useState } from "react";
import {
  Col,
  Stack,
  Spinner,
  Card,
  Toast,
  ToastContainer,
} from "react-bootstrap";
import { BiArrowBack } from "react-icons/bi";
import { useNavigate, useLocation } from "react-router-dom";
import { useFormik } from "formik";
import * as yup from "yup";
import { FILE_SIZE, SUPPORTED_FORMATS } from "../../assets/projectassets";
import { postData } from "../../customHooks/postdata";

function RequestReview() {
  const [myRevision, setMyRevision] = useState({
    loading: false,
    data: null,
    notification: "",
    loaded: false,
  });
  const [showToast, setShowToast] = useState(false);
  const [project, setProject] = useState(null)
  console.log(project)
  const url = `${process.env.REACT_APP_API_URL}/accounts/revision`;
  const onSubmit = async () => {
    setMyRevision({ ...myRevision, loading: true });
    const formdata = new FormData();
    for (const value in values) {
      if (value === "reviewfiles") {
        for (let i = 0; i < values[value].length; i++) {
          const files = values[value];
          formdata.append(`file${i}`, files[i]);
        }
      } else {
        formdata.append(value, values[value]);
      }
    }
    postData(url, formdata)
      .then((res) => {
        console.log(res);
        setMyRevision({
          loaded: true,
          loading: false,
          data: res,
          notification: "Revision Requested Successfully",
        });
      })
      .catch((err) => {
        console.log(err);
        setMyRevision({
          ...setMyRevision,
          loading: false,
          notification: "Revision Request Failed",
        });
      });
  };
  const location = useLocation();

  useEffect(() => {
    const project = JSON.parse(localStorage.getItem("revisionProject"));
    console.log(project)
    if (project) {
      setProject(project)
      setFieldValue("projectid", project?.id);
    }
  
  }, [])
  
  
  const navigate = useNavigate();
  const reviewvalidation = yup.object().shape({
    instructions: yup
      .string()
      .required("Give a brief Description of Revision to be Done on Project")
      .min(50, "Revision instructions Should have at least 50 Characters")
      .max(5000, "Instructions should not exceed 5000 characters"),
    reviewfiles: yup
      .array()
      .of(
        yup
          .mixed()
          .test(
            "fileSize",
            "File too large MAX 10MB for Each file",
            (value) => value && value.size <= FILE_SIZE
          )
          .test(
            "fileFormat",
            "Unsupported File Format! Supported formats =>(.docx, .jpeg, .jpg, .png, .xlxs, .ppt)",
            (value) => value && SUPPORTED_FORMATS.includes(value.type)
          )
      )
      .max(5, "You can Upload a Maximum of 5 files Per Project for Revision"),
  });
  const {
    resetForm,
    handleChange,
    values,
    errors,
    setFieldValue,
    handleSubmit,
  } = useFormik({
    initialValues: {
      projectid: "",
      instructions: "",
      reviewfiles: [],
    },
    onSubmit,
    validationSchema: reviewvalidation,
  });

  useEffect(() => {
    if (myRevision.notification) {
      setShowToast(true);
      resetForm();
    }
  }, [myRevision.notification]);

  return (
    <Col className="mainarea">
      <div className="pagetitle">
        <BiArrowBack onClick={() => navigate(-1)} className="pagenavbutton" />
        <h1 className="title">
          Request Revision on Project ID: {project?.id}{" "}
        </h1>
      </div>
      <ToastContainer
        position="bottom-end"
        className="p-3"
        style={{ zIndex: 99999 }}>
        <Toast
          delay={3000}
          autohide
          className={
            myRevision.notification === "Revision Requested Successfully"
              ? "bg-success text-white"
              : "bg-danger text-white"
          }
          show={showToast}
          onClose={() => {
            setShowToast(false);
            setMyRevision({
              ...myRevision,
              notification: "",
            });
          }}>
          <Toast.Body>{myRevision.notification}</Toast.Body>
        </Toast>
      </ToastContainer>
      <Card className="itemscontainerwithtabs" style={{ height: "83vh" }}>
        <Card.Header as="h5" style={{ textAlign: "center" }}>
          {project?.title}
        </Card.Header>
        <Card.Body style={{ height: "70vh" }}>
          <form onSubmit={handleSubmit} encType="multipart/form-data">
            <Stack gap={3}>
              <label htmlFor="instructions" style={{ color: "green" }}>
                Give Brief Revision Instructions to your Writer
              </label>
              <textarea
                id="instructions"
                onChange={handleChange}
                cols={10}
                rows={7}></textarea>
              {errors.instructions && (
                <p className="error">{errors.instructions}</p>
              )}
              <label htmlFor="files" style={{ color: "green" }}>
                Additional Files
              </label>
              <input
                type="file"
                id="files"
                multiple
                onChange={(event) => {
                  const files = event.currentTarget.files;
                  const myfiles = [];
                  for (let i = 0; i < files.length; i++) {
                    myfiles.push(files[i]);
                  }
                  setFieldValue("reviewfiles", myfiles);
                }}
              />
              {errors.reviewfiles && (
                <p className="error">{errors.reviewfiles}</p>
              )}
              <div className="d-flex justify-content-center">
                <button
                  type="submit"
                  className="btn btn-sm btn-outline-success"
                  disabled={myRevision.loading || myRevision.loaded}>
                  {myRevision.loading ? (
                    <>
                      <Spinner as="span" animation="border" size="sm" />{" "}
                      <span>Posting...</span>
                    </>
                  ) : (
                    <span>Request Revision </span>
                  )}
                </button>
              </div>
            </Stack>
          </form>
        </Card.Body>
      </Card>
    </Col>
  );
}

export default RequestReview;
