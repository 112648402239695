import React from "react";
import { Col, Accordion } from "react-bootstrap";

function EmployerFaqs() {
  return (
    <div>
      <div className="pagetitle">
        <h1 className="title">Guide Lines</h1>
      </div>
      <div className="contents">
        <Accordion>
          <Accordion.Item eventKey="0">
            <Accordion.Header>
              {" "}
              <h6>Order Creation/Posting</h6>
            </Accordion.Header>
            <Accordion.Body>
              <ul>
                <li>Click on the "Post order" button</li>
                <li>
                  Fill in the details of your order, including the order title,
                  description, scope, requirements, and budget.
                </li>
                <li>
                  You can also upload any additional files that may be helpful
                </li>
                <li>Click on the "Post order" button</li>
                <li>
                  {" "}
                  If successful, you will be redirected to Unassigned orders
                </li>
              </ul>
            </Accordion.Body>
          </Accordion.Item>
          <Accordion.Item eventKey="1">
            <Accordion.Header>
              {" "}
              <h6>Order Type (Visibility)</h6>
            </Accordion.Header>
            <Accordion.Body>
              While Posting a order on the platform, you can choose between
              <ol>
                <li>
                  <b>Public orders</b>
                  <ul>
                    <li>
                      Public orders are visible to all writers/freelancers
                    </li>
                    <li>Any writer on the platform can bid on this orders</li>
                  </ul>
                </li>
                <li>
                  <b> Private Bid Orders</b>

                  <ul>
                    <li>
                      {" "}
                      <b>You Must have personal Writers to Post this orders</b>
                    </li>
                    <li>Private Bid orders are only visible to your writers</li>
                    <li>Only your writers can bid on this orders</li>
                  </ul>
                </li>
                <li>
                  <b>Private Take Orders</b>

                  <ul>
                    <li>
                      <b>You Must have personal Writers to Post this orders</b>
                    </li>
                    <li>
                      Private Take orders are only visible to your writers
                    </li>
                    <li>Only your writers can take on this orders</li>
                    <li>
                      Any of your writers can take this orders hence no need for
                      bidding
                    </li>
                    <li>
                      Before posting a private take order make sure that your
                      writers are well conversant
                    </li>
                  </ul>
                </li>
              </ol>
            </Accordion.Body>
          </Accordion.Item>
          <Accordion.Item eventKey="2">
            <Accordion.Header>
              {" "}
              <h6>Order Type (Payment)</h6>
            </Accordion.Header>
            <Accordion.Body>
              <ol>
                <li>
                  <b>Escrowed orders</b>
                  <ul>
                    <li>
                      These are all orders where You have to load funds to the
                      platform before awarding the order
                    </li>
                    <li>These can either be Private or Public orders</li>
                  </ul>
                </li>
                <li>
                  <b>PayLater Trusted orders</b>
                  <ul>
                    <li>
                      These are those orders which you have chosen to select a
                      future Payment Date.
                    </li>
                    <li>These orders Must be Private Bid orders</li>
                    <li>You Must be a Trusted Employer to post these orders</li>
                    <li>You Must have personal Writers to Post these orders</li>
                  </ul>
                </li>
              </ol>
            </Accordion.Body>
          </Accordion.Item>
          <Accordion.Item eventKey="3">
            <Accordion.Header>
              {" "}
              <h6>Order Flow</h6>
            </Accordion.Header>
            <Accordion.Body>
              <ol>
                <li>
                  <b>Unassigned orders</b>
                  <ul>
                    <li>
                      These are all orders you have posted on the platform which
                      you have not assigned to anyone or no writer has taken
                      them.
                    </li>
                  </ul>
                </li>
                <li>
                  <b>In Progress</b>
                  <ul>
                    <li>
                      These are all orders that have been assigned to a writer
                      and are yet to be completed.
                    </li>
                  </ul>
                </li>
                <li>
                  <b> On Revision</b>
                  <ul>
                    <li>
                      These are all orders that a writer has uploaded files but
                      the work was not satisfactory you request a writer to make
                      some revisions.
                    </li>
                  </ul>
                </li>
                <li>
                  <b>In Dispute</b>
                  <ul>
                    <li>
                      These are all orders that have been assigned to a writer
                      and have been on dispute.
                    </li>
                  </ul>
                </li>
                <li>
                  <b> Resolved</b>
                  <ul>
                    <li>
                      These are all orders that were previously in dispute and
                      have been resolved.
                    </li>
                  </ul>
                </li>
                <li>
                  <b>Completed</b>
                  <ul>
                    <li>
                      These are all orders that you have marked complete. It
                      means that the writers have completed the jobs and you are
                      satisfied with it.
                    </li>
                  </ul>
                </li>
              </ol>
            </Accordion.Body>
          </Accordion.Item>
          <Accordion.Item eventKey="16">
            <Accordion.Header>
              <h6>Essay's Host Wallet</h6>
            </Accordion.Header>
            <Accordion.Body>
              <ul>
                <li>
                  <b>Escrow Wallet</b>
                  <ol>
                    <li>
                      Holds funds for an order in progress, on revision or in
                      dispute
                    </li>
                    <li>
                      Funds in Escrow Wallet cannot be withdrawn by either
                      Writer or Employer
                    </li>
                  </ol>
                </li>
                <li>
                  <b>Unpaid amount</b>
                  <ol>
                    <li>
                      Funds for completed pay later Orders that you have not yet
                      paid for.
                    </li>
                  </ol>
                </li>
                <li>
                  <b>Wallet Balance</b>
                  <ol>
                    <li>
                      This are the funds which you have deposited to use in
                      awarding Projects.
                    </li>
                    <li>You can withdraw this amount</li>
                  </ol>
                </li>
                <li>
                  <b>Total Expenditure</b>
                  <ol>
                    <li>
                      This is the total amount of cash you have used on the
                      platform
                    </li>
                  </ol>
                </li>
              </ul>
            </Accordion.Body>
          </Accordion.Item>
          <Accordion.Item eventKey="4">
            <Accordion.Header>
              {" "}
              <h6>How To Add Personal Writers</h6>
            </Accordion.Header>
            <Accordion.Body>
              <ul>
                <li>
                  Personal Writers are Able to Bid and Take your Private orders
                </li>
                <li>Click on Writers on the Sidebar</li>
                <li>A list of all Writers on the platform Will be Displayed</li>
                <li>
                  Choose the Writer you want to add to your personal Writers
                  <ul>
                    <li>
                      NB: You can Search for the writer using his/her name
                    </li>
                  </ul>
                </li>
                <li>Click on the button indicated Writers Bio</li>
                <li>
                  It Will take you to a page with all the Writer's Information
                  click on the Add Button
                </li>
                <li>
                  A notification Will be sent to the Writer requesting him/her
                  to accept being your Writer
                </li>
                <li>
                  If the writer Accepts to become your Writer you will receive a
                  notification that they have accepted to be your writers
                </li>
                <li>These Writers will appear in the My Writers Page</li>
              </ul>
            </Accordion.Body>
          </Accordion.Item>
          <Accordion.Item eventKey="5">
            <Accordion.Header>
              {" "}
              <h6>Review Bids</h6>
            </Accordion.Header>
            <Accordion.Body>
              <ul>
                <li>Receive bids from interested writers</li>
                <li>
                  Review the bids and the profiles of writers to make an
                  informed decision.
                </li>
                <li>
                  Communicate with writers via chat to discuss order details
                  further.
                </li>
                <li>
                  Click on the "Award to writer" button to hire a writer for
                  your order
                </li>
              </ul>
            </Accordion.Body>
          </Accordion.Item>
          <Accordion.Item eventKey="6">
            <Accordion.Header>
              {" "}
              <h6>Order Award</h6>
            </Accordion.Header>
            <Accordion.Body>
              <ul>
                <li>
                  After clicking on the "Award" button, you will be redirected
                  to the award page
                </li>
                <li>
                  Here click on the award button to confirm the award of the
                  order to the writer
                </li>
                <li>
                  If you have Cash in your wallet funds equivalent to the bid
                  amount will be withdrawn and deposited to your Escrow Wallet.
                </li>
                <li>
                  If you do not have sufficient funds in your wallet, an STK
                  push will made to your phone requesting you to make a payment
                  this funds will be deposited to your escrow wallet.
                </li>
              </ul>
            </Accordion.Body>
          </Accordion.Item>
          <Accordion.Item eventKey="7">
            <Accordion.Header>
              {" "}
              <h6>Monitor Order Progress</h6>
            </Accordion.Header>
            <Accordion.Body>
              <ul>
                <li>
                  Communicate with the writer to address any questions or
                  concerns.
                </li>
                <li>
                  Keep track of the order's progress through our platform.
                </li>
              </ul>
            </Accordion.Body>
          </Accordion.Item>
          <Accordion.Item eventKey="8">
            <Accordion.Header>
              {" "}
              <h6>Re Assign an Order</h6>
            </Accordion.Header>
            <Accordion.Body>
              <ul>
                <li>
                  In the Event a Writer cannot handle an Order you had assigned
                  to them you can click the re assign button.
                </li>
                <li>
                  An Order can Only be re assigned only when the writer has not
                  submitted any files.
                </li>
                <li>
                  In the event a writer has submitted some files for an order
                  you can only request revision or alternatively dispute for sub
                  standard work.
                </li>
              </ul>
            </Accordion.Body>
          </Accordion.Item>
          <Accordion.Item eventKey="9">
            <Accordion.Header>
              {" "}
              <h6>Request Revision</h6>
            </Accordion.Header>
            <Accordion.Body>
              <ul>
                <li>
                  When a writer delivers work that falls below expectation you
                  can request them to make some revision by clicking the
                  revision button in the order in progress.
                </li>
                <li>
                  You will be redirected to a page where you will give the
                  writer instructions for making the revisions
                </li>
              </ul>
            </Accordion.Body>
          </Accordion.Item>
          <Accordion.Item eventKey="10">
            <Accordion.Header>
              {" "}
              <h6>Order Completion</h6>
            </Accordion.Header>
            <Accordion.Body>
              <ul>
                <li>
                  Once the writer has completed the order, you will receive a
                  notification via Email.
                </li>
                <li>
                  You can review the order and request for revisions if
                  necessary.
                </li>
                <li>
                  If you are satisfied with the order, click on the "Complete
                  order" button.
                </li>
                <li>
                  You will be redirected to the complete order page where you
                  can rate the writer and leave a review and finally complete
                  the order.
                </li>
                <li>
                  The funds will be released from your Escrow wallet to the
                  writer's wallet and the order will be marked as complete.
                </li>
              </ul>
            </Accordion.Body>
          </Accordion.Item>
          <Accordion.Item eventKey="11">
            <Accordion.Header>
              {" "}
              <h6>Justifications for Refunds and Disputes</h6>
            </Accordion.Header>
            <Accordion.Body>
              <ul>
                <li>
                  Plagiarism: The content delivered by the writer is found to be
                  plagiarized.
                </li>
                <li>
                  Late Delivery: The writer fails to meet the agreed-upon
                  deadline for order completion.
                </li>
                <li>
                  Incomplete Work: The delivered work is incomplete or
                  significantly lacks the required content.
                </li>
                <li>
                  Quality Issues: The quality of the writing does not meet the
                  employer's standards or expectations.
                </li>
                <li>
                  Communication Problems: Lack of communication or
                  unresponsiveness from the writer during the order.
                </li>
                <li>
                  Missed Revisions: The writer does not address requested
                  revisions or modifications as outlined by the employer.
                </li>
                <li>
                  Non-Compliance with Instructions: The writer disregards
                  specific instructions provided by the employer regarding
                  formatting, style, or content.
                </li>
                <li>
                  Failure to Respect Deadlines: The writer consistently fails to
                  meet agreed-upon milestones or interim deadlines for the
                  order.
                </li>
              </ul>
            </Accordion.Body>
          </Accordion.Item>
          <Accordion.Item eventKey="12">
            <Accordion.Header>
              {" "}
              <h6>Dispute Resolution</h6>
            </Accordion.Header>
            <Accordion.Body>
              <ul>
                <li>
                  In case of a dispute, the employer and writer will attempt to
                  resolve the issue between themselves by providing evidence.
                </li>
                <li>
                  The employer and writer will have the option to communicate
                  through the platform's chat.
                </li>
                <li>
                  Only the Writer or Admin can resolve a dispute put forward by
                  an Employer
                </li>
              </ul>
            </Accordion.Body>
          </Accordion.Item>
          <Accordion.Item eventKey="13">
            <Accordion.Header>
              {" "}
              <h6>Writer Accepts and Refunds</h6>
            </Accordion.Header>
            <Accordion.Body>
              <ul>
                <li>
                  In case of a dispute, the writer has the option to accept the
                  dispute and agree to refund the employer.
                </li>
              </ul>
            </Accordion.Body>
          </Accordion.Item>
          <Accordion.Item eventKey="14">
            <Accordion.Header>
              {" "}
              <h6>Admin Resolution</h6>
            </Accordion.Header>
            <Accordion.Body>
              <ul>
                <li>
                  In case of a dispute, the admin will review the order and make
                  a decision on the best course of action.
                </li>
                <li>
                  If the writer and employer cannot come to an agreement or if
                  the dispute is complex, the platform admin will step in to
                  assess the situation.
                </li>
                <li>
                  The admin may choose to refund the employer or release the
                  funds to the writer.
                </li>
              </ul>
            </Accordion.Body>
          </Accordion.Item>
          <Accordion.Item eventKey="15">
            <Accordion.Header>
              {" "}
              <h6>Communication </h6>
            </Accordion.Header>
            <Accordion.Body>
              <ul>
                <li>
                  You can communicate to the writer through the chat feature, NB
                  chats are created based on orders.
                </li>
                <li>
                  Sharing of personal information is strictly Prohibited on the
                  platform.
                </li>
                <li>
                  To avoid writers spamming your inbox you initiate the chats
                  from bids made on a order.
                </li>
                <li>
                  Unless you have awarded the order to the writer and you have
                  not communicated about the order initially the writer can be
                  able to initiate a chat with you.
                </li>
                <li>
                  You can communicate to the admin through email
                  info@essayshost.com
                </li>
              </ul>
            </Accordion.Body>
          </Accordion.Item>
        </Accordion>
      </div>
    </div>
  );
}

export default EmployerFaqs;
