import React, {useState} from 'react'
import axios from 'axios';
import { Col, Row, Spinner } from "react-bootstrap"
import { useNavigate } from 'react-router-dom';
import FileUpload from './global/FileUpload';
function Similarity() {
  const navigate = useNavigate()
    const [text, setText] = useState(
        ''
  );    
  const [checked, setChecked] = useState([]);
     const [generating, setGenerating] = useState(false);
     const makeRequest = async (text, title) => {
       setGenerating(true);
       const options = {
           url: "https://api.edenai.run/v2/text/plagia_detection",
         data: {
           providers: "originalityai",
             text: text,
           title: title
         },
       };
       const config = {
         headers: {
           Authorization:
             "Bearer eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJ1c2VyX2lkIjoiYWRlNjRiNTQtMDI4OS00ZDg2LThjNDItZGJiMWM3MTViNDAzIiwidHlwZSI6ImFwaV90b2tlbiJ9.YH5qsRGXnSktAp18ukvJVZMQGQwZu-S8NfYNrb_35yI",
         },
       };
       try {
         const res = await axios.post(options.url, options.data, config);
         console.log(res);
         setGenerating(false);
         return res;
       } catch (err) {
         console.log(err);
         return err;
       }
     };
  return (
    <div>
      <div className="pagetitle" id="mymaintitle">
        <h1 className="title">Check for Plagiarism</h1>
      </div>
    <Col>
      <Row>
        <Col>
          {text && (
            <button
              disabled={generating}
              className="btn btn-sm btn-success"
              onClick={() => {
                makeRequest(text);
              }}>
              {generating ? (
                <>
                  <Spinner as="span" variant="border" size="sm" />
                  ...Checking
                </>
              ) : (
                <>Check</>
              )}
            </button>
          )}
          {checked.length > 0 && (
            <div>
              {checked.map((item, index) => (
                <div key={index}>
                  <h6>
                    {item.status} SIMILARITY SCORE:{" "}
                    {Math.round(Number(item.ai_score) * 100)}%
                  </h6>
                  <button
                    onClick={() => {
                      localStorage.setItem("aicheck", JSON.stringify(item));
                      navigate("/employer/ai_check_view");
                    }}>
                    Details
                  </button>
                </div>
              ))}
            </div>
          )}
        </Col>
      </Row>
      <FileUpload text={text} setText={setText} />
    </Col>
    </div>
  );
}

export default Similarity