import * as action from "./types";
import axios from "axios";

export const postproject = (formdata) => async (dispatch) => {
  const config = {
    headers: {
      Authorization: `JWT ${localStorage.getItem("access")}`,
      Accept: "application/json",
    },
  };

  try {
    const message = "Project Successfully Posted";
    const res = await axios.post(
      `${process.env.REACT_APP_API_URL}/accounts/project`,
      formdata,
      config
    );
    dispatch({
      type: action.POST_PROJECT_SUCCESS,
      payload: res.data,
    });
    return message;
  } catch (err) {
    const message = "Posting Project Failed";
    dispatch({
      type: action.POST_PROJECT_FAIL,
    });
    console.log(err);
    return message;
  }
};
