import React from "react";
import emptyfolder from "../../images/dangerous.png";


function Empty({ message }) {
  return (
    <div
      style={{ height: "70vh" }}
      className="d-flex justify-content-center align-items-center">
      <div className="emptyfolderContainer d-flex justify-content-center align-items-center">
        <div>
          <div className="d-flex justify-content-center align-items-center">
            <img src={emptyfolder} alt="emptyfolder" className="emptyfolder" />
          </div>
          <button
            mt={2}
            disabled="true"
            className="text-center btn btn-sm btn-dark"
            style={{ color: "white" }}>
            {message}
          </button>
        </div>
      </div>
    </div>
  );
}

export default Empty;
