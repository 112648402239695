import axios from "axios";
import * as action from "./types";

export const load_user = () => async (dispatch) => {
  if (localStorage.getItem("access")) {
    const config = {
      headers: {
        "Content-Type": "application/json",
        Authorization: `JWT ${localStorage.getItem("access")}`,
        Accept: "application/json",
      },
    };

    try {
      const res = await axios.get(
        `${process.env.REACT_APP_API_URL}/auth/users/me/`,
        config
      );
      dispatch({
        type: action.USER_LOADED_SUCCESS,
        payload: res.data,
      });
    } catch {
      dispatch({
        type: action.USER_LOADED_FAIL,
      });
    }
  } else {
    dispatch({
      type: action.USER_LOADED_FAIL,
    });
  }
};

export const checkAuthenticated = () => async (dispatch) => {
  if (localStorage.getItem("access")) {
    const config = {
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
    };
    const body = JSON.stringify({ token: localStorage.getItem("access") });
    try {
      const res = await axios.post(
        `${process.env.REACT_APP_API_URL}/auth/jwt/verify/`,
        body,
        config
      );

      if (res.data.code !== "token_not_valid") {
        dispatch({
          type: action.AUTHENTICATED_SUCCESS,
        });
        dispatch(load_user());
      } else {
        dispatch({
          type: action.AUTHENTICATED_FAIL,
        });
      }
    } catch (err) {
      dispatch({
        type: action.AUTHENTICATED_FAIL,
      });
    }
  } else {
    dispatch({
      type: action.AUTHENTICATED_FAIL,
    });
  }
};

export const login = (email, password) => async (dispatch) => {
  const config = {
    headers: {
      "Content-Type": "application/json",
    },
  };
  const body = JSON.stringify({ email, password });
  try {
    const message = "Login Successfull";
    const res = await axios.post(
      `${process.env.REACT_APP_API_URL}/auth/jwt/create/`,
      body,
      config
    );
    dispatch({
      type: action.LOGIN_SUCCESS,
      payload: res.data,
    });
    dispatch(load_user());
    return message;
  } catch (err) {
    const message = "Invalid Credentials or Unverified Account";
    console.log(err);
    dispatch({
      type: action.LOGIN_FAIL,
    });
    return message;
  }
};

export const signup =
  (email, first_name, last_name, phone_no, role, password, re_password, country) =>
  async (dispatch) => {
    const config = {
      headers: {
        "Content-Type": "application/json",
      },
    };


    // const phone = phone_no.startsWith("+254")
    //   ? phone_no.replace(/^\+254/, "")
    //   : phone.startsWith("+")
    //   ? phone.substring(1)
    //   : phone;
    
   
   
   
    localStorage.setItem("email", email);
    const body = JSON.stringify({
      email,
      first_name,
      last_name,
      phone_no,
      role,
      password,
      re_password,
      country
    });
   
    try {
      const message = "Success";
      const res = await axios.post(
        `${process.env.REACT_APP_API_URL}/auth/users/`,
        body,
        config
      );
      console.log(res.data);
      dispatch({
        type: action.SIGNUP_SUCCESS,
        payload: res.data,
      });
      dispatch(load_user());
      return message;
    } catch (err) {
      const message = "Non Unique Credentials or User Exists";
      console.log(err);
      dispatch({
        type: action.SIGNUP_FAIL,
      });
      return message;
    }
  };

export const verify = (uid, token) => async (dispatch) => {
  const config = {
    headers: {
      "Content-Type": "application/json",
    },
  };
  console.log(process.env.REACT_APP_API_URL);
  const body = JSON.stringify({ uid, token });
  try {
    await axios.post(
      `${process.env.REACT_APP_API_URL}/auth/users/activation/`,
      body,
      config
    );
    dispatch({
      type: action.ACTIVATION_SUCCESS,
    });
    dispatch(load_user());
  } catch (err) {
    dispatch({
      type: action.ACTIVATION_FAIL,
    });
  }
};

export const reset_password = (email) => async (dispatch) => {
  const config = {
    headers: {
      "Content-Type": "application/json",
    },
  };

  const body = JSON.stringify({ email });

  try {
    await axios.post(
      `${process.env.REACT_APP_API_URL}/auth/users/reset_password/`,
      body,
      config
    );

    dispatch({
      type: action.PASSWORD_RESET_SUCCESS,
    });
  } catch {
    dispatch({
      type: action.PASSWORD_RESET_FAIL,
    });
  }
};

export const reset_password_confirm =
  (uid, token, new_password, re_new_password) => async (dispatch) => {
    const config = {
      headers: {
        "Content-Type": "application/json",
      },
    };
    const body = JSON.stringify({ uid, token, new_password, re_new_password });

    try {
      await axios.post(
        `${process.env.REACT_APP_API_URL}/auth/users/reset_password_confirm/`,
        body,
        config
      );

      dispatch({
        type: action.PASSWORD_RESET_CONFIRM_SUCCESS,
      });
    } catch {
      dispatch({
        type: action.PASSWORD_RESET_CONFIRM_FAIL,
      });
    }
  };

export const logout = () => async (dispatch) => {
  const config = {
    headers: {
      Authorization: `JWT ${localStorage.getItem("access")}`,
      "Content-Type": "application/json",
      Accept: "application/json",
    },
  };
  const online_url = `${process.env.REACT_APP_API_URL}/accounts/online_check`;
  const online = false;
  const body = JSON.stringify(online);
  try {
    const onlineCheck = await axios.post(online_url, body, config);
    dispatch({
      type: action.LOGOUT,
    });
    localStorage.removeItem("access");
    console.log(onlineCheck);
    return onlineCheck;
  } catch (err) {
    console.log(err);
    return err;
  }
};
