import React, { useState } from 'react'
import axios from 'axios';
import FileUpload from './global/FileUpload';
import { Col, Spinner, Row } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';

function AiDetect() {
  const navigate= useNavigate()
    const [generating, setGenerating] = useState(false)
  const [text, setText] = useState("")
  const [checked, setChecked] = useState([])
    const makeRequest = async (text) => {
      setGenerating(true);
      const options = {
        url: "https://api.edenai.run/v2/text/ai_detection",
        data: {
          providers: "winstonai",
          text: text,
        },
      };
      const config = {
        headers: {
          Authorization:
            "Bearer eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJ1c2VyX2lkIjoiYWRlNjRiNTQtMDI4OS00ZDg2LThjNDItZGJiMWM3MTViNDAzIiwidHlwZSI6ImFwaV90b2tlbiJ9.YH5qsRGXnSktAp18ukvJVZMQGQwZu-S8NfYNrb_35yI",
        },
      };
      try {
        const res = await axios.post(options.url, options.data, config);
        console.log(res);
        setChecked([...checked, res.data.winstonai])
        setGenerating(false);
        return res;
      } catch (err) {
        console.log(err);
        return err;
      }
    };
    
  return (
    <Col>
      <div className="pagetitle" id="mymaintitle">
        <h1 className="title">Check for AI Content</h1>
      </div>
      <Row>
        <Col>
          {text && (
            <button
              disabled={generating}
              className="btn btn-sm btn-success"
              onClick={() => {
                makeRequest(text);
              }}>
              {generating ? (
                <>
                  <Spinner as="span" variant="border" size="sm" />
                  ...Checking
                </>
              ) : (
                <>Check</>
              )}
            </button>
          )}
          {checked.length > 0 && (
            <div>
              {checked.map((item, index) => (
                <div key={index}>
                  <h6>
                    {item.status} AI SCORE:{" "}
                    {Math.round(Number(item.ai_score) * 100)}%
                  </h6>
                  <button
                    onClick={() => {
                      localStorage.setItem("aicheck", JSON.stringify(item));
                      navigate("/employer/ai_check_view");
                    }}>
                    Details
                  </button>
                </div>
              ))}
            </div>
          )}
        </Col>
      </Row>
      <FileUpload text={text} setText={setText} />
    </Col>
  );
}

export default AiDetect