import React, { useState } from "react";
import { Col, Accordion } from "react-bootstrap";

import { Link } from "react-router-dom";
import useFetch from "../../customHooks/useFetch";

import { useNavigate } from "react-router-dom";
import Empty from "../global/Empty";
import Busy from "../global/Busy";

import WriterProject from "./writerProject/WriterProject";

function ForRevision() {
  const [state, setState] = useState({
    currentpage: 1,
    postsperpage: 15,
    loading: false,
  });

  const url = `${process.env.REACT_APP_API_URL}/accounts/list_revision_projects`;
  const navigate = useNavigate();
  const projects = useFetch(url, navigate);

  let currentProjects = [];
  // Implement Page Numbers
  const pageNumbers = [];
  if (!projects.loading && projects.loading !== undefined) {
    const indexOfLastProject = state.currentpage * state.postsperpage;
    const indexOfFirstProject = indexOfLastProject - state.postsperpage;
    currentProjects = projects.data.slice(
      indexOfFirstProject,
      indexOfLastProject
    );

    // Depending on the length of the projects available implement the page numbers
    for (
      let i = 1;
      i <= Math.ceil(projects.data.length / state.postsperpage);
      i++
    ) {
      pageNumbers.push(i);
    }
  }

  const setPage = (num) => {
    setState({ ...state, currentpage: num });
  };
  return (
    <Col className="mainarea">
      {projects.loading ? (
        <Busy />
      ) : (
        <>
          <div className="pagetitle">
            <h1 className="title">
              Orders On Revision {projects.data.length}
            </h1>
          </div>
          <Accordion>
            <Accordion.Item>
              <Accordion.Header className="accordionheader">
                <table>
                  <col style={{ width: "20%" }} />
                  <col style={{ width: "40%" }} />
                  <col style={{ width: "40%" }} />
                  <thead>
                    <th> ID</th>
                    <th>FIELD</th>
                    <th>BUDGET </th>
                  </thead>
                </table>
              </Accordion.Header>
            </Accordion.Item>
          </Accordion>
          <Accordion>
            {currentProjects.length === 0 ? (
              <Empty message="No Orders In Revision" />
            ) : (
                <div  className="contentarea">
                {currentProjects.map((project) => {
                  return (
                    <Accordion.Item eventKey={project.id} key={project.id}>
                      <WriterProject project={project}>
                        <div className="d-flex justify-content-between">
                          <Link
                            to="/writer/completedupload"
                            className="btn btn-outline-success btn-sm"
                            onClick={() => {
                              localStorage.setItem(
                                "projectUpload",
                                JSON.stringify(project)
                              );
                            }}>
                            Upload Files
                          </Link>
                          <Link
                            to="/writer/revisioninstructions"
                            className="btn btn-outline-success btn-sm"
                            onClick={() => {
                              localStorage.setItem(
                                "revisionProject",
                                JSON.stringify(project)
                              );
                            }}>
                            View Revision Instructions
                          </Link>
                        </div>
                      </WriterProject>
                    </Accordion.Item>
                  );
                })}
              </div>
            )}
          </Accordion>
          <div className="paginationcontainer d-flex justify-content-center">
            {pageNumbers.map((pageNumber, index) => (
              <span
                key={index}
                className={
                  pageNumber === state.currentpage
                    ? "text-light bg-success paginationitem"
                    : "text-dark bg-light paginationitem"
                }
                onClick={() => {
                  setPage(pageNumber);
                }}>
                {pageNumber}
              </span>
            ))}
          </div>
        </>
      )}
    </Col>
  );
}

export default ForRevision;
