import axios from "axios";
import React, { useEffect, useState } from "react";
import {
  Accordion,
  Col,
  Spinner,
  Stack,
  Modal,
  Toast,
  ToastContainer,
  Row,
  NavDropdown
} from "react-bootstrap";
import { Link, useNavigate } from "react-router-dom";
import * as actions from "../../actions/types";
import { useDispatch } from "react-redux";
import {
  BsFillCalendarDateFill,
  BsStack,
} from "react-icons/bs";
import { HiOutlineAcademicCap } from "react-icons/hi";
import { AiOutlineFileText, AiOutlineClockCircle } from "react-icons/ai";
import { RxSpaceEvenlyVertically } from "react-icons/rx";
import { SlSpeech } from "react-icons/sl";
import parse from "html-react-parser";
import moment from "moment";
import { postData } from "../../customHooks/postdata";
import { connect } from "react-redux";
import Empty from "../global/Empty";
import Busy from "../global/Busy";
import ReconnectingWebSocket from "reconnecting-websocket";
import { GoRepoLocked } from "react-icons/go";
import { LuBookOpenCheck } from "react-icons/lu";
import { RiDeleteBinLine, RiEdit2Line } from "react-icons/ri";
import {

  FacebookShareButton,
  
   TwitterShareButton,
  WhatsappShareButton,
  
  FacebookIcon,

   XIcon,
   WhatsappIcon,
 
} from "react-share";
import { GiThorHammer } from "react-icons/gi";
import { BiArrowToLeft, BiArrowToRight } from "react-icons/bi";
import { useSelector } from "react-redux";


function Unassigned({ user }) {
  const country = useSelector((state)=> state.userCountry.country)
  const [projects, setProjects] = useState({
    currentPage: 1,
    myProjects: [],
    loading: true,
    postsPerPage: 15,
  });
  const [state, setState] = useState({
    loading: false,
    message: "",
  });

  const [showToast, setShowToast] = useState(false);

  const [socket, setSocket] = useState(null);
  const [projectAddSocket, setProjectAddSocket] = useState(null);

  useEffect(() => {
    const newSocket = new ReconnectingWebSocket(
      `${process.env.REACT_APP_WEBSOCKET_URL}/ws/bid_add/${user.id}`
    );
    const projectAddSocket = new ReconnectingWebSocket(
      `${process.env.REACT_APP_WEBSOCKET_URL}/ws/project_add/${user.id}`
    );
    setSocket(newSocket);
    setProjectAddSocket(projectAddSocket);

    return () => {
      newSocket.close();
      projectAddSocket.close();
    };
  }, []);

  console.log(user.id);

  useEffect(() => {
    if (socket) {
      socket.addEventListener("message", (e) => {
        const data = JSON.parse(e.data);
        console.log(data);

        get_my_projects();
      });
    }
  }, [socket]);

  useEffect(() => {
    if (projectAddSocket) {
      projectAddSocket.addEventListener("message", (e) => {
        const data = JSON.parse(e.data);
        console.log(data);
        get_my_projects();
      });
    }
  }, [projectAddSocket]);

  const navigate = useNavigate();

  const dispatch = useDispatch();
  const get_my_projects = async () => {
    const config = {
      headers: {
        Authorization: `JWT ${localStorage.getItem("access")}`,
        "Content-Type": "application/json",
        Accept: "application/json",
      },
    };

    try {
      const res = await axios.get(
        `${process.env.REACT_APP_API_URL}/accounts/employer_projects`,
        config
      );
      setProjects({ ...projects, myProjects: res.data, loading: false });
      dispatch({
        type: actions.EMPLOYER_UNASSIGNED_PROJECTS,
        payload: res.data,
      });
    } catch (err) {
      navigate("/register/login");
      console.log(err);
    }
  };

  useEffect(() => {
    get_my_projects();
  }, []);

  const deletUrl = `${process.env.REACT_APP_API_URL}/accounts/delete_project`;
  const [projectid, setProjectid] = useState("");
  const [show, setShow] = useState(false);

  const deleteproject = () => {
    setState({ ...state, loading: true });
    postData(deletUrl, projectid)
      .then((res) => {
        const message = "Project Successfully Deleted";
        setState({ message: message, loading: false });
        setShow(false);
        get_my_projects();
      })
      .catch((err) => {
        const message = "Project Could not be Deleted";
        setState({ message: message, loading: false });
        setShow(false);
      });
  };

  const showModal = (id) => {
    setProjectid(id);
    setShow(true);
  };

  const indexLastProject = projects.currentPage * projects.postsPerPage;
  const indexFirstProject = indexLastProject - projects.postsPerPage;
  const displayProjects = projects.myProjects.slice(
    indexFirstProject,
    indexLastProject
  );

  const pageNum = [];

  for (
    let i = 1;
    i <= Math.ceil(projects.myProjects.length / projects.postsPerPage);
    i++
  ) {
    pageNum.push(i);
  }
  const setPage = (number) => {
    setProjects({ ...projects, currentPage: number });
  };
  console.log(displayProjects);

  useEffect(() => {
    if (state.message) {
      setShowToast(true);
    }
  }, [state.message]);

  return (
    <div>
      <ToastContainer position="top-end" style={{ zIndex: 99999 }}>
        <Toast
          autohide
          delay={3000}
          className={
            state.message === "Project Successfully Deleted"
              ? "bg-success text-white"
              : "bg-danger text-white"
          }
          show={state.message}
          onClose={() => {
            setState({ ...state, message: "" });
          }}>
          <Toast.Body>{state.message}</Toast.Body>
        </Toast>
      </ToastContainer>
      {projects.loading ? (
        <Busy />
      ) : (
        <>
          <div className="pagetitle">
            <h1 className="title">
              Unassigned Orders {projects.myProjects.length}
            </h1>
          </div>
          <div>
            <Accordion>
              <Accordion.Item eventKey="0">
                <Accordion.Header className="accordionheader">
                  <table style={{ width: "100%" }}>
                    <col style={{ width: "10%" }} />
                    <col style={{ width: "20%" }} />
                    <col style={{ width: "20" }} />
                    <col style={{ width: "40%" }} />
                    <col style={{ width: "10%" }} />

                    <thead>
                      <th> ID</th>
                      <th>FIELD</th>
                      <th>TYPE</th>
                      <th>BUDGET</th>
                      <th>BIDS</th>
                    </thead>
                  </table>
                </Accordion.Header>
              </Accordion.Item>
              {projects.myProjects.length === 0 ? (
                <Empty message="No Unassigned Orders" />
              ) : (
                <div className="contentarea">
                  {displayProjects.map((project) => {
                    //get today's date and time
                    var now = new Date().getTime();
                    //convert the json date to milliseconds
                    var deadline = new Date(`${project.deadline}`).getTime();

                    var remainingtime = deadline - now;

                    function daysminhours(milliseconds) {
                      let seconds = Math.floor(milliseconds / 1000);
                      let minutes = Math.floor(seconds / 60);
                      let hours = Math.floor(minutes / 60);
                      let days = Math.floor(hours / 24);

                      seconds = seconds % 60;
                      // 👇️ if seconds are greater than 30, round minutes up (optional)
                      minutes = seconds >= 30 ? minutes + 1 : minutes;

                      minutes = minutes % 60;

                      hours = hours % 24;

                      if (days > 0) {
                        return `${days}dys: ${hours}hrs : ${minutes}mins`;
                      } else if (hours > 0) {
                        return ` ${hours}hrs : ${minutes}mins`;
                      } else {
                        return `${minutes}mins`;
                      }
                    }

                    return (
                      <Accordion.Item eventKey={project.id} key={project.id}>
                        <Accordion.Header>
                          <table style={{ width: "100%" }}>
                            <col style={{ width: "10%" }} />
                            <col style={{ width: "20%" }} />
                            <col style={{ width: "20" }} />
                            <col style={{ width: "40%" }} />
                            <col style={{ width: "10%" }} />

                            <tbody>
                              <td
                                style={{
                                  color: "green",
                                  fontWeight: "bold",
                                }}>
                                #{project.id}
                              </td>
                              <td className="single-line">{project.subject}</td>
                              <td
                                style={
                                  project.private
                                    ? {
                                        display: "flex",
                                        color: "#006a78",
                                        alignItems: "center",
                                      }
                                    : {
                                        display: "flex",
                                        color: "green",
                                        alignItems: "center",
                                      }
                                }>
                                {project.private ? (
                                  <>
                                    <GoRepoLocked
                                      size={window.innerWidth < 768 ? 12 : 17}
                                    />{" "}
                                    Private
                                  </>
                                ) : (
                                  <>
                                    <LuBookOpenCheck
                                      size={window.innerWidth < 768 ? 12 : 17}
                                    />{" "}
                                    Public
                                  </>
                                )}
                              </td>
                              <td>
                                {project.take ? (
                                  <>
                                    {country === "Kenya" ? <>Kshs</> : <>USD</>}{" "}
                                    {project.amount}
                                  </>
                                ) : (
                                  <>
                                    {country === "Kenya" ? <>Kshs</> : <>USD</>}{" "}
                                    {project.bidlow} - {project.bidhigh}/=
                                  </>
                                )}
                              </td>

                              <td>
                                {project.take ? (
                                  <span>Take</span>
                                ) : project.bids_on_project.length > 1 ? (
                                  <span>
                                    {project.bids_on_project.length} Bids
                                  </span>
                                ) : (
                                  <span>
                                    {project.bids_on_project.length} Bid
                                  </span>
                                )}
                              </td>
                            </tbody>
                          </table>
                        </Accordion.Header>
                        <Accordion.Body>
                          <Row>
                            <Col lg={4} md={4} sm={12} className="mb-3">
                              <h6 className="papertype">{project.papertype}</h6>{" "}
                            </Col>
                            <Col
                              lg={4}
                              md={4}
                              sm={12}
                              className={
                                window.innerWidth > 768
                                  ? "mb-3 d-flex justify-content-center"
                                  : "mb-3"
                              }>
                              {project.trusted ? (
                                <h6 className="projecttrusted">
                                  Payment Date:{" "}
                                  {moment(project.paymentdate).format(
                                    "MMM Do YYYY"
                                  )}
                                </h6>
                              ) : (
                                <h6 className="projectescrow">Escrow</h6>
                              )}
                            </Col>
                            <Col lg={4} md={4} sm={12} className="textAligner">
                              <h6 style={{ color: "#006a78" }}>
                                <AiOutlineClockCircle className="projecticon" />
                                <span className="projectintext">
                                  Remaining:{" "}
                                </span>
                                {remainingtime <= 1000
                                  ? "Expired"
                                  : daysminhours(remainingtime)}
                              </h6>
                              <Stack
                                direction="horizontal"
                                gap={2}
                                className="d-flex justify-content-end align-items-center">
                                Share Order:
                                <WhatsappShareButton
                                  title={`Share Order ID #${
                                    project.id
                                  } \nTitle: ${project.title} \nField: ${
                                    project.papertype
                                  } \nSubject: ${
                                    project.subject
                                  }  \nBudget Kshs ${
                                    project.bidlow
                                  }-${project.bidhigh} \nDeadline ${moment(
                                    project.deadline
                                  ).format("MMM Do YYYY HH:MM")} \n`}
                                  url="https://essayshost.com">
                                  <WhatsappIcon size={20} round={true} />
                                </WhatsappShareButton>
                                <TwitterShareButton
                                  title={`Share Order ID #${
                                    project.id
                                  } \nTitle: ${project.title} \nField: ${
                                    project.papertype
                                  } \nSubject: ${
                                    project.subject
                                  }  \nBudget Kshs ${
                                    project.bidlow
                                  }-${project.bidhigh} \nDeadline ${moment(
                                    project.deadline
                                  ).format("MMM Do YYYY HH:MM")} \n`}
                                  url="https://essayshost.com">
                                  <XIcon size={20} round={true} />
                                </TwitterShareButton>
                                <FacebookShareButton
                                  hashtag={`#Share Order ID #${project.id} \nTitle: ${project.title} \nField: ${project.papertype} \nSubject: ${project.subject}  \nBudget Kshs ${project.bidlow}-${project.bidhigh}  \n`}
                                  url="https://essayshost.com">
                                  <FacebookIcon size={20} round={true} />
                                </FacebookShareButton>
                              </Stack>
                            </Col>
                          </Row>
                          <hr />
                          <h6 className="text-center projecttitle">
                            {"  "}
                            {project.title}
                          </h6>
                          <p>{parse(project.description)}</p>
                          <hr />
                          {project.project_files.length > 0 ? (
                            <h6 style={{ color: "#006a78" }}>
                              Project Attachments
                            </h6>
                          ) : (
                            <h6 style={{ color: "#006a78" }}>No Attachments</h6>
                          )}
                          {project.project_files.length > 0 &&
                            project.project_files.map((myfile) => {
                              return (
                                <Stack>
                                  <a
                                    key={myfile.id}
                                    href={myfile.project_file}
                                    download>
                                    {myfile.filename}
                                  </a>
                                </Stack>
                              );
                            })}
                          <hr />
                          <Row>
                            <Col lg={6} sm={12}>
                              <h6 style={{ color: "#006a78" }}>
                                <AiOutlineFileText className="projecticon" />
                                <span className="projectintext">
                                  {project.papertype == "Presentation"
                                    ? "Slides"
                                    : "Pages"}
                                </span>{" "}
                                {project.pages}{" "}
                                {project.papertype !== "Presentation"
                                  ? project.spacing === "Single Spacing"
                                    ? `Aprox ${project.pages * 500} Words`
                                    : `Aprox ${project.pages * 250} Words`
                                  : ""}
                              </h6>
                            </Col>
                            <Col lg={6} sm={12}>
                              <h6 style={{ color: "#006a78" }}>
                                <AiOutlineFileText className="projecticon" />
                                <span className="projectintext">
                                  Format:
                                </span>{" "}
                                {project.formatstyle}
                              </h6>
                            </Col>
                            <Col lg={6} sm={12}>
                              <h6 style={{ color: "#006a78" }}>
                                <RxSpaceEvenlyVertically className="projecticon" />
                                <span className="projectintext">Spacing:</span>{" "}
                                {project.spacing}
                              </h6>
                            </Col>

                            <Col lg={6} sm={12}>
                              <h6 style={{ color: "#006a78" }}>
                                <HiOutlineAcademicCap className="projecticon" />
                                <span className="projectintext">
                                  Level:
                                </span>{" "}
                                {project.level}
                              </h6>
                            </Col>
                            <Col lg={6} sm={12}>
                              <h6 style={{ color: "#006a78" }}>
                                <SlSpeech className="projecticon" />
                                <span className="projectintext">
                                  Language:
                                </span>{" "}
                                {project.language}
                              </h6>
                            </Col>

                            <Col lg={6} sm={12}>
                              <h6 style={{ color: "#006a78" }}>
                                <BsFillCalendarDateFill className="projecticon" />{" "}
                                <span className="projectintext">Posted:</span>{" "}
                                {moment(project.timestamp).format(
                                  "MMM Do YYYY, h:mm a"
                                )}
                              </h6>
                            </Col>
                            <Col lg={6} sm={12}>
                              <h6 style={{ color: "#006a78" }}>
                                <BsFillCalendarDateFill className="projecticon" />{" "}
                                <span className="projectintext">Due: </span>
                                {moment(project.deadline).format(
                                  "MMM Do YYYY, h:mm A"
                                )}
                              </h6>
                            </Col>
                            <Col lg={6} sm={12}>
                              <h6 style={{ color: "#006a78" }}>
                                <BsStack className="projecticon" />
                                <span className="projectintext">
                                  Sources:
                                </span>{" "}
                                {project.sources}
                              </h6>
                            </Col>
                          </Row>
                          <hr />
                          <div className="d-flex justify-content-around">
                            {project.take ? (
                              <h6 style={{ color: "#006a78" }}>Take Project</h6>
                            ) : project.bids_on_project.length > 0 ? (
                              <Link
                                to="/employer/bids"
                                className="btn btn-outline-success btn-sm"
                                onClick={() => {
                                  localStorage.setItem(
                                    "biddedProject",
                                    JSON.stringify(project)
                                  );
                                }}>
                                <GiThorHammer />
                                Bids
                              </Link>
                            ) : (
                              <h6 style={{ color: "#006a78" }}>
                                No Bids Placed
                              </h6>
                            )}

                            <Link
                              to="/employer/editproject"
                              className="btn btn-outline-success btn-sm"
                              onClick={() => {
                                localStorage.setItem(
                                  "project",
                                  JSON.stringify(project)
                                );
                              }}>
                              <RiEdit2Line />
                              Edit
                            </Link>
                            <button
                              className="btn btn-outline-danger btn-sm"
                              onClick={() => {
                                showModal(project.id);
                              }}>
                              <RiDeleteBinLine />
                              Delete
                            </button>
                          </div>
                        </Accordion.Body>
                      </Accordion.Item>
                    );
                  })}
                </div>
              )}
            </Accordion>

            <div className="paginationcontainer d-flex justify-content-center">
              {projects.currentPage > 1 && ( // Only show left arrow if not on first page
                <span
                  onClick={() => setPage(projects.currentPage - 1)}
                  className="text-dark bg-light paginationitem">
                  <BiArrowToLeft />
                </span>
              )}

              {/* Display only 3 page numbers around the current page */}
              {Array.from({ length: 3 }, (_, i) => i + projects.currentPage - 1)
                .filter(
                  (pageIndex) => pageIndex >= 0 && pageIndex < pageNum.length
                ) // Ensure indexes are valid
                .map((pageIndex) => (
                  <span
                    key={pageNum[pageIndex]} // Use page number for unique key
                    onClick={() => setPage(pageNum[pageIndex])}
                    className={
                      pageNum[pageIndex] === projects.currentPage
                        ? "text-light bg-success paginationitem"
                        : "text-dark bg-light paginationitem"
                    }>
                    {pageNum[pageIndex]}
                  </span>
                ))}

              {projects.currentPage < pageNum.length && ( // Only show right arrow if not on last page
                <span
                  onClick={() => setPage(projects.currentPage + 1)}
                  className="text-dark bg-light paginationitem">
                  <BiArrowToRight />
                </span>
              )}
            </div>
          </div>
        </>
      )}

      {/* <div className="paginationcontainer d-flex justify-content-center">
        {pageNum.map((num) => {
          return (
            <span
              onClick={() => {
                setPage(num);
              }}
              className={
                num === projects.currentPage
                  ? "text-light bg-success paginationitem"
                  : "text-dark bg-light paginationitem"
              }>
              {num}
            </span>
          );
        })}
      </div> */}

      <Modal
        show={show}
        onHide={() => setShow(false)}
        centered
        keyboard={false}>
        <Modal.Header closeButton>
          <Modal.Title>
            <h6 className="text-center">Delete Project {projectid}</h6>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <p>
            !!!When you Click on the DELETE button project ID {projectid} and
            all its related contents is going to be deleted permanently
          </p>
        </Modal.Body>
        <Modal.Footer className="d-flex justify-content-between">
          <button
            className="btn btn-sm btn-outline-danger"
            onClick={deleteproject}>
            {state.loading ? (
              <span>
                <Spinner as="span" variant="border" size="sm" />
                ...Deleting
              </span>
            ) : (
              <>
                <RiDeleteBinLine /> <span>Delete</span>
              </>
            )}
          </button>
          <button
            className="btn btn-sm btn-outline-success"
            onClick={() => setShow(false)}>
            Cancel
          </button>
        </Modal.Footer>
      </Modal>
    </div>
  );
}

const mapStateToProps = (state) => ({ user: state.auth.user });
export default connect(mapStateToProps)(Unassigned);
