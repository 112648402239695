import React, { useEffect, useState, useRef } from "react";
import PlagDocument from "./PlagDocument";
import { Button} from "react-bootstrap";
import { BlobProvider } from "@react-pdf/renderer";
import { BiArrowBack } from "react-icons/bi";
import { useNavigate } from "react-router-dom";
import { FaArrowRight } from "react-icons/fa6";

function DocumentView() {
  const navigate = useNavigate();
  const [document, setDocument] = useState({});
  const iframeRef = useRef(null);
  useEffect(() => {
    const document = JSON.parse(localStorage.getItem("aicheck"));
    console.log(document);
    setDocument(document);
  }, []);

  // const handlePrint = (url) => {
  //   const printWindow = window.open(url, "_blank");
  //   printWindow.onload = () => {
  //     printWindow.print();
  //   };
  // };

  const handlePrint = (url) => {
    if (iframeRef.current) {
      iframeRef.current.src = url;
      iframeRef.current.onload = () => {
        iframeRef.current.contentWindow.print();
      };
    }
  };

  return (
    <>
      <div className="pagetitle">
        <BiArrowBack onClick={() => navigate(-1)} className="pagenavbutton" />
        <h1 className="title"> </h1>
        <button
          className="btn btn-outline-dark btn-sm"
          style={{ position: "absolute", right: "2vw" }}
          onClick={() => {
            navigate("/finance");
          }}>
          Edit <FaArrowRight size={10} />
        </button>
      </div>
      <div style={{ position: "relative" }}>
        {document && (
          <BlobProvider document={<PlagDocument document={document} type={"AI SCORE:"} />}>
            {({ blob, url, loading, error }) => {
              if (error) {
                return <div>An error occurred: {error.message}</div>;
              }
              if (loading) {
                return <div>Loading document...</div>;
              }
              return (
                <div>
                  <Button
                    style={{ position: "absolute", left: "50%", bottom: "6%" }}
                    className="btn-sm"
                    variant="dark"
                    href={url}
                    download={`${document?.customer?.customer_name}-${document?.document_number}.pdf`}>
                    Download document
                  </Button>
                  <Button
                    variant="warning"
                    className="btn-sm"
                    onClick={() => handlePrint(url)}
                    style={{ position: "absolute", left: "65%", bottom: "6%" }}>
                    Print document
                  </Button>
                  <iframe
                    src={url}
                    style={{ width: "100vw", height: "80vh" }}
                  />
                </div>
              );
            }}
          </BlobProvider>
        )}
      </div>
      <iframe ref={iframeRef} style={{ display: "none" }} title="print-frame" />
    </>
  );
}

export default DocumentView;
