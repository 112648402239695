import {useState, useEffect} from 'react'
import axios from 'axios'


const getData = async (url, nav)=> {

    const config = {
        headers:{
            "Authorization": `JWT ${localStorage.getItem("access")}`
        }
    }
   
    try{
        const res = await axios.get(url, config)  
        const data = res.data
        return data
    } 
    catch(error){
        nav("/register/login")
        console.error(`Could not get Projects : ${error}`)
    }
}


//NB hooks can only be used inside of functional components and custom hooks.
//NB hooks should always start with the word use

const useFetch = (url, nav)=> {
    const [state, setState] = useState({
        data: null,
        loading: true
    })
    useEffect(()=>{
     getData(url, nav).then((res)=>{
        setState({
            data:res,
            loading:false,   
        })
     }).catch((err)=>{
        console.log(err)
     })    
    }, [])

    return state
}

export  default useFetch