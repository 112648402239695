import React, { useEffect, useState } from "react";
import { Table, Col } from "react-bootstrap";
import axios from "axios";
import moment from "moment";
import Busy from "../global/Busy";
import Empty from "../global/Empty";

function Transactions() {
  const [state, setState] = useState({
    currentPage: 1,
    postPerPage: 20,
    transactions: [],
    loading: true,
  });

  const url = `${process.env.REACT_APP_API_URL}/accounts/writer_transactions`;
  const getData = async (url) => {
    const config = {
      headers: {
        Authorization: `JWT ${localStorage.getItem("access")}`,
      },
    };

    try {
      const res = await axios.get(url, config);
      console.log(res.data);

      return res.data;
    } catch (error) {
      console.error(`Could not get Projects : ${error}`);
    }
  };

  useEffect(() => {
    getData(url)
      .then((res) => {
        console.log("success");
        setState({ ...state, loading: false, transactions: res });
      })
      .catch((err) => {
        console.log(err);
      });
  }, [url]);

  console.log(state.transactions);

  let currentTransactions = [];
  let pageNumber = [];

  if (!state.loading) {
    let indexOfLastTransaction = state.currentPage * state.postPerPage;
    let indexOfFirstTransaction = indexOfLastTransaction - state.postPerPage;
    currentTransactions = state.transactions.slice(
      indexOfFirstTransaction,
      indexOfLastTransaction
    );

    for (
      let i = 1;
      i <= Math.ceil(state.transactions.length / state.postPerPage);
      i++
    ) {
      pageNumber.push(i);
    }
  }

  const setPage = (num) => {
    setState({ ...state, currentPage: num });
  };
  return (
    <>
      {state.transactions.loading ? (
        <Busy />
      ) : (
        <Col>
          {currentTransactions.length > 0 ? (
            <>
              <div className="contentarea">
                <Table striped bordered hover size="lg">
                  <thead>
                    <tr>
                      <th style={{ width: "10%" }}>ID</th>
                      <th style={{ width: "20%" }}>AMOUNT</th>
                      <th style={{ width: "35%" }}>DESCRIPTION</th>
                      <th style={{ width: "35%" }}>DATE & TIME</th>
                    </tr>
                  </thead>
                  <tbody>
                    {currentTransactions.map((transaction) => {
                      return (
                        <tr>
                          <td style={{ width: "10%" }}>{transaction.id}</td>
                          <td style={{ width: "20%" }}>
                            Kshs {transaction.amount}/=
                          </td>
                          <td style={{ width: "35%" }}>
                            {transaction.description}
                          </td>
                          <td style={{ width: "35%" }}>
                            {moment(transaction.timestamp).format(
                              "MMM Do YYYY, h:mm A"
                            )}
                          </td>
                        </tr>
                      );
                    })}
                  </tbody>
                </Table>
              </div>
              <div className="paginationcontainer d-flex justify-content-center">
                {pageNumber.map((number) => {
                  return (
                    <span
                      onClick={() => {
                        setPage(number);
                      }}
                      className={
                        number === state.currentPage
                          ? "text-light bg-success paginationitem"
                          : "text-dard bg-light paginationitem"
                      }>
                      {number}
                    </span>
                  );
                })}
              </div>
            </>
          ) : (
            <Empty message={"No Transactions Done"} />
          )}
        </Col>
      )}
    </>
  );
}

export default Transactions;
