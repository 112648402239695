import React, { useState, useEffect } from "react";
import { onMessageListener } from "./firebaseInit";

import Empty from "./components/global/Empty";

const Notification = () => {
  const [notification, setNotification] = useState([]);
  
  useEffect(() => {

    return setNotification([])
  }, [])
  
  // messaging.onMessage((payload) => {
  //       setNotification([
  //         ...notification,
  //         {
  //           title: payload?.notification?.title,
  //           body: payload?.notification?.body,
  //         },
  //       ]);
  // })

  // onMessageListener()
  //   .then((payload) => {
  //     setNotification([
  //       ...notification,
  //       {
  //         title: payload?.notification?.title,
  //         body: payload?.notification?.body,
  //       },
  //     ]);
  //   })
  //   .catch((err) => console.log("failed: ", err));

    return notification.length > 0 ? (
      notification.map((noti) => {
        return (
          <div>
            <p>
              <b>{noti?.title}</b>
            </p>
            <p>{noti?.body}</p>
          </div>
        );
      })
    ) : (
      <Empty message={"No notifications"}/>
    );
  }

 


export default Notification;
