import * as actions from "../actions/types"

const initialState = {
    message: ""
}


const escrowMessage = (state=initialState, action)=>{
    const { type, payload} = action;
    switch(type){
        case actions.PROJECT_ESCROW_SUCCESS:
            return {...state, message: payload}

        case actions.DELETE_MESSAGE:
            return {...state, message:""}
        default:
            return state
    }
    
}

export default escrowMessage