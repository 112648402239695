import React, { useState } from "react";
import { Col, Accordion, Spinner } from "react-bootstrap";
import { Link } from "react-router-dom";

import useFetch from "../../customHooks/useFetch";

import { useNavigate } from "react-router-dom";

import Empty from "../global/Empty";
import Busy from "../global/Busy";

import Project from "./Project/Project";
import axios from "axios";

function EmployerResolved() {
  const [state, setState] = useState({
    currentpage: 1,
    postsperpage: 10,
    loading: false,
  });

  const [notification, setNotification] = useState("");
  const [reassign, setReassign] = useState(false);

  const reassignProject = async (project_id) => {
    const url = `${process.env.REACT_APP_API_URL}/accounts/reassign_project`;
    const config = {
      headers: {
        Authorization: `JWT ${localStorage.getItem("access")}`,
        "Content-Type": "application/json",
        Accept: "application/json",
      },
    };

    const body = JSON.stringify(project_id);
    try {
      const res = await axios.post(url, body, config);
      const data = res.data;
      setReassign(false);
      setNotification("Project Reassigned Successfully");
      return data;
    } catch (error) {
      setReassign(false);
      setNotification("Project Could Not be Reassigned");
      console.log(error);
      return error;
    }
  };

  const url = `${process.env.REACT_APP_API_URL}/accounts/resolved_for_user`;
  const navigate = useNavigate();
  const projects = useFetch(url, navigate);

  console.log(projects);

  let currentProjects = [];
  // Implement Page Numbers
  const pageNumbers = [];
  if (!projects.loading && projects.loading !== undefined) {
    const indexOfLastProject = state.currentpage * state.postsperpage;
    const indexOfFirstProject = indexOfLastProject - state.postsperpage;
    currentProjects = projects.data.slice(
      indexOfFirstProject,
      indexOfLastProject
    );

    // Depending on the length of the projects available implement the page numbers
    for (
      let i = 1;
      i <= Math.ceil(projects.data.length / state.postsperpage);
      i++
    ) {
      pageNumbers.push(i);
    }
  }

  const setPage = (num) => {
    setState({ ...state, currentpage: num });
  };

  console.log(projects.length);
  return (
    <div>
      {projects.loading ? (
        <Busy />
      ) : (
        <div>
          <div className="pagetitle">
            <h1 className="title">Resolved Orders {projects.data.length}</h1>
          </div>
          <Accordion>
            <Accordion.Item>
              <Accordion.Header className="accordionheader">
                <table>
                  <col style={{ width: "20%" }} />
                  <col style={{ width: "40%" }} />
                  <col style={{ width: "40%" }} />
                  <thead>
                    <th> ID</th>
                    <th>FIELD</th>
                    <th>BUDGET </th>
                  </thead>
                </table>
              </Accordion.Header>
            </Accordion.Item>
          </Accordion>
          <Accordion>
            {currentProjects.length === 0 ? (
              <Empty message="No Resolved Projects" />
            ) : (
              <div className="contentarea">
                {currentProjects.map((project) => {
                  return (
                    <Accordion.Item eventKey={project.id} key={project.id}>
                      <Project project={project}>
                        <div className="d-flex justify-content-around">
                          {!project.reassigned ? (
                            <button
                              disabled
                              onClick={() => {
                                setReassign(true);
                                reassignProject(project.id);
                              }}
                              className="btn btn-outline-success btn-sm">
                              {reassign ? (
                                <>
                                  <Spinner
                                    as="span"
                                    animation="border"
                                    size="sm"
                                  />{" "}
                                  Reassigning ...
                                </>
                              ) : (
                                <>Reassign</>
                              )}
                            </button>
                          ) : (
                            <h6>Project Reassigned</h6>
                          )}
                          <Link
                            to="/employer/disputeview"
                            className="btn btn-outline-info btn-sm"
                            onClick={() => {
                              localStorage.setItem(
                                "disputeProject",
                                JSON.stringify(project)
                              );
                            }}>
                            View Dispute
                          </Link>
                        </div>
                      </Project>
                    </Accordion.Item>
                  );
                })}
              </div>
            )}
          </Accordion>
          <div className="paginationcontainer d-flex justify-content-center">
            {pageNumbers.map((pageNumber, index) => (
              <span
                key={index}
                className={
                  pageNumber === state.currentpage
                    ? "text-light bg-success paginationitem"
                    : "text-dark bg-light paginationitem"
                }
                onClick={() => {
                  setPage(pageNumber);
                }}>
                {pageNumber}
              </span>
            ))}
          </div>
        </div>
      )}
    </div>
  );
}

export default EmployerResolved;
