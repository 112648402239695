import axios from "axios";
import React, { useEffect, useState } from "react";
import { Accordion, Col } from "react-bootstrap";
import { useNavigate } from "react-router-dom";

import Empty from "../global/Empty";
import Busy from "../global/Busy";
import { BiArrowToLeft, BiArrowToRight } from "react-icons/bi";
import Project from "./Project/Project";

function Completed() {
  const [projects, setProjects] = useState({
    currentPage: 1,
    myProjects: [],
    loading: true,
    postsPerPage: 15,
  });
  const navigate = useNavigate();

  const get_completed = async () => {
    const config = {
      headers: {
        Authorization: `JWT ${localStorage.getItem("access")}`,
        "Content-Type": "application/json",
        Accept: "application/json",
      },
    };
    try {
      const res = await axios.get(
        `${process.env.REACT_APP_API_URL}/accounts/completedprojects`,
        config
      );
      console.log(res);
      setProjects({ ...projects, myProjects: res.data, loading: false });
    } catch (err) {
      navigate("/register/login");
      console.log(err);
    }
  };

  useEffect(() => {
    get_completed();
    const interval = setInterval(() => {
      get_completed()
        .then((res) => {
          console.log(res);
        })
        .catch((err) => {
          console.log(err);
        });
    }, 10000);
    return () => clearInterval(interval);
  }, []);

  const indexLastProject = projects.currentPage * projects.postsPerPage;
  const indexFirstProject = indexLastProject - projects.postsPerPage;
  const displayProjects = projects.myProjects.slice(
    indexFirstProject,
    indexLastProject
  );

  const pageNum = [];

  for (
    let i = 1;
    i <= Math.ceil(projects.myProjects.length / projects.postsPerPage);
    i++
  ) {
    pageNum.push(i);
  }
  const setPage = (number) => {
    setProjects({ ...projects, currentPage: number });
  };
  console.log(displayProjects);
  return (
    <div>
      {projects.loading ? (
        <Busy />
      ) : (
        <>
          <div className="pagetitle">
            <h1 className="title">
              Completed Orders {projects.myProjects.length}
            </h1>
          </div>
          <Accordion>
            <Accordion.Item eventKey="0">
              <Accordion.Header className="accordionheader">
                <table>
                  <col style={{ width: "20%" }} />
                  <col style={{ width: "40%" }} />
                  <col style={{ width: "40%" }} />
                  <thead>
                    <th> ID</th>
                    <th>FIELD</th>
                    <th>BUDGET </th>
                  </thead>
                </table>
              </Accordion.Header>
            </Accordion.Item>
            {projects.myProjects.length === 0 && (
              <Empty message="No Completed Projects" />
            )}
            <div className="contentarea">
              {displayProjects.map((project) => {
                return (
                  <Accordion.Item eventKey={project.id} key={project.id}>
                    <Project project={project} />
                  </Accordion.Item>
                );
              })}
            </div>
          </Accordion>
        </>
      )}

      <div className="paginationcontainer d-flex justify-content-center">
        {projects.currentPage > 1 && ( // Only show left arrow if not on first page
          <span
            onClick={() => setPage(projects.currentPage - 1)}
            className="text-dark bg-light paginationitem">
            <BiArrowToLeft />
          </span>
        )}

        {/* Display only 3 page numbers around the current page */}
        {Array.from({ length: 3 }, (_, i) => i + projects.currentPage - 1)
          .filter((pageIndex) => pageIndex >= 0 && pageIndex < pageNum.length) // Ensure indexes are valid
          .map((pageIndex) => (
            <span
              key={pageNum[pageIndex]} // Use page number for unique key
              onClick={() => setPage(pageNum[pageIndex])}
              className={
                pageNum[pageIndex] === projects.currentPage
                  ? "text-light bg-success paginationitem"
                  : "text-dark bg-light paginationitem"
              }>
              {pageNum[pageIndex]}
            </span>
          ))}

        {projects.currentPage < pageNum.length && ( // Only show right arrow if not on last page
          <span
            onClick={() => setPage(projects.currentPage + 1)}
            className="text-dark bg-light paginationitem">
            <BiArrowToRight />
          </span>
        )}
      </div>
    </div>
  );
}

export default Completed;
