import "bootstrap/dist/css/bootstrap.min.css";
import "./styles/index.css";
import "./styles/faqs.css";
import "./styles/admin.css";
import "./styles/layout.css";
import "./styles/nav.css";
import "./styles/home.css";
import "./styles/forms.css";
import React, { Suspense, lazy } from "react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import Busy from "./components/global/Busy";
import Layout from "./components/Layout";

import AiAssist from "./components/AiAssist";
import Similarity from "./components/Similarity";
import AiDetect from "./components/AiDetect";
import DocumentView from "./components/DocumentView";
import AdminDashBoard from "./components/admin/AdminDashBoard";

import {
  Freelancers,
  Bids,
  Postprojects,
  Profile,
  Unassigned,
  Wallet,
  EmployerDashboard,
  Disputed,
  Completed,
  Inprogress,
  BidAward,
  MakeDispute,
  RequestReview,
  MarkComplete,
  EditProject,
  InRevision,
  EmployerRevisionInstructions,
  DisputeView,
  EmployerResolved,
  EmployerChat,
  Notifications,
  EmployerFaqs,
  Writers,
} from "./components/employer/exporter";

import {
  WriterBids,
  WriterAssigned,
  WriterProfile,
  WriterProjects,
  WriterWallet,
  WriterDashboard,
  PlaceBid,
  WriterCompleted,
  WriterDisputed,
  CompletedUpload,
  ForRevision,
  RevisionInstructions,
  ViewDispute,
  Escalate,
  WriterResolved,
  EditBid,
  WriterChat,
  WriterNotifications,
  WriterFaqs,
  Employers,
} from "./components/writer/writerexporter";

const Home = lazy(() => import("./pages/Home"));
const Register = lazy(() => import("./forms/Register"));
const Signup = lazy(() => import("./forms/Signup"));
const Login = lazy(() => import("./forms/Login"));
const ResetPassword = lazy(() => import("./forms/ResetPassword"));
const ResetPasswordConfirm = lazy(() => import("./forms/ResetPasswordConfirm"));
const WriterMainSideBar = lazy(() => import("./components/WriterMainSideBar"));
const EmployerMainSideBar = lazy(() =>
  import("./components/EmployerMainSideBar")
);
const AdminMainSidebar = lazy(() =>
  import("./components/admin/AdminMainSidebar")
);
const PasswordEmailSent = lazy(() => import("./forms/PasswordEmailSent"));
const VerifyEmail = lazy(() => import("./forms/VerifyEmail"));
const Activate = lazy(() => import("./forms/Activate"));
const Nopagefound = lazy(() => import("./pages/Nopagefound"));
const UpdateWriterProfile = lazy(() =>
  import("./components/writer/UpdateWriterProfile")
);
const UpdateEmployerPhoneNo = lazy(() =>
  import("./components/employer/UpdateEmployerPhoneNo")
);
const UpdatePhoneNumber = lazy(() =>
  import("./components/writer/UpdatePhoneNumber")
);
const Disputes = lazy(() => import("./components/admin/Disputes"));
const Resolved = lazy(() => import("./components/admin/Resolved"));
const EscalatedProjects = lazy(() =>
  import("./components/admin/EscalatedProjects")
);
const AllEmployers = lazy(() => import("./components/admin/AllEmployers"));
const AllWriters = lazy(() => import("./components/admin/AllWriters"));
const PrivacyPolicy = lazy(() => import("./components/PrivacyPolicy"));
const AdminChat = lazy(() => import("./components/admin/AdminChat"));
const TermsOfService = lazy(() => import("./components/TermsOfService"));
const DisputeResolution = lazy(() =>
  import("./components/admin/DisputeResolution")
);
const MarkedComplete = lazy(() => import("./components/admin/MarkedComplete"));
const AdminProfile = lazy(() => import("./components/admin/AdminProfile"));
const Transactions = lazy(() => import("./components/admin/Transactions"));
const Faqs = lazy(() => import("./components/Faqs"));
const WriterInfo = lazy(() => import("./components/employer/WriterInfo"));
const AdminSendNotifications = lazy(() =>
  import("./components/admin/AdminSendNotifications")
);



function App() {
  return (
    <Router>
      <Suspense fallback={<Busy />}>
        <Routes>
          <Route path="/" element={<Layout />}>
            <Route index element={<Home />} />
            <Route path="writer" element={<WriterMainSideBar />}>
              <Route index element={<WriterDashboard />} />
              <Route path="home" element={<WriterDashboard />} />
              <Route path="projects" element={<WriterProjects />} />
              <Route path="my_projects" element={<WriterAssigned />} />
              <Route path="disputed" element={<WriterDisputed />} />
              <Route path="forrevision" element={<ForRevision />} />
              <Route
                path="revisioninstructions"
                element={<RevisionInstructions />}
              />
              <Route path="completed" element={<WriterCompleted />} />
              <Route path="bids">
                <Route index element={<WriterBids />} />
                <Route path="editbid" element={<EditBid />} />
              </Route>
              <Route path="chat" element={<WriterChat />} />
              <Route path="transactions" element={<WriterWallet />} />
              <Route path="profile" element={<WriterProfile />} />
              <Route path="placebid" element={<PlaceBid />} />
              <Route path="updateprofile" element={<UpdateWriterProfile />} />
              <Route path="updatephoneno" element={<UpdatePhoneNumber />} />
              <Route path="completedupload" element={<CompletedUpload />} />
              <Route path="viewdispute" element={<ViewDispute />} />
              <Route path="escalate" element={<Escalate />} />
              <Route path="resolved" element={<WriterResolved />} />
              <Route path="notifications" element={<WriterNotifications />} />
              <Route path="in-faqs" element={<WriterFaqs />} />
              <Route path="my-employers" element={<Employers />} />
              <Route path="ai_assist" element={<AiAssist />} />
            </Route>
            <Route path="employer" element={<EmployerMainSideBar />}>
              <Route index element={<EmployerDashboard />} />
              <Route path="home" element={<EmployerDashboard />} />
              <Route path="post_projects" element={<Postprojects />} />
              <Route path="freelancers" element={<Freelancers />} />
              <Route path="chat" element={<EmployerChat />} />
              <Route path="bids" element={<Bids />} />
              <Route path="profile" element={<Profile />} />
              <Route
                path="transactions"
                element={<Wallet />}
              />
              <Route
                path="transactions/:OrderTrackingId/:OrderMerchantReference"
                element={<Wallet />}
              />
              <Route path="my_projects" element={<Unassigned />} />
              <Route path="editproject" element={<EditProject />} />
              <Route path="notifications" element={<Notifications />} />
              <Route path="writer-info" element={<WriterInfo />} />
              <Route path="faqs" element={<EmployerFaqs />} />
              <Route path="my_writers" element={<Writers />} />
              <Route path="ai_assist" element={<AiAssist />} />
              <Route path="ai_detect" element={<AiDetect />} />
              <Route path="similarity" element={<Similarity />} />
              <Route path="ai_check_view" element={<DocumentView />} />
              <Route
                path="revisioninstructions"
                element={<EmployerRevisionInstructions />}
              />
             
              <Route path="disputed" element={<Disputed />} />
              <Route path="inrevision" element={<InRevision />} />
              <Route path="completed" element={<Completed />} />
              <Route path="inprogress" element={<Inprogress />} />
              <Route path="complete" element={<MarkComplete />} />
              <Route path="dispute" element={<MakeDispute />} />
              <Route path="requestrevision" element={<RequestReview />} />
              <Route path="updatephoneno" element={<UpdateEmployerPhoneNo />} />
              <Route path="awardbid" element={<BidAward />} />
              <Route path="disputeview" element={<DisputeView />} />
              <Route path="resolved" element={<EmployerResolved />} />
            </Route>
            <Route path="siteadmin" element={<AdminMainSidebar />}>
              <Route index element={<AdminDashBoard />} />
              <Route path="disputes" element={<Disputes />} />
              <Route path="resolved" element={<Resolved />} />
              <Route path="escalated" element={<EscalatedProjects />} />
              <Route path="chat" element={<AdminChat />} />
              <Route path="employers" element={<AllEmployers />} />
              <Route path="freelancers" element={<AllWriters />} />
              <Route path="disputeresolve" element={<DisputeResolution />} />
              <Route path="markedcomplete" element={<MarkedComplete />} />
              <Route path="profile" element={<AdminProfile />} />
              <Route path="transactions" element={<Transactions />} />
              <Route
                path="sendnotifications"
                element={<AdminSendNotifications />}
              />
            </Route>
          </Route>
          <Route path="/activate/:uid/:token" element={<Activate />} />
          <Route
            path="/password/reset/confirm/:uid/:token"
            element={<ResetPasswordConfirm />}
          />
          <Route path="/faqs" element={<Faqs />} />
          <Route path="/privacy-policy" element={<PrivacyPolicy />} />
          <Route path="/terms-of-service" element={<TermsOfService />} />
          <Route path="/register" element={<Register />}>
            <Route index element={<Signup />} />
            <Route path="login" element={<Login />} />
            <Route path="change_password" element={<ResetPassword />} />
            <Route
              path="password_reset_email_sent"
              element={<PasswordEmailSent />}
            />
            <Route path="verify_email" element={<VerifyEmail />} />
          </Route>
          <Route path="*" element={<Nopagefound />} />
        </Routes>
      </Suspense>
    </Router>
  );
}

export default App;
