import React, { useEffect, useState } from "react";
import { Card, Row, Col, Spinner } from "react-bootstrap";
import axios from "axios";
import { HiOutlineUserCircle } from "react-icons/hi";

function Admins() {
  const usersurl = `${process.env.REACT_APP_API_URL}/siteadmin/users`;
  const [admins, setAdmins] = useState(null);
  const [loading, setLoading] = useState(true);
  const getData = async (url) => {
    const config = {
      headers: {
        Authorization: `JWT ${localStorage.getItem("access")}`,
        "Content-Type": "application/json",
        Accept: "application/json",
      },
    };

    try {
      const res = await axios.get(url, config);
      const data = res.data;
      console.log(data);
      return data;
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    getData(usersurl)
      .then((res) => {
        setLoading(false);
        const admins = res.filter((user) => {
          return user.role === "Admin";
        });
        setAdmins(admins);
      })
      .catch((err) => console.log(err));
  }, []);

  return loading ? (
    <Row>
      <Col
        className="loader d-flex justify-content-center align-items-center"
        lg={12}
        sm={12}
        md={12}
        style={{ height: "20vh" }}>
        <Spinner animation="border" variant="success" />
      </Col>
    </Row>
  ) : (
    <Card className="mb-4">
      <Card.Header>
        <h4>Admins {admins?.length}</h4>
      </Card.Header>
      <Card.Body>
        <table className="table table-striped">
          <tr>
            <th>Name</th>
            <th>Phone</th>
            <th>Email</th>
          </tr>
          {admins?.map((admin) => {
            return (
              <tr>
                <td>
                  <h6>
                    {admin.profile_pic !== null ? (
                      <img
                        src={process.env.REACT_APP_API_URL + admin.profile_pic}
                        className="project_image"
                        alt="employer_image"
                      />
                    ) : (
                      <HiOutlineUserCircle className="project_no_image" />
                    )}{" "}
                    {admin.first_name} {admin.last_name}{" "}
                  </h6>
                </td>{" "}
                <td> 0{admin.phone_no}</td>
                <td>{admin.email}</td>
              </tr>
            );
          })}
        </table>
      </Card.Body>
    </Card>
  );
}

export default Admins;
