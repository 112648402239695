import React, { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { BiArrowBack } from "react-icons/bi";
import axios from "axios";
import { Modal, Col, Stack, Spinner, Row } from "react-bootstrap";
import { HiOutlineUserCircle } from "react-icons/hi";
import { connect } from "react-redux";
import { get_user } from "../../actions/myuser";
import ReconnectingWebSocket from "reconnecting-websocket";
import { FcOk, FcHighPriority } from "react-icons/fc";
import * as yup from "yup";
import { useFormik } from "formik";
import moment from "moment";
import CardPayment from "./CardPayment";
import { useSelector } from "react-redux";

function BidAward({ employer, get_user }) {
  const country = useSelector((state) => state.userCountry.country)
  const [paymentShow, setPaymentShow] = useState(false);
  //modal state
  const phoneRegExp =
    /^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/;
  const [project, setProject] = useState({});
  const [show, setShow] = useState(false);
  const [paid, setPaid] = useState(false);
  const [fail, setFail] = useState(false);
  const [stk, setSTK] = useState(false);
  const [escrow, setEscrow] = useState(false);
  const [paylater, setPaylater] = useState(false);
  const [escrowfail, setEscrowFail] = useState(false);
  const handleEscrowFail = () => setEscrowFail(true);
  const handleEscrowFailDone = () => setEscrowFail(false);
  const handleEscrow = () => {
    setEscrow(true);
  };
  const handleEscrowDone = () => {
    setEscrow(false);
    navigate("/employer/inprogress");
  };
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const handlePaid = () => {
    setPaid(true);
  };
  const handlePaidDone = () => {
    setPaid(false);
    navigate("/employer/inprogress");
  };
  const handleFail = () => setFail(true);
  const handleFailDone = () => setFail(false);
  const [codeEntry, setCodeEntry] = useState(false);
  const showCodeEntry = () => setCodeEntry(true);
  const hideCodeEntry = () => setCodeEntry(false);
  const handleSTKsent = () => setSTK(true);
  const stkClose = () => setSTK(false);

  const [buttonload, setButtonload] = useState(false);
  const navigate = useNavigate();
  const location = useLocation();
  const [writer, setWriter] = useState({});
  const [amount, setAmount] = useState(0);
  const [projectid, setProjectid] = useState(0);
  const [employer_id, setEmployer_id] = useState(0);
  const [socket, setSocket] = useState(null);
  const [verifying, setVerifying] = useState(false);
  const [awarded, setAwarded] = useState(false);

  useEffect(() => {
    const bidData = JSON.parse(localStorage.getItem("awardData"));
    if (bidData) {
      setWriter(bidData.writer);
      setAmount(bidData.amount);
      setProjectid(bidData.project.id);
      setEmployer_id(bidData.project.employer.id);
      setProject(bidData.project);
    }
  }, []);

  const award = async (employerid, writerid, amount, projectid, otherNumber, depositNumber) => {
    const config = {
      headers: {
        "Content-Type": "application/json",
        Authorization: `JWT ${localStorage.getItem("access")}`,
        Accept: "application/json",
      },
    };
    const body = JSON.stringify({ employerid, writerid, amount, projectid, otherNumber, depositNumber });
    console.log(body)
    try {
      const res = await axios.post(
        `${process.env.REACT_APP_API_URL}/accounts/award`,
        body,
        config
      );
      return res.data;
    } catch (err) {
      console.log(err);
    }
  };

  const trustedAward = async (employerid, writerid, amount, projectid) => {
    const config = {
      headers: {
        "Content-Type": "application/json",
        Authorization: `JWT ${localStorage.getItem("access")}`,
        Accept: "application/json",
      },
    };
    const body = JSON.stringify({ employerid, writerid, amount, projectid });
    try {
      const res = await axios.post(
        `${process.env.REACT_APP_API_URL}/accounts/trustedAward`,
        body,
        config
      );
      return res.data;
    } catch (err) {
      console.log(err);
    }
  };

  const makeEscrow = async (employerid, writerid, amount, projectid) => {
    const config = {
      headers: {
        "Content-Type": "application/json",
        Authorization: `JWT ${localStorage.getItem("access")}`,
        Accept: "application/json",
      },
    };
    const body = JSON.stringify({ employerid, writerid, amount, projectid });
    try {
      const res = await axios.post(
        `${process.env.REACT_APP_API_URL}/accounts/make_escrow`,
        body,
        config
      );
      console.log(res.data);
      return res.data;
    } catch (err) {
      console.log(err);
      return err;
    }
  };


  useEffect(() => {
    // Replace 'your_backend_url' with the actual backend WebSocket URL

    const socket = new ReconnectingWebSocket(
      `${process.env.REACT_APP_WEBSOCKET_URL}/ws/mpesa_response/${employer_id}`
    );
    setSocket(socket);
    console.log(socket);
    socket.addEventListener("open", () => {
      console.log("WebSocket connected");
    });
    socket.addEventListener("close", () => {
      console.log("WebSocket disconnected");
    });
    return () => {
      socket.close();
    };
  }, [employer_id]);

  const validationSchema = yup.object().shape({
    amount: yup.number().required("Amount is required"),
    depositNumber: yup
      .string()
      .when("otherNumber", {
        is: true,
        then: yup.string().matches(phoneRegExp, "Enter Valid Phone Number").required("Deposit number is required"),
      })
      
  });
  

  const { errors, values, touched, handleChange, handleSubmit, setFieldValue } =
    useFormik({
      initialValues: {
        employerid: JSON.parse(localStorage.getItem("awardData")).project.employer.id || "",
        writerid: JSON.parse(localStorage.getItem("awardData")).writer.id || "",
        amount: JSON.parse(localStorage.getItem("awardData")).amount || "",
        projectid: JSON.parse(localStorage.getItem("awardData")).project.id || "",
        otherNumber: false,
        depositNumber: "",
      },
      onSubmit: (values) => {
        setButtonload(true);
        award(
          values.employerid,
          values.writerid,
          values.amount,
          values.projectid,
          values.otherNumber,
          values.depositNumber
        )
          .then((res) => {
            handleClose();
            setButtonload(false);
          })
          .catch((err) => {
            console.log(err);
            setButtonload(false);
            handleClose();
            stkClose();
            handleFail();
          });
      },
      validationSchema: validationSchema,
    });

  console.log(values)

  useEffect(() => {
    if (socket) {
      socket.addEventListener("message", (event) => {
        const data = JSON.parse(event.data);
        console.log(data.status);
        if (data.status === "stk_push") {
          handleSTKsent();
        } else if (data.status === "payment_success") {
          stkClose();
          handlePaid();
        } else if (data.status === "payment_failed") {
          stkClose();
          handleFail();
        }
      });
    }
  }, [projectid]);

  if (socket) {
    socket.addEventListener("message", (event) => {
      const data = JSON.parse(event.data);
      console.log(data);
      if (data.status === "stk_push") {
        handleSTKsent();
      } else if (data.status === "payment_success") {
        stkClose();
        handlePaid();
      } else if (data.status === "payment_failed") {
        stkClose();
        handleFail();
      }
    });
  }


  return project?.trusted ? (
    <div>
      <div className="pagetitle">
        <BiArrowBack onClick={() => navigate(-1)} className="pagenavbutton" />
        <h1 className="title">Order {projectid}</h1>
      </div>
      <div className="itemscontainer">
        <div className="d-flex justify-content-center align-items-center">
          <Stack gap={3}>
            <Row>
              <Col lg={12} sm={12} className="mb-3">
                {project.trusted ? (
                  <h6 className="projecttrusted">
                    {" "}
                    Payment Date:{" "}
                    {moment(project.paymentdate).format("MMM Do YYYY")}
                  </h6>
                ) : (
                  <h6 className="projectescrow">Escrowed</h6>
                )}
              </Col>
              <Col lg={12} sm={12}>
                <h5 className="text-center">Award Order {projectid} </h5>
                <h6 className="text-center">To writer</h6>
                <h6 className="text-center">
                  <span>
                    {writer.profile_pic ? (
                      <img
                        src={process.env.REACT_APP_API_URL + writer.profile_pic}
                        className="project_image"
                        alt="employer_image"
                        style={{ height: "50px", width: "50px" }}
                      />
                    ) : (
                      <HiOutlineUserCircle
                        style={{ height: "50px", width: "50px" }}
                      />
                    )}
                  </span>{" "}
                  {writer.first_name} {writer.last_name}
                </h6>
                <h6 className="text-center">
                  <span className="projectintext">Writer's Bid Amount: </span>
                  {country === "Kenya" ? (
                    <> Kshs {amount}</>
                  ) : (
                    <> USD {amount / 100}</>
                  )}
                </h6>
                <div className="d-flex justify-content-center">
                  <button
                    disabled={awarded}
                    className="btn btn-outline-success btn-sm"
                    onClick={() => {
                      setPaylater(true);
                    }}>
                    Award Order
                  </button>
                </div>
              </Col>
              <Col lg={4} sm={12} className="mb-3 d-none d-lg-block"></Col>
            </Row>
          </Stack>
        </div>
      </div>
      <Modal
        show={paylater}
        onHide={() => {
          setPaylater(false);
        }}
        backdrop="static"
        size="md"
        aria-labelledby="contained-modal-title-vcenter"
        centered>
        <Modal.Header closeButton>
          <Modal.Title>
            <h5>Award Pay Later Order</h5>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <p>
            Award Pay Later Order to {writer.first_name} {writer.last_name}{" "}
            <br />
            Bid Amount <b>Kshs {amount}</b>
          </p>
        </Modal.Body>
        <Modal.Footer className="d-flex justify-content-center">
          <button
            disabled={buttonload}
            onClick={() => {
              setButtonload(true);
              trustedAward(employer.id, writer.id, amount, projectid)
                .then((res) => {
                  console.log(res);
                  setButtonload(false);
                  setAwarded(true);
                  setPaylater(false);
                })
                .catch((err) => {
                  setButtonload(false);
                  setPaylater(false);
                });
            }}
            className="btn btn-success btn-sm">
            {buttonload ? (
              <span>
                {" "}
                <Spinner as="span" variant="border" size="sm" />
                Award Request Sent...
              </span>
            ) : (
              <span>Award </span>
            )}
          </button>
        </Modal.Footer>
      </Modal>
      <Modal
        show={awarded}
        onHide={() => {
          setAwarded(false);
          navigate("/employer/inprogress");
        }}
        aria-labelledby="contained-modal-title-vcenter"
        backdrop="static"
        centered>
        <Modal.Header closeButton>
          <Modal.Title className="text-center">
            <h5>
              {" "}
              <FcOk size={window.innerWidth > 768 ? 24 : 15} /> Order{" "}
              {projectid} has been Awarded Successfully.
            </h5>
          </Modal.Title>
        </Modal.Header>
      </Modal>
    </div>
  ) : (
    <div >
      <div className="pagetitle">
        <BiArrowBack onClick={() => navigate(-1)} className="pagenavbutton" />
        <h1 className="title">Order {projectid}</h1>
      </div>
      <div className="itemscontainer">
        <div className="d-flex justify-content-center align-items-center">
          <Stack gap={3}>
            <Row>
              <Col lg={4} sm={12} className="mb-3">
                {project.trusted ? (
                  <h6 className="projecttrusted">
                    {" "}
                    Payment Date:{" "}
                    {moment(project.paymentdate).format("MMM Do YYYY")}
                  </h6>
                ) : (
                  <h6 className="projectescrow">Escrow</h6>
                )}
              </Col>
              <Col lg={4} sm={12}>
                <h5 className="text-center">Award Order {projectid} </h5>
                <h6 className="text-center">To Writer</h6>
                <h6 className="text-center">
                  <span>
                    {writer.profile_pic ? (
                      <img
                        src={process.env.REACT_APP_API_URL + writer.profile_pic}
                        className="project_image"
                        alt="employer_image"
                        style={{ height: "50px", width: "50px" }}
                      />
                    ) : (
                      <HiOutlineUserCircle
                        style={{ height: "50px", width: "50px" }}
                      />
                    )}
                  </span>{" "}
                  {writer.first_name} {writer.last_name}
                </h6>
                <h6 className="text-center">
                  <span className="projectintext">Writer's Bid Amount: </span>
                  {country === "Kenya" ? (
                    <> Kshs {amount}</>
                  ) : (
                    <> USD {amount / 100}</>
                  )}
                </h6>
              </Col>
              <Col lg={4} sm={12} className="mb-3 d-none d-lg-block"></Col>
            </Row>
            <ul>
              <li>
                If you click Award Order if you have money in your wallet Cash
                equivalent to the bid amount from the writer is going to be
                withdrawn.
              </li>
              <br />
              <li>
                Incase you have funds in your wallet but it does not cover the
                writer bid amount, an MPESA STK push prompt will be sent
                requesting you to pay amount equivalent to the Bid amount. Cash
                in your wallet won't be deducted.
              </li>
            </ul>
            <div className="d-flex justify-content-center">
              <button
                className="btn btn-outline-success btn-sm"
                onClick={() => {
                  if (country === "Kenya") {
                    handleShow();
                  } else {
                    setPaymentShow(true);
                  }
                }}>
                Award Order
              </button>
            </div>
            <ul>
              <li>
                Incase you attempted to Escrow an Order and the Payment was
                successful but you are not redirected to the InProgress. Click
                the Button below to ReLoad and complete the Escrow.
              </li>
            </ul>
            <div className="d-flex justify-content-center">
              <button
                className="btn btn-outline-info btn-sm"
                onClick={() => {
                  window.location.reload();
                }}>
                ReLoad
              </button>
            </div>
          </Stack>
        </div>
        <CardPayment
          showPayment={paymentShow}
            setShowPayment={setPaymentShow}
            cardtitle = {`Award Project ${projectid}`}
        />

        <Modal
          show={show}
          onHide={handleClose}
          size="md"
          aria-labelledby="contained-modal-title-vcenter"
          centered>
          <Modal.Header closeButton>
            <Modal.Title id="contained-modal-title-vcenter">
              <h5 className="text-center">
                {" "}
                {employer.user_wallet.cash >= amount ? (
                  <span>
                    Escrow Kshs {amount} from your wallet for Order ID:{" "}
                    {projectid}
                  </span>
                ) : (
                  <span>
                    Escrow Kshs: <span className="bidamount">{amount}</span> to
                    Essays Host for Order ID: {projectid}
                  </span>
                )}
              </h5>
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <p></p>
            {employer.user_wallet.cash >= amount ? (
              <span>
                {" "}
                When you click Deposit Kshs {amount} from your wallet will be
                Deposited to Escrow for Order ID: <b>{projectid}</b>
              </span>
            ) : (
              <span>
                Kshs <span className="bidamount">{amount}</span> will be
                escrowed for Order id {projectid} on Essay Host. <br />
                {!values.otherNumber && (
                  <>
                    Click DEPOSIT to recieve an STK push notification on{" "}
                    <b>0{employer.phone_no} </b>
                  </>
                )}
                <br />
                {values.otherNumber ? (
                  <>To Deposit From My Saved Number </>
                ) : (
                  <>To Deposit from another Number </>
                )}
                {values.otherNumber ? (
                  <button
                    className="btn btn-sm btn-outline-info"
                    onClick={() => {
                      setFieldValue("otherNumber", false);
                    }}>
                    {values.otherNumber ? <>My Number</> : <>Other</>}
                  </button>
                ) : (
                  <button
                    className="btn btn-sm btn-outline-info"
                    onClick={() => {
                      setFieldValue("otherNumber", true);
                    }}>
                    {values.otherNumber ? <>My Number</> : <>Other</>}
                  </button>
                )}
              </span>
            )}
          </Modal.Body>
          <Modal.Footer className="d-flex justify-content-center align-items-center">
            {employer.user_wallet.cash >= amount ? (
              <>
                <button
                  disabled={buttonload}
                  className="btn btn-success"
                  onClick={() => {
                    setButtonload(true);
                    makeEscrow(employer.id, writer.id, amount, projectid)
                      .then((res) => {
                        console.log(res);
                        setButtonload(false);
                        handleClose();
                        handleEscrow();
                      })
                      .catch((err) => {
                        console.log(err);
                        setButtonload(false);
                        handleClose();
                        handleEscrowFail();
                      });
                  }}>
                  {buttonload ? (
                    <span>
                      {" "}
                      <Spinner as="span" variant="border" size="sm" />
                      Award Request Sent...
                    </span>
                  ) : (
                    <span>Award From Wallet</span>
                  )}
                </button>
              </>
            ) : (
              <>
                <form onSubmit={handleSubmit} className="mt-2">
                  <Stack gap={2}>
                    {values.otherNumber && (
                      <>
                        <label
                          htmlFor="depositNumber"
                          className="postlabel text-center">
                          Phone Number
                        </label>
                        <input
                          type="number"
                          placeholder="Phone Number"
                          id="depositNumber"
                          name="depositNumber"
                          value={values.depositNumber}
                          onChange={handleChange}
                        />
                        {errors.depositNumber && touched.depositNumber ? (
                          <p className="text-danger">{errors.depositNumber}</p>
                        ) : null}
                      </>
                    )}
                    {values.otherNumber ? (
                      <button
                        className="btn btn-sm btn-success"
                        type="submit"
                        disabled={buttonload}>
                        {buttonload ? (
                          <span>
                            <Spinner as="span" variant="border" size="sm" />{" "}
                            Award Request Sent...
                          </span>
                        ) : (
                          <span>Award</span>
                        )}
                      </button>
                    ) : (
                      <button
                        type="button"
                        className="btn btn-success"
                        onClick={() => {
                          setButtonload(true);
                          const otherNumber = false;
                          const depositNo = "";
                          award(
                            employer.id,
                            writer.id,
                            amount,
                            projectid,
                            otherNumber,
                            depositNo
                          )
                            .then((res) => {
                              handleClose();
                              setButtonload(false);
                            })
                            .catch((err) => {
                              console.log(err);
                              setButtonload(false);
                              handleClose();
                              stkClose();
                              handleFail();
                            });
                        }}
                        disabled={buttonload}>
                        {buttonload ? (
                          <span>
                            <Spinner as="span" variant="border" size="sm" />{" "}
                            Award Request Sent...
                          </span>
                        ) : (
                          <span>Award</span>
                        )}
                      </button>
                    )}
                  </Stack>
                </form>
              </>
            )}
          </Modal.Footer>
        </Modal>
        <Modal
          show={paid}
          onHide={handlePaidDone}
          aria-labelledby="contained-modal-title-vcenter"
          backdrop="static"
          centered>
          <Modal.Header closeButton>
            <Modal.Title className="text-center">
              <h5>
                <FcOk size={window.innerWidth > 768 ? 24 : 15} />{" "}
                Congratulations Escrow Deposit for Project {projectid}{" "}
                Successful
              </h5>
            </Modal.Title>
          </Modal.Header>
        </Modal>
        <Modal
          show={escrow}
          onHide={handleEscrowDone}
          aria-labelledby="contained-modal-title-vcenter"
          backdrop="static"
          centered>
          <Modal.Header closeButton>
            <Modal.Title className="text-center">
              <h5>
                <FcOk size={window.innerWidth > 768 ? 24 : 15} />{" "}
                Congratulations Escrow Deposit from wallet for Project{" "}
                {projectid} Successful
              </h5>
            </Modal.Title>
          </Modal.Header>
        </Modal>
        <Modal
          show={escrowfail}
          onHide={handleEscrowFailDone}
          aria-labelledby="contained-modal-title-vcenter"
          backdrop="static"
          centered>
          <Modal.Header closeButton>
            <Modal.Title className="text-center">
              <h5>
                <FcHighPriority size={window.innerWidth > 768 ? 24 : 15} />
                Could Not Escrow cash for Project {projectid} From Your Wallet
              </h5>
            </Modal.Title>
          </Modal.Header>
        </Modal>
        <Modal
          show={fail}
          onHide={handleFailDone}
          aria-labelledby="contained-modal-title-vcenter"
          centered>
          <Modal.Header closeButton>
            <Modal.Title className="text-center">
              <h5>
                <FcHighPriority size={window.innerWidth > 768 ? 24 : 15} />
                Sorry, Deposit for Project {projectid} Failed
              </h5>
            </Modal.Title>
          </Modal.Header>
        </Modal>
        <Modal
          show={codeEntry}
          onHide={hideCodeEntry}
          aria-labelledby="contained-modal-title-vcenter"
          centered>
          <Modal.Header closeButton>
            <Modal.Title className="text-center">
              Enter MPESA Transaction Code
            </Modal.Title>
          </Modal.Header>
          <form onSubmit={handleSubmit}>
            <Modal.Body>
              <div className="d-flex justify-content-center align-items-center">
                <input
                  id="transactionCode"
                  type="text"
                  className="form-control"
                  placeholder="Enter MPESA Transaction Code"
                  onChange={handleChange}
                />
              </div>
              {errors.transactionCode && touched.transactionCode ? (
                <p className="error">{errors.transactionCode}</p>
              ) : (
                ""
              )}
            </Modal.Body>
            <Modal.Footer className="d-flex justify-content-center align-items-center">
              <button
                className="btn btn-success"
                type="submit"
                disabled={verifying}>
                Submit
              </button>
            </Modal.Footer>
          </form>
        </Modal>
        <Modal
          show={stk}
          onHide={stkClose}
          size="md"
          aria-labelledby="contained-modal-title-vcenter"
          centered>
          <Modal.Header closeButton>
            <Modal.Title className="text-center">
              <h5>An STK push notification has been sent to your phone</h5>
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <p>
              Please Enter your MPESA pin on your phone to complete the
              transaction. A notification will Pop Up indicating whether the
              transaction was successful or not.
            </p>
          </Modal.Body>
        </Modal>
      </div>
    </div>
  );
}

const mapStateToProps = (state) => ({
  employer: state.current_user.user,
});
export default connect(mapStateToProps, { get_user })(BidAward);
