// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getMessaging, getToken } from "firebase/messaging";
import axios from "axios";

// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: "AIzaSyCYwSLgbm8ekdbSapp0rra4v3X2eoPWfSY",
  authDomain: "essayshost.firebaseapp.com",
  projectId: "essayshost",
  storageBucket: "essayshost.appspot.com",
  messagingSenderId: "94979816207",
  appId: "1:94979816207:web:46330e2d5791ce1d3c9c7a",
  measurementId: "G-XDKK76YH74",
};

// Initialize Firebase
export const app = initializeApp(firebaseConfig);

export const messaging = getMessaging(app);

export const checkServiceWorkerRegistration = async () => {
  try {
    const registration = await navigator.serviceWorker.ready;
    return registration;
  } catch (error) {
    console.log(error);
    return error;
  }
};


export const getFCMToken = async () => {
  try {
    const currentToken = await getToken(messaging, {
      serviceWorkerRegistration: navigator.serviceWorker.registration,
      vapidKey:
        "BOWY7kVRH0mUWgBGSJa-KAe32URKU7M00PEYxTZQmsoeDNRmwKEVTNAl-ul7wBgSSp8BNIfW6oKFaNzOU8NFoZk",
    });
    localStorage.setItem("fcmToken", currentToken);
    return currentToken;
  } catch (error) {
    if (error.name === "DOMException" && error.code === 12) {
      // Handle error: No active Service Worker
      console.error(
        "Error: Failed to execute 'subscribe' on 'PushManager':",
        error
      );
      // Provide informative feedback to the user, e.g., "Notification permissions are required for push notifications. Please allow permissions and try again.")
    } else {
      console.error("Error retrieving FCM token:", error);
      // Handle other errors
    }
    return error; // Or return null or a more relevant value based on your use case
  }
};
let isRequestPending = false;

export const sendFCMTokentoBackend = async (theToken) => {
  if (isRequestPending) {
    console.log("Request already in progress. Skipping.");
    return; // Exit the function if a request is ongoing
  }
  isRequestPending = true; // Set flag to indicate that a request is now in progress

  const body = {
    fcm_token: theToken,
  };
  try {
    let response = await axios.post(
      `${process.env.REACT_APP_API_URL}/accounts/send_token_to_backend`,
      body,
      {
        headers: {
          Accept: "application/json",
          Authorization: `JWT ${localStorage.getItem("access")}`,
        },
      }
    );
    isRequestPending = false; // Clear flag once the request is complete
    return response.data;
  } catch (error) {
    isRequestPending = false; // Clear flag once the request is complete
    console.log(error);
  }
};

export const getServerToken = async () => {
  const url = `${process.env.REACT_APP_API_URL}/accounts/get_fcm_token`;
  let response = await axios.get(url, {
    headers: {
      Accept: "application/json",
      Authorization: `JWT ${localStorage.getItem("access")}`,
    },
  });
  try {
    let data = await response.data;
    console.log({ fcm_token: data?.registration_id });
    return data;
  } catch (error) {
    return error;
  }
};

export const onMessageListener = () =>
  new Promise((resolve) => {
    messaging.onMessage((payload) => {
      resolve(payload);
    });
  });


