import * as actions from "../actions/types";

const initialState = {
  innerWidth: 0,
};

const windowSize = (state = initialState, action) => {
  const { type, payload } = action;
  switch (type) {
    case actions.WINDOWSIZE:
      return { ...state, innerWidth: payload };

    default:
      return state;
  }
};

export default windowSize
