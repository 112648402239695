import React, { useEffect, useState } from "react";
import { Stack, Row, Col, Spinner, Card } from "react-bootstrap";
import axios from "axios";
import moment from "moment";
import depositsimg from "../../../images/deposit.png";
import withdrawalimg from "../../../images/cash-withdrawal.png";
import transactionimg from "../../../images/transaction.png";
import ReconnectingWebSocket from "reconnecting-websocket";
import { Link } from "react-router-dom";

function Transactions() {
  const [loading, setLoading] = useState(true);
  const transactionsurl = `${process.env.REACT_APP_API_URL}/siteadmin/all_transactions`;
  const utilityUrl = `${process.env.REACT_APP_API_URL}/siteadmin/balance`;
  const earningsurl = `${process.env.REACT_APP_API_URL}/siteadmin/essayshost_earnings`;
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);
  const [targetDate, setTargetDate] = useState(null);
  const [earnings, setEarnings] = useState(null);
  const [earningsSum, setEarningsSum] = useState(0);
  const [filteredEarnings, setFilteredEarnings] = useState(null);
  const [socket, setSocket] = useState(null);
  const [clientEarningsSum, setClientEarningsSum] = useState(0);
  const [utility, setUtility] = useState({});

  const [transactions, setTransactions] = useState({
    deposits: [],
    withdrawals: [],
    internals: [],
    allTransactions: [],
  });

  const [filteredTransactions, setFilteredTransactions] = useState({
    deposits: [],
    withdrawals: [],
    internals: [],
    allTransactions: [],
  });

  const [transactionsSum, setTransactionsSum] = useState({
    depositsSum: 0,
    withdrawalsSum: 0,
    internalSum: 0,
  });

  const [filteredSum, setFilteredSum] = useState({
    depositsSum: 0,
    withdrawalsSum: 0,
    internalSum: 0,
  });

  console.log(earnings);

  console.log(transactions.deposits);

  function filterObjectsByDateRange(arr, startDate, endDate) {
    return arr?.filter((item) => {
      const itemDate = new Date(item.timestamp);
      return itemDate >= new Date(startDate) && itemDate <= new Date(endDate);
    });
  }

  function specificDateFilter(arr, specificDate) {
    return arr?.filter((item) => {
      const itemDate = new Date(item.timestamp);
      const targetDate = new Date(specificDate);

      return (
        moment(itemDate).format("DD/MM/YYYY") ===
        moment(targetDate).format("DD/MM/YYYY")
      );
    });
  }

  useEffect(() => {
    const ws = new ReconnectingWebSocket(
      `${process.env.REACT_APP_WEBSOCKET_URL}/ws/mpesa_response/Admin`
    );
    setSocket(ws);
  }, []);

  const getData = async (url) => {
    const config = {
      headers: {
        Authorization: `JWT ${localStorage.getItem("access")}`,
        "Content-Type": "application/json",
        Accept: "application/json",
      },
    };

    try {
      const res = await axios.get(url, config);
      const data = res.data;
      console.log(data);
      return data;
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    getData(utilityUrl)
      .then((res) => {
        setUtility(res);
      })
      .catch((err) => console.log(err));
  }, []);

  useEffect(() => {
    getData(transactionsurl)
      .then((res) => {
        setTransactions({
          ...transactions,
          deposits: res.filter((transaction) => {
            return transaction.transaction_type === "Deposit";
          }),
          withdrawals: res.filter((transaction) => {
            return transaction.transaction_type === "Withdrawal";
          }),
          internals: res.filter((transaction) => {
            return transaction.transaction_type === "Internal";
          }),
          allTransactions: res,
        });
        setTransactionsSum({
          ...transactionsSum,
          depositsSum: res.reduce((acc, curr) => {
            if (curr.transaction_type === "Deposit") {
              return acc + curr.amount;
            }
            return acc;
          }, 0),
          withdrawalsSum: res.reduce((acc, curr) => {
            if (curr.transaction_type === "Withdrawal") {
              return acc + curr.amount;
            }
            return acc;
          }, 0),
          internalSum: res.reduce((acc, curr) => {
            if (curr.transaction_type === "Internal") {
              return acc + curr.amount;
            }
            return acc;
          }, 0),
        });

        setFilteredSum({
          ...filteredSum,
          depositsSum: res.reduce((acc, curr) => {
            if (curr.transaction_type === "Deposit") {
              return acc + curr.amount;
            }
            return acc;
          }, 0),
          withdrawalsSum: res.reduce((acc, curr) => {
            if (curr.transaction_type === "Withdrawal") {
              return acc + curr.amount;
            }
            return acc;
          }, 0),
          internalSum: res.reduce((acc, curr) => {
            if (curr.transaction_type === "Internal") {
              return acc + curr.amount;
            }
            return acc;
          }, 0),
        });
        setFilteredTransactions({
          ...filteredTransactions,
          deposits: res.filter((transaction) => {
            return transaction.transaction_type === "Deposit";
          }),
          withdrawals: res.filter((transaction) => {
            return transaction.transaction_type === "Withdrawal";
          }),
          internals: res.filter((transaction) => {
            return transaction.transaction_type === "Internal";
          }),
          allTransactions: res,
        });
        setLoading(false);
      })
      .catch((err) => console.log(err));
    getData(earningsurl)
      .then((res) => {
        setEarnings(res);
        setFilteredEarnings(res);
        setEarningsSum(
          res.reduce((acc, curr) => {
            return acc + curr.cash;
          }, 0)
        );
        setClientEarningsSum(
          res.reduce((acc, curr) => {
            return acc + curr.user_cash;
          }, 0)
        );
        setLoading(false);
      })
      .catch((err) => console.log(err));
  }, []);

  useEffect(() => {
    if (startDate && endDate) {
      setFilteredTransactions({
        ...filteredTransactions,
        deposits: transactions.deposits.filter((transaction) => {
          const transactionDate = new Date(transaction.timestamp);
          return (
            transactionDate >= new Date(startDate) &&
            transactionDate <= new Date(endDate)
          );
        }),
        withdrawals: transactions.withdrawals.filter((transaction) => {
          const transactionDate = new Date(transaction.timestamp);
          return (
            transactionDate >= new Date(startDate) &&
            transactionDate <= new Date(endDate)
          );
        }),
        internals: transactions.internals.filter((transaction) => {
          const transactionDate = new Date(transaction.timestamp);
          return (
            transactionDate >= new Date(startDate) &&
            transactionDate <= new Date(endDate)
          );
        }),
        allTransactions: transactions.allTransactions.filter((transaction) => {
          const transactionDate = new Date(transaction.timestamp);
          return (
            transactionDate >= new Date(startDate) &&
            transactionDate <= new Date(endDate)
          );
        }),
      });
      setFilteredSum({
        ...filteredSum,
        depositsSum: filterObjectsByDateRange(
          transactions.deposits,
          startDate,
          endDate
        ).reduce((acc, curr) => {
          return acc + curr.amount;
        }, 0),
        withdrawalsSum: filterObjectsByDateRange(
          transactions.withdrawals,
          startDate,
          endDate
        ).reduce((acc, curr) => {
          return acc + curr.amount;
        }, 0),
        internalSum: filterObjectsByDateRange(
          transactions.internals,
          startDate,
          endDate
        ).reduce((acc, curr) => {
          return acc + curr.amount;
        }, 0),
      });
      setFilteredEarnings(
        filterObjectsByDateRange(earnings, startDate, endDate)
      );
      setEarningsSum(
        filterObjectsByDateRange(earnings, startDate, endDate).reduce(
          (acc, curr) => {
            return acc + curr.cash;
          },
          0
        )
      );
      setClientEarningsSum(
        filterObjectsByDateRange(earnings, startDate, endDate).reduce(
          (acc, curr) => {
            return acc + curr.user_cash;
          },
          0
        )
      );
    }
  }, [startDate, endDate]);

  useEffect(() => {
    if (targetDate) {
      setFilteredTransactions({
        ...filteredTransactions,
        deposits: specificDateFilter(transactions.deposits, targetDate),
        withdrawals: specificDateFilter(transactions.withdrawals, targetDate),
        internals: specificDateFilter(transactions.internals, targetDate),
        allTransactions: specificDateFilter(
          transactions.allTransactions,
          targetDate
        ),
      });
      setFilteredSum({
        ...filteredSum,
        depositsSum: specificDateFilter(
          transactions.deposits,
          targetDate
        ).reduce((acc, curr) => {
          return acc + curr.amount;
        }, 0),
        withdrawalsSum: specificDateFilter(
          transactions.withdrawals,
          targetDate
        ).reduce((acc, curr) => {
          return acc + curr.amount;
        }, 0),
        internalSum: specificDateFilter(
          transactions.internals,
          targetDate
        ).reduce((acc, curr) => {
          return acc + curr.amount;
        }, 0),
      });
      setFilteredEarnings(specificDateFilter(earnings, targetDate));
      setEarningsSum(
        specificDateFilter(earnings, targetDate).reduce((acc, curr) => {
          return acc + curr.cash;
        }, 0)
      );
      setClientEarningsSum(
        specificDateFilter(earnings, targetDate).reduce((acc, curr) => {
          return acc + curr.user_cash;
        }, 0)
      );
    }
  }, [targetDate]);

  console.log(utility);

  return !loading ? (
    <Col>
      <Card>
        <Card.Header>
          <h4>Transactions {filteredTransactions?.allTransactions.length}</h4>
        </Card.Header>
        <Card.Body>
          <Row>
            <Col sm={12} lg={12}>
              <form className="d-flex justify-content-between">
                <Row>
                  <Col lg={4} sm={6}>
                    <Stack className="m-2">
                      <label htmlFor="startDate" style={{ color: "#006a78" }}>
                        Start Date
                      </label>
                      <input
                        type="date"
                        onChange={(event) => {
                          setStartDate(event.target.value);
                        }}
                      />
                    </Stack>
                  </Col>
                  <Col lg={4} sm={6}>
                    <Stack className="m-2">
                      <label htmlFor="startDate" style={{ color: "#006a78" }}>
                        End Date
                      </label>
                      <input
                        type="date"
                        onChange={(event) => {
                          setEndDate(event.target.value);
                        }}
                      />
                    </Stack>
                  </Col>
                  <Col lg={4} sm={6}>
                    <Stack className="m-2">
                      <label htmlFor="startDate" style={{ color: "#006a78" }}>
                        Specific Date
                      </label>
                      <input
                        type="date"
                        onChange={(event) => {
                          setTargetDate(event.target.value);
                        }}
                      />
                    </Stack>
                  </Col>
                </Row>
              </form>
            </Col>
          </Row>

          <Row>
            <Col lg={4} sm={6}>
              <Card className="mt-2">
                <Card.Body>
                  <Row>
                    <Col lg={7} sm={6}>
                      <Link to="/siteadmin/transactions/#deposits">
                        <h6>Deposits</h6>
                        <h4>{filteredTransactions.deposits.length} </h4>
                        <h6>Kshs {filteredSum.depositsSum}</h6>
                      </Link>
                    </Col>
                    <Col
                      lg={5}
                      sm={6}
                      className="d-flex justify-content-center align-items-center">
                      <img
                        src={depositsimg}
                        alt="writingpng"
                        className="admin_dash_icon"
                      />
                    </Col>
                  </Row>
                </Card.Body>
              </Card>
            </Col>
            <Col lg={4} sm={6}>
              <Card className="mt-2">
                <Card.Body>
                  <Row>
                    <Col lg={7} sm={6}>
                      <Link
                        to="/siteadmin/transactions/#withdrawals"
                        style={{
                          textDecoration: "none",
                          textDecorationColor: "none",
                        }}>
                        <h6>Withdrawals</h6>
                        <h4>{filteredTransactions.withdrawals.length} </h4>
                        <h6>Kshs {filteredSum.withdrawalsSum} </h6>
                      </Link>
                    </Col>
                    <Col
                      lg={5}
                      sm={6}
                      className="d-flex justify-content-center align-items-center">
                      <img
                        src={withdrawalimg}
                        alt="writingpng"
                        className="admin_dash_icon"
                      />
                    </Col>
                  </Row>
                </Card.Body>
              </Card>
            </Col>
            <Col lg={4} sm={6}>
              <Card className="mt-2">
                <Card.Body>
                  <Row>
                    <Col lg={6}>
                      <h6>Internal</h6>
                      <h4>{filteredTransactions.internals.length} </h4>
                      <h6>Kshs {filteredSum.internalSum}</h6>
                    </Col>
                    <Col
                      lg={6}
                      className="d-flex justify-content-center align-items-center">
                      <img
                        src={transactionimg}
                        alt="writingpng"
                        className="admin_dash_icon"
                      />
                    </Col>
                  </Row>
                </Card.Body>
              </Card>
            </Col>
            <Col lg={4} sm={6}>
              <Card className="mt-2">
                <Card.Body>
                  <Row>
                    <Col lg={6}>
                      <h6>User's Earnings</h6>
                      <h4> {filteredEarnings?.length} </h4>
                      <h6>Kshs {clientEarningsSum}</h6>
                    </Col>
                    <Col
                      lg={6}
                      className="d-flex justify-content-center align-items-center">
                      <img
                        src={transactionimg}
                        alt="writingpng"
                        className="admin_dash_icon"
                      />
                    </Col>
                  </Row>
                </Card.Body>
              </Card>
            </Col>
            <Col lg={4} sm={6}>
              <Card className="mt-2">
                <Card.Body>
                  <Row>
                    <Col lg={6}>
                      <h6> Income</h6>
                      <h4> {filteredEarnings?.length} </h4>
                      <h6>Kshs {earningsSum}</h6>
                    </Col>
                    <Col
                      lg={6}
                      className="d-flex justify-content-center align-items-center">
                      <img
                        src={transactionimg}
                        alt="writingpng"
                        className="admin_dash_icon"
                      />
                    </Col>
                  </Row>
                </Card.Body>
              </Card>
            </Col>
            <Col lg={4} sm={6}>
              <Card className="mt-2">
                <Card.Body>
                  <Row>
                    <Col lg={6}>
                      <h6> B2C Balance</h6>
                      <h4> </h4>
                      <h6>Kshs {utility?.utility_account_funds}</h6>
                    </Col>
                    <Col
                      lg={6}
                      className="d-flex justify-content-center align-items-center">
                      <img
                        src={transactionimg}
                        alt="writingpng"
                        className="admin_dash_icon"
                      />
                    </Col>
                  </Row>
                </Card.Body>
              </Card>
            </Col>
          </Row>
        </Card.Body>
      </Card>
    </Col>
  ) : (
    <Row>
      <Col
        lg={12}
        sm={12}
        md={12}
        className="loader d-flex justify-content-center align-items-center"
        style={{ height: "20vh" }}>
        <Spinner animation="border" variant="success" />
      </Col>
    </Row>
  );
}

export default Transactions;
