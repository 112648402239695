import React, { useEffect, useState } from "react";
import {  Card, Stack } from "react-bootstrap";
import { useLocation } from "react-router-dom";
import { BiArrowBack } from "react-icons/bi";
import { useNavigate } from "react-router-dom";
import parse from "html-react-parser";
import axios from "axios";

function DisputeView() {
  const disputeUrl = `${process.env.REACT_APP_API_URL}/accounts/single_dispute`;
  const location = useLocation();
  const navigate = useNavigate();
  const [project, setProject] = useState({
    dispute: {},
    title: "",
  });
  const [dispute, setDispute] = useState(null);
  useEffect(() => {
    const project = JSON.parse(localStorage.getItem("disputeProject"));
    
    if (project) {
      setProject({
        ...project,
        dispute: project.dispute,
        title: project.title,
      });
    }
  }, []);
  
  const getData = async (url, projectid) => {
    const config = {
      headers: {
        Authorization: `JWT ${localStorage.getItem("access")}`,
        "Content-Type": "application/json",
      },
      params: {
        projectid: projectid,
      },
    };

    try {
      const res = await axios.get(url, config);
      const data = res.data;
      return data;
    } catch (error) {
      console.error(`Could not post data : ${error}`);
      return error;
    }
  };
  useEffect(() => {
    if (localStorage.getItem("disputeProject")) {
      getData(disputeUrl, JSON.parse(localStorage.getItem("disputeProject")).id)
        .then((res) => {
          console.log(res);
          setDispute(res);
        })

        .catch((err) => console.log(err));
    }
  }, []);
  return (
    <div>
      <div className="pagetitle">
        {" "}
        <BiArrowBack onClick={() => navigate(-1)} className="pagenavbutton" />
        <h1 className="title">Dispute Reasons</h1>
      </div>
      <Card className="itemscontainerwithtabs" style={{ height: "83vh" }}>
        <Card.Header as="h5" className="text-center">
          {project.title}
        </Card.Header>
        <Card.Body style={{ overflowY: "auto" }}>
          <Card>
            <Card.Header as="h6" className="text-center">
              Employer Dispute
            </Card.Header>
            <Card.Body>
              {project.dispute.employer_reason}
              {dispute && dispute.dispute_files.length > 0 && (
                <Stack className="mt-2">
                  <h6>Employer Evidence Files</h6>
                  {dispute.dispute_files.map((file) => (
                    <a href={process.env.REACT_APP_API_URL + file.disputefile}>
                      {file.filename}
                      download
                    </a>
                  ))}
                </Stack>
              )}
            </Card.Body>
          </Card>
          {project.dispute.writer_reason && (
            <Card style={{ marginTop: "1vh" }}>
              <Card.Header as="h6" className="text-center">
                Writer Dispute
              </Card.Header>
              <Card.Body>{parse(project.dispute.writer_reason)}</Card.Body>
            </Card>
          )}
          {project.dispute.essayhost_solution && (
            <Card style={{ marginTop: "1vh" }}>
              <Card.Header as="h6" className="text-center">
                EssaysHost Resolution
              </Card.Header>
              <Card.Body>{project.dispute.essayhost_solution}</Card.Body>
            </Card>
          )}
        </Card.Body>
      </Card>
    </div>
  );
}

export default DisputeView;
