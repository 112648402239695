import React, { useEffect, useState } from "react";
import { Col, Card, Stack } from "react-bootstrap";
import { useLocation } from "react-router-dom";
import { BiArrowBack } from "react-icons/bi";
import { useNavigate } from "react-router-dom";
import parse from "html-react-parser";
import axios from "axios";

function RevisionInstructions() {
  const location = useLocation();
  const navigate = useNavigate();
  const [revision, setRevision] = useState(null);

  const [projectInstructions, setProjectInstructions] = useState("");
  const [projectTitle, setProjectTitle] = useState("");
  const [projectID, setProjectID] = useState(0);
  useEffect(() => {
    const project = JSON.parse(localStorage.getItem("revisionProject"));
    console.log(project);
    if (project) {
      setProjectInstructions(project.projectrevision[0].instructions);
      setProjectTitle(project.title);
      setProjectID(project.id);
    }
  }, []);

  const revisionUrl = `${process.env.REACT_APP_API_URL}/accounts/single_revision`;
  const getData = async (url, projectid) => {
    const config = {
      headers: {
        Authorization: `JWT ${localStorage.getItem("access")}`,
        "Content-Type": "application/json",
      },
      params: {
        projectid: projectid,
      },
    };

    try {
      const res = await axios.get(url, config);
      const data = res.data;
      return data;
    } catch (error) {
      console.error(`Could not post data : ${error}`);
      return error;
    }
  };
  useEffect(() => {
    if (localStorage.getItem("revisionProject")) {
      getData(
        revisionUrl,
        JSON.parse(localStorage.getItem("revisionProject")).id
      )
        .then((res) => {
          console.log(res);
          setRevision(res);
        })

        .catch((err) => console.log(err));
    }
  }, []);

  return (
    <Col className="mainarea">
      <div className="pagetitle">
        <BiArrowBack onClick={() => navigate(-1)} className="pagenavbutton" />
        <h1 className="title">
          Revision Instructions for Project ID #{projectID}
        </h1>
      </div>
      <div className="itemscontainer">
        <Card>
          <Card.Header as="h5" className="text-center">
            {" "}
            {projectTitle}
          </Card.Header>
          <Card.Body>
            {parse(projectInstructions)}
            {revision && revision.revision_files.length > 0 && (
              <Stack className="mt-2">
                <h6>Additional Files</h6>
                {revision.revision_files.map((file) => (
                  <a href={process.env.REACT_APP_API_URL + file.revisionfile}>
                    {file.filename}
                  </a>
                ))}
              </Stack>
            )}
          </Card.Body>
        </Card>
      </div>
    </Col>
  );
}

export default RevisionInstructions;
