import React, { useEffect, useState } from "react";
import Empty from "../global/Empty";
import axios from "axios";
import {
  Col,
  Card,
  Row,
  Spinner,
  Toast,
  ToastContainer,
} from "react-bootstrap";
import { HiOutlineUserCircle } from "react-icons/hi";
import getData from "../../customHooks/getData";

function EmploymentRequests({state, setState}) {
  const [employments, setEmployments] = useState([]);
  const [loading, setLoading] = useState(false);
  const [acceptLoading, setAcceptLoading] = useState(false);
  const [notification, setNotification] = useState("");
  const url = `${process.env.REACT_APP_API_URL}/writersadmin/get_employers`;
  const employmentAcceptance = async (writerResponse, employer_id) => {
    const config = {
      headers: {
        Authorization: `JWT ${localStorage.getItem("access")}`,
        "Content-Type": "application/json",
        Accept: "application/json",
      },
    };
    const body = JSON.stringify({ writerResponse, employer_id });
    const url = `${process.env.REACT_APP_API_URL}/writersadmin/employment_acceptance`;
    try {
      const res = await axios.post(url, body, config);
      setNotification(res.data.message);

      return res.data;
    } catch (err) {
      console.log(err);
      setNotification(err.response.data.message);
    }
  };

  

  const get_employment_requests = async () => {
    const config = {
      headers: {
        Authorization: `JWT ${localStorage.getItem("access")}`,
        "Content-Type": "application/json",
        Accept: "application/json",
      },
    };
    const url = `${process.env.REACT_APP_API_URL}/writersadmin/employment_requests`;

    try {
      const res = await axios.get(url, config);
      return res.data;
    } catch (err) {
      console.log(err);
    }
  };

  useEffect(() => {
    get_employment_requests()
      .then((res) => {
        console.log(res);
        setEmployments(res);
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);

  useEffect(() => {
    getData(url)
      .then((res) => {
        setState({
          ...state,
          employers: res,
          loading: false,
        });
      })
      .catch((err) => {
        console.log(err);
        setState({
          ...state,
          loading: false,
        });
      });
     get_employment_requests()
      .then((res) => {
        console.log(res);
        setEmployments(res);
      })
      .catch((err) => {
        console.log(err);
      });
  }, [notification]);

  return employments.length > 0 ? (
    <Card className="itemscontainerwithtabs">
      {employments.map((employment) => {
        return (
          <Row className="m-3">
            <ToastContainer position="top-end">
              <Toast
                className={
                  notification === "You have accepted the request"
                    ? "bg-success text-white"
                    : "bg-danger text-white"
                }
                onClose={() => setNotification("")}
                show={notification !== ""}
                delay={3000}
                autohide>
                <Toast.Body>{notification}</Toast.Body>
              </Toast>
            </ToastContainer>
            <Col sm={3} lg={3} xl={3} className="p-3">
              {employment.employer.profile_pic !== null ? (
                <img
                  src={
                    process.env.REACT_APP_API_URL +
                    employment.employer.profile_pic
                  }
                  className="chat_image"
                  alt="employer_image"
                />
              ) : (
                <HiOutlineUserCircle className="chat_no_image" />
              )}{" "}
              {employment.employer.first_name} {employment.employer.last_name}
            </Col>
            <Col sm={12} lg={12} xl={12} md={12} className="p-4">
              {employment.message}
              <div className="d-flex justify-content-between mt-2">
                <button
                  disabled={loading || acceptLoading}
                  className="btn btn-sm btn-outline-success"
                  onClick={() => {
                    setAcceptLoading(true);
                    const accept = true;
                    employmentAcceptance(accept, employment.employer.id)
                      .then((res) => {
                        console.log(res);
                        setAcceptLoading(false);
                        get_employment_requests();
                      })
                      .catch((err) => {
                        console.log(err);
                        setAcceptLoading(false);
                      });
                  }}>
                  {acceptLoading ? (
                    <>
                      <Spinner as="span" variant="border" size="sm" />{" "}
                      Accepting...
                    </>
                  ) : (
                    <>Accept</>
                  )}
                </button>
                <button
                  disabled={loading || acceptLoading}
                  className="btn btn-sm btn-outline-danger"
                  onClick={() => {
                    setLoading(true);
                    const reject = false;
                    employmentAcceptance(reject, employment.employer.id)
                      .then((res) => {
                        console.log(res);
                        setLoading(false);
                        get_employment_requests();
                      })
                      .catch((err) => {
                        console.log(err);
                        setLoading(false);
                      });
                  }}>
                  {loading ? (
                    <>
                      <Spinner as="span" variant="border" size="sm" />{" "}
                      Rejecting..
                    </>
                  ) : (
                    <>Reject</>
                  )}
                </button>
              </div>
            </Col>
          </Row>
        );
      })}
    </Card>
  ) : (
    <Empty message="No Employment Requests Made" />
  );
}

export default EmploymentRequests;
