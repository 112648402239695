import React, { useEffect, useState } from "react";
import axios from "axios";
import { Stack, Row, Col, Container, Card } from "react-bootstrap";

import Projects from "./dashComponents/Projects";
import Busy from "../global/Busy";

function AdminDash() {
  const usersurl = `${process.env.REACT_APP_API_URL}/siteadmin/users`;

  const projectsurl = `${process.env.REACT_APP_API_URL}/siteadmin/all_projects`;

  const [writers, setWriters] = useState(null);
  const [employers, setEmployers] = useState(null);
  const [admins, setAdmins] = useState(null);
  const [earnings, setEarnings] = useState(null);
  const [loading, setLoading] = useState(true);

  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);

  const [projects, setProjects] = useState(null);
  const [targetDate, setTargetDate] = useState(null);

  console.log(projects);

  console.log(startDate, endDate);

  const getData = async (url) => {
    const config = {
      headers: {
        Authorization: `JWT ${localStorage.getItem("access")}`,
        "Content-Type": "application/json",
        Accept: "application/json",
      },
    };

    try {
      const res = await axios.get(url, config);
      const data = res.data;
      console.log(data);
      setLoading(false);
      return data;
    } catch (error) {
      console.error(error);
      setLoading(false);
    }
  };

  useEffect(() => {
    getData(usersurl)
      .then((res) => {
        const writer = res.filter((user) => {
          return user.role === "Writer";
        });
        setWriters(writer);
        const employer = res.filter((user) => {
          return user.role === "Employer";
        });
        setEmployers(employer);
        const admins = res.filter((user) => {
          return user.role === "Admin";
        });
        setAdmins(admins);
      })
      .catch((err) => console.log(err));
    getData(projectsurl).then((res) => setProjects(res));
  }, []);

  const items = [
    {
      name: "Employers",
      total: employers?.length,
      color: "light-blue",
    },
    {
      name: "Writers",
      total: writers?.length,
      color: "#fffff",
    },
    {
      name: "Admins",
      total: admins?.length,
      color: "light-green",
    },
    {
      name: "Projects",
      total: projects?.length,
      color: "gold",
    },
  ];
  return loading ? (
    <Busy />
  ) : (
    <Container fluid>
      <Row className="page-header-row">
        <Col lg={12} md={12} xs={12} className="m-0 page-header">
          <div>
            <div className="d-flex justify-content-between">
              <div>
                <h3 className=" text-white">Essays' Host Admin</h3>
              </div>
              <div></div>
            </div>
          </div>
        </Col>
        {items?.map((item, index) => {
          return (
            <Col xl={3} lg={3} md={4} xs={6} key={index}>
              <Card
                bg={item.color}
                className="custom-card text-center p-3 d-flex align-items-start ">
                <h6>{item.name}</h6>
                <h4>{item.total}</h4>
              </Card>
            </Col>
          );
        })}
      </Row>

      <Row className="custom-row">
        <Col>
          <Projects />
        </Col>
        {/* card  */}
      </Row>
    </Container>
  );
}

export default AdminDash;
