import React, { useEffect, useState, useRef } from "react";
import { Container, Row, Modal } from "react-bootstrap";
import Navigation from "./Navigation";
import { connect, useDispatch } from "react-redux";
import { checkAuthenticated } from "../actions/auth";
import { Outlet } from "react-router-dom";
import Home from "../pages/Home";
import { get_user } from "../actions/myuser";
import Busy from "./global/Busy";
import { useNavigate, useLocation } from "react-router-dom";
import ReconnectingWebSocket from "reconnecting-websocket";
import { USER_COUNTRY } from "../actions/types";
import { useSelector } from "react-redux";




function Layout({ checkAuthenticated, authenticated, user, get_user }) {
  const location = useLocation();
  const navigate = useNavigate();
  const [loading, setLoading] = useState(true);
  const [installModal, setInstallModal] = useState(false);
  const [deferredPrompt, setDeferredPrompt] = useState(null);
  const installButtonRef = useRef(null);
  const dispatch = useDispatch();
  const country = useSelector((state) => state.userCountry.country);
  const path = location.pathname;

  useEffect(() => {
    // if (!country) {
    //       axios
    //         .get(
    //           "https://api.ipgeolocation.io/ipgeo?apiKey=d5d9b0aeca894200862f3d567966c24b"
    //         )
    //         .then((res) => {
    //           const data = res.data;
    //           dispatch({ type: USER_COUNTRY, payload: data.country_name });
    //         })
    //         .catch((error) => {
    //           console.error(error);
    //           dispatch({ type: USER_COUNTRY, payload: "Kenya" });
    //         });
    //  }
    dispatch({ type: USER_COUNTRY, payload: "Kenya" });
  },[])

  useEffect(() => {
    localStorage.setItem("location", location.pathname)
  }, [location.pathname]);

  console.log(location.pathname)

  useEffect(() => {
    if (user && authenticated) {
      const newSocket = new ReconnectingWebSocket(
        `${process.env.REACT_APP_WEBSOCKET_URL}/ws/check_online/${user?.id}`
      );
      newSocket.addEventListener("open", () => {
        console.log("Online Check open");
      });
      document.addEventListener("visibilitychange", function () {
        if (document.hidden) {
          // The page is not visible
          const data = {
            userid: user?.id,
            online: false,
          };
          newSocket.send(JSON.stringify(data));
        } else {
          // The page is visible
          const data = {
            userid: user?.id,
            online: true,
          };
          newSocket.send(JSON.stringify(data));
        }
      });
      window.addEventListener("beforeUnload", () => {
        // Your cleanup logic here
        const data = {
          user_id: user?.id,
          online: false,
        };
        newSocket.send(JSON.stringify(data));
      });
    }
  }, []);

  useEffect(() => {
    if (user && authenticated) {
      const newSocket = new ReconnectingWebSocket(
        `${process.env.REACT_APP_WEBSOCKET_URL}/ws/check_online/${user?.id}`
      );
      if (navigator.onLine) {
        const data = {
          userid: user?.id,
          online: true,
        };
        newSocket.send(JSON.stringify(data));
      } else {
        const data = {
          userid: user?.id,
          online: false,
        };
        newSocket.send(JSON.stringify(data));
      }
    }
  }, [navigator.onLine]);

  useEffect(() => {
    get_user();
    checkAuthenticated()
      .then(() => {
        setLoading(false);
      })
      .catch((err) => {
        setLoading(false);
      });
  }, []);
  

  useEffect(() => {
    if (user && authenticated) {
      

      // Check if the current path includes '/employer/transactions'
      if (!path.startsWith("/employer/transactions")) {
       if (user.role === "Employer") {
        navigate("/employer");
      } else if (user.role === "Writer") {
        navigate("/writer");
      } else if (user.role === "Admin" || user.role === "Manager") {
        navigate("/siteadmin");
      } 
      } 
    }
  }, [user, authenticated]);


 

  return (
    <Container fluid >
      {authenticated && user ? (
        
       
            <Outlet />
        
      ) : (
        <Row style={{overflowX: "hidden"}} id="homepage">
          <Navigation />
          <Home />
        </Row>
      )}
    </Container>
  );
}
const mapStateToProps = (state) => ({
  authenticated: state.auth.isAuthenticated,
  access: state.auth.access,
  user: state.auth.user,
});
export default connect(mapStateToProps, { checkAuthenticated, get_user })(
  Layout
);
