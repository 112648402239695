import axios from "axios";
import React, { useEffect, useState } from "react";
import {
  Accordion,
  Col,
  Spinner,
  Modal,
  Toast,
  ToastContainer,
} from "react-bootstrap";

import { useNavigate } from "react-router-dom";
import parse from "html-react-parser";
import { postData } from "../../customHooks/postdata";
import Empty from "../global/Empty";
import Busy from "../global/Busy";
import { RiDeleteBinLine, RiEdit2Line } from "react-icons/ri";

function WriterBids() {
  const navigate = useNavigate()
  const [state, setState] = useState({
    currentPage: 1,
    bid: [],
    loading: false,
    postsPerPage: 15,
  });
  const [notification, setNotification] = useState("");

  const display_bids = async () => {
    const config = {
      headers: {
        Authorization: `JWT ${localStorage.getItem("access")}`,
        "Content-Type": "application/json",
        Accept: "application/json",
      },
    };
    try {
      const res = await axios.get(
        `${process.env.REACT_APP_API_URL}/accounts/userbids`,
        config
      );
      console.log(res.data);
      setState({
        ...state,
        bid: res.data,

        loading: false,
      });
    } catch (err) {
      console.log(err);
    }
  };



  useEffect(() => {
    setState({ ...state, loading: true });
    display_bids();
  }, [notification]);

  const deletUrl = `${process.env.REACT_APP_API_URL}/accounts/delete_bid`;
  const [show, setShow] = useState(false);
  const [bidid, setBidid] = useState("");

  const delete_bid = () => {
    setState({ ...state, loading: true });
    postData(deletUrl, bidid)
      .then((res) => {
        console.log(res);
        setNotification("Bid Successfully Deleted");
        setState({ ...state, loading: false });
        setShowToast(true);
        setShow(false);
      })
      .catch((err) => {
        console.log(err);
        setShowToast(true);
        setState({ ...state, loading: false });
        setNotification("Error Deleting Bid");
        setShow(false);
      });
  };

  const showModal = (id) => {
    setBidid(id);
    setShow(true);
  };


  const indexOfLastBid = state.currentPage * state.postsPerPage;
  const indexOfFirstBid = indexOfLastBid - state.postsPerPage;
  const currentBids = state.bid
    ? state.bid.slice(indexOfFirstBid, indexOfLastBid)
    : [];

  //Array holding page numbers
  const pageNum = [];

  //Depending on the number of bids  and posts per page available generate page numbers
  for (
    let i = 1;
    i <= Math.ceil(state.bid ? state.bid.length / state.postsPerPage : 0);
    i++
  ) {
    pageNum.push(i);
  }

  const setPage = (pageNumber) => {
    setState({ ...state, currentPage: pageNumber });
  };

  const [showToast, setShowToast] = useState(false);
  useEffect(() => {
    if (notification) {
      setShowToast(true);
    }
  }, [notification]);

  return (
    <Col className="mainarea">
      <ToastContainer
        position="top-end"
        className="p-2 mt-5"
        style={{ zIndex: 99999 }}>
        <Toast
          show={showToast}
          autohide
          delay={3000}
          className={
            notification === "Bid Successfully Deleted"
              ? "bg-success text-white"
              : "bg-danger text-white"
          }
          onClose={() => {
            setShowToast(false);
            setNotification("");
          }}>
          <Toast.Body>{notification}</Toast.Body>
        </Toast>
      </ToastContainer>
      {state.loading ? (
        <Busy />
      ) : (
        <>
          <div className="pagetitle mt-2 mb-2">
            <h1 className="title">
              My Bids {state.bid ? state.bid.length : 0}
            </h1>
          </div>
          <Accordion>
            <Accordion.Item eventKey="0">
              <Accordion.Header className="accordionheader">
                <table>
                  <thead>
                    <th>ORDER ID</th>
                    <th>ORDER FIELD</th>
                    <th></th>
                    <th>BID AMOUNT</th>
                  </thead>
                </table>
              </Accordion.Header>
            </Accordion.Item>
          </Accordion>

          <Accordion>
            {currentBids.length === 0 ? (
              <>
                <Empty message="No bids Placed" />
              </>
            ) : (
              <>
                
                {currentBids.map((bid) => {
                  return (
                    <div key={bid.id}>
                      <Accordion.Item eventKey={bid.id}>
                        <Accordion.Header>
                          <table>
                            <tbody>
                              <td
                                style={{
                                  color: "green",
                                  fontWeight: "bold",
                                }}>
                                #{bid.project.id}
                              </td>
                              <td>{bid.project.subject}</td>
                              <td></td>
                              <td>Kshs: {bid.amount}/=</td>
                            </tbody>
                          </table>
                        </Accordion.Header>
                        <Accordion.Body>
                          <h5 className="text-center">Proposal</h5>
                          <h6>Order Title: {bid.project.title}</h6>
                          {parse(bid.description)}
                          <hr />
                          <div className="d-flex justify-content-around">
                            <button
                              className="btn btn-sm btn-outline-success"
                              onClick={() => {
                                localStorage.setItem(
                                  "bid",
                                  JSON.stringify(bid)
                                );
                                navigate("/writer/bids/editbid");
                              }}>
                              <RiEdit2Line /> Edit
                            </button>
                            <button
                              className="btn btn-sm btn-outline-danger"
                              Delete
                              onClick={() => {
                                showModal(bid.id);
                              }}>
                              <RiDeleteBinLine /> Delete
                            </button>
                          </div>
                        </Accordion.Body>
                      </Accordion.Item>
                    </div>
                  );
                })}
              </>
            )}
          </Accordion>

          <div className="paginationcontainer d-flex justify-content-center">
            {pageNum.map((num, index) => {
              return (
                <p
                  key={index}
                  className={
                    num === state.currentPage
                      ? "text-light bg-success paginationitem"
                      : "text-dark bg-light paginationitem"
                  }
                  onClick={() => {
                    setPage(num);
                  }}>
                  {num}
                </p>
              );
            })}
          </div>
        </>
      )}
      <Modal
        show={show}
        onHide={() => setShow(false)}
        centered
        keyboard={false}>
        <Modal.Header closeButton>
          <Modal.Title>
            <h5 className="text-center">Delete Proposal</h5>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <p>
            !!!When you Click on the DELETE Button this Proposal and all its
            related contents is going to be deleted permanently
          </p>
        </Modal.Body>
        <Modal.Footer className="d-flex justify-content-between">
          <button
            className="btn btn-sm btn-outline-danger"
            onClick={delete_bid}>
            {state.loading ? (
              <span>
                <Spinner as="span" variant="border" size="sm" />
                ...Deleting
              </span>
            ) : (
              <span><RiDeleteBinLine/> Delete</span>
            )}
          </button>
          <button
            className="btn btn-sm btn-outline-success"
            onClick={() => setShow(false)}>
            Cancel
          </button>
        </Modal.Footer>
      </Modal>
    </Col>
  );
}

export default WriterBids;
