import React, { useEffect, useState } from "react";
import { Col, Card, Modal, Spinner, Stack } from "react-bootstrap";
import { BiArrowBack } from "react-icons/bi";
import { useNavigate, Link } from "react-router-dom";
import parse from "html-react-parser";
import axios from "axios";

function ViewDispute() {
  const navigate = useNavigate();
  const [accept, setAccept] = useState(false);
  const handleAccept = () => setAccept(true);
  const handleClose = () => setAccept(false);
  const acceptUrl = `${process.env.REACT_APP_API_URL}/accounts/accept_dispute`;
  const disputeUrl = `${process.env.REACT_APP_API_URL}/accounts/single_dispute`;
  const [loading, setLoading] = useState(false);
  const [project, setProject] = useState({
    projectid: 0,
    title: "",
    escalated: false,
    dispute: {
      employer_reason: "",
      writer_reason: "",
      essayhost_solution: ""
    },
    disputed: false,
  });
  const [dispute, setDispute] = useState(null);

  const postData = async (url, body) => {
    const config = {
      headers: {
        Authorization: `JWT ${localStorage.getItem("access")}`,
        "Content-Type": "application/json",
      },
    };

    const jsonbody = JSON.stringify(body);

    try {
      const res = await axios.post(url, jsonbody, config);
      const data = res.data;
      return data;
    } catch (error) {
      console.error(`Could not post data : ${error}`);
      return error;
    }
  };

  const getData = async (url, projectid) => {
    const config = {
      headers: {
        Authorization: `JWT ${localStorage.getItem("access")}`,
        "Content-Type": "application/json",
      },
      params: {
        projectid: projectid,
      },
    };

    try {
      const res = await axios.get(url, config);
      const data = res.data;
      return data;
    } catch (error) {
      console.error(`Could not post data : ${error}`);
      return error;
    }
  };
  useEffect(() => {
    if (localStorage.getItem("disputed_project")) {
      getData(
        disputeUrl,
        JSON.parse(localStorage.getItem("disputed_project")).id
      )
        .then((res) => {
          console.log(res);
          setDispute(res);
        })

        .catch((err) => console.log(err));
    }
  }, []);

  console.log(project);

  useEffect(() => {
    const project = JSON.parse(localStorage.getItem("disputed_project"));
    console.log(project);
    if (project) {
      setProject({
        ...project,
        title: project.title,
        escalated: project.escalated,
        dispute: {
          employer_reason: project.dispute.employer_reason,
          writer_reason: project.dispute.writer_reason,
          essayhost_solution: project.dispute.essayhost_solution,
        },
        disputed: project.disputed,
      });
    }
  }, []);

  return (
    <Col className="mainarea">
      <div className="pagetitle">
        {" "}
        <BiArrowBack onClick={() => navigate(-1)} className="pagenavbutton" />
        <h1 className="title">Dispute Reasons And Solution</h1>
      </div>
      <div className="itemscontainerwithtabs">
        <Card style={{ height: "100%", width: "100%" }}>
          <Card.Header as="h5" className="text-center">
            {project.title}
          </Card.Header>
          <Card.Body style={{ overflowY: " auto" }}>
            <Card>
              <Card.Header as="h6" className="text-center">
                Employer's Reason
              </Card.Header>
              <Card.Body>
                {parse(project?.dispute.employer_reason)}
                {dispute && dispute.dispute_files.length > 0 && (
                  <Stack className="mt-2">
                    <h6>Employer Evidence Files</h6>
                    {dispute.dispute_files.map((file) => (
                      <a
                        href={process.env.REACT_APP_API_URL + file.disputefile}>
                        {file.filename}
                        download
                      </a>
                    ))}
                  </Stack>
                )}
              </Card.Body>
            </Card>

            {project.escalated && (
              <Card style={{ marginTop: "1vh" }}>
                <Card.Header as="h6" className="text-center">
                  My Defence
                </Card.Header>
                <Card.Body>{parse(project.dispute.writer_reason)}</Card.Body>
              </Card>
            )}
            {project?.dispute.essayhost_solution && (
              <Card style={{ marginTop: "1vh" }}>
                <Card.Header as="h6" className="text-center">
                  EssaysHost Admin Resolution
                </Card.Header>
                <Card.Body>{project.dispute.essayhost_solution}</Card.Body>
              </Card>
            )}
          </Card.Body>

          {project.disputed ? (
            <Card.Footer className="d-flex justify-content-around">
              <button
                className="btn btn-outline-success btn-sm"
                onClick={handleAccept}>
                Accept Dispute and Refund
              </button>
              {!project.escalated && (
                <Link
                  to="/writer/escalate"
                  className="btn btn-outline-danger btn-sm"
                  onClick={() =>
                    localStorage.setItem(
                      "disputed_project",
                      JSON.stringify(project)
                    )
                  }>
                  Escalate
                </Link>
              )}
            </Card.Footer>
          ) : null}
        </Card>
      </div>
      <Modal show={accept} onHide={handleClose} centered>
        <Modal.Header closeButton>
          <Modal.Title>
            <h5>Accept Dispute</h5>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <p>
            Are you sure you want to accept the dispute and refund the employer?
          </p>
        </Modal.Body>
        <Modal.Footer className="d-flex justify-content-between">
          <button
            disabled={loading}
            className="btn-sm btn btn-outline-danger"
            onClick={() => {
              setLoading(true);
              postData(acceptUrl, {
                projectid: JSON.parse(localStorage.getItem("disputed_project"))
                  .id,
              })
                .then((res) => {
                  console.log(res);
                  handleClose();
                  navigate("/writer/resolved");
                })
                .catch((err) => {
                  console.log(err);
                  setLoading(false);
                });
            }}>
            {loading ? (
              <>
                {" "}
                <Spinner as="span" size="sm" /> ... Refunding
              </>
            ) : (
              "Yes"
            )}
          </button>
          <button
            className="btn-sm btn btn-outline-success"
            onClick={handleClose}>
            No
          </button>
        </Modal.Footer>
      </Modal>
    </Col>
  );
}

export default ViewDispute;
