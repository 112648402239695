import React, { useEffect, useState } from "react";
import Empty from "../global/Empty";
import axios from "axios";
import { Col, Card, Row, Spinner, Stack } from "react-bootstrap";
import { HiOutlineUserCircle } from "react-icons/hi";
import ReconnectingWebSocket from "reconnecting-websocket";
import { useSelector } from "react-redux/es/hooks/useSelector";

function HireRequests() {
  const [employments, setEmployments] = useState([]);
  const [loading, setLoading] = useState(false);
  const [acceptLoading, setAcceptLoading] = useState(false);
  const [hiredata, setHiredata] = useState(null);

  const user = useSelector((state) => state.current_user.user);

  console.log(user);

  useEffect(() => {
    const hiredata = new ReconnectingWebSocket(
      `${process.env.REACT_APP_WEBSOCKET_URL}/ws/hire/${user.id}`
    );
    hiredata.addEventListener("open", () => {
      console.log("hire socket open");
    });
    hiredata.addEventListener("close", () => {
      console.log("hire socket closed");
    });
    setHiredata(hiredata);
  }, []);

  useEffect(() => {
    if (hiredata) {
      hiredata.addEventListener("message", (e) => {
        const data = JSON.parse(e.data);
        console.log(data);
        setEmployments((prev) => [...prev, data]);
      });
    }
  }, [hiredata]);

  const employmentAcceptance = async (writerResponse, employer_id) => {
    const config = {
      headers: {
        Authorization: `JWT ${localStorage.getItem("access")}`,
        "Content-Type": "application/json",
        Accept: "application/json",
      },
    };
    const body = JSON.stringify({ writerResponse, employer_id });
    const url = `${process.env.REACT_APP_API_URL}/writersadmin/employment_acceptance`;
    try {
      const res = await axios.post(url, body, config);
      return res.data;
    } catch (err) {
      console.log(err);
    }
  };

  const get_employment_requests = async () => {
    const config = {
      headers: {
        Authorization: `JWT ${localStorage.getItem("access")}`,
        "Content-Type": "application/json",
        Accept: "application/json",
      },
    };
    const url = `${process.env.REACT_APP_API_URL}/writersadmin/employer_employment_requests`;

    try {
      const res = await axios.get(url, config);
      return res.data;
    } catch (err) {
      console.log(err);
    }
  };

  useEffect(() => {
    get_employment_requests()
      .then((res) => {
        console.log(res);
        setEmployments(res);
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);

  return employments.length > 0 ? (
    <Card className="itemscontainerwithtabs">
      {employments.map((employment) => {
        return (
          <Row className="m-2">
            <Col sm={3} lg={3} xl={3} className="p-2">
              <Stack
                direction="horizontal"
                gap={1}
                className="d-flex justify-content-start align-items-center">
                <div>
                  {employment.writer.profile_pic !== null ? (
                    <img
                      src={
                        process.env.REACT_APP_API_URL +
                        employment.writer.profile_pic
                      }
                      className="chat_image"
                      alt="employer_image"
                    />
                  ) : (
                    <HiOutlineUserCircle className="chat_no_image" />
                  )}{" "}
                </div>
                <h6>
                  {employment.writer.first_name} {employment.writer.last_name}
                </h6>
              </Stack>
            </Col>
            <Col sm={12} lg={12} xl={12} md={12} className="p-4">
              <p>
                A request has been made to hire {employment.writer.first_name}{" "}
                {employment.writer.last_name}. When they accept the request.
                They will appear in your list of Writers.
              </p>
              <div className="d-flex justify-content-between mt-2">
                <button
                  disabled={loading || acceptLoading}
                  className="btn btn-sm btn-outline-danger"
                  onClick={() => {
                    setLoading(true);
                    const reject = false;
                    employmentAcceptance(reject, employment.employer.id)
                      .then((res) => {
                        console.log(res);
                        setLoading(false);
                        get_employment_requests();
                      })
                      .catch((err) => {
                        console.log(err);
                        setLoading(false);
                      });
                  }}>
                  {loading ? (
                    <>
                      <Spinner as="span" variant="border" size="sm" />{" "}
                      Revoking...
                    </>
                  ) : (
                    <>Revoke</>
                  )}
                </button>
              </div>
            </Col>
          </Row>
        );
      })}
    </Card>
  ) : (
    <Empty message="No Employment Requests Made" />
  );
}

export default HireRequests;
