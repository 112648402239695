import React, { useEffect, useState } from "react";
import {
  Col,
  Accordion,
  Stack,
  Row,
  Spinner,
  Toast,
  ToastContainer,
} from "react-bootstrap";
import { connect } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import { BsFillCalendarDateFill, BsStack } from "react-icons/bs";
import { HiOutlineUserCircle, HiOutlineAcademicCap } from "react-icons/hi";
import { GiThorHammer } from "react-icons/gi";
import { AiOutlineFileText, AiOutlineClockCircle } from "react-icons/ai";
import { RxSpaceEvenlyVertically } from "react-icons/rx";
import { SlSpeech } from "react-icons/sl";
import parse from "html-react-parser";
import axios from "axios";
import moment from "moment";
import Empty from "../global/Empty";
import Busy from "../global/Busy";
import ReconnectingWebSocket from "reconnecting-websocket";
import { GoRepoLocked } from "react-icons/go";
import { LuBookOpenCheck } from "react-icons/lu";
import { BiArrowToLeft, BiArrowToRight } from "react-icons/bi";

//displays all the projects available to a writer for bidding.

function WriterProjects({ user }) {
  const [state, setState] = useState({
    currentPage: 1,
    projects: [],
    postsPerPage: 15,
    loading: true,
  });
  const navigate = useNavigate();
  const [take, setTake] = useState(false);
  const [showToast, setShowToast] = useState(false);
  const [socket, setSocket] = useState(null);
  const [notification, setNotification] = useState("");

  console.log(state.projects);

  useEffect(() => {
    const newSocket = new ReconnectingWebSocket(
      `${process.env.REACT_APP_WEBSOCKET_URL}/ws/project_add/Writer`
    );

    setSocket(newSocket);

    // Clean up the WebSocket connection when the component unmounts

    newSocket.addEventListener("open", () =>
      console.log("WebSocket display projects connected")
    );
    newSocket.addEventListener("close", () =>
      console.log("WebSocket display projects disconnected")
    );

    // Clean up the WebSocket connection when the component unmounts
    return () => {
      newSocket.close();
    };
  }, []);

  useEffect(() => {
    if (socket) {
      socket.addEventListener("message", (event) => {
        const data = JSON.parse(event.data);
        display_projects();
      });
    }
  }, [notification]);

  const take_project = async (projectid) => {
    const config = {
      headers: {
        Authorization: `JWT ${localStorage.getItem("access")}`,
        "Content-Type": "application/json",
        Accept: "application/json",
      },
    };
    const body = JSON.stringify(projectid);
    try {
      const res = await axios.post(
        `${process.env.REACT_APP_API_URL}/accounts/take_project`,
        body,
        config
      );
      setNotification("You have Taken the Project Successfully");
      navigate("/writer/my_projects");
    } catch (err) {
      console.log(err);
      setTake(false);
      setNotification("Taking Project Was Not Successfull !");
    }
  };

  const display_projects = async () => {
    const config = {
      headers: {
        Authorization: `JWT ${localStorage.getItem("access")}`,
        "Content-Type": "application/json",
        Accept: "application/json",
      },
    };
    try {
      const res = await axios.get(
        `${process.env.REACT_APP_API_URL}/accounts/projects`,
        config
      );
      setState({ ...state, projects: res.data, loading: false });
    } catch (err) {
      console.log(err);
    }
  };

  useEffect(() => {
    display_projects();
  }, []);

  let currentProjects = [];
  if (!state.loading) {
    // Get currentProjects
    const indexOfLastProject = state.currentPage * state.postsPerPage;
    const indexOfFirstProject = indexOfLastProject - state.postsPerPage;
    currentProjects = state.projects.slice(
      indexOfFirstProject,
      indexOfLastProject
    );
  }

  // Implement Page Numbers
  const pageNum = [];

  // Depending on the length of the projects available implement the page numbers
  for (
    let i = 1;
    i <= Math.ceil(state.projects.length / state.postsPerPage);
    i++
  ) {
    pageNum.push(i);
  }
  //Set current page
  const setPage = (pageNum) => {
    setState({ ...state, currentPage: pageNum });
  };

  return (
    <Col className="mainarea">
      {state.loading ? (
        <Busy />
      ) : (
        <Stack className="m-0 ">
          <div className="pagetitle mt-2 mb-2">
            <h1 className="title">Available Orders{state.projects.length}</h1>
          </div>
          <Accordion>
            <Accordion.Item>
              <Accordion.Header className="accordionheader">
                <table className="ps-0" width="100%">
                  <col style={{ width: "10%" }} />
                  <col style={{ width: "30%" }} />
                  <col style={{ width: "30%" }} />

                  <col style={{ width: "30%" }} />
                  <thead width="100%">
                    <tr>
                      <th>ID</th>
                      <th>FIELD</th>
                      <th>TYPE</th>
                      <th>BUDGET</th>
                    </tr>
                  </thead>
                </table>
              </Accordion.Header>
            </Accordion.Item>
          </Accordion>
          <div >
            <ToastContainer
              position="top-end"
              className="p-3 mt-5"
              style={{ zIndex: 99999 }}>
              <Toast
                delay={3000}
                autohide
                className={
                  notification === "You have Taken the Project Successfully"
                    ? "bg-success text-white"
                    : "bg-danger text-white"
                }
                show={showToast}
                onClose={() => {
                  setShowToast(false);
                }}>
                <Toast.Body>{notification}</Toast.Body>
              </Toast>
            </ToastContainer>
            <Accordion>
              {state.projects.length === 0 ? (
                <Empty message="No Orders Available" />
              ) : (
                <div className="contentarea">
                  {currentProjects.map((project) => {
                    const writer_id = [];
                    project.bids_on_project?.map((bid) => {
                      return writer_id.push(bid.writer);
                    });
                    //get today's date and time
                    var now = new Date().getTime();
                    //convert the json date to milliseconds
                    var deadline = new Date(`${project.deadline}`).getTime();

                    var remainingtime = deadline - now;

                    function daysminhours(milliseconds) {
                      let seconds = Math.floor(milliseconds / 1000);
                      let minutes = Math.floor(seconds / 60);
                      let hours = Math.floor(minutes / 60);
                      let days = Math.floor(hours / 24);

                      seconds = seconds % 60;
                      // 👇️ if seconds are greater than 30, round minutes up (optional)
                      minutes = seconds >= 30 ? minutes + 1 : minutes;

                      minutes = minutes % 60;

                      // 👇️ If you don't want to roll hours over, e.g. 24 to 00
                      // 👇️ comment (or remove) the line below
                      // commenting next line gets you `24:00:00` instead of `00:00:00`
                      // or `36:15:31` instead of `12:15:31`, etc.
                      hours = hours % 24;

                      if (days > 0) {
                        return `${days}dys: ${hours}hrs : ${minutes}mins`;
                      } else if (hours > 0) {
                        return ` ${hours}hrs : ${minutes}mins`;
                      } else {
                        return `${minutes}mins`;
                      }
                    }

                    return (
                      <Accordion.Item eventKey={project.id} key={project.id}>
                        <Accordion.Header>
                          <table style={{ width: "100%" }}>
                            <col style={{ width: "10%" }} />
                            <col style={{ width: "30%" }} />
                            <col style={{ width: "30%" }} />
                            <col style={{ width: "30%" }} />

                            <tbody>
                              <tr>
                                <td
                                  style={{
                                    color: "green",
                                    fontWeight: "bold",
                                  }}>
                                  #{project.id}
                                </td>

                                <td className="single-line">
                                  {project.subject}
                                </td>
                                <td
                                  style={
                                    project.private
                                      ? {
                                          display: "flex",
                                          color: "#006a78",
                                          alignItems: "center",
                                        }
                                      : {
                                          display: "flex",
                                          color: "green",
                                          alignItems: "center",
                                        }
                                  }>
                                  {project.private ? (
                                    <span>
                                      <GoRepoLocked
                                        size={window.innerWidth < 768 ? 12 : 17}
                                      />
                                      Private
                                    </span>
                                  ) : (
                                    <span>
                                      <LuBookOpenCheck
                                        size={window.innerWidth < 768 ? 12 : 17}
                                      />
                                      Public
                                    </span>
                                  )}
                                </td>
                                <td>
                                  {project.employer.country === "Kenya" ? (
                                    <>
                                      {project.take ? (
                                        <>Kshs {project.amount}</>
                                      ) : (
                                        <>
                                          Kshs {project.bidlow} -{" "}
                                          {project.bidhigh}
                                          /=
                                        </>
                                      )}
                                    </>
                                  ) : (
                                    <>
                                      {project.take ? (
                                        <>Kshs {project.amount*100}</>
                                      ) : (
                                        <>
                                          Kshs {project.bidlow*100} -{" "}
                                          {project.bidhigh*100}
                                          /=
                                        </>
                                      )}
                                    </>
                                  )}
                                </td>
                              </tr>
                            </tbody>
                          </table>
                        </Accordion.Header>
                        <Accordion.Body>
                          <div>
                            <Row>
                              <Col
                                lg={4}
                                sm={12}
                                className={
                                  window.innerWidth > 768 ? "mb-3 " : "mb-2"
                                }>
                                <h6 className="papertype">
                                  {project.papertype}
                                </h6>
                              </Col>
                              <Col
                                lg={4}
                                sm={12}
                                className={
                                  window.innerWidth > 768
                                    ? "mb-3 d-flex justify-content-center"
                                    : "mb-2"
                                }>
                                {project.trusted ? (
                                  <h6 className="projecttrusted">
                                    {" "}
                                    Payment Date:{" "}
                                    {moment(project.paymentdate).format(
                                      "MMM Do YYYY"
                                    )}
                                  </h6>
                                ) : (
                                  <h6 className="projectescrow">Escrowed</h6>
                                )}
                              </Col>
                              <Col lg={4} sm={12} className="textAligner">
                                <h6 style={{ color: "#006a78" }}>
                                  <AiOutlineClockCircle className="projecticon" />
                                  <span className="projectintext">
                                    Remaining:
                                  </span>
                                  {remainingtime <= 1000
                                    ? "Expired"
                                    : daysminhours(remainingtime)}
                                </h6>
                              </Col>
                            </Row>
                          </div>
                          <hr />
                          <h5 className="text-center projecttitle">
                            {"  "}
                            {project.title}
                          </h5>
                          <p>{parse(project.description)}</p>
                          <hr />
                          {project.project_files.length > 0 ? (
                            <h6 style={{ color: "#006a78" }}>
                              Project Attachments
                            </h6>
                          ) : (
                            <h6 style={{ color: "#006a78" }}>No Attachments</h6>
                          )}
                          {project.project_files.length > 0 &&
                            project.project_files.map((myfile) => {
                              return (
                                <Stack>
                                  <a
                                    key={myfile.id}
                                    href={myfile.project_file}
                                    download>
                                    {myfile.filename}
                                  </a>
                                </Stack>
                              );
                            })}
                          <hr />
                          <Row>
                            <Col lg={6} sm={12}>
                              <h6 style={{ color: "#006a78" }}>
                                <AiOutlineFileText className="projecticon" />
                                {project.papertype == "Presentation" ? (
                                  project.pages > 1 ? (
                                    <>{project.pages} Slides</>
                                  ) : (
                                    <>{project.pages} Slide</>
                                  )
                                ) : project.pages > 1 ? (
                                  <>
                                    {project.pages} Pages,{" "}
                                    {project.spacing === "Single Spacing"
                                      ? `${project.pages * 500} Words`
                                      : `${project.pages * 250} Words`}
                                  </>
                                ) : (
                                  <>
                                    {project.pages} Page,{" "}
                                    {project.spacing === "Single Spacing"
                                      ? `${project.pages * 500} Words`
                                      : `${project.pages * 250} Words`}
                                  </>
                                )}{" "}
                              </h6>
                            </Col>
                            <Col lg={6} sm={12}>
                              <h6 style={{ color: "#006a78" }}>
                                <AiOutlineFileText className="projecticon" />
                                <span className="projectintext">
                                  Format:
                                </span>{" "}
                                {project.formatstyle}
                              </h6>
                            </Col>
                            <Col lg={6} sm={12}>
                              <h6 style={{ color: "#006a78" }}>
                                <RxSpaceEvenlyVertically className="projecticon" />
                                <span className="projectintext">Spacing:</span>{" "}
                                {project.spacing}
                              </h6>
                            </Col>

                            <Col lg={6} sm={12}>
                              <h6 style={{ color: "#006a78" }}>
                                <HiOutlineAcademicCap className="projecticon" />
                                <span className="projectintext">
                                  Level:
                                </span>{" "}
                                {project.level}
                              </h6>
                            </Col>
                            <Col lg={6} sm={12}>
                              <h6 style={{ color: "#006a78" }}>
                                <SlSpeech className="projecticon" />
                                <span className="projectintext">
                                  Language:
                                </span>{" "}
                                {project.language}
                              </h6>
                            </Col>

                            <Col lg={6} sm={12}>
                              <h6 style={{ color: "#006a78" }}>
                                <BsFillCalendarDateFill className="projecticon" />{" "}
                                <span className="projectintext">Posted:</span>{" "}
                                {moment(project.timestamp).format(
                                  "MMM Do YYYY, h:mm a"
                                )}
                              </h6>
                            </Col>
                            <Col lg={6} sm={12}>
                              <h6 style={{ color: "#006a78" }}>
                                {project.take ? (
                                  <span>Take Project</span>
                                ) : project.bids_on_project.length > 1 ? (
                                  <span>
                                    <GiThorHammer className="projecticon" />
                                    <span className="projectintext">
                                      Bids:{" "}
                                    </span>
                                    {project.bids_on_project.length} Bids
                                  </span>
                                ) : (
                                  <span>
                                    <GiThorHammer className="projecticon" />
                                    <span className="projectintext">
                                      Bids:{" "}
                                    </span>
                                    {project.bids_on_project.length} Bid
                                  </span>
                                )}
                              </h6>
                            </Col>

                            <Col lg={6} sm={12}>
                              <h6 style={{ color: "#006a78" }}>
                                <BsFillCalendarDateFill className="projecticon" />{" "}
                                <span className="projectintext">Due: </span>
                                {moment(project.deadline).format(
                                  "MMM Do YYYY, h:mm A"
                                )}
                              </h6>
                            </Col>
                            <Col lg={6} sm={12}>
                              <h6 style={{ color: "#006a78" }}>
                                <BsStack className="projecticon" />
                                <span className="projectintext">
                                  Sources:
                                </span>{" "}
                                {project.sources}
                              </h6>
                            </Col>
                          </Row>
                          <hr />
                          <div className="d-flex justify-content-between">
                            <h6 style={{ color: "#006a78" }}>
                              {project.employer.profile_pic !== null ? (
                                <img
                                  src={project.employer.profile_pic}
                                  className="project_image"
                                  alt="employer_image"
                                />
                              ) : (
                                <HiOutlineUserCircle className="project_no_image" />
                              )}{" "}
                              {project.employer.first_name}{" "}
                              {project.employer.last_name}
                            </h6>
                            {project.take ? (
                              <button
                                className="btn btn-success btn-sm"
                                disabled={take}
                                onClick={() => {
                                  setTake(true);
                                  take_project(project.id);
                                }}>
                                {take ? (
                                  <>
                                    <Spinner
                                      as="span"
                                      variant="border"
                                      size="sm"
                                    />
                                    Taking...
                                  </>
                                ) : (
                                  <>Take</>
                                )}
                              </button>
                            ) : writer_id.includes(user.id) ? (
                              <h6 style={{ color: "#006a78" }}>Bid Placed</h6>
                            ) : (
                              <Link
                                to="/writer/placebid"
                                className="btn btn-success btn-sm"
                                onClick={() => {
                                  localStorage.setItem(
                                    "bidproject",
                                    JSON.stringify(project)
                                  );
                                }}>
                                <GiThorHammer /> Bid
                              </Link>
                            )}
                          </div>
                        </Accordion.Body>
                      </Accordion.Item>
                    );
                  })}
                </div>
              )}
            </Accordion>
            <div className="paginationcontainer d-flex justify-content-center">
              {state.currentPage > 1 && ( // Only show left arrow if not on first page
                <span
                  onClick={() => setPage(state.currentPage - 1)}
                  className="text-dark bg-light paginationitem">
                  <BiArrowToLeft />
                </span>
              )}

              {/* Display only 3 page numbers around the current page */}
              {Array.from({ length: 3 }, (_, i) => i + state.currentPage - 1)
                .filter(
                  (pageIndex) => pageIndex >= 0 && pageIndex < pageNum.length
                ) // Ensure indexes are valid
                .map((pageIndex) => (
                  <span
                    key={pageNum[pageIndex]} // Use page number for unique key
                    onClick={() => setPage(pageNum[pageIndex])}
                    className={
                      pageNum[pageIndex] === state.currentPage
                        ? "text-light bg-success paginationitem"
                        : "text-dark bg-light paginationitem"
                    }>
                    {pageNum[pageIndex]}
                  </span>
                ))}

              {state.currentPage < pageNum.length && ( // Only show right arrow if not on last page
                <span
                  onClick={() => setPage(state.currentPage + 1)}
                  className="text-dark bg-light paginationitem">
                  <BiArrowToRight />
                </span>
              )}
            </div>
          </div>
        </Stack>
      )}
    </Col>
  );
}

const mapStateToProps = (state) => ({
  user: state.auth.user,
});

export default connect(mapStateToProps)(WriterProjects);
