import React, { useEffect, useState } from "react";
import { Alert, Card, Col, Spinner, Stack } from "react-bootstrap";
import { useLocation, useNavigate } from "react-router-dom";
import { BiArrowBack } from "react-icons/bi";
import { useFormik } from "formik";
import * as yup from "yup";
import { postData } from "../../customHooks/postdata";
import { CKEditor } from "@ckeditor/ckeditor5-react";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";

function Escalate() {
  const navigate = useNavigate();
  const url = `${process.env.REACT_APP_API_URL}/accounts/escalate`;
  const [loading, setLoading] = useState(false);
  const [message, setMessage] = useState("");
  const mymessage = ["Escalation Succcessful", "Escalation Failed"];
  const [project, setProject] = useState({});
  useEffect(() => {
    const project = JSON.parse(localStorage.getItem("disputed_project"));
    if (project) {
      setProject(project);
    }
  }, []);

  const onSubmit = (values) => {
    setLoading(true);
    postData(url, values)
      .then((res) => {
        console.log(res);
        setMessage(mymessage[0]);
        setTimeout(() => {
          navigate("/writer/disputed");
          setMessage("");
        }, 2000);
      })
      .catch((err) => {
        setLoading(false);
        setMessage(mymessage[1]);
      });
  };

  const writervalidation = yup.object().shape({
    writerreason: yup
      .string()
      .min(100, "Give a reason for escalation to Essayhost Min 100 Characters")
      .max(500, "Give a reason for escalation to Essayhost Max 500 Characters ")
      .required("Escalation Reason is required"),
  });

  const { values, errors, touched, handleSubmit, setFieldValue } = useFormik({
    initialValues: {
      projectid: JSON.parse(localStorage.getItem("disputed_project")).id,
      writerreason: "",
    },
    validationSchema: writervalidation,
    onSubmit,
  });
  return (
    <Col className="mainarea">
      <div className="pagetitle">
        <BiArrowBack onClick={() => navigate(-1)} className="pagenavbutton" />
        <h1 className="title">Escalate Dispute To EssayHost</h1>
      </div>

      <Card className="itemscontainerwithtabs" style={{ height: "83vh" }}>
        <Card.Header as="h5" style={{ textAlign: "center" }}>
          {project.title}
        </Card.Header>
        <Card.Body>
          {message && <Alert>{message}</Alert>}
          <form onSubmit={handleSubmit}>
            <label
              htmlFor="writerreason"
              style={{ color: "green", textAlign: "center" }}>
              Justify why Essay's Host Dispute Resolution Team Should Solve this
              Dispute in your Favor (Min 100 Characters) Max 500 Characters
            </label>
            <CKEditor
              id="writerreason"
              editor={ClassicEditor}
              data={values.writerreason}
              onChange={(event, editor) => {
                const data = editor.getData();
                console.log({ event, editor, data });
                setFieldValue("writerreason", data);
              }}
            />
            {errors.writerreason && touched.writerreason ? (
              <p className="error">{errors.writerreason}</p>
            ) : (
              " "
            )}
            <div className="d-flex justify-content-center align-items-center mt-4">
              <button className="btn btn-outline-danger btn-sm" type="submit">
                {loading ? (
                  <>
                    <Spinner as="span" animation="border" size="sm" />
                    ...Escalating
                  </>
                ) : (
                  <span>Escalate</span>
                )}
              </button>
            </div>
          </form>
        </Card.Body>
      </Card>
    </Col>
  );
}

export default Escalate;
