import React from "react";
import { Row, Col, Stack } from "react-bootstrap";

import { TbBrandFacebook, TbBrandInstagram } from "react-icons/tb";
import { Link } from "react-router-dom";
import essayhostlogo from "../images/hlogo.png";
import { FaXTwitter } from "react-icons/fa6";
import { useSelector } from "react-redux";
import mpesa from "../images/mpesa.png";
import cardpayments from "../images/creditcards.png";

function Footer() {
  const date = new Date();
  const year = date.getFullYear();
  const country = useSelector((state) => state.userCountry.country)
  return (
    <>
      <Col className="footer m-0 p-2">
        <Row className="d-flex justify-content-between">
          <Col lg={4} md={4} sm={12}>
            <Stack className="d-flex justify-content-center align-items-center mt-3">
              <img
                src={essayhostlogo}
                alt="Essay Host Logo"
                className="essayhostlogo"
              />
              <h3 className="footertext">Essays Host</h3>
            </Stack>
          </Col>
          <Col lg={4} md={4} sm={12}>
            <Stack className="d-flex justify-content-center align-items-center">
              <a href="mailto:support@essayshost.com">
                <h6
                  style={{
                    color: "white",
                    marginTop: "2vh",
                    textDecoration: "none",
                  }}
                  className="essayhostmail">
                  support@essayshost.com
                </h6>
              </a>
              {country === "Kenya" && (
                <h6
                  style={{
                    color: "white",
                    marginTop: "2vh",
                  }}>
                  Tel: +254790115186
                </h6>
              )}
              <div className="d-flex flex-row  m-1">
                <Link to="https://www.facebook.com/profile.php?id=100090819432586&mibextid=ZbWKwL">
                  <TbBrandFacebook className="footericon m-4" />
                </Link>
                <Link to="https://twitter.com/EssayHost">
                  <FaXTwitter className="footericon m-4" />
                </Link>
                <Link to="https://instagram.com/essayshost?igshid=ZGUzMzM3NWJiOQ==">
                  <TbBrandInstagram className="footericon m-4" />
                </Link>
              </div>
            </Stack>
          </Col>
          <Col lg={4} md={4} sm={12}>
            <Stack
              gap={3}
              className="d-flex justify-content-center align-items-center">
              <Link
                to="/terms-of-service/#terms"
                className="mt-3 footerlink hover-underline-animation-footer">
                Terms of Service
              </Link>
              <Link
                to="/privacy-policy/#privacy"
                className="footerlink hover-underline-animation-footer">
                Privacy Policy
              </Link>
              <Link
                to="/faqs/#faqspage"
                className="footerlink hover-underline-animation-footer">
                FAQS
              </Link>
              <div className="d-flex justify-content-center align-items-center">
                <img
                  src={cardpayments}
                  style={{ height: "5vh", width: "auto", marginBottom: "1vh" }}
                />{" "}
                <img src={mpesa} style={{ height: "4vh", width: "auto" }} />{" "}
              </div>
            </Stack>
          </Col>
        </Row>
      </Col>
      <Row className="footer_bottom">
        <Col
          lg={12}
          className="d-flex justify-content-center align-items-center">
          <h6 style={{ color: "white", textAlign: "center" }}>
            {" "}
            &copy; {year} essayshost.com TechZen Group Limited&trade;
          </h6>
        </Col>
      </Row>
    </>
  );
}

export default Footer;
