import { React, useEffect, useState } from "react";
import {
  Navbar,
  Nav,
  NavDropdown,
  Button,
  Container,
  Spinner,
  Modal,
  Stack,
  Toast,
  ToastContainer

} from "react-bootstrap";
import { AiOutlineMail } from "react-icons/ai";
import hlogo from "../images/hlogo.png";

import { connect } from "react-redux";
import { logout } from "../actions/auth";
import { BsPower, BsCashCoin } from "react-icons/bs";
import { BiUser } from "react-icons/bi";
import { NavLink, useNavigate } from "react-router-dom";
import { FaRegBell, FaRegBellSlash } from "react-icons/fa6";
import { Tooltip } from "react-tooltip";
import {
  getFCMToken,
  getServerToken,
  sendFCMTokentoBackend,
} from "../firebaseInit";
import axios from "axios";
import { TbBellXFilled } from "react-icons/tb";
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";


function Navigation({ logout, isAuthenticated, user, myuser }) {
  const country = useSelector((state)=>state.userCountry.country)
  const navigate = useNavigate();
  const [mynavbar, setMynavbar] = useState(false);
  const [loggingOut, setLoggingOut] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [disableModal, setDisableModal] = useState(false);
  const [notificationStatus, setNotificationStatus] = useState("")
  const [nofcmToken, setNofcmToken] = useState(false);
  const [fcmDisabled, setFcmDisabled] = useState(false)
  const [tokenSent, setTokenSent] = useState(false);

  const dispatch = useDispatch()
  
  const enableFcmToken = async () => {
    const url = `${process.env.REACT_APP_API_URL}/accounts/disable_enable_token`;
    const body = {
      active: true,
    };
    const config = {
      headers: {
        Authorization: `JWT ${localStorage.getItem("access")}`,
        Accept: "Application/json",
      },
    };
    try {
      const res = axios.patch(url, body, config);
      setFcmDisabled(false)
    } catch (err) {
      console.log(err);
    }
  };

  const remove_device_token = async (token) => {
    const url = `${process.env.REACT_APP_API_URL}/accounts/remove_device_token`;
    const body = {
      token: token
    }
    const config = {
      headers: {
        Authorization: `JWT ${localStorage.getItem("access")}`,
        Accept: "Application/json"
      }
    }
    try {
      const res = axios.post(url, body, config)
    }
    catch (err) {
      console.log(err)
    }

  }

  const disableNotifications = async () => {
    const url = `${process.env.REACT_APP_API_URL}/accounts/disable_enable_token`;
    const body = {
      active: false
    }
    const config = {
      headers: {
        Authorization: `JWT ${localStorage.getItem("access")}`,
        Accept: "Application/json"
      }
    }
    try {
      const res = axios.patch(url, body, config)
      setFcmDisabled(true)
    }
    catch (err) {
      console.log(err)
    }
  }


  useEffect(() => {
      const fcmToken = localStorage.getItem("fcmToken") || null;
      if (Notification.permission === "granted" && isAuthenticated) {
        getServerToken()
          .then((res) => {
            console.log(res);
            if (fcmToken) {
              if (res.registration_id !== fcmToken) {
                remove_device_token(fcmToken)
                  .then((res) => {
                    console.log(res)
                    localStorage.removeItem("fcmToken");
                    setTimeout(() => {
                      setNofcmToken(true);
                    }, 4000);
                   })
                  .catch((err) => { 
                    console.log(err); 
                     setNofcmToken(false);
                  })
               }
              else if (res.registration_id === fcmToken) {
                setNofcmToken(false);
                if (res.active === false) {
                  setFcmDisabled(true);
                }
                
              }
            }
            else {
              remove_device_token(res.registration_id)
                .then((res) => {
                  console.log(res);
                  setTimeout(() => {
                    setNofcmToken(true);
                  }, 4000);
      
                })
                .catch((err) => {
                  console.log(err);
                  setNofcmToken(false);
                });
            }

          })
          .catch((err) => {
            console.log(err);
            if (fcmToken) {
              remove_device_token(fcmToken)
                .then((res) => {
                  localStorage.removeItem("fcmToken");
                  setTimeout(() => {
                    setNofcmToken(true);
                  }, 4000);
                })
                .catch((err) => {
                  console.log(err); 
                   setNofcmToken(false);
                });
            }
            else {
              setTimeout(() => {
                setNofcmToken(true);
              }, 4000);
            }
            
          });
      }
    }, [isAuthenticated, user, Notification.permission]);

  useEffect(() => {
    // Check notification permission when the component mounts
    if (user && isAuthenticated) {
      setTimeout(() => {
        handleNotificationPermission();
      }, 4000);
    }
  }, []);

  const handleNotificationPermission = async () => {
    try {
      const permission = await Notification.requestPermission();
      if (permission === "granted") {
        console.log("Notification permission granted");
       
        // Proceed with additional logic if needed
        setShowModal(false);
      } else {
        console.warn("Notification permission denied");
       
        setShowModal(false);
      }
    } catch (error) {
      console.error("Error requesting notification permission:", error);
    }
  };

  //navbar scroll changeColorfunction
  const changeColor = () => {
    if (window.scrollY >= 66) {
      setMynavbar(true);
    } else {
      setMynavbar(false);
    }
  };
  useEffect(() => {
    window.addEventListener("scroll", changeColor);
     return () => {
       window.removeEventListener("scroll", changeColor);
     };
  }, []);


  

  return (
    <Container fluid>
      <ToastContainer
        position="bottom-end"
        className="mb-2 me-2"
        style={{ zIndex: 99999 }}>
        <Toast
          show={notificationStatus}
          autohide
          delay={4000}
          className={
            notificationStatus === "Notifications Successfully Enabled" ||
            notificationStatus === "Notifications Successfully Disabled"
              ? "bg-success text-white"
              : "bg-danger text-white"
          }
          onClose={() => {
            setNotificationStatus("");
          }}>
          <Toast.Body>{notificationStatus} </Toast.Body>
        </Toast>
      </ToastContainer>
      <Modal
        backdrop="static"
        centered
        show={nofcmToken}
        onHide={() => {
          setNofcmToken(false);
        }}>
        <Modal.Header closeButton>
          <Modal.Title>
            <FaRegBell size={25} /> Enable Notifications
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Stack className="d-flex justify-content-center align-items-center">
            <p>Click the button below to enable notifications:</p>
            <button
              disabled={tokenSent}
              variant="success"
              onClick={() => {
                setTokenSent(true);
                getServerToken()
                  .then((res) => {
                    if (res.active === false) {
                      enableFcmToken()
                        .then((res) => {
                          console.log(res);
                          setTokenSent(false);
                          setNofcmToken(false);
                          setNotificationStatus(
                            "Notifications Successfully Enabled"
                          );
                        })
                        .catch((err) => {
                          console.log(err);
                          setTokenSent(false);
                          setNofcmToken(false);
                          setNotificationStatus(
                            "Notifications Could Not be Enabled"
                          );
                        });
                    }
                  })
                  .catch((err) => {
                    getFCMToken().then((res) => {
                      console.log(res);
                      if (!res.stack) {
                        sendFCMTokentoBackend(res)
                          .then((res) => {
                            if (res.resultCode === 1) {
                              setTokenSent(false);
                              setNofcmToken(false);
                            } else {
                              setTokenSent(false);
                              setNofcmToken(false);
                              setNotificationStatus(
                                "Notifications Successfully Enabled"
                              );
                            }
                          })
                          .catch((err) => {
                            console.log(err);
                            setTokenSent(false);
                            setNofcmToken(false);
                            setNotificationStatus(
                              "Notifications Could Not be Enabled Try Again Later"
                            );
                          });
                      } else {
                        setTokenSent(false);
                        setNofcmToken(false);
                        setNotificationStatus(
                          "Notifications Could Not be Enabled Try Again Later"
                        );
                      }
                    });
                  });
              }}
              className="btn btn-success btn-sm">
              {tokenSent ? (
                <>
                  <Spinner
                    as="span"
                    animation="border"
                    size="sm"
                    role="status"
                    aria-hidden="true"
                  />{" "}
                  Enabling ...
                </>
              ) : (
                "Enable"
              )}
            </button>
          </Stack>
        </Modal.Body>
      </Modal>
      <Modal
        backdrop="static"
        centered
        show={disableModal}
        onHide={() => {
          setDisableModal(false);
        }}>
        <Modal.Header closeButton>
          <Modal.Title>
            <FaRegBellSlash size={25} /> Disable Notifications
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Stack className="d-flex justify-content-center align-items-center">
            <p>Click the button below to disable notifications:</p>
            <button
              variant="success"
              className="btn btn-danger btn-sm"
              disabled={tokenSent}
              onClick={() => {
                setTokenSent(true);
                disableNotifications()
                  .then((res) => {
                    console.log(res);
                    setTokenSent(false);
                    setNotificationStatus(
                      "Notifications Successfully Disabled"
                    );
                    setDisableModal(false);
                  })
                  .catch((err) => {
                    console.log(err);
                    setDisableModal(false);
                    setNotificationStatus(
                      "Notifications Successfully Disabled"
                    );
                  });
              }}>
              {tokenSent ? (
                <>
                  <Spinner
                    as="span"
                    animation="border"
                    size="sm"
                    role="status"
                    aria-hidden="true"
                  />{" "}
                  Disabling ...
                </>
              ) : (
                "Disable"
              )}
            </button>
          </Stack>
        </Modal.Body>
      </Modal>
      <Navbar
        style={{height: "8vh"}}
        expand="md"
        className={
          isAuthenticated
            ? "navigation color-nav p-0"
            : mynavbar
            ? "navigation opaque-nav p-0"
            : "transparent-nav navigation p-0"
        }
        variant="dark"
        fixed="top"
        collapseOnSelect>
        <Navbar.Brand
          href="#"
          className="justify-content-start flex-grow-1 ps-5 ms-0">
          <img
            src={hlogo}
            alt="logo"
            className="logo"
            style={{ marginRight: "10px" }}
          />
          <span
            id="navbrand"
            style={
              !isAuthenticated
                ? { color: "rgba(25,135, 84, 1)", fontWeight: "bold", fontSize: "2em" }
                : { color: "white", fontWeight: "bold" }
            }>
            Essays' Host
          </span>
        </Navbar.Brand>
        {!isAuthenticated ? (
          <Navbar.Toggle
            aria-controls="responsive-navbar-nav"
            className="me-4"
            id="navbar-toggler"
          />
        ) : (
          <>
            {isAuthenticated && user !== null && (
              <Nav className="pe-3 me-3 d-lg-none d-md-none d-sm-block">
                <div>
                  {myuser !== null ? (
                    myuser.profile_pic !== null ? (
                      <img
                        src={myuser.profile_pic}
                        className="nav_image"
                        alt="user_image"
                      />
                    ) : (
                      <BiUser className="nav_icon" style={{ color: "white" }} />
                    )
                  ) : (
                    <BiUser className="nav_icon" style={{ color: "white" }} />
                  )}
                </div>
              </Nav>
            )}
          </>
        )}
        <Navbar.Collapse id={"responsive-navbar-nav"}>
          {isAuthenticated &&
          user !== null &&
          user.role !== "Admin" &&
          user.role !== "Manager" ? (
            <Nav className="me-auto">
              <Nav.Item>
                <Nav.Link
                  href={
                    user.role === "Writer"
                      ? "/writer/transactions"
                      : "/employer/transactions"
                  }>
                  <Button variant="success" size="sm">
                    <BsCashCoin className="cash" /> Wallet:{" "}
                    {country !== "Kenya" ? <>USD </> : <>Kshs </>}
                    {myuser && myuser.user_wallet.cash ? (
                      <span>{myuser.user_wallet.cash}.00</span>
                    ) : (
                      <span> 0.00</span>
                    )}
                  </Button>
                </Nav.Link>
              </Nav.Item>
              <Nav.Item>
                <Nav.Link href="#">
                  <AiOutlineMail className="bell" />{" "}
                </Nav.Link>
              </Nav.Item>
              <Nav.Item>
                {Notification.permission === "granted" ? (
                  nofcmToken || fcmDisabled ? (
                    <>
                      <Nav.Link href="#">
                        <FaRegBellSlash
                          data-tooltip-id="notification"
                          className="bell"
                          onClick={() => {
                            setNofcmToken(true);
                          }}
                        />{" "}
                      </Nav.Link>
                    </>
                  ) : (
                    <>
                      <Nav.Link href="#">
                        <FaRegBell
                          data-tooltip-id="disablenotification"
                          className="bell"
                          onClick={() => {
                            setDisableModal(true);
                          }}
                        />{" "}
                      </Nav.Link>
                    </>
                  )
                ) : (
                  <Nav.Link href="#">
                    <TbBellXFilled
                      data-tooltip-id="notificationdenied"
                      className="bell"
                    />
                  </Nav.Link>
                )}
              </Nav.Item>
              <Tooltip
                content="Enable Notifications"
                id="notification"
                variant="success"
              />
              <Tooltip
                content="Disable Notifications"
                id="disablenotification"
              />
              <Tooltip
                content="Login Again and Allow Notifications For Essays' Host"
                id="notificationdenied"
              />
            </Nav>
          ) : user?.role !== "Admin" && user?.role !== "Manager" ? (
            <Nav className="me-auto">
              <Nav.Item>
                <Nav.Link
                  href="#aboutus"
                  style={
                    !isAuthenticated ? { color: "green" } : { color: "white" }
                  }
                  className="hover-underline-animation">
                  About Us
                </Nav.Link>
              </Nav.Item>
              <Nav.Item>
                <Nav.Link
                  href="#howitworks"
                  style={
                    !isAuthenticated ? { color: "green" } : { color: "white" }
                  }
                  className="hover-underline-animation">
                  How It Works
                </Nav.Link>
              </Nav.Item>
              <Nav.Item>
                <Nav.Link
                  href="#features"
                  style={
                    !isAuthenticated ? { color: "green" } : { color: "white" }
                  }
                  className="hover-underline-animation">
                  Features
                </Nav.Link>
              </Nav.Item>
              <NavDropdown title="FAQS">
                <NavDropdown.Item>
                  <NavLink
                    className="dropdownlink"
                    to="/faqs"
                    style={{ textDecoration: "none", color: "#212529" }}>
                    FAQS
                  </NavLink>
                </NavDropdown.Item>
                <NavDropdown.Item>
                  <NavLink
                    className="dropdownlink"
                    to="/privacy-policy"
                    style={{ textDecoration: "none", color: "#212529" }}>
                    Privacy Policy
                  </NavLink>
                </NavDropdown.Item>
              </NavDropdown>
            </Nav>
          ) : user?.role === "Admin" ? (
            <Nav className="me-auto">
              <Nav.Item>
                <Nav.Link
                  href="#aboutus"
                  style={
                    !isAuthenticated ? { color: "green" } : { color: "white" }
                  }
                  className="hover-underline-animation">
                  Admin Guide
                </Nav.Link>
              </Nav.Item>
            </Nav>
          ) : (
            user?.role === "Manager" && (
              <Nav className="me-auto">
                <Nav.Item>
                  <Nav.Link
                    href="#aboutus"
                    style={
                      !isAuthenticated ? { color: "green" } : { color: "white" }
                    }
                    className="hover-underline-animation">
                    Manager Guide
                  </Nav.Link>
                </Nav.Item>
              </Nav>
            )
          )}
          {isAuthenticated && user !== null ? (
            <Nav className="pe-4 me-4 ">
              <NavDropdown
                className="pe-5 me-4 "
                title={
                  myuser !== null ? (
                    myuser.profile_pic !== null ? (
                      <img
                        src={myuser.profile_pic}
                        className="nav_image"
                        alt="user_image"
                      />
                    ) : (
                      <BiUser className="nav_icon" />
                    )
                  ) : (
                    <BiUser className="nav_icon" />
                  )
                }>
                {user.role === "Employer" ? (
                  <NavDropdown.Item>
                    <NavLink
                      to="/employer/profile"
                      className="dropdownlink"
                      style={{ textDecoration: "none", color: "#212529" }}>
                      <BiUser className="dropdown_icon" /> Profile
                    </NavLink>
                  </NavDropdown.Item>
                ) : user.role === "Writer" ? (
                  <NavDropdown.Item>
                    <NavLink
                      to="/writer/profile"
                      className="dropdownlink"
                      style={{ textDecoration: "none", color: "#212529" }}>
                      <BiUser className="dropdown_icon" /> Profile
                    </NavLink>
                  </NavDropdown.Item>
                ) : (
                  <NavDropdown.Item>
                    <NavLink
                      className="dropdownlink"
                      href="/siteadmin/profile"
                      style={{ textDecoration: "none", color: "#212529" }}>
                      <BiUser className="dropdown_icon" /> Profile
                    </NavLink>
                  </NavDropdown.Item>
                )}
                <NavDropdown.Item
                  onClick={() => {
                    setLoggingOut(true);
                    logout()
                      .then(() => {
                        setLoggingOut(false);
                        navigate("/register/login");
                      })
                      .catch((err) => {
                        console.log(err);
                        setLoggingOut(false);
                        navigate("/register/login");
                        alert.error("Something went wrong");
                      });
                  }}>
                  <NavLink
                    style={{ textDecoration: "none", color: "#212529" }}
                    className="dropdownlink">
                    {loggingOut ? (
                      <Spinner animation="border" size="sm" />
                    ) : (
                      <>
                        <BsPower /> Logout
                      </>
                    )}
                  </NavLink>
                </NavDropdown.Item>
              </NavDropdown>
            </Nav>
          ) : (
            <Nav className="pe-5 me-5">
              <Nav.Link href="/register/login">
                <button className="btn  btn-outline-success">Login</button>
              </Nav.Link>
              <Nav.Link eventKey={2} href="/register">
                <button className="btn btn-success">Sign Up</button>
              </Nav.Link>
            </Nav>
          )}
        </Navbar.Collapse>
      </Navbar>
    </Container>
  );
}

const mapStateToProps = (state) => ({
  isAuthenticated: state.auth.isAuthenticated,
  user: state.auth.user,
  myuser: state.current_user.user,
});

export default connect(mapStateToProps, { logout })(Navigation);
