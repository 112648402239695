import { React, useEffect, useState } from "react";
import { Accordion, Col, Modal, Toast, ToastContainer } from "react-bootstrap";
import axios from "axios";
import { connect } from "react-redux";
import { useNavigate, Link } from "react-router-dom";
import Empty from "../global/Empty";
import Busy from "../global/Busy";
import Project from "./Project/Project";


function Inprogress({ notification }) {
  const [reassign, setReassign] = useState(false);
  const [reassigning, setReassigning] = useState(false);
  const [project, setProject] = useState(null);
  const [reassignNotification, setReassignNotification] = useState("");
 
  const [state, setState] = useState({
    currentPage: 1,
    postsPerPage: 10,
    myProjects: [],
    loading: true,
    message: "",
  });
  const navigate = useNavigate();

  const getData = async (url) => {
    const config = {
      headers: {
        Authorization: `JWT ${localStorage.getItem("access")}`,
      },
    };

    try {
      const res = await axios.get(url, config);
      const data = res.data;
      return data;
    } catch (error) {
      console.error(`Could not get Projects : ${error}`);
      navigate("/register/login");
    }
  };

  const reassignProject = async (id) => {
    setReassigning(true);
    const config = {
      headers: {
        Authorization: `JWT ${localStorage.getItem("access")}`,
      },
    };
    const data = {
      id: id,
    };
    try {
      const res = await axios.post(
        `${process.env.REACT_APP_API_URL}/accounts/reassign_project`,
        data,
        config
      );
      setReassignNotification("Project Reassigned Successfully");
      setReassigning(false);
       getData(`${process.env.REACT_APP_API_URL}/accounts/employer_assigned`)
         .then((res) => {
           setState({
             ...state,
             myProjects: res,
             loading: false,
           });
         })
         .catch((err) => {
           console.log(err);
         });
      console.log(res)
      setReassign(false);
      return res
    }
    catch (error) {
      setReassign(false)
      console.error(`Could not reassign Project : ${error}`);
      setReassignNotification("Project Reassignment Failed");
      setReassigning(false);
    }
  }



  useEffect(() => {
    getData(`${process.env.REACT_APP_API_URL}/accounts/employer_assigned`)
      .then((res) => {
        console.log(res)
        setState({
          ...state,
          myProjects: res,
          loading: false,
        });
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);

  let currentProjects = [];
  let pageNumbers = [];
  if (!state.loading) {
    const indexOfLastProject = state.currentPage * state.postsPerPage;
    const indexOfFirstProject = indexOfLastProject - state.postsPerPage;
    currentProjects = state.myProjects.slice(
      indexOfFirstProject,
      indexOfLastProject
    );
    for (
      let i = 1;
      i <= Math.ceil(state.myProjects.length / state.postsPerPage);
      i++
    ) {
      pageNumbers.push(i);
    }
  }

  const setPage = (num) => {
    setState({ ...state, currentPage: num });
  };

  return (
    <div>
      {state.loading ? (
        <Busy />
      ) : (
        <>
          <div className="pagetitle">
            <h1 className="title">
              Orders In Progress {state.myProjects.length}
            </h1>
          </div>
          <div>
            <Accordion>
              <Accordion.Item eventKey="0">
                <Accordion.Header className="accordionheader">
                  <table>
                    <col style={{ width: "20%" }} />
                    <col style={{ width: "40%" }} />
                    <col style={{ width: "40%" }} />
                    <thead>
                      <th> ID</th>
                      <th>FIELD</th>
                      <th>BUDGET </th>
                    </thead>
                  </table>
                </Accordion.Header>
              </Accordion.Item>

              {state.myProjects.length === 0 ? (
                <Empty message="No Orders In Progress" />
              ) : (
                <div  className="contentarea">
                  {currentProjects.map((project) => {
                    return (
                      <Accordion.Item eventKey={project.id} key={project.id}>
                        <Project project={project}>
                          {project.writer_project_files.length > 0 ? (
                            <div className="d-flex justify-content-around">
                              <Link
                                to="/employer/complete"
                                className="btn btn-outline-success btn-sm"
                                onClick={() => {
                                  localStorage.setItem(
                                    "completeProject",
                                    JSON.stringify(project)
                                  );
                                }}>
                                Complete
                              </Link>
                              <Link
                                to="/employer/requestrevision"
                                className="btn btn-outline-success btn-sm"
                                onClick={() => {
                                  localStorage.setItem(
                                    "revisionProject",
                                    JSON.stringify(project)
                                  );
                                }}>
                                Request Revision
                              </Link>
                              {!project.trusted && (
                                <Link
                                  to="/employer/dispute"
                                  className="btn btn-outline-danger btn-sm"
                                  onClick={() => {
                                    localStorage.setItem(
                                      "disputeProject",
                                      JSON.stringify(project)
                                    );
                                  }}>
                                  Dispute
                                </Link>
                              )}
                              {project.trusted && (
                                <button
                                  className="btn btn-outline-danger btn-sm"
                                  onClick={() => {
                                    setReassign(true);
                                    setProject(project);
                                  }}>
                                  Re Assign
                                </button>
                              )}
                            </div>
                          ) : (
                            <div className="d-flex justify-content-around">
                              {!project.trusted && (
                                <Link
                                  to="/employer/dispute"
                                  className="btn btn-outline-danger btn-sm"
                                  onClick={() => {
                                    localStorage.setItem(
                                      "disputeProject",
                                      JSON.stringify(project)
                                    );
                                  }}>
                                  Dispute
                                </Link>
                              )}
                              <button
                                className="btn btn-outline-danger btn-sm"
                                onClick={() => {
                                  setReassign(true);
                                  setProject(project);
                                }}>
                                Re Assign
                              </button>
                            </div>
                          )}
                        </Project>
                      </Accordion.Item>
                    );
                  })}
                </div>
              )}
            </Accordion>
            <div className="paginationcontainer d-flex justify-content-center">
              {pageNumbers.map((num) => {
                return (
                  <span
                    onClick={() => {
                      setPage(num);
                    }}
                    className={
                      num === state.currentPage
                        ? "text-light bg-success paginationitem"
                        : "text-dark bg-light paginationitem"
                    }>
                    {num}
                  </span>
                );
              })}
            </div>
          </div>
        </>
      )}

      <Modal
        show={state.message ? true : false}
        onHide={() => setState({ ...state, message: "" })}>
        <Modal.Body>{state.message}</Modal.Body>
      </Modal>
      <Modal show={reassign} onHide={() => setReassign(false)} centered>
        <Modal.Header closeButton>
          <Modal.Title className="text-center">
            {" "}
            <h5>Re Assign Project {project?.id}</h5>{" "}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <ul>
            <li>
              When You Re Assign a Project, The Previous Writer Will Not Be Able
              to handle The Project.
            </li>
            {!project?.trusted && !project?.take && (
              <li>
                <li>
                  Cash From The Escrow Wallet for this Project will be
                  Transferred To Your Wallet.
                </li>
              </li>
            )}
            <li>This Project will Now Appear in the Unassigned Projects</li>
            <li>
              {project?.take
                ? "Another Writer Will Take this Project"
                : "Bids Placed on Project Will Still be there so You Can Assign to another Writer"}
            </li>
          </ul>
        </Modal.Body>
        <Modal.Footer className="d-flex justify-content-center">
          <button
            disabled={reassigning}
            className="btn btn-outline-danger btn-sm"
            onClick={() => {
              reassignProject(project.id);
            }}>
            {reassigning ? "Re Assigning..." : "Re Assign"}
          </button>
        </Modal.Footer>
      </Modal>
      <ToastContainer
        position="top-end"
        className="p-3"
        style={{ zIndex: 99999 }}>
        <Toast
          onClose={() => setReassignNotification("")}
          show={reassignNotification ? true : false}
          delay={3000}
          autohide
          className={
            reassignNotification === "Project Reassigned Successfully"
              ? "bg-success text-white"
              : "bg-danger text-white"
          }>
          <Toast.Body>{reassignNotification}</Toast.Body>
        </Toast>
      </ToastContainer>
    </div>
  );
}

const mapStateToProps = (state) => ({
  notification: state.notification,
});
export default connect(mapStateToProps)(Inprogress);
