import React, { useState, useEffect } from "react";
import { HiOutlineUserCircle } from "react-icons/hi";
import { Row } from "react-bootstrap";
import parse from "html-react-parser";
import { BsFillCalendarDateFill, BsStack } from "react-icons/bs";
import { HiOutlineAcademicCap } from "react-icons/hi";
import { GiThorHammer } from "react-icons/gi";
import { AiOutlineFileText, AiOutlineClockCircle } from "react-icons/ai";
import { RxSpaceEvenlyVertically } from "react-icons/rx";
import { SlSpeech } from "react-icons/sl";
import { useNavigate } from "react-router-dom";
import moment from "moment";
import axios from "axios";
import { Accordion, Col, Stack } from "react-bootstrap";
import { PiChats } from "react-icons/pi";

function WriterProject({ project, children }) {
  const [remainingtime, setRemainingtime] = useState(0);
  const [chat, setChat] = useState(false);
  const navigate = useNavigate();

  function daysminhours(milliseconds) {
    let seconds = Math.floor(milliseconds / 1000);
    let minutes = Math.floor(seconds / 60);
    let hours = Math.floor(minutes / 60);
    let days = Math.floor(hours / 24);

    seconds = seconds % 60;
    // 👇️ if seconds are greater than 30, round minutes up (optional)
    minutes = seconds >= 30 ? minutes + 1 : minutes;

    minutes = minutes % 60;

    hours = hours % 24;

    return `${days}dys ${hours}hrs ${minutes}mins`;
  }

  useEffect(() => {
    var now = new Date().getTime();
    //convert the json date to milliseconds

    var deadline = new Date(`${project.deadline}`).getTime();

    setRemainingtime(deadline - now);
  }, [project.deadline]);
  const createRoom = async (projectid, writerid) => {
    const url = `${process.env.REACT_APP_API_URL}/accounts/create_room`;
    const config = {
      headers: {
        Authorization: `JWT ${localStorage.getItem("access")}`,
        "Content-Type": "application/json",
      },
    };
    const body = JSON.stringify({ projectid, writerid });
    try {
      const res = await axios.post(url, body, config);

      navigate("/writer/chat");
      return res.data;
    } catch (err) {
      console.log(err);
    }
  };
  return (
    <>
      <Accordion.Header>
        <table>
          <col style={{ width: "20%" }} />
          <col style={{ width: "40%" }} />
          <col style={{ width: "40%" }} />

          <tbody>
            <td style={{ color: "green", fontWeight: "bold" }}>
              #{project.id}
            </td>
            <td>{project.subject}</td>
            <td>Kshs {project.amount}.00</td>
          </tbody>
        </table>
      </Accordion.Header>
      <Accordion.Body>
        <Row>
          <Col lg={4} sm={12} className="mb-3">
            <h6 className="papertype">{project.papertype}</h6>
          </Col>
          <Col lg={4} sm={12} className="mb-3">
            {project.trusted ? (
              <h6 className="projecttrusted">
                {" "}
                Payment Date:{" "}
                {moment(project.paymentdate).format("MMM Do YYYY")}
              </h6>
            ) : (
              <h6 className="projectescrow">Escrowed</h6>
            )}
          </Col>
          <Col lg={4} sm={12} className="textAligner">
            <h6 style={{ color: "#006a78" }}>
              <AiOutlineClockCircle className="projecticon" />
              <span className="projectintext">Remaining:</span>
              {remainingtime <= 1000 ? "Expired" : daysminhours(remainingtime)}
            </h6>
          </Col>
        </Row>

        <hr />
        <h5 style={{ textAlign: "center" }}>{project.title}</h5>
        {parse(project.description)}
        <hr />
        {project.project_files.length > 0 ? (
          <>
            <h6 style={{ color: "#006a78" }}>Project Attachments</h6>
            {project.project_files.map((projectfile) => {
              return (
                <div>
                  <a href={projectfile.project_file} download>
                    {projectfile.filename}
                  </a>
                </div>
              );
            })}
          </>
        ) : (
          <h6 style={{ color: "#006a78" }}>Project has no Attachments</h6>
        )}
        <hr />
        <Stack>
          <h6 style={{ color: "#006a78" }}>
            Files you have Uploaded for Project {project.id}
          </h6>
          {project.writer_project_files.length > 0 ? (
            project.writer_project_files.map((writerfile) => {
              return (
                <a href={writerfile.writer_project_file}>
                  {writerfile.filename}
                </a>
              );
            })
          ) : (
            <h6 style={{ color: "#006a78" }}>
              You have Not Uploaded any Files for This Project
            </h6>
          )}
        </Stack>
        <hr />
        <Row>
          <Col lg={6} sm={12}>
            <h6 style={{ color: "#006a78" }}>
              <AiOutlineFileText className="projecticon" />
              <span className="projectintext">
                {project.papertype === "Presentation" ? "Slides" : "Pages"}
              </span>{" "}
              {project.pages}{" "}
              {project.papertype !== "Presentation"
                ? project.spacing === "Single Spacing"
                  ? `Aprox ${project.pages * 500} Words`
                  : `Aprox ${project.pages * 250} Words`
                : ""}
            </h6>
          </Col>
          <Col lg={6} sm={12}>
            <h6 style={{ color: "#006a78" }}>
              <AiOutlineFileText className="projecticon" />
              <span className="projectintext">Format:</span>{" "}
              {project.formatstyle}
            </h6>
          </Col>
          <Col lg={6} sm={12}>
            <h6 style={{ color: "#006a78" }}>
              <RxSpaceEvenlyVertically className="projecticon" />
              <span className="projectintext">Spacing:</span> {project.spacing}
            </h6>
          </Col>

          <Col lg={6} sm={12}>
            <h6 style={{ color: "#006a78" }}>
              <HiOutlineAcademicCap className="projecticon" />
              <span className="projectintext">Level:</span> {project.level}
            </h6>
          </Col>
          <Col lg={6} sm={12}>
            <h6 style={{ color: "#006a78" }}>
              <SlSpeech className="projecticon" />
              <span className="projectintext">Language:</span>{" "}
              {project.language}
            </h6>
          </Col>

          <Col lg={6} sm={12}>
            <h6 style={{ color: "#006a78" }}>
              <BsFillCalendarDateFill className="projecticon" />{" "}
              <span className="projectintext">Posted:</span>{" "}
              {moment(project.timestamp).format("MMM Do YYYY, h:mm a")}
            </h6>
          </Col>
          <Col lg={6} sm={12}>
            <h6 style={{ color: "#006a78" }}>
              {project.bids_on_project.length > 1 ? (
                <span>
                  <GiThorHammer className="projecticon" />
                  <span className="projectintext">Bids: </span>
                  {project.bids_on_project.length} Bids
                </span>
              ) : (
                <span>
                  <GiThorHammer className="projecticon" />
                  <span className="projectintext">Bids: </span>
                  {project.bids_on_project.length} Bid
                </span>
              )}
            </h6>
          </Col>

          <Col lg={6} sm={12}>
            <h6 style={{ color: "#006a78" }}>
              <BsFillCalendarDateFill className="projecticon" />{" "}
              <span className="projectintext">Due: </span>
              {moment(project.deadline).format("MMM Do YYYY, h:mm A")}
            </h6>
          </Col>
          <Col lg={6} sm={12}>
            <h6 style={{ color: "#006a78" }}>
              <BsStack className="projecticon" />
              <span className="projectintext">Sources:</span> {project.sources}
            </h6>
          </Col>
        </Row>
        <hr />
        <div className="d-flex justify-content-between">
          <h6 style={{ color: "#006a78" }}>
            {project.employer.profile_pic ? (
              <img
                src={project.employer.profile_pic}
                className="project_image"
                alt="employer_image"
              />
            ) : (
              <HiOutlineUserCircle className="project_no_image" />
            )}{" "}
            {project.employer.first_name} {project.employer.last_name}
          </h6>
          <button
            className="btn btn-outline-success btn-sm"
            onClick={() => {
              setChat(true);
              createRoom(project.id, project.writer.id)
                .then((res) => {
                  console.log(res);
                  localStorage.setItem("chatRoom", JSON.stringify(res));
                })
                .catch((err) => {
                  setChat(false);
                  console.log(err);
                });
            }}>
            {chat ? (
              "...Initiating Chat"
            ) : (
              <>
                <PiChats /> {`Chat ${project.employer.first_name}`}
              </>
            )}
          </button>
        </div>
        <hr />
        {children}
      </Accordion.Body>
    </>
  );
}

export default WriterProject;
