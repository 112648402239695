import axios from "axios";

export const postData = async (url, body) => {
  const config = {
    headers: {
      Authorization: `JWT ${localStorage.getItem("access")}`,
      Accept: "application/json",
    },
  };

  try {
    const res = await axios.post(url, body, config);
    const data = res.data;
    return data;
  } catch (error) {
    console.log(error);
    return error;
  }
};
