import React, { useState, useEffect } from "react";
import Empty from "../global/Empty";
import getData from "../../customHooks/getData";
import Busy from "../global/Busy";
import { Accordion, Col, Spinner } from "react-bootstrap";
import { HiOutlineUserCircle } from "react-icons/hi";
import { useSelector } from "react-redux";
import moment from "moment";

function MyEmployers({state, setState}) {
 
  const [myprojects, setMyProjects] = useState([]);
  const [totalUnpaid, setTotalUnpaid] = useState(0);
  const [employerId, setEmployerId] = useState(0);
  const [inProgressUnpaid, setInProgressUnpaid] = useState(0);
  const [completedUnpaid, setCompletedUnpaid] = useState(0);
  const [employerProjects, setEmployerProjects] = useState([]);
  const [employerTotalUnpaid, setEmployerTotalUnpaid] = useState(0);
  const [accordionLoad, setAccordionLoad] = useState(false);

  useEffect(() => {
    getData(`${process.env.REACT_APP_API_URL}/accounts/writer_trusted_assigned`)
      .then((res) => {
        setMyProjects(res);
        const totalUnpaid = res.reduce((total, project) => {
          return total + project.amount;
        }, 0);
        setTotalUnpaid(totalUnpaid);
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);

  const employers = useSelector((state) => state.employers);

  const url = `${process.env.REACT_APP_API_URL}/writersadmin/get_employers`;

  const [page, setPage] = useState(1);

  let [currentEmployers, setCurrentEmployers] = useState([]);
  let pageNumber = [];

  useEffect(() => {
    if (!state.loading) {
      let indexofLastEmployer = state.currentPage * state.postPerPage;
      let indexofFirstEmployer = indexofLastEmployer - state.postPerPage;
      currentEmployers = state.employers.slice(
        indexofFirstEmployer,
        indexofLastEmployer
      );

      setCurrentEmployers(currentEmployers);
    }
  }, [state.currentPage, state.loading]);

  for (
    let i = 1;
    i <= Math.ceil(state.employers.length / state.postPerPage);
    i++
  ) {
    pageNumber.push(i);
  }

  useEffect(() => {
    getData(url)
      .then((res) => {
        setState({
          ...state,
          employers: res,
          loading: false,
        });
      })
      .catch((err) => {
        console.log(err);
        setState({
          ...state,
          loading: false,
        });
      });
  }, []);

  const getEmployerDetails = (employerid) => {
    const employerProjects = myprojects.filter((project) => {
      return project.employer.id === employerid;
    });
    const completedProjects = employerProjects.filter((project) => {
      return project.completed === true;
    });

    const inProgress = employerProjects.filter((project) => {
      return project.completed === false;
    });
    const inProgressUnpaid = inProgress.reduce((total, project) => {
      return total + project.amount;
    }, 0);
    const completedUnpaid = completedProjects.reduce((total, project) => {
      return total + project.amount;
    }, 0);
    const employertotalUnpaid = employerProjects.reduce((total, project) => {
      return total + project.amount;
    }, 0);
    setEmployerProjects(employerProjects);
    setEmployerTotalUnpaid(employertotalUnpaid);
    setInProgressUnpaid(inProgressUnpaid);
    setCompletedUnpaid(completedUnpaid);
    setAccordionLoad(false);
  };

  console.log(myprojects);

  return (
    <Col className="mainarea">
      {state.loading ? (
        <Busy />
      ) : state.employers.length > 0 ? (
        <>
          <Accordion>
            <Accordion.Item>
              <Accordion.Header className="accordionheader">
                <table>
                  <thead>
                    <tr>
                      <th width={20}>ID</th>
                      <th width={60}>NAME</th>
                      <th width={20}>DONE</th>
                    </tr>
                  </thead>
                </table>
              </Accordion.Header>
            </Accordion.Item>
            {currentEmployers.map((employer) => {
              return (
                employer.employer_profile !== null && (
                  <Accordion.Item
                    key={employer.id}
                    eventKey={employer.id}
                    onClick={() => {
                      setAccordionLoad(true);
                      getEmployerDetails(employer.id);
                    }}>
                    <Accordion.Header>
                      <table>
                        <tbody>
                          <tr>
                            <td width="20%">
                              <b style={{ color: "green" }}>#{employer.id}</b>
                            </td>
                            <td width="60%">
                              {employer.profile_pic !== null ? (
                                <img
                                  src={
                                    
                                    employer.profile_pic
                                  }
                                  className="project_image"
                                  alt="employer"
                                />
                              ) : (
                                <HiOutlineUserCircle className="project_no_image" />
                              )}{" "}
                              {employer.first_name} {employer.last_name}
                            </td>
                            <td width="20%">
                              {employer.employer_profile.completedcount}
                            </td>
                          </tr>
                        </tbody>
                      </table>
                    </Accordion.Header>
                    <Accordion.Body>
                      {accordionLoad ? (
                        <div className="d-flex justify-content-center">
                          <Spinner as="span" variant="success" size="sm" />
                        </div>
                      ) : (
                        <div>
                          {employerProjects.length > 0 ? (
                            <div>
                              <h5 className="text-center">
                                {employerProjects.length} Trusted Unpaid
                                Projects
                              </h5>
                              <hr />
                              <table>
                                <thead className="thead-light">
                                  <tr>
                                    <th width="10%">ID</th>
                                    <th width="15%">Subject</th>
                                    <th width="25%">Payment Date</th>
                                    <th width="25%">Status</th>
                                    <th width="25%">Amount</th>
                                  </tr>
                                </thead>
                                <tbody>
                                  {employerProjects.map((project) => {
                                    return (
                                      <tr>
                                        <td style={{ color: "green" }}>
                                          <b>#{project.id}</b>
                                        </td>
                                        <td>{project.subject}</td>

                                        <td>
                                          {moment(project.paymentdate).format(
                                            "MMM Do YYYY"
                                          )}
                                        </td>
                                        <td>
                                          {project.completed
                                            ? "Completed"
                                            : "In Progress"}
                                        </td>
                                        <td>Kshs {project.amount}</td>
                                      </tr>
                                    );
                                  })}

                                  <tr
                                    className="border-top"
                                    style={{ color: "#006a78" }}>
                                    <td colSpan={3}></td>
                                    <td>Total in Progress</td>
                                    <td>
                                      <b>Kshs {inProgressUnpaid}</b>
                                    </td>
                                  </tr>
                                  <tr style={{ color: "green" }}>
                                    <td colSpan={3}></td>
                                    <td>Total Completed</td>
                                    <td>
                                      <b>Kshs {completedUnpaid}</b>
                                    </td>
                                  </tr>
                                  <tr style={{ color: "blue" }}>
                                    <td colSpan={3}></td>
                                    <td>Total Unpaid</td>
                                    <td>
                                      <b>Kshs {employerTotalUnpaid}</b>
                                    </td>
                                  </tr>
                                </tbody>
                              </table>
                            </div>
                          ) : (
                            <p>No Unpaid Orders</p>
                          )}
                        </div>
                      )}
                    </Accordion.Body>
                  </Accordion.Item>
                )
              );
            })}
          </Accordion>
          <div className="paginationcontainer d-flex justify-content-center">
            {pageNumber.map((num) => {
              return (
                <span
                  onClick={() => {
                    setPage(num);
                  }}
                  className={
                    num === state.currentPage
                      ? "text-light bg-success paginationitem"
                      : "text-dark bg-light paginationitem"
                  }>
                  {num}
                </span>
              );
            })}
          </div>
        </>
      ) : (
        <Empty message={"You Do not have Any Employers"} />
      )}
    </Col>
  );
}

export default MyEmployers;
