import axios from "axios";
import React, { useEffect } from "react";
import { Row, Col, Card, Spinner, Modal, Stack} from "react-bootstrap";
import { connect } from "react-redux";
import { get_user } from "../../actions/myuser";
import { FcOk, FcHighPriority } from "react-icons/fc";
import warning from "../../images/warning.gif";
import ReconnectingWebSocket from "reconnecting-websocket";
import getData from "../../customHooks/getData";
import mpesa from "../../images/mpesa.png";
import { GiMoneyStack } from "react-icons/gi";
import { BiMoneyWithdraw } from "react-icons/bi";
import { GiCash } from "react-icons/gi";

function WriterFinance({ user, get_user }) {
  const [withdraw, setWithdraw] = React.useState(false);
  const [loading, setLoading] = React.useState(false);
  const [withdrawSocket, setWithdrawSocket] = React.useState(null);
  const [successWithdraw, setSuccessWithdraw] = React.useState(false);
  const [failedWithdraw, setFailedWithdraw] = React.useState(false);
  const handleClose = () => setWithdraw(false);
  const withdrawfunc = () => setWithdraw(true);
  const [insufficient, setInsufficient] = React.useState(false);
  const [serverError, setServerError] = React.useState(false);
  const [withdrawPending, setWithdrawPending] = React.useState(false);
  const [writerCompleteUnpaid, setWriterCompleteUnpaid] = React.useState(0);

  useEffect(() => {
    getData(`${process.env.REACT_APP_API_URL}/accounts/writer_trusted_assigned`)
      .then((res) => {
        const my_projects = res.filter((project) => {
          return (
            project.completed === true &&
            new Date(project.paymentdate) < new Date()
          );
        });
        const totalUnpaid = my_projects.reduce((total, project) => {
          return total + project.amount;
        }, 0);
        setWriterCompleteUnpaid(totalUnpaid);
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);

  useEffect(() => {
    get_user()
      .then(() => {
        console.log("success");
      })
      .catch((err) => {
        console.log(err);
      });
    const socket = new ReconnectingWebSocket(
      `${process.env.REACT_APP_WEBSOCKET_URL}/ws/withdrawal_response/${user?.id}`
    );
    socket.addEventListener("open", () => {
      console.log("withrawal socket open");
    });
    setWithdrawSocket(socket);
  }, []);

  useEffect(() => {
    if (withdrawSocket) {
      withdrawSocket.addEventListener("message", (e) => {
        const data = JSON.parse(e.data);
        if (data.status === "Withdraw Successful") {
          get_user();
          handleClose();
          if (withdrawPending === true) {
            setWithdrawPending(false);
          }
          setSuccessWithdraw(true);
          setLoading(false);
        } else if (data.status === "Insufficient Funds") {
          handleClose();
          setInsufficient(true);
          if (withdrawPending) {
            setWithdrawPending(false);
          }
          setLoading(false);
        } else if (data.status === "Withdraw Failed") {
          setServerError(true);
          if (withdrawPending) {
            setWithdrawPending(false);
          }
          setLoading(false);
          handleClose();
        } else if (data.status === "Withdraw Pending") {
          setWithdrawPending(true);
          setLoading(false);
          handleClose();
        }
      });
    }
  }, [withdrawSocket]);

  const cashWithdraw = async (amount) => {
    setLoading(true);
    const config = {
      headers: {
        Authorization: `JWT ${localStorage.getItem("access")}`,
        "Content-Type": "application/json",
      },
    };
    const body = JSON.stringify({ amount });
    try {
      const res = await axios.post(
        `${process.env.REACT_APP_API_URL}/accounts/withdraw`,
        body,
        config
      );
      return res.data;
    } catch (err) {
      setLoading(false);
      console.log(err);
    }
  };

  console.log(serverError);

  return (
    <Card style={{ overflowY: "auto", overflowX: "hidden" }}>
      <Card.Body className="itemscontainerwithtabs mt-1">
        <Row>
          <Col lg={6} sm={12} md={6}>
            <Card className="walletcard">
              <Card.Header as="h6">Escrow Wallet</Card.Header>
              <Card.Body
                style={{ height: "29vh" }}
                >
                
                  <h6>Amount Held in Escrow By Essay host</h6>
                  <div className="d-flex justify-content-center align-items-center">
                    <GiMoneyStack className="finance-icon" />
                  </div>
                  <h5>Kshs {user.writer_profile.cash}</h5>
            
              </Card.Body>
            </Card>
          </Col>
          <Col lg={6} sm={12} md={6}>
            <Card className="walletcard">
              <Card.Header as="h6">Wallet Balance</Card.Header>
              <Card.Body style={{ height: "29vh" }}>
                <h6>Released amount for withdrawal</h6>
                <div>
                  <BiMoneyWithdraw className="finance-icon" />
                </div>
                {user.user_wallet ? (
                  <h5>Kshs {user.user_wallet.cash}</h5>
                ) : (
                  <h5>Kshs 0.00</h5>
                )}
                <button
                  onClick={withdrawfunc}
                  className="btn btn-outline-success btn-sm">
                  Withdraw
                </button>
              </Card.Body>
            </Card>
          </Col>
        </Row>
        <Row>
          <Col lg={6} sm={12} md={6}>
            <Card className="walletcard">
              <Card.Header as="h6">Unpaid Amount</Card.Header>
              <Card.Body style={{ height: "29vh" }}>
                <h6>Unpaid Amount for completed & Due Pay Later Projects</h6>
                <div>
                  <GiMoneyStack className="finance-icon" />
                </div>
                <h5>Kshs {writerCompleteUnpaid}</h5>
              </Card.Body>
            </Card>
          </Col>
          <Col lg={6} sm={12} md={6}>
            <Card className="walletcard">
              <Card.Header as="h6">Total Earnings</Card.Header>
              <Card.Body style={{ height: "29vh" }}>
                <h6>Total cash Earned</h6>
                <div>
                  <GiCash className="finance-icon" />
                </div>
                <h5>Kshs {user.total_amount}</h5>
              </Card.Body>
            </Card>
          </Col>
        </Row>
      </Card.Body>
      <Modal
        show={withdraw}
        onHide={handleClose}
        aria-labelledby="contained-modal-title-vcenter"
        centered
        backdrop="static"
        keyboard={false}>
        <Modal.Header closeButton>
          <Modal.Title className="text-center h6-2">
            Withdraw Cash{" "}
            <img src={mpesa} alt="mpesaimage" className="mpesaimage" />
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {user.user_wallet.cash >= 50 ? (
            <div>
              <Stack className="d-flex justify-content-center h6-2">
                <p className="text-center">
                  {" "}
                  Confirm withdrawal of <b>
                    Kshs {user.user_wallet.cash}.00
                  </b>{" "}
                  to <b>0{user.phone_no}</b> from your wallet
                </p>
                <div className="d-flex justify-content-center h6-2">
                  <button
                    disabled={loading}
                    className="btn btn-outline-success btn-sm"
                    onClick={() => {
                      cashWithdraw(user.user_wallet.cash);
                    }}>
                    {loading ? (
                      <span>
                        {" "}
                        <Spinner as="span" variant="border" size="sm" />{" "}
                        ...Withdrawing
                      </span>
                    ) : (
                      <span>Withdraw</span>
                    )}
                  </button>
                </div>
              </Stack>
            </div>
          ) : (
            <h6 className="text-center">
              {" "}
              <img
                src={warning}
                alt="warning"
                style={{ width: "30px", height: "30px" }}
              />
              You Do not have enough funds in your Wallet to withdraw minimum
              Kshs 50 <br /> <br /> Wallet Balance Kshs {user.user_wallet.cash}
              .00{" "}
            </h6>
          )}
        </Modal.Body>
      </Modal>

      <Modal
        show={successWithdraw}
        onHide={() => setSuccessWithdraw(false)}
        centered>
        <Modal.Header closeButton>
          <Modal.Title>
            <FcOk /> Withdrawal Completed Successfully
          </Modal.Title>
        </Modal.Header>
      </Modal>
      <Modal show={insufficient} onHide={() => setInsufficient(false)} centered>
        <Modal.Header closeButton>
          <Modal.Title className="text-center">
            <img
              src={warning}
              alt="warning"
              style={{ width: "30px", height: "30px" }}
            />
            Insufficient Funds
          </Modal.Title>
        </Modal.Header>
        <Modal.Body className="text-center">
          {" "}
          Minimum withdrawal Amount Kshs 50.00
        </Modal.Body>
      </Modal>
      <Modal
        show={failedWithdraw}
        onHide={() => {
          setFailedWithdraw(false);
        }}
        centered>
        <Modal.Header closeButton>
          <Modal.Title className="text-center">
            {" "}
            <FcHighPriority />
            Server Error
          </Modal.Title>
          <Modal.Body>Withdrawal Could Not be Completed</Modal.Body>
        </Modal.Header>
      </Modal>
      <Modal
        show={serverError}
        onHide={() => {
          setServerError(false);
        }}
        centered>
        <Modal.Header closeButton>
          <Modal.Title>
            {" "}
            <FcHighPriority />
            Internal Server Error Please Try Again Later
          </Modal.Title>
        </Modal.Header>
      </Modal>
      <Modal
        show={withdrawPending}
        onHide={() => {
          setWithdrawPending(false);
        }}
        centered>
        <Modal.Header closeButton>
          <Modal.Title>
            {" "}
            <FcHighPriority />
            You have a pending withdrawal request
          </Modal.Title>
        </Modal.Header>
      </Modal>
    </Card>
  );
}

const mapStateToProps = (state) => ({
  user: state.current_user.user,
});

export default connect(mapStateToProps, { get_user })(WriterFinance);
