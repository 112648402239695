import React, { useState, useEffect, useRef } from "react";
import {
  Col,
  Row,
  Card,
  NavLink,
  Stack,
  Modal,
  Offcanvas,
} from "react-bootstrap";
import { connect } from "react-redux";
import axios from "axios";
import { BiSend } from "react-icons/bi";
import { HiOutlineUserCircle } from "react-icons/hi";
import moment from "moment";
import { AiOutlineMail, AiOutlineMenu } from "react-icons/ai";
import ReconnectingWebSocket from "reconnecting-websocket";
import Busy from "../global/Busy";
import red_dot from "../../images/reddot.png";
import green_dot from "../../images/greendot.png";
import { Tooltip } from "react-tooltip";

function EmployerChat({ user }) {

  const phoneNumberRegex = /\+?[^\r\na-zA-Z]*\d{8,13}[^\r\na-zA-Z]*\b/;

  const emailRegex = /\b[A-Za-z0-9._%+-]+@[A-Za-z0-9.-]+\.[A-Z|a-z]{2,}\b/g;
  const [socket, setSocket] = useState(null);
  const [message, setMessage] = useState("");
  const [messages, setMessages] = useState([]);
  const [rooms, setRooms] = useState([]);
  const [roomid, setRoomid] = useState(null);
  const [projectid, setProjectId] = useState(null);
  const [loading, setLoading] = useState(true);
  const [title, setTitle] = useState("");
  const [show, setShow] = useState(false);
  const [offCanvas, setOffCanvas] = useState(false);

  const offCanvasToggle = () => setOffCanvas((s) => !s);

  const closeChatofCanvas = () => setOffCanvas(false);

  const messagesContainerRef = useRef();

  useEffect(() => {
    // Scroll to the bottom when messages change
    scrollToBottom();
  }, [messages]);
  const scrollToBottom = () => {
    // Using vanilla JavaScript to scroll to the bottom
    if (messagesContainerRef.current) {
      messagesContainerRef.current.scrollTop =
        messagesContainerRef.current.scrollHeight;
    }
  };

    const handleKeyDown = (event) => {
    if (event.key === 'Enter') {
      event.preventDefault();
      if (message.trim() !== '') {
        handleSubmit(event);
        setMessage(''); // Clear the input after submission
      }
    }
  };

  useEffect(() => {
    const newSocket = new ReconnectingWebSocket(
      `${process.env.REACT_APP_WEBSOCKET_URL}/ws/chat/${roomid}`
    );

    newSocket.addEventListener("open", () =>
      console.log("Reconnect WebSocket connected")
    );
    newSocket.addEventListener("close", () => {
      console.log("WebSocket disconnected");
    });
    setSocket(newSocket);

    // //Clean up the WebSocket connection when the component unmounts
    return () => {
      newSocket.close();
    };
  }, [roomid]);

  useEffect(() => {
    if (localStorage.getItem("chatRoom")) {
      const my_room = JSON.parse(localStorage.getItem("chatRoom"));
      console.log(my_room);
      setRoomid(my_room.id);
      setTitle(my_room.project_title);
      setProjectId(my_room.projectid);
      setMessages(my_room.room_messages);
    }
  }, []);

  const getRooms = async () => {
    const config = {
      headers: {
        "Content-Type": "application/json",
        Authorization: `JWT ${localStorage.getItem("access")}`,
      },
    };
    try {
      const res = await axios.get(
        `${process.env.REACT_APP_API_URL}/accounts/chat_rooms`,
        config
      );
      setLoading(false);
      setRooms(res.data);
    } catch (err) {
      console.log(err);
    }
  };

  useEffect(() => {
    getRooms()
      .then((res) => {
        console.log("success");
        console.log(res);
      })
      .catch((err) => {
        console.log(err);
      });
  }, [roomid, socket, messages]);

  useEffect(() => {
    if (socket) {
      socket.addEventListener("message", (message) => {
        const data = JSON.parse(message.data);
        setMessages((prevMessages) => [...prevMessages, data]);
      });
    }
  }, [socket]);


  const handleSubmit = (event) => {
    event.preventDefault();

    if (phoneNumberRegex.test(message) || emailRegex.test(message)) {
      // If it does, show the modal
      setShow(true);
    } else {
      if (message && socket) {
        const data = {
          content: message,
          room_id: roomid,
          sender: user.id,
        };
        socket.send(JSON.stringify(data));
        setMessage("");
      }
    }
  };

 const [windowWidth, setWindowWidth] = useState(window.innerWidth);

 useEffect(() => {
   const handleResize = () => {
     setWindowWidth(window.innerWidth);
   };

   window.addEventListener("resize", handleResize);

   return () => {
     window.removeEventListener("resize", handleResize);
   };
 }, []);

  return (
    <div>
      {loading ? (
        <Busy />
      ) : (
        <>
          <div className="chatpagetitle">
            <AiOutlineMenu
              onClick={offCanvasToggle}
              className="d-lg-none d-md-none d-sm-block me-2"
              size={23}
              style={{ cursor: "pointer" }}
            />{" "}
            <h1 className="title text-center">Chats</h1>
          </div>
          <Card className="itemscontainerwithtabs" style={{ height: "83vh" }}>
            <Row>
              <Col
                lg={3}
                xl={3}
                md={3}
                className="m-0 pe-0 ps-2 d-none d-sm-none d-md-block d-lg-block d-xl-block"
                style={{
                  backgroundColor: "#cedbf5",
                  height: "85vh",
                  overflowY: "auto",
                  borderRight: "1px solid #006a78",
                }}>
                <Col
                  style={{ height: "5vh" }}
                  className="d-flex flex-column"
                  lg={12}
                  sm={12}
                  xl={12}>
                  <h5
                    className="text-center"
                    style={{
                      padding: "5px",
                      color: "#006a78",
                      borderBottom: "1px solid #006a78",
                    }}>
                    Conversations
                  </h5>
                </Col>
                <Col style={{ height: "80vh" }} lg={12} sm={12} xl={12} md={12}>
                  <nav className="navigationcolumn">
                    {rooms.length > 0 &&
                      rooms?.map((my_room, index) => (
                        <NavLink
                          className="chatnavlink"
                          key={index}
                          action
                          active={my_room.id === roomid}
                          onClick={() => {
                            setRoomid(my_room.id);
                            setTitle(my_room.project_title);
                            setProjectId(my_room.projectid);
                            setMessages(my_room.room_messages);
                          }}>
                          <Row>
                            <Stack direction="horizontal">
                              <Col sm={3} lg={3} xl={3} className="pe-2">
                                {my_room.writer.profile_pic !== null ? (
                                  <img
                                    src={my_room.writer.profile_pic}
                                    className="chat_image"
                                    alt="employer_image"
                                  />
                                ) : (
                                  <HiOutlineUserCircle className="chat_no_image" />
                                )}{" "}
                              </Col>
                              <Col
                                sm={8}
                                lg={8}
                                xl={8}
                                className="single-line p-0 m-0">
                                <span>
                                  {my_room.writer.first_name}{" "}
                                  {my_room.writer.last_name}
                                  {my_room.writer.online ? (
                                    <>
                                      <img
                                        data-tooltip-id="online"
                                        src={green_dot}
                                        alt="online"
                                        style={{
                                          width: "10px",
                                          height: "10px",
                                          marginLeft: "15px",
                                          marginBottom: "5px",
                                        }}
                                      />
                                      <Tooltip
                                        id="online"
                                        content="Writer Online"
                                        variant="success"
                                      />
                                    </>
                                  ) : (
                                    <>
                                      <img
                                        data-tooltip-id="offline"
                                        src={red_dot}
                                        alt="offline"
                                        style={{
                                          width: "10px",
                                          height: "10px",
                                          marginLeft: "15px",
                                          marginBottom: "5px",
                                        }}
                                      />
                                      <Tooltip
                                        id="offline"
                                        content="Writer Offline"
                                      />
                                    </>
                                  )}
                                </span>{" "}
                                <br />
                                <span
                                  style={{
                                    fontSize: "small",
                                  }}>
                                  <b>{my_room.projectid}</b>{" "}
                                  {my_room.project_title}
                                </span>
                              </Col>
                            </Stack>
                          </Row>
                        </NavLink>
                      ))}
                  </nav>
                </Col>
              </Col>
              <Col
                lg={9}
                md={9}
                sm={12}
                style={{ height: "85vh" }}
                className="p-0 m-0">
                {roomid == null ? (
                  <div
                    className="d-flex justify-content-center align-items-center flex-column flex-grow-1 nochat"
                    style={{ height: "60vh" }}>
                    <AiOutlineMail className="nochaticon" />
                    <h1 className="text-center noconversation">
                      {" "}
                      No Conversation Selected
                    </h1>
                    {windowWidth < 768 ? (
                      <h6>Click on 3 lines to Select a Conversation</h6>
                    ) : (
                      <h6>Select a conversation from the Conversations list</h6>
                    )}
                  </div>
                ) : (
                  <Row>
                    <Col
                      lg={12}
                      sm={12}
                      md={12}
                      xl={12}
                      style={{ height: "6vh" }}
                      className="d-flex flex-column single-line">
                      <h6
                        className="text-center single-line"
                        style={{
                          backgroundColor: "#e5e5ea",
                          padding: "10px 15px",
                          color: "#006a78",
                          fontFamily: "EssayHostFontRegular",
                        }}>
                        <b>#{projectid}</b> {title}
                      </h6>
                    </Col>
                    <Col
                      ref={messagesContainerRef}
                      style={{ height: "60vh", overflowY: "auto" }}
                      lg={12}
                      sm={12}
                      xl={12}>
                      <ul>
                        {messages.map((message, index) => (
                          <li
                            key={index}
                            className={
                              message.sender === user.id
                                ? "senderStyle"
                                : "receiverStyle"
                            }>
                            <div>{message.message}</div>
                            <div
                              style={{
                                textAlign: "right",
                                fontSize: "small",
                                marginTop: "10px",
                              }}>
                              {moment(message.timestamp).format(
                                "MMMM Do YYYY, h:mm A"
                              )}
                            </div>
                          </li>
                        ))}
                      </ul>
                    </Col>
                    <Col
                      style={{
                        height: "10vh",
                      }}
                      lg={12}
                      sm={12}
                      xl={12}>
                      <form
                        onSubmit={handleSubmit}
                        style={{
                          padding: "10px",
                          backgroundColor: "#e5e5ea",
                        }}>
                      
                          <div
                            style={{ position: "relative", width: "100%" }}
                            className="p-2  d-flex align-items-center justify-content-center">
                            <textarea
                              type="text"
                              style={{ borderRadius: "10px", paddingRight: "10%" }}
                              className="mt-2"
                              onChange={(event) =>
                                setMessage(event.target.value)
                              }
                              onKeyDown={handleKeyDown}
                              value={message}
                              cols={100}
                            />
                          
                              <button
                                disabled={!message}
                                data-tooltip-id="send"
                              style={{
                                borderRadius: "50%",
                                height: "5vh",
                                width: "5vh",
                                position: "absolute",
                                bottom: "30%",
                                right: "5%",
                              }}
                              type="submit"
                              className="btn btn-sm btn-success d-flex justify-content-center align-items-center">
                                <BiSend size={21} />
                                <Tooltip id="send" content="Send Message"  />
                            </button>
                          </div>
                       
                      </form>
                    </Col>
                  </Row>
                )}
              </Col>
            </Row>
          </Card>
        </>
      )}
      <Modal
        show={show}
        onHide={() => setShow(false)}
        centered
        keyboard={false}>
        <Modal.Header closeButton>
          <Modal.Title>
            <h5 className="text-center">
              Sharing of Contact Information is Prohibited
            </h5>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <p>
            No sharing of Phone Number or Email is allowed through chat or files{" "}
            <br />
            If a client is found sharing this info he will be banned from
            accessing our services
          </p>
        </Modal.Body>
      </Modal>
      <Offcanvas
        show={offCanvas}
        onHide={closeChatofCanvas}
        className="chatOffCanvas">
        <Offcanvas.Header closeButton className="chatOffCanvasHeader">
          <Offcanvas.Title>Conversations</Offcanvas.Title>
        </Offcanvas.Header>
        <Offcanvas.Body className="chatOffCanvasBody">
          <nav className="navigationcolumn">
            {rooms.length > 0 &&
              rooms?.map((my_room, index) => (
                <NavLink
                  className="chatnavlink"
                  key={index}
                  action
                  active={my_room.id === roomid}
                  onClick={() => {
                    setRoomid(my_room.id);
                    setTitle(my_room.project_title);
                    setMessages(my_room.room_messages);
                    setProjectId(my_room.projectid);
                    closeChatofCanvas();
                  }}>
                  <Row>
                    <Stack direction="horizontal">
                      <Col sm={3} lg={3} xl={3} className="pe-2">
                        {my_room.writer.profile_pic !== null ? (
                          <img
                            src={my_room.writer.profile_pic}
                            className="chat_image"
                            alt="employer_image"
                          />
                        ) : (
                          <HiOutlineUserCircle className="chat_no_image" />
                        )}{" "}
                      </Col>
                      <Col sm={8} lg={8} xl={8} className="single-line p-0 m-0">
                        <span>
                          {my_room.writer.first_name} {my_room.writer.last_name}
                          {my_room.writer.online ? (
                            <>
                              <img
                                data-tooltip-id="online"
                                src={green_dot}
                                alt="online"
                                style={{
                                  width: "10px",
                                  height: "10px",
                                  marginLeft: "15px",
                                  marginBottom: "5px",
                                }}
                              />
                              <Tooltip
                                id="online"
                                content="Writer Online"
                                variant="success"
                              />
                            </>
                          ) : (
                            <>
                              <img
                                data-tooltip-id="offline"
                                src={red_dot}
                                alt="offline"
                                style={{
                                  width: "10px",
                                  height: "10px",
                                  marginLeft: "15px",
                                  marginBottom: "5px",
                                }}
                              />
                              <Tooltip id="offline" content="Writer Offline" />
                            </>
                          )}
                        </span>{" "}
                        <br />
                        <span
                          style={{
                            fontSize: "small",
                            marginLeft: "10px",
                          }}>
                          <b>{my_room.projectid}</b> {my_room.project_title}
                        </span>
                      </Col>
                    </Stack>
                  </Row>
                </NavLink>
              ))}
          </nav>
        </Offcanvas.Body>
      </Offcanvas>
    </div>
  );
}

const mapStateToProps = (state) => ({
  user: state.current_user.user,
});
export default connect(mapStateToProps)(EmployerChat);
