import React from "react";
import { Col } from "react-bootstrap";
import PageContainer from "../global/PageContainer";
import { useSelector } from "react-redux";
import AdminDash from "./AdminDash";
import ManagerDash from "./ManagerDash";

function AdminDashBoard() {
  const user = useSelector((state) => state.current_user.user);
  console.log(user);
  return (
    <PageContainer>
      {user.role === "Admin" ? <AdminDash /> : <ManagerDash />}
    </PageContainer>
  );
}

export default AdminDashBoard;
