import { Document, Page, Text, View, StyleSheet } from "@react-pdf/renderer";

// Create styles
const styles = StyleSheet.create({
  page: {
    flexDirection: "row",
    padding: 10,
  },
  mainsection: {
    flexGrow: 1,
    flexDirection: "column",
  },
  section: {
    margin: 10,
    padding: 10,
    flexGrow: 1,
  },
  title: {
    fontSize: 16,
    fontWeight: "bold",
  },
  original: {
    fontFamily: "Courier",
    lineHeight: 1.5,
    fontSize: 12,
  },
  aigenerated: {
    fontFamily: "Courier",
    fontSize: 12,
    lineHeight: 1.5,
    backgroundColor: "yellow",
  },
});

console.log(document)

// Create Document Component
const PlagDocument = ({ document, type }) => (
  <Document>
    <Page size="A4" style={styles.page}>
      <View style={styles.mainsection}>
        <View style={styles.section}>
          <Text>:{type}: {Math.round(Number(document.ai_score) * 100)}%</Text>
        </View>
        <View style={styles.section}>
          <Text>
            {document?.items?.map((item) => {
              return (
                <Text
                  style={
                    item.prediction === "original"
                      ? styles.original
                      : styles.aigenerated
                  }>
                  {item.text}
                </Text>
              );
            })}
          </Text>
        </View>
      </View>
    </Page>
  </Document>
);

export default PlagDocument