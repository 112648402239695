import React, { useEffect, useState } from "react";
import {
  Col,
  Stack,
  Toast,
  ToastContainer,
  Spinner,
  Card,
} from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import { useFormik } from "formik";
import { bid } from "../../actions/writeractions";
import { connect } from "react-redux";
import * as yup from "yup";
import { BiArrowBack } from "react-icons/bi";
import { CKEditor } from "@ckeditor/ckeditor5-react";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";

function PlaceBid({ bid }) {
  const [state, setState] = useState({
    loading: false,
    disable: false,
    notification: null,
  });
  const [showToast, setShowToast] = useState(false);
  const project = JSON.parse(localStorage.getItem("bidproject"));
  console.log(project);
  const navigate = useNavigate();
  const onSubmit = (values) => {
    setState({ ...state, loading: true, disable: true });
    bid(project.id, values.bidamount, values.biddescription).then((res) => {
      setState({
        loading: false,
        disable: true,
        notification: res,
      });
      setTimeout(() => {
        navigate("/writer/bids");
      }, 4000);
    });
  };

  const placebid = yup.object().shape({
    bidamount: yup
      .number()
      .max(
        project.employer.country === "Kenya"
          ? project.bidhigh
          : project.bidhigh * 100,
        "The Bid amount Should not exceed the Max Project Budget"
      )
      .min(
        project.employer.country === "Kenya"
          ? project.bidlow
          : project.bidlow * 100,
        "The Bid amount should not be lower than the Min Project Budget"
      )
      .required("Bid amount is a required field"),
    biddescription: yup
      .string()
      .max(700, "Number of Characters should not exceed 700")
      .required("Give a reason why you are suited to handle this bid"),
  });

  const {
    values,
    errors,
    touched,
    handleChange,
    handleSubmit,
    handleBlur,
    setFieldValue,
  } = useFormik({
    initialValues: {
      bidamount: "",
      biddescription: "",
    },
    validationSchema: placebid,
    onSubmit,
  });

  useEffect(() => {
    if (state.notification) {
      setShowToast(true);
    }
  }, [state.notification]);
  return (
    <Col >
      <ToastContainer
        position="top-end"
        className="p-2 mt-5"
        style={{ zIndex: 99999 }}>
        <Toast
          delay={3000}
          autohide
          className={
            state.notification === "Bid successfully Placed"
              ? "bg-success text-white"
              : "bg-danger text-white"
          }
          show={showToast}
          onClose={() => {
            setShowToast(false);
          }}>
          <Toast.Body>{state.notification}</Toast.Body>
        </Toast>
      </ToastContainer>
      <div className="pagetitle mt-2 mb-2">
        <h1 className="title">
          <BiArrowBack onClick={() => navigate(-1)} className="pagenavbutton" />
          Bid On Order ID: #{project.id}
        </h1>
      </div>
      <div className="itemscontainer d-flex align-items-center justify-content-center">
        <Card style={{ width: "100%", height: "100%" }}>
          <Card.Body>
            <h6>
              <span className="inlineitem">Project Budget:</span> Min Kshs{" "}
              {project.employer.country === "Kenya" ? (
                <>
                  {project.bidlow} Max Kshs {project.bidhigh}
                </>
              ) : (
                <>
                  {project.bidlow * 100} Max Kshs {project.bidhigh * 100}
                </>
              )}
            </h6>
            <h6>
              <span className="inlineitem">Subject:</span> {project.subject}
            </h6>
            <form onSubmit={handleSubmit}>
              <p>
                This amount should not be lower or exceed the project budget
              </p>
              <p>
                If Awarded 9% is deducted from your Bid amount for EssaysHost
                Operations
              </p>
              <Stack gap={1}>
                <label htmlFor="bidamount" style={{ color: "green" }}>
                  Amount
                </label>
                <input
                  type="number"
                  id="bidamount"
                  onChange={handleChange}
                  onBlur={handleBlur}
                  value={values.bidamount}
                  disabled={state.disable}
                  className={
                    errors.bidamount && touched.bidamount ? "input_error" : ""
                  }
                />
                <p style={{ color: "014d57", fontWeight: "bold" }}>
                  {" "}
                  You will get{" "}
                  {values.bidamount ? (
                    <span>
                      Kshs{" "}
                      {values.bidamount -
                        Math.ceil((9 / 100) * values.bidamount)}
                      .00
                    </span>
                  ) : (
                    <span>Kshs 0.00</span>
                  )}
                </p>
                {errors.bidamount && touched.bidamount ? (
                  <p className="error">{errors.bidamount}</p>
                ) : (
                  ""
                )}

                <label htmlFor="biddescription" style={{ color: "green" }}>
                  Give a brief description of why you are suited to handle this
                  project
                </label>
                <CKEditor
                  id="biddescription"
                  editor={ClassicEditor}
                  data={values.biddescription}
                  onChange={(event, editor) => {
                    const data = editor.getData();
                    console.log({ event, editor, data });
                    setFieldValue("biddescription", data);
                  }}
                  className={
                    errors.biddescription && touched.biddescription
                      ? "input_error ckeditor"
                      : "ckeditor"
                  }
                />
                {errors.biddescription && touched.biddescription ? (
                  <p className="error">{errors.biddescription}</p>
                ) : (
                  ""
                )}
                <div className="d-flex justify-content-center">
                  <button
                    type="submit"
                    className="btn  btn-success"
                    disabled={state.disable}>
                    {console.log(state.disable)}
                    {state.loading ? (
                      <span>
                        <Spinner as="span" animation="border" size="sm" />
                        Posting Bid...
                      </span>
                    ) : state.disable ? (
                      <span>Bid Placed</span>
                    ) : (
                      <span>Bid</span>
                    )}
                  </button>
                </div>
              </Stack>
            </form>
          </Card.Body>
        </Card>
      </div>
    </Col>
  );
}

export default connect(null, { bid })(PlaceBid);
