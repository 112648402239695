import React, { useEffect, useState } from "react";
import { useFormik } from "formik";
import {
  Stack,
  Spinner,
  Col,
  Card,
  Toast,
  ToastContainer,
} from "react-bootstrap";
import * as yup from "yup";
import { FILE_SIZE, SUPPORTED_FORMATS } from "../../assets/projectassets";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import { BiArrowBack } from "react-icons/bi";

function CompletedUpload() {
  const navigate = useNavigate();
  const [state, setState] = useState({
    loaded: false,
    loading: false,
    notification: "",
  });
  const [project, setProject] = useState(null);

  useEffect(() => {
    const project = JSON.parse(localStorage.getItem("projectUpload"));
    if (project) {
      setProject(project);
    }
  }, []);

  console.log(project);

  const writerFiles = async (formdata) => {
    const config = {
      headers: {
        Authorization: `JWT ${localStorage.getItem("access")}`,
      },
    };
    const url = `${process.env.REACT_APP_API_URL}/accounts/writer_upload_files`;
    try {
      const res = await axios.post(url, formdata, config);

      return res.data;
    } catch (err) {
      console.log(err);
      return err;
    }
  };
  const initialValues = {
    projectid: JSON.parse(localStorage.getItem("projectUpload")).id,
    projectFiles: [],
  };
  const onSubmit = (values) => {
    console.log(values);
    setState({ ...state, loading: true });
    const formdata = new FormData();
    for (const value in values) {
      if (value === "projectFiles") {
        const files = values[value];
        for (let j = 0; j < files.length; j++) {
          console.log(files[j]);
          formdata.append(`files${j}`, files[j]);
        }
      } else {
        formdata.append(value, values[value]);
      }
    }
    writerFiles(formdata)
      .then((res) => {
        console.log(res);

        setState({
          ...state,
          loaded: true,
          loading: false,
          notification: "Files Uploaded Successfully",
        });
        setTimeout(() => {
          navigate(-1);
        }, 3000);
      })
      .catch((err) => {
        console.log(err);
        setState({
          ...state,
          loading: false,
          notification: "Files Upload Failed",
        });
      });
  };

  const writerfilesvalidation = yup.object().shape({
    projectid: yup.number(),
    projectFiles: yup
      .array()
      .of(
        yup
          .mixed()
          .test(
            "fileSize",
            "File too large MAX 5MB for Each file",
            (value) => value && value.size <= FILE_SIZE
          )
          .test(
            "fileFormat",
            "Unsupported File Format! Supported formats =>(.docx, .jpeg, .jpg, .png, .xlxs, .ppt)",
            (value) => value && SUPPORTED_FORMATS.includes(value.type)
          )
      )
      .min(1, "You must Upload at least 1 file")
      .max(5, "You can Upload a Maximum of 5 files Per Project"),
  });
  const { values, errors, setFieldValue, handleSubmit, resetForm } = useFormik({
    initialValues: initialValues,
    validationSchema: writerfilesvalidation,
    onSubmit,
  });

  const [showToast, setShowToast] = useState(false);
  useEffect(() => {
    if (state.notification) {
      setShowToast(true);
    }
  }, [state.notification]);

  return (
    <Col className="mainarea">
      <div className="pagetitle mt-2 mb-2">
        <BiArrowBack onClick={() => navigate(-1)} className="pagenavbutton" />
        <h1 className="title">
          Upload files for Project ID: <strong>#{project?.id}</strong>
        </h1>
      </div>

      <Card className="itemscontainerwithtabs" style={{ height: "83vh" }}>
        <Card.Body style={{ pading: "10px" }}>
          <ToastContainer
            position="top-end"
            className="p-3"
            style={{ zIndex: 99999 }}>
            <Toast
              delay={3000}
              autohide
              className={
                state.notification === "Files Uploaded Successfully"
                  ? "bg-success text-white"
                  : "bg-danger text-white"
              }
              show={showToast}
              onClose={() => {
                setShowToast(false);
                setState({
                  ...state,
                  notification: "",
                });
              }}>
              <Toast.Body>{state.notification}</Toast.Body>
            </Toast>
          </ToastContainer>
          <Stack className="d-flex justify-content-center align-items-center mb-3 mt-3">
            <h6>Files you have Uploaded for Project {project?.id}</h6>
            {project?.writer_project_files.length > 0 ? (
              project?.writer_project_files.map((writerfile) => {
                return (
                  <a
                    href={
                      process.env.REACT_APP_API_URL +
                      writerfile.writer_project_file
                    }>
                    {writerfile.filename}
                  </a>
                );
              })
            ) : (
              <h6>You have Not Uploaded any Files for This Project</h6>
            )}
          </Stack>
          <Stack className="d-flex justify-content-center align-items-center">
            <h6>Upload Files for project ID: {project?.id}</h6>
            <form onSubmit={handleSubmit} encType="multiPart/formdata">
              <Stack gap={3}>
                <input
                  id="projectFiles"
                  type="file"
                  onChange={(event) => {
                    const files = [];
                    for (let i = 0; i < event.currentTarget.files.length; i++) {
                      files.push(event.currentTarget.files[i]);
                    }
                    setFieldValue("projectFiles", files);
                  }}
                  multiple
                />
                {errors.projectFiles ? (
                  <p className="error">{errors.projectFiles}</p>
                ) : (
                  ""
                )}

                <button
                  type="submit"
                  className="btn btn-sm btn-outline-success"
                  disabled={state.loading || state.loaded}>
                  {state.loading ? (
                    <>
                      <Spinner as="span" animation="border" size="sm" />{" "}
                      <span>Posting...</span>
                    </>
                  ) : (
                    <span>Submit </span>
                  )}
                </button>
              </Stack>
            </form>
          </Stack>
        </Card.Body>
      </Card>
    </Col>
  );
}

export default CompletedUpload;
