export const FILE_SIZE = 10485760; //10MB
export const SUPPORTED_FORMATS = [
  "application/pdf",
  "image/jpeg",
  "image/png",
  "image/jpg",
  "image/jfif",
  "application/vnd.openxmlformats-officedocument.wordprocessingml.document",
  "text/csv",
  "application/vnd.openxmlformats-officedocument.presentationml.presentation",
  "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
  "application/vnd.ms-excel",
  "application/vnd.ms-word",
  "application/vnd.ms-powerpoint",
  "application/zip",
];

export const PROFILE_IMAGE_FILE_SIZE = 2097152; //2 MB
export const PROFILE_IMAGE_SUPPORTED_FORMATS = [
  "application/pdf",
  "image/jpeg",
  "image/png",
  "image/jpg",
  "image/jfif",
];

export const phoneRegExp = /\+?[^\r\na-zA-Z]*\d{8,13}[^\r\na-zA-Z]*\b/;
export const subjects = [
  "English",
  "Literature",
  "Arts",
  "Sciences",
  "Mathematics",
  "Physics",
  "Chemistry",
  "Biology",
  "Calculus",
  "Medicine",
  "Nursing",
  "Business",
  "Finance",
  "Architecture",
  "Engineering",
  "Computer Science",
  "Information Technology",
  "Other",
];
export const levels = [
  "HighSchool",
  "Undergraduate",
  "Masters",
  "Doctorate/PHD",
  "Other",
];
export const papertypes = [
  "Article",
  "Essay",
  "Thesis/Dissertation",
  "Term Paper",
  "Presentation",
  "Article Review",
  "Research Proposal",
  "Research Paper",
  "Annotated Bibliography",
  "Reaction Paper",
  "Application Paper",
  "Lab Report",
  "Coursework",
  "Capstone Project",
  "Discussion Post",
  "Book/Movie Review",
  "Editing and Proofreading",
  "Admission Essay",
  "Case Study",
  "Report",
  "Other",
];
export const formats = ["APA", "MLA", "HARVARD", "CHICAGO", "OSCOLA", "OTHER"];
export const projectspacing = ["Double Spacing", "Single Spacing", "Other"];
export const availablelanguages = ["US English", "UK English", "Other"];
export const ipapi_access_key = "7009737752b735f061da1592a00df0ca";
