import React, { useEffect, useState } from "react";
import { Accordion, Col } from "react-bootstrap";
import { Link, useNavigate } from "react-router-dom";
import axios from "axios";
import { HiOutlineUserCircle } from "react-icons/hi";
import { BiArrowBack } from "react-icons/bi";
import { connect } from "react-redux";
import parse from "html-react-parser";
import Busy from "../global/Busy";
import { BiSolidStar } from "react-icons/bi";

function Bids({ user }) {
  console.log(user);
  const [projectid, setProjectid] = useState(0);

  const [chat, setChat] = useState(false);
  const navigate = useNavigate();
  const [state, setState] = useState({
    currentPage: 1,
    bids: [],
    loading: false,
    posts: 10,
  });

  useEffect(() => {
    const project = localStorage.getItem("biddedProject");
    if (project) {
      setProjectid(JSON.parse(project).id);
    }
  }, []);

  const get_bids_on_project = async (projectid) => {
    const config = {
      headers: {
        Authorization: `JWT ${localStorage.getItem("access")}`,
        "Content-Type": "application/json",
      },
    };
    const body = JSON.stringify({ projectid });
    try {
      const res = await axios.post(
        `${process.env.REACT_APP_API_URL}/accounts/bids_on_project`,
        body,
        config
      );
      console.log(res.data);
      setState({ ...state, bids: res.data, loading: false });
    } catch (err) {}
  };

  const createRoom = async (projectid, writerid) => {
    const url = `${process.env.REACT_APP_API_URL}/accounts/create_room`;
    const config = {
      headers: {
        Authorization: `JWT ${localStorage.getItem("access")}`,
        "Content-Type": "application/json",
      },
    };
    const body = JSON.stringify({ projectid, writerid });
    try {
      const res = await axios.post(url, body, config);

      navigate("/employer/chat");
      return res.data;
    } catch (err) {
      console.log(err);
    }
  };

  useEffect(() => {
    setState({ ...state, loading: true });
    get_bids_on_project(projectid);
  }, [projectid]);

  const postend = state.currentPage * state.posts;
  const poststart = postend - state.posts;
  const currentbids = state.bids.slice(poststart, postend);

  const pageNum = [];

  for (let i = 1; i <= Math.ceil(state.bids.length / state.posts); i++) {
    pageNum.push(i);
  }

  const set_current_page = (num) => {
    setState({ ...state, currentPage: num });
  };

  return (
    <Col >
      <div className="pagetitle">
        <BiArrowBack onClick={() => navigate(-1)} className="pagenavbutton" />
        <h1 className="title">Bids on Project ID: {projectid}</h1>
      </div>
      {state.loading ? (
        <Busy />
      ) : (
        state.bids.length > 0 && (
          <Accordion>
            <Accordion.Item eventKey="0">
              <Accordion.Header className="accordionheader">
                <table>
                  <thead>
                    <th width="30%">WRITER</th>
                    <th width="25%">RATING</th>
                    <th width="15%">LEVEL</th>
                    <th width="20%">AMOUNT</th>
                    <th width="10%">DONE</th>
                  </thead>
                </table>
              </Accordion.Header>
            </Accordion.Item>
            {currentbids.map((bid) => {
              return (
                <Accordion.Item key={bid.id} eventKey={bid.id}>
                  <Accordion.Header>
                    <table>
                      <td width="30%" className="single-line">
                        {bid.writer.profile_pic !== null ? (
                          <img
                            src={bid.writer.profile_pic}
                            className="project_image"
                            alt="writer_image"
                          />
                        ) : (
                          <HiOutlineUserCircle
                            className="project_icon"
                            style={{
                              height: "30px",
                              width: "30px",
                              marginRight: "1vw",
                            }}
                          />
                        )}{" "}
                        {bid.writer.first_name} {bid.writer.last_name}
                      </td>
                      <td width="25%">
                        <b className="boldrating">
                          <BiSolidStar
                            className="ratingstar"
                            style={{ color: "gold", verticalAlign: "-4px" }}
                          />

                          {bid.writer.writer_profile.rating}
                        </b>{" "}
                        ({bid.writer.writer_profile.ratingcount} Reviews)
                      </td>
                      <td width="15%">{bid.writer.writer_profile.level}</td>
                      <td width="20%">{user.country == "Kenya" ? (<> Kshs {bid.amount}.00</>) : (<> USD {bid.amount / 100}</>)}</td>
                      <td width="10%">
                        {bid.writer.writer_profile.completedcount}
                      </td>
                    </table>
                  </Accordion.Header>
                  <Accordion.Body>
                    <div>
                      {parse(bid.description)}
                      <hr />
                      <div className="d-flex justify-content-between">
                        <button
                          className="btn btn-outline-success btn-sm d-flex align-items-center"
                          onClick={() => {
                            setChat(true);
                            createRoom(projectid, bid.writer.id)
                              .then((res) => {
                                console.log(res);

                                localStorage.setItem(
                                  "chatRoom",
                                  JSON.stringify(res)
                                );
                              })
                              .catch((err) => {
                                setChat(false);
                                console.log(err);
                              });
                          }}>
                          {chat
                            ? "...Initiating Chat"
                            : `Chat ${bid.writer.first_name}`}
                        </button>

                        <Link
                          to="/employer/awardbid"
                          onClick={() => {
                            localStorage.setItem(
                              "awardData",
                              JSON.stringify(bid)
                            );
                          }}
                          className="btn btn-outline-primary btn-sm">
                          Award to {bid.writer.first_name}
                        </Link>
                        <button
                          className="btn btn-outline-info btn-sm"
                          onClick={() => {
                            localStorage.setItem(
                              "writer",
                              JSON.stringify(bid.writer)
                            );
                            navigate("/employer/writer-info");
                          }}>
                          {bid.writer.first_name}'s Bio
                        </button>
                      </div>
                    </div>
                  </Accordion.Body>
                </Accordion.Item>
              );
            })}
          </Accordion>
        )
      )}
      <div className="paginationcontainer  d-flex justify-content-center">
        {pageNum.map((num) => {
          return (
            <span
              onClick={() => {
                set_current_page(num);
              }}
              className={
                state.currentPage === num
                  ? "text-light bg-success paginationitem"
                  : "text-dark bg-light paginationitem"
              }>
              {num}
            </span>
          );
        })}
      </div>
    </Col>
  );
}

const mapStateToProps = (state) => ({
  user: state.auth.user,
});

export default connect(mapStateToProps)(Bids);
