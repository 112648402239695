import React, { useEffect } from "react";

import { BiSolidStar } from "react-icons/bi";
import axios from "axios";
import { Tooltip } from "react-tooltip";

const Rating = ({ writerid, projectid, setNotification, setShowToast1 }) => {
  const [rate, setRate] = React.useState(0);
  const [rating, setRating] = React.useState(false);

  useEffect(() => {
    const project = JSON.parse(localStorage.getItem("completeProject"));
    console.log(project);
    if (project) {
      if (project.rated === true) {
        setRating(true);
        setRate(project.rate);
      }
    }
  }, []);

  const createRating = async (therate) => {
    const config = {
      headers: {
        "Content-Type": "application/json",
        Authorization: `JWT ${localStorage.getItem("access")}`,
        Accept: "application/json",
      },
    };
    const body = JSON.stringify({
      rate: therate,
      writerid: writerid,
      projectid: projectid,
    });
    const url = `${process.env.REACT_APP_API_URL}/accounts/rate_writer`;
    try {
      const response = await axios.post(url, body, config);
      setShowToast1(true);
      setNotification("Writer Successfully Rated");
      console.log(response.data);
    } catch (err) {
      console.log(err);
      setShowToast1(true);
      setNotification("Error Rating Writer");
    }
  };

  console.log(rate);
  return (
    <div className="rating">
      {[1, 2, 3, 4, 5].map((index) => (
        <>
          <BiSolidStar
            data-tooltip-id={index}
            className={index <= rate ? "star starselected" : "star"}
            size={25}
            key={index}
            onClick={() => {
              if (!rating) {
                setRating(true);
                setRate(index);
                createRating(index)
                  .then((res) => {
                    if (res.data.resultCode === 0) {
                      setRating(false);
                    }
                  })
                  .catch((err) => {
                    console.log(err);
                  });
              }
            }}
          />
          <Tooltip
            id={index}
            content={index}
            place="bottom"
            variant="success"
          />
        </>
      ))}
    </div>
  );
};

export default Rating;
