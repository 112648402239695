import React, { useState } from "react";
import { Accordion, Col, Spinner } from "react-bootstrap";
import useFetch from "../../customHooks/useFetch";

import { useNavigate, Link } from "react-router-dom";
import Empty from "../global/Empty";
import Busy from "../global/Busy";
import axios from "axios";
import WriterProject from "./writerProject/WriterProject";
import { PiUploadSimpleDuotone } from "react-icons/pi";


export const WriterAssigned = () => {
  const [state, setState] = useState({
    currentpage: 1,
    postsperpage: 15,
    loading: false,
  });
  const navigate = useNavigate();
  const projects = useFetch(
    `${process.env.REACT_APP_API_URL}/accounts/writer_assigned`,
    navigate
  );
  const [markingComplete, setMarkingComplete] = useState(false);
  const [markedComplete, setMarkedComplete] = useState(false);

  let currentProjects = [];
  // Implement Page Numbers
  const pageNumbers = [];
  if (!projects.loading && projects.loading !== undefined) {
    const indexOfLastProject = state.currentpage * state.postsperpage;
    const indexOfFirstProject = indexOfLastProject - state.postsperpage;
    currentProjects = projects.data.slice(
      indexOfFirstProject,
      indexOfLastProject
    );

    // Depending on the length of the projects available implement the page numbers
    for (
      let i = 1;
      i <= Math.ceil(projects.data.length / state.postsperpage);
      i++
    ) {
      pageNumbers.push(i);
    }
  }

  const setPage = (num) => {
    setState({ ...state, currentpage: num });
  };

  const markComplete = async (projectid) => {
    setMarkingComplete(true);

    const config = {
      headers: {
        Authorization: `JWT ${localStorage.getItem("access")}`,
        "Content-Type": "application/json",
        Accept: "application/json",
      },
    };
    const url = `${process.env.REACT_APP_API_URL}/accounts/writer_mark_complete`;
    const body = JSON.stringify(projectid);
    try {
      const res = await axios.post(url, body, config);
      setMarkedComplete(true);
      setMarkingComplete(false);
      setState({
        ...state,
        notification: "Project has been Successfully Marked as Commplete",
      });
    } catch (err) {
      console.log(err);
      setMarkingComplete(false);
      setState({
        ...state,
        notification: "Project could not be Marked as Complete",
      });
    }
  };

  return (
    <Col className="mainarea">
      {projects.loading ? (
        <Busy />
      ) : (
        <>
          <div className="pagetitle">
            <h1 className="title">
              Orders in Progress {projects.data.length}
            </h1>
          </div>
          <Accordion>
            <Accordion.Item eventKey="0">
              <Accordion.Header className="accordionheader">
                <table>
                  <col style={{ width: "20%" }} />
                  <col style={{ width: "40%" }} />
                  <col style={{ width: "40%" }} />
                  <thead>
                    <th> ID</th>
                    <th>FIELD</th>
                    <th>BUDGET </th>
                  </thead>
                </table>
              </Accordion.Header>
            </Accordion.Item>
          </Accordion>
          <Accordion>
            {currentProjects.length === 0 ? (
              <Empty message="No Orders in Progress" />
            ) : (
              <div className="contentarea">
                {currentProjects.map((project) => {
                  return (
                    <Accordion.Item eventKey={project.id} key={project.id}>
                      <WriterProject project={project}>
                        <div className="d-flex justify-content-between">
                          {project.writermarkedcomplete ? (
                            <button
                              className="btn btn-sm btn-success"
                              disabled={true}>
                              Project Completed
                            </button>
                          ) : (
                            <Link
                              to="/writer/completedupload"
                              className="btn  btn-sm btn-outline-success"
                              onClick={() => {
                                localStorage.setItem(
                                  "projectUpload",
                                  JSON.stringify(project)
                                );
                                }}>
                                <PiUploadSimpleDuotone/> 
                              Upload Files
                            </Link>
                          )}
                          {project.writer_project_files.length > 0 &&
                          !project.writermarkedcomplete ? (
                            <button
                              disabled={markingComplete || markedComplete}
                              className="btn btn-sm btn-success"
                              onClick={() => {
                                markComplete(project?.id);
                              }}>
                              {markingComplete ? (
                                <>
                                  <Spinner
                                    as="span"
                                    animation="border"
                                    size="sm"
                                  />{" "}
                                  <span>Marking as Complete...</span>
                                </>
                              ) : (
                                <span>Mark as Complete </span>
                              )}
                            </button>
                          ) : (
                            ""
                          )}
                        </div>
                      </WriterProject>
                    </Accordion.Item>
                  );
                })}
              </div>
            )}
          </Accordion>
          <div className="paginationcontainer d-flex justify-content-center">
            {pageNumbers.map((pageNumber, index) => (
              <span
                key={index}
                className={
                  pageNumber === state.currentpage
                    ? "text-light bg-success paginationitem"
                    : "text-dark bg-light paginationitem"
                }
                onClick={() => {
                  setPage(pageNumber);
                }}>
                {pageNumber}
              </span>
            ))}
          </div>
        </>
      )}
    </Col>
  );
};
