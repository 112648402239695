import axios from "axios";
import * as actions from "./types";

export const get_user = () => async (dispatch) => {
  const config = {
    headers: {
      Authorization: `JWT ${localStorage.getItem("access")}`,
      "Content-Type": "application/json",
      Accept: "application/json",
    },
  };
  try {
    const res = await axios.get(
      `${process.env.REACT_APP_API_URL}/accounts/get_user`,
      config
    );
    dispatch({
      type: actions.CURRENT_USER_LOADED_SUCCESS,
      payload: res.data,
    });
    return res.data;
  } catch (err) {
    dispatch({
      type: actions.CURRENT_USER_LOADED_FAIL,
    });
    console.log(err);
  }
};
