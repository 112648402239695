import React, { useEffect, useState } from "react";
import { Card, Row, Stack, Col, Spinner } from "react-bootstrap";
import axios from "axios";
import moment from "moment";
import agreement from "../../../images/agreement.png";
import file from "../../../images/file.png";
import document from "../../../images/document.png";
import disputed from "../../../images/disputed.png";
import review from "../../../images/review.png";
import revision from "../../../images/revision.png";

function Projects() {
  const [loading, setLoading] = useState(true);
  const projectsurl = `${process.env.REACT_APP_API_URL}/siteadmin/all_projects`;
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);
  const [targetDate, setTargetDate] = useState(null);
  const [projects, setProjects] = useState({
    not_started: [],
    completed: [],
    disputed: [],
    in_progress: [],
    resolved: [],
    in_revision: [],
    all_projects: [],
  });

  const [filteredProjects, setFilteredProjects] = useState({
    not_started: [],
    completed: [],
    disputed: [],
    in_progress: [],
    resolved: [],
    in_revision: [],
    all_projects: [],
  });

  const getData = async (url) => {
    const config = {
      headers: {
        Authorization: `JWT ${localStorage.getItem("access")}`,
        "Content-Type": "application/json",
        Accept: "application/json",
      },
    };

    try {
      const res = await axios.get(url, config);
      const data = res.data;
      console.log(data);
      return data;
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    getData(projectsurl).then((res) => {
      setLoading(false);
      setProjects({
        not_started: res.filter((project) => {
          return project.assigned === false;
        }),
        completed: res.filter((project) => {
          return project.completed === true;
        }),
        disputed: res.filter((project) => {
          return project.disputed === true;
        }),
        in_progress: res.filter((project) => {
          return project.inprogress === true;
        }),
        in_revision: res.filter((project) => {
          return project.revision === true;
        }),
        resolved: res.filter((project) => {
          return project.resolved === true;
        }),

        all_projects: res,
      });
      setFilteredProjects({
        not_started: res.filter((project) => {
          return project.assigned === false;
        }),
        completed: res.filter((project) => {
          return project.completed === true;
        }),
        disputed: res.filter((project) => {
          return project.disputed === true;
        }),
        in_progress: res.filter((project) => {
          return project.inprogress === true;
        }),
        in_revision: res.filter((project) => {
          return project.revision === true;
        }),
        resolved: res.filter((project) => {
          return project.resolved === true;
        }),

        all_projects: res,
      });
    });
  }, []);

  useEffect(() => {
    if (startDate && endDate) {
      setFilteredProjects({
        ...projects,
        all_projects: projects.all_projects.filter((project) => {
          const projectDate = new Date(project.timestamp);
          return projectDate >= startDate && projectDate <= endDate;
        }),
        completed: projects.completed.filter((project) => {
          const projectDate = new Date(project.completedtime);
          return projectDate >= startDate && projectDate <= endDate;
        }),
        disputed: projects.disputed.filter((project) => {
          const projectDate = new Date(project.disputedtime);
          return projectDate >= startDate && projectDate <= endDate;
        }),
        in_progress: projects.in_progress.filter((project) => {
          const projectDate = new Date(project.assignedtime);
          return projectDate >= startDate && projectDate <= endDate;
        }),
        in_revision: projects.in_revision.filter((project) => {
          const projectDate = new Date(project.revisiontime);
          return new projectDate() >= startDate && new projectDate() <= endDate;
        }),
        not_started: projects.not_started.filter((project) => {
          const projectDate = new Date(project.timestamp);
          return projectDate >= startDate && projectDate <= endDate;
        }),
        resolved: projects.resolved.filter((project) => {
          const projectDate = new Date(project.resolvedtime);
          return projectDate >= startDate && projectDate <= endDate;
        }),
      });
    }
  }, [startDate, endDate]);

  console.log(startDate, endDate, targetDate);

  useEffect(() => {
    if (targetDate) {
      setFilteredProjects({
        ...projects,
        all_projects: projects.all_projects.filter((project) => {
          return (
            moment(project.timestamp).format("MM/DD/YYYY") ===
            moment(targetDate).format("MM/DD/YYYY")
          );
        }),
        completed: projects.completed.filter((project) => {
          return (
            moment(project.timestamp).format("MM/DD/YYYY") ===
            moment(targetDate).format("MM/DD/YYYY")
          );
        }),
        disputed: projects.disputed.filter((project) => {
          return (
            moment(project.timestamp).format("MM/DD/YYYY") ===
            moment(targetDate).format("MM/DD/YYYY")
          );
        }),
        in_progress: projects.in_progress.filter((project) => {
          return (
            moment(project.assignedtime).format("MM/DD/YYYY") ===
            moment(targetDate).format("MM/DD/YYYY")
          );
        }),
        in_revision: projects.in_revision.filter((project) => {
          return (
            moment(project.timestamp).format("MM/DD/YYYY") ===
            moment(targetDate).format("MM/DD/YYYY")
          );
        }),
        not_started: projects.not_started.filter((project) => {
          return (
            moment(project.timestamp).format("MM/DD/YYYY") ===
            moment(targetDate).format("MM/DD/YYYY")
          );
        }),
        resolved: projects.resolved.filter((project) => {
          return (
            moment(project.timestamp).format("MM/DD/YYYY") ===
            moment(targetDate).format("MM/DD/YYYY")
          );
        }),
      });
    }
  }, [targetDate]);

  return !loading ? (
    <Card>
      <Card.Header>
        <h4>Projects {filteredProjects.all_projects.length}</h4>
      </Card.Header>
      <Card.Body>
        <Row>
          <Col lg={12} sm={12}>
            <form className="d-flex">
              <Row className="fluid">
                <Col lg={4} sm={12} md={4}>
                  <Stack className="m-2">
                    <label htmlFor="startDate" style={{ color: "#006a78" }}>
                      Start Date
                    </label>
                    <input
                      type="date"
                      onChange={(event) => {
                        setStartDate(new Date(event.target.value));
                      }}
                    />
                  </Stack>
                </Col>
                <Col lg={4} sm={12} md={4}>
                  <Stack className="m-2">
                    <label htmlFor="startDate" style={{ color: "#006a78" }}>
                      End Date
                    </label>
                    <input
                      type="date"
                      onChange={(event) => {
                        setEndDate(new Date(event.target.value));
                      }}
                    />
                  </Stack>
                </Col>
                <Col lg={4} sm={12} md={4}>
                  <Stack className="m-2">
                    <label htmlFor="startDate" style={{ color: "#006a78" }}>
                      Specific Date
                    </label>
                    <input
                      type="date"
                      onChange={(event) => {
                        setTargetDate(new Date(event.target.value));
                      }}
                    />
                  </Stack>
                </Col>
              </Row>
            </form>
          </Col>
        </Row>
        <Row>
          <Col lg={4} sm={6}>
            <Card className="mt-2">
              <Card.Body>
                <Row>
                  <Col lg={7}>
                    <h6>In Progress</h6>
                    <h4>{filteredProjects.in_progress.length}</h4>
                  </Col>
                  <Col lg={5}>
                    <img
                      src={agreement}
                      alt="writingpng"
                      className="admin_dash_icon"
                    />
                  </Col>
                </Row>
              </Card.Body>
            </Card>
          </Col>
          <Col lg={4} sm={6}>
            <Card className="mt-2">
              <Card.Body>
                <Row>
                  <Col lg={7}>
                    <h6>Completed</h6>
                    <h4>{filteredProjects.completed.length}</h4>
                  </Col>
                  <Col lg={5}>
                    <img
                      src={document}
                      className="admin_dash_icon"
                      alt="documents"
                    />
                  </Col>
                </Row>
              </Card.Body>
            </Card>
          </Col>
          <Col lg={4} sm={6}>
            <Card className="mt-2">
              <Card.Body>
                <Row>
                  <Col lg={7}>
                    <h6>Not Started</h6>
                    <h4>{filteredProjects.not_started.length}</h4>
                  </Col>
                  <Col lg={5}>
                    <img
                      src={file}
                      alt="documentsgif"
                      className="admin_dash_icon"
                    />
                  </Col>
                </Row>
              </Card.Body>
            </Card>
          </Col>
          <Col lg={4} sm={6}>
            <Card className="mt-2">
              <Card.Body>
                <Row>
                  <Col lg={7}>
                    <h6>In Revision</h6>
                    <h4>{filteredProjects.in_revision.length}</h4>
                  </Col>
                  <Col lg={5}>
                    <img
                      src={revision}
                      className="admin_dash_icon"
                      alt="revision"
                    />
                  </Col>
                </Row>
              </Card.Body>
            </Card>
          </Col>
          <Col lg={4} sm={6}>
            <Card className="mt-2">
              <Card.Body>
                <Row>
                  <Col lg={7}>
                    <h6>Disputed</h6>
                    <h4>{filteredProjects.disputed.length}</h4>
                  </Col>
                  <Col lg={5}>
                    <img
                      src={disputed}
                      className="admin_dash_icon"
                      alt="disputed"
                    />
                  </Col>
                </Row>
              </Card.Body>
            </Card>
          </Col>
          <Col lg={4} sm={6}>
            <Card className="mt-2">
              <Card.Body>
                <Row>
                  <Col lg={7}>
                    <h6>Resolved</h6>
                    <h4>{filteredProjects.resolved.length}</h4>
                  </Col>
                  <Col lg={5}>
                    <img
                      src={review}
                      className="admin_dash_icon"
                      alt="review"
                    />
                  </Col>
                </Row>
              </Card.Body>
            </Card>
          </Col>
        </Row>
      </Card.Body>
    </Card>
  ) : (
    <Row>
      <Col
        lg={12}
        sm={12}
        md={12}
        className="loader d-flex justify-content-center align-items-center"
        style={{ height: "20vh" }}>
        <Spinner animation="border" variant="success" />
      </Col>
    </Row>
  );
}

export default Projects;
