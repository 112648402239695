import React, { useState, useEffect } from "react";
import {
  Col,
  Row,
  Stack,
  Spinner,
  Toast,
  ToastContainer,
} from "react-bootstrap";
import { useNavigate, useLocation } from "react-router-dom";
import { BiArrowBack, BiArrowToRight, BiArrowToLeft } from "react-icons/bi";
import { useFormik } from "formik";
import * as yup from "yup";
import {
  subjects,
  papertypes,
  levels,
  formats,
  projectspacing,
  FILE_SIZE,
  availablelanguages,
} from "../../assets/projectassets";
import usePatch from "../../customHooks/usePatch";
import { CKEditor } from "@ckeditor/ckeditor5-react";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import moment from "moment";
import { RiEdit2Line } from "react-icons/ri";
function EditProject() {
  const [state, setState] = useState({
    loading: false,
    notification: null,
    project: {},
  });
  const [showError, setShowError] =useState(false)
  const navigate = useNavigate();
  const location = useLocation();
  let [sectiondisplay, setSectiondisplay] = useState(true);
  const changesection1 = () => {
    setSectiondisplay(false);
  };
  const changesection2 = () => {
    setSectiondisplay(true);
  };

  const url = `${process.env.REACT_APP_API_URL}/accounts/project`;
  const project = JSON.parse(localStorage.getItem("project"));
  const patch = usePatch();
  // const { project } = location.state;
  console.log(project);

  const onSubmit = (values) => {
    console.log(values);
    setState({ ...state, loading: true });
    let formData = new FormData();
    for (let value in values) {
      if (value === "projectfiles") {
        for (let j = 0; j < values[value].length; j++) {
          const file = values[value];
          formData.append(`file${j}`, file[j]);
        }
      } else {
        formData.append(value, values[value]);
      }
    }
    patch(url, formData)
      .then((res) => {
        const message = "Project Edited Successfully";
        setState({
          loading: true,
          notification: message,
          project: res,
        });

        setTimeout(() => {
          navigate(-1);
        }, 3000);
      })
      .catch((err) => {
        console.log(err);
        const message = "Project Could Not be Edited";
        setState({
          loading: false,
          notification: message,
        });
      });
  };
  useEffect(() => {
    console.log(state.project);
  }, [state.project]);

  const projectSchema1 = yup.object().shape({
    title: yup.string().required("Project title required"),
    description: yup.string().required("Description required"),
    projectfiles: yup
      .array()
      .of(
        yup
          .mixed()
          .test(
            "fileSize",
            "File too large MAX 10MB for Each file",
            (value) => value && value.size <= FILE_SIZE
          )
      )
      .max(10, "You can Upload a Maximum of 10 files Per Project"),
  });
  const projectSchema2 = yup.object().shape({
    level: yup.string().required("Academic Level required"),
    papertype: yup.string().required("Type of Project required"),
    subject: yup.string().required("Project subject required"),
    
    formatstyle: yup.string().required("Project formatstyle required"),
    pages: yup
      .number()
      .min(1, "Min number of pages 1")
      .required("Number of pages required"),
    
    deadline: yup
      .date()
      .min(new Date(), "Deadline should be at least one hour from now")
      .required("Project Deadline required"),
    bidlow: yup
      .number()
      .required("Lowest bid required")
      .min(250, "The minimum bid amount for a project should be Kshs 250"),
    bidhigh: yup
      .number()
      .required("Highest bid required")
      .min(
        yup.ref("bidlow"),
        "Should be equal or higher than the lowest bid Amount"
      ),
    spacing: yup.string().required("Project Spacing required"),
  });

  const {
    errors,
    touched,
    values,
    handleChange,
    handleBlur,
    handleSubmit,
    setFieldValue,
    setStatus,
  } = useFormik({
    initialValues: {
      projectid: project?.id || 0,
      title: project?.title || "",
      description: project?.description || "",
      pages: project?.pages || 0,
      spacing: project?.spacing || "",
      papertype: project?.papertype || "",
      formatstyle: project?.formatstyle || "",
      level: project?.level || "",
      subject: project?.subject || "",
      sources: project?.sources || 0,
      bidhigh: project?.bidhigh || 0,
      bidlow: project?.bidlow || 0,
      deadline: project?.deadline || "",
      projectfiles: [],
    },
    onSubmit,
    validationSchema: sectiondisplay === false ? projectSchema2 : projectSchema1,
  });
  console.log(values);
  const [showToast, setShowToast] = useState(false);
  useEffect(() => {
    if (state.notification) {
      setShowToast(true);
    }
  }, [state.notification]);


  const validate = (projectSchema) => {
    let validationSchema = projectSchema;
    
    // If there's a validation schema, validate and return the result
    if (validationSchema) {
      try {
        validationSchema.validateSync(values, { abortEarly: false });
        setShowError(false);
        return true; // Validation succeeded
      } catch (validationError) {
        const validationErrors = validationError.inner.reduce((errors, err) => {
          return { ...errors, [err.path]: err.message };
        }, {});
        setStatus(validationErrors);
        setShowError(true);
        return false; // Validation failed
      }
    }

    return true; // Default to true if no validation schema is found
  };

   useEffect(() => {
     if (values.papertype === "Presentation") {
       setFieldValue("bidlow", values.pages * 150);
     } else {
       setFieldValue("bidlow", values.pages * 250);
     }
   }, [values.pages, values.papertype]);
  
  console.log(errors)

  return (
    <div>
      <div className="pagetitle" id="mymaintitle">
        <h1 className="title">
          <BiArrowBack onClick={() => navigate(-1)} className="pagenavbutton" />{" "}
          Edit Order {project?.id}
        </h1>
      </div>
      <div className="itemscontainer mt-2">
        <ToastContainer
          position="top-end"
          className="pt-5 m-1"
          autohide
          delay={3000}
          style={{ zIndex: 99999 }}>
          <Toast
            className={
              state.notification === "Project Edited Successfully"
                ? "bg-success text-white"
                : "bg-danger text-white"
            }
            show={showToast}
            onClose={() => {
              setShowToast(false);
            }}>
            <Toast.Body>{state.notification}</Toast.Body>
          </Toast>
        </ToastContainer>
        <form
          onSubmit={handleSubmit}
          encType="multipart/form-data"
          className="postprojectscontainer"
          id="postprojectsform">
          {sectiondisplay ? (
            <Row>
              <Row>
                <Col>
                  <Stack gap={3}>
                    <label
                      htmlFor="title"
                      style={{ textAlign: "center" }}
                      className="postlabel">
                      Project Title/Topic
                      <span style={{ color: "red" }}>&#42;</span>
                    </label>
                    <input
                      type="text"
                      id="title"
                      onChange={handleChange}
                      onBlur={handleBlur}
                      value={values.title}
                      className={showError && errors.title ? "input_error" : ""}
                    />
                    {showError && errors.title ? (
                      <p className="error">{errors.title}</p>
                    ) : (
                      ""
                    )}
                  </Stack>
                </Col>
              </Row>
              <Row>
                <Col>
                  <Stack gap={3}>
                    <label htmlFor="description" className="postlabel">
                      Project Details<span style={{ color: "red" }}>&#42;</span>
                    </label>
                    <CKEditor
                      id="description"
                      editor={ClassicEditor}
                      data={values.description}
                      onChange={(event, editor) => {
                        const data = editor.getData();
                        console.log({ event, editor, data });
                        setFieldValue("description", data);
                      }}
                      className={
                        errors.description && showError
                          ? "input_error"
                          : ""
                      }
                    />
                    {errors.description && showError ? (
                      <p className="error">{errors.description}</p>
                    ) : (
                      ""
                    )}
                  </Stack>
                </Col>
              </Row>
              <Row>
                <Col>
                  <Stack gap={3}>
                    <label htmlFor="files" className="postlabel">
                      Upload Project Attachments (Optional)
                    </label>
                    <input
                      type="file"
                      id="files"
                      name="files"
                      onChange={(event) => {
                        const files = [];
                        for (
                          let i = 0;
                          i < event.currentTarget.files.length;
                          i++
                        ) {
                          files.push(event.currentTarget.files[i]);
                        }
                        setFieldValue("projectfiles", files);
                      }}
                      multiple
                    />
                    {showError && errors.projectfiles ? (
                      <p className="error">{errors.projectfiles}</p>
                    ) : (
                      ""
                    )}
                  </Stack>
                </Col>
              </Row>
            </Row>
          ) : (
            <Row>
              <Row>
                <Col sm={12} lg={6}>
                  <Stack gap={3}>
                    <label htmlFor="level" className="postlabel">
                      Academic Level<span style={{ color: "red" }}>&#42;</span>
                    </label>
                    <select
                      value={values.level}
                      id="level"
                      name="level"
                      onChange={handleChange}
                      className={
                        errors.level && touched.level ? "input_error" : ""
                      }>
                      {levels.map((level, index) => {
                        return <option key={index}>{level}</option>;
                      })}
                    </select>
                    {errors.level && touched.level ? (
                      <p className="error">{errors.level}</p>
                    ) : (
                      ""
                    )}
                  </Stack>
                </Col>
                <Col sm={12} lg={6}>
                  <Stack gap={3}>
                    <label htmlFor="papertype" className="postlabel">
                      Type Of Project<span style={{ color: "red" }}>&#42;</span>
                    </label>
                    <select
                      value={values.papertype}
                      name="papertype"
                      list="mypapertype"
                      id="papertype"
                      onChange={handleChange}
                      className={
                        errors.papertype && touched.papertype
                          ? "input_error"
                          : ""
                      }>
                      {" "}
                      {papertypes.map((type, index) => {
                        return <option key={index}>{type}</option>;
                      })}
                    </select>
                    {errors.papertype && touched.papertype ? (
                      <p className="error">{errors.papertype}</p>
                    ) : (
                      ""
                    )}
                  </Stack>
                </Col>
              </Row>
              <Row>
                <Col sm={12} lg={6}>
                  <Stack gap={3}>
                    <label htmlFor="subject" className="postlabel">
                      Subject<span style={{ color: "red" }}>&#42;</span>
                    </label>
                    <select
                      value={values.subject}
                      list="mysubject"
                      name="subject"
                      id="subject"
                      onChange={handleChange}
                      className={
                        errors.subject && touched.subject ? "input_error" : ""
                      }>
                      {subjects.map((mysubject, index) => {
                        return <option key={index}>{mysubject}</option>;
                      })}
                    </select>
                    {errors.subject && touched.subject ? (
                      <p className="error">{errors.subject}</p>
                    ) : (
                      ""
                    )}
                  </Stack>
                </Col>
                <Col sm={12} lg={6}>
                  <Stack gap={3}>
                    <label htmlFor="format" className="postlabel">
                      Format<span style={{ color: "red" }}>&#42;</span>
                    </label>
                    <select
                      value={values.formatstyle}
                      name="format"
                      id="format"
                      onChange={handleChange}
                      className={
                        errors.format && touched.format ? "input_error" : ""
                      }>
                      {formats.map((myformat, index) => {
                        return <option key={index}>{myformat}</option>;
                      })}
                    </select>
                    {errors.format && touched.format ? (
                      <p className="error">{errors.format}</p>
                    ) : (
                      ""
                    )}
                  </Stack>
                </Col>
              </Row>
              <Row>
                <Col lg={6} sm={12}>
                  <Stack gap={3}>
                    <label htmlFor="pages" className="postlabel">
                      Number of Pages<span style={{ color: "red" }}>&#42;</span>
                    </label>
                    <input
                      type="number"
                      name="pages"
                      id="pages"
                      value={values.pages}
                      onChange={handleChange}
                      className={
                        errors.pages && touched.pages ? "input_error" : ""
                      }
                    />
                    {errors.pages && touched.pages ? (
                      <p className="error">{errors.pages}</p>
                    ) : (
                      ""
                    )}
                  </Stack>
                </Col>
                <Col lg={6} sm={12}>
                  <Stack gap={3}>
                    <label htmlFor="spacing" className="postlabel">
                      Spacing<span style={{ color: "red" }}>&#42;</span>
                    </label>
                    <select
                      value={values.spacing}
                      name="spacing"
                      id="spacing"
                      onChange={handleChange}
                      className={
                        errors.spacing && touched.spacing ? "input_error" : ""
                      }>
                      {projectspacing.map((myspacing, index) => {
                        return <option key={index}>{myspacing}</option>;
                      })}
                    </select>
                    {errors.spacing && touched.spacing ? (
                      <p className="error">{errors.spacing}</p>
                    ) : (
                      ""
                    )}
                  </Stack>
                </Col>
              </Row>
              <Row>
                <Col lg={6} sm={12}>
                  <Stack gap={3}>
                    <label htmlFor="deadline" className="postlabel">
                      Project Deadline
                      <span style={{ color: "red" }}>&#42;</span>{" "}
                      <span style={{ color: "green" }}>
                        {moment(values.deadline).format("MMM Do YYYY")}
                      </span>
                    </label>
                    <input
                      type="datetime-local"
                      id="deadline"
                      value={values.deadline}
                      onChange={handleChange}
                      className={
                        errors.deadline && touched.deadline ? "input_error" : ""
                      }
                    />
                    {errors.deadline && touched.deadline ? (
                      <p className="error">{errors.deadline}</p>
                    ) : (
                      ""
                    )}
                  </Stack>
                </Col>
                <Col lg={6} sm={12}>
                  <Stack gap={3}>
                    <label htmlFor="sources" className="postlabel">
                      Source Count<span style={{ color: "red" }}>&#42;</span>
                    </label>
                    <input
                      type="number"
                      id="sources"
                      value={values.sources}
                      onChange={handleChange}
                      className={
                        errors.sources && touched.sources ? "input_error" : ""
                      }
                    />
                    {errors.sources && touched.sources ? (
                      <p className="error">{errors.sourcecount}</p>
                    ) : (
                      ""
                    )}
                  </Stack>
                </Col>
              </Row>
              <Row>
                <Col lg={4} sm={12}>
                  <Stack gap={3}>
                    <label htmlFor="language" className="postlabel">
                      Language<span style={{ color: "red" }}>&#42;</span>
                    </label>
                    <select
                      value={values.essaylanguage}
                      type="number"
                      id="language"
                      onChange={handleChange}
                      className={
                        errors.language && touched.language ? "input_error" : ""
                      }>
                      {" "}
                      {availablelanguages.map((language, index) => {
                        return <option key={index}>{language}</option>;
                      })}
                    </select>
                    {errors.language && touched.language ? (
                      <p className="error">{errors.language}</p>
                    ) : (
                      ""
                    )}
                  </Stack>
                </Col>
                <Col lg={4} sm={12}>
                  <Stack gap={3}>
                    <label htmlFor="bidlow" className="postlabel">
                      Lowest Bid Amount
                      <span style={{ color: "red" }}>&#42;</span>
                    </label>
                    <input
                      disabled
                      type="number"
                      id="bidlow"
                      value={values.bidlow}
                      onChange={handleChange}
                      className={
                        errors.bidlow && touched.bidlow ? "input_error" : ""
                      }
                    />
                    {errors.bidlow && touched.bidlow ? (
                      <p className="error">{errors.bidlow}</p>
                    ) : (
                      ""
                    )}
                  </Stack>
                </Col>
                <Col lg={4} sm={12}>
                  <Stack gap={3}>
                    <label htmlFor="bidhigh" className="postlabel">
                      Highest Bid Amount
                      <span style={{ color: "red" }}>&#42;</span>
                    </label>
                    <input
                      disabled={project.take}
                      type="number"
                      id="bidhigh"
                      value={values.bidhigh}
                      onChange={handleChange}
                      className={
                        errors.bidhigh && touched.bidhigh ? "input_error" : ""
                      }
                    />
                    {errors.bidhigh && touched.bidhigh ? (
                      <p className="error">{errors.bidhigh}</p>
                    ) : (
                      ""
                    )}
                  </Stack>
                </Col>
              </Row>
            </Row>
          )}
          <Row>
            <Col className="d-flex justify-content-between mt-4 pe-4">
              <button
                type="button"
                onClick={changesection2}
                className={
                  sectiondisplay
                    ? "d-flex justify-content-center align-items-center btn btn-sm btn-outline-success"
                    : "d-flex justify-content-center align-items-center btn btn-sm btn-outline-success"
                }>
                <BiArrowToLeft /> Previous
              </button>
              <button
                type="submit"
                className={
                  sectiondisplay == 1 ? "d-none" : "btn btn-success btn-sm"
                }
                disabled={state.loading}>
                {state.loading ? (
                  <>
                    <Spinner as="span" animation="border" size="sm" />{" "}
                    <span>Editing...</span>
                  </>
                ) : (
                  <span>
                    <RiEdit2Line />
                    Edit Project{" "}
                  </span>
                )}
              </button>
              <button
                type="button"
                onClick={() => {
                  if (validate(projectSchema1)) {
                    changesection1();
                  }
                }}
                className={
                  sectiondisplay
                    ? " btn btn-outline-info btn-sm"
                    : " btn btn-outline-info btn-sm"
                }>
                Next <BiArrowToRight style={{}} />
              </button>
            </Col>
          </Row>
        </form>
      </div>
    </div>
  );
}

export default EditProject;
