import { React, useState, useEffect } from "react";
import { useFormik } from "formik";
import {
  Col,
  Row,
  Stack,
  Spinner,
  Toast,
  ToastContainer,
  ProgressBar,
} from "react-bootstrap";
import { postproject } from "../../actions/employeractions";
import { get_user } from "../../actions/myuser";
import { connect } from "react-redux";
import { FILE_SIZE } from "../../assets/projectassets";
import { useNavigate } from "react-router-dom";
import * as yup from "yup";
import { BiArrowToLeft, BiArrowToRight } from "react-icons/bi";
import RenderPostProject from "./RenderPostProject";

import { useSelector } from "react-redux";

function Postprojects({ postproject }) {
  const country = useSelector((state) => state.userCountry.country)
  const [state, setState] = useState({
    loading: false,
    notification: null,
  });
  let [sectiondisplay, setSectiondisplay] = useState(1);
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);
  const [showToast, setShowToast] = useState(false);
  const [showError, setShowError] = useState(false);
  const [fillFields, setFillFields] = useState(false);
  const currentDate = new Date();
  const navigate = useNavigate();
  const initialValues = {
    academiclevel: "",
    papertype: "",
    subject: "",
    title: "",
    description: "",
    format: "",
    numberofpages: "",
    projectfiles: [],
    deadline: "",
    bidhigh: "",
    bidlow: "",
    spacing: "",
    sourcecount: "",
    essaylanguage: "",
    project_type: "Public",
    award_type: "Bid",
    trusted: false,
    payment_date: currentDate.toISOString().slice(0, 19).replace("T", " "),
  };

  useEffect(() => {
    // Update window width when the window is resized
    const handleResize = () => {
      setWindowWidth(window.innerWidth);
    };

    // Attach the event listener
    window.addEventListener("resize", handleResize);

    // Clean up the event listener when the component unmounts
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  const onSubmit = (values) => {
    setState({ ...state, loading: true});
    let formData = new FormData();
    for (let value in values) {
      if (value === "projectfiles") {
        for (let j = 0; j < values[value].length; j++) {
          const file = values[value];
          formData.append(`file${j}`, file[j]);
        }
      } else {
        formData.append(value, values[value]);
      }
    }
    postproject(formData)
      .then((res) => {
        setState({
          notification: res,
          loading: true
        });
        setTimeout(() => {
          if (res === "Project Successfully Posted") {
            navigate("/employer/my_projects");
          }
        }, 3000);
      })
      .catch((err) => {
        console.log(err);
      });
  };
  const projectSchema = yup.object().shape({
    title: yup.string().required("Project title required"),
    description: yup.string().required("Project instructions required"),
    projectfiles: yup
      .array()
      .of(
        yup
          .mixed()
          .test(
            "fileSize",
            "File too large MAX 5MB for Each file",
            (value) => value && value.size <= FILE_SIZE
          )
      )
      .max(10, "You can Upload a Maximum of 10 files Per Project"),
  });

  const projectSchema2 = yup.object().shape({
    academiclevel: yup.string().required("Academic Level required"),
    papertype: yup.string().required("Type of Project required"),
    subject: yup.string().required("Project subject required"),
    format: yup.string().required("Project format required"),
    numberofpages: yup
      .number()
      .min(1, "Min number of pages 1")
      .required("Number of pages required"),
    deadline: yup
      .date()
      .min(new Date(), "Deadline should be at least one hour from now")
      .required("Project Deadline required"),
    bidlow: yup
      .number()
      .min(country === "Kenya"? 150: 5, country === "Kenya"? "The lowest budget for a project is Kshs 150": "The lowest budget for a project is USD 5")
      .required("Lowest Order budget required"),
    bidhigh: yup
      .number()
      .required("Highest Order budget required")
      .min(
        yup.ref("bidlow"),
        "Should be equal or higher than the lowest Order budget Amount"
    )
    .max(100000, "Maximum Project budged is Kshs 100000"),
    spacing: yup.string().required("Project Spacing required"),
    sourcecount: yup.number().min(0, "Zero or More Sources Required").required("Reference Sources Required"),
    essaylanguage: yup.string().required("Essay language required"),
    payment_date: yup.date().when("trusted", {
      is: true,
      then: yup
        .date()
        .min(
          new Date(),
          "Payment Date and time should be at least one day From now"
        )
        .required("Payment Date required"),
      otherwise: yup.date().notRequired(),
    }),
  });

  const {
    errors,
    touched,
    values,
    handleChange,
    handleBlur,
    handleSubmit,
    setFieldValue,
    setFieldError,
    setStatus,
  } = useFormik({
    initialValues,
    validationSchema: sectiondisplay === 1 ? projectSchema : projectSchema2,
    onSubmit,
  });

  useEffect(() => {
    if (state.notification) {
      setShowToast(true);
    }
  }, [state.notification]);

  useEffect(() => {
    if (showError && Object.keys(errors).length === 0) {
      setFillFields(true);
    }
  }, [showError]);


  const validate = (values, section) => {
    let validationSchema = null;
    switch (section) {
      case 1:
        // Validate step 0 fields
        validationSchema = projectSchema;
        break;

      case 2:
        // Validate step 1 fields
        validationSchema = projectSchema2;
        break;
    }
    // If there's a validation schema, validate and return the result
    if (validationSchema) {
      try {
        validationSchema.validateSync(values, { abortEarly: false });
        setShowError(false);
        return true; // Validation succeeded
      } catch (validationError) {
        const validationErrors = validationError.inner.reduce((errors, err) => {
          return { ...errors, [err.path]: err.message };
        }, {});
        setStatus(validationErrors);
        setShowError(true);
        return false; // Validation failed
      }
    }

    return true; // Default to true if no validation schema is found
  };

  return (
    <div>
      <div className="pagetitle" id="myprojectpost">
        <h1 className="title">Post Order</h1>
      </div>
      <div className="itemscontainer mt-2">
        <ToastContainer
          position="top-end"
          className="p-3"
          style={{ zIndex: 99999 }}>
          <Toast
            delay={3000}
            autohide
            className={
              state.notification === "Project Successfully Posted"
                ? "bg-success text-white"
                : "bg-danger text-white"
            }
            show={showToast}
            onClose={() => {
              setShowToast(false);
            }}>
            <Toast.Body>{state.notification}</Toast.Body>
          </Toast>
          <Toast
            delay={3000}
            autohide
            show={fillFields}
            onClose={() => {
              setFillFields(false);
              setShowError(false);
            }}
            className="bg-danger text-white">
            <Toast.Body>Please Fill in the Required Fields</Toast.Body>
          </Toast>
        </ToastContainer>
        <Row spacing={3}>
          <Col
            className="d-flex justify-content-around position-relative"
            lg={12}
            sm={12}>
            <ProgressBar
              variant="success"
              className="w-100"
              now={sectiondisplay * 33.33}
              style={{
                zIndex: 1,
                position: "absolute",
                top: "35%",
              }}
            />
            <div className="p-2" style={{ zIndex: 2 }}>
              <Stack className="d-flex justify-content-center align-items-center">
                <div
                  onClick={() => {
                    setSectiondisplay(1);
                  }}
                  className={
                    sectiondisplay >= 1
                      ? "sectiondiv2 d-flex justify-content-center align-items-center"
                      : "sectiondiv d-flex justify-content-center align-items-center"
                  }>
                  1
                </div>
              </Stack>
            </div>
            <div className="p-2" style={{ zIndex: 2 }}>
              <Stack className="d-flex justify-content-center align-items-center">
                <div
                  onClick={() => {
                    if (validate(values, sectiondisplay)) {
                      setSectiondisplay(2);
                    }
                  }}
                  className={
                    sectiondisplay >= 2
                      ? "sectiondiv2 d-flex justify-content-center align-items-center"
                      : "sectiondiv d-flex justify-content-center align-items-center"
                  }>
                  2
                </div>
              </Stack>
            </div>
            <div className="p-2" style={{ zIndex: 2 }}>
              <Stack className="d-flex justify-content-center align-items-center">
                <div
                  onClick={() => {
                    if (validate(values, sectiondisplay)) {
                      setSectiondisplay(3);
                    }
                  }}
                  className={
                    sectiondisplay === 3
                      ? "sectiondiv2 d-flex justify-content-center align-items-center"
                      : "sectiondiv d-flex justify-content-center align-items-center"
                  }>
                  3
                </div>
              </Stack>
            </div>
          </Col>
          <Col lg={12} sm={12}>
            <form
              onSubmit={handleSubmit}
              encType="multipart/form-data"
              className="postprojectscontainer"
              id="postprojectsform">
              <RenderPostProject
                values={values}
                handleChange={handleChange}
                handleBlur={handleBlur}
                errors={errors}
                touched={touched}
                setFieldValue={setFieldValue}
                state={state}
                sectiondisplay={sectiondisplay}
                showError={showError}
                setFieldError={setFieldError}
                setShowError={setShowError}
              />
              <div className="d-flex justify-content-between pe-4 mb-1 mt-2">
                <button
                  type="button"
                  disabled={sectiondisplay === 1}
                  className={
                    sectiondisplay === 1
                      ? "btn btn-sm btn-secondary disabled"
                      : "btn  btn-sm btn-primary"
                  }
                  onClick={() => {
                    setSectiondisplay(sectiondisplay - 1);
                  }}>
                  <BiArrowToLeft /> Previous
                </button>
                {sectiondisplay === 3 && (
                  <button
                    type="submit"
                    className="btn btn-sm btn-success"
                    disabled={state.loading}>
                    {state.loading ? (
                      <>
                        <Spinner as="span" animation="border" size="sm" />{" "}
                        <span>Posting...</span>
                      </>
                    ) : (
                      <span>Post Order</span>
                    )}
                  </button>
                )}

                <button
                  type="button"
                  className={
                    sectiondisplay === 3
                      ? "btn btn-sm btn-secondary disabled"
                      : "btn btn-sm btn-primary"
                  }
                  onClick={() => {
                    if (validate(values, sectiondisplay)) {
                      setSectiondisplay(sectiondisplay + 1);
                    }
                  }}>
                  Next <BiArrowToRight />
                </button>
              </div>
            </form>
          </Col>
        </Row>
      </div>
    </div>
  );
}

export default connect(null, { postproject, get_user })(Postprojects);
