import React, { useEffect, useState } from "react";
import { Col, Row, Stack, Table, Modal } from "react-bootstrap";
import { CKEditor } from "@ckeditor/ckeditor5-react";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import moment from "moment";
import {
  subjects,
  papertypes,
  levels,
  formats,
  projectspacing,
  availablelanguages,
} from "../../assets/projectassets";
import { Link } from "react-router-dom";
import getData from "../../customHooks/getData";
import warning from "../../images/warning.gif";
import { useSelector } from "react-redux";
import { IoWarningOutline } from "react-icons/io5";
import { Tooltip } from "react-tooltip";


function RenderPostProject({
  values,
  handleChange,
  handleBlur,
  errors,
  touched,
  setFieldValue,
  sectiondisplay,
  setFieldError,
  showError,
}) {
  const [privateorder, setPrivateorder] = useState(false);
  const [writers, setWriters] = useState([]);
  const [nowriters, setNowriters] = useState(false);
  const [nofunds, setNofunds] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const url = `${process.env.REACT_APP_API_URL}/writersadmin/get_writers`;
  const user = useSelector((state) => state.current_user.user);
  const country = useSelector((state) => state.userCountry.country)

  useEffect(() => {
    getData(url)
      .then((res) => {
        setWriters(res);
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);

  useEffect(() => {
    if (values.project_type === "Private" && writers.length < 1) {
      setNowriters(true);
      setFieldValue("project_type", "Public");
    } else if (values.project_type === "Private") {
      setPrivateorder(true);
    }
    if (values.project_type === "Public") {
      setFieldValue("trusted", false);
    }
  }, [values.project_type]);

  useEffect(() => {
    if (values.trusted === true) {
      setShowModal(true);
    } else {
      setShowModal(false);
    }
  }, [values.trusted]);

 
  useEffect(() => {
    if (values.papertype === "Presentation") {
      if (country === "Kenya") {
         setFieldValue("bidlow", values.numberofpages * 150);
      }
      else{
        setFieldValue("bidlow", values.numberofpages * 3);
       }
    } else {
      if (country === "Kenya") {
        setFieldValue("bidlow", values.numberofpages * 150);
      } else {
        setFieldValue("bidlow", values.numberofpages * 5);
      }
    }
  }, [values.numberofpages, values.papertype]);

  useEffect(() => {
    
    if (
      values.award_type === "Take" &&
      user.user_wallet.cash < values.bidhigh
    ) {
      console.log("Insufficient funds");
      setNofunds(true);
      setFieldError(
        "award_type",
        "Wallet Should have Cash Equal or Exceeding the Highest Bid Amount"
      );
    }
    if (values.award_type === "Bid") {
      setNofunds(false);
    }
    if (values.award_type === "Take") {
      setFieldValue("trusted", false);
    }
  }, [values.award_type]);

  useEffect(() => {
    if (showError) {
      console.log(errors);
    }
  }, [showError]);
  // eslint-disable-next-line react-hooks/exhaustive-deps

  switch (sectiondisplay) {
    case 1:
      return (
        <Col>
          <Row>
            <Col>
              <Stack gap={3}>
                <label
                  htmlFor="title"
                  style={{ textAlign: "center" }}
                  className="postlabel">
                  Title/Topic
                  <span style={{ color: "red" }}>&#42;</span>
                </label>
                <input
                  type="text"
                  id="title"
                  onChange={handleChange}
                  onBlur={handleBlur}
                  value={values.title}
                  className={showError && errors.title ? "input_error" : ""}
                />
                {showError && errors.title ? (
                  <p className="error">{errors.title}</p>
                ) : (
                  ""
                )}
              </Stack>
            </Col>
          </Row>
          <Row>
            <Col>
              <Stack gap={3}>
                <label
                  htmlFor="description"
                  className="postlabel"
                  style={{ textAlign: "center" }}>
                  Instructions
                  <span style={{ color: "red" }}>&#42;</span>
                </label>
                <CKEditor
                  id="description"
                  editor={ClassicEditor}
                  data={values.description}
                  onChange={(event, editor) => {
                    const data = editor.getData();
                    setFieldValue("description", data);
                  }}
                />
                {showError && errors.description ? (
                  <p className="error">{errors.description}</p>
                ) : (
                  ""
                )}
              </Stack>
            </Col>
          </Row>
          <Row>
            <Col>
              <Stack gap={3}>
                <label htmlFor="files" className="postlabel mt-3 single-line">
                  Upload Order Attachments (Optional) Accepted File Types:
                  jpeg, png, jpg, gif, pdf, doc, docx, ppt, pptx, xls, xlsx, zip
                </label>
                <input
                  type="file"
                  id="files"
                  name="files"
                  onChange={(event) => {
                    const files = [];
                    for (let i = 0; i < event.currentTarget.files.length; i++) {
                      files.push(event.currentTarget.files[i]);
                    }
                    setFieldValue("projectfiles", files);
                  }}
                  multiple
                />
                {showError && errors.projectfiles ? (
                  <p className="error">{errors.projectfiles}</p>
                ) : (
                  ""
                )}
              </Stack>
            </Col>
          </Row>
        </Col>
      );

    case 2:
      return (
        <Col>
          <Row>
            <Col sm={12} lg={6}>
              <Stack gap={3}>
                <label htmlFor="academiclevel" className="postlabel">
                  Academic Level
                  <span style={{ color: "red" }}>&#42;</span>
                </label>
                <select
                  value={values.academiclevel}
                  id="academiclevel"
                  name="academiclevel"
                  onChange={handleChange}
                  className={
                    showError && errors.academiclevel ? "input_error" : ""
                  }
                  placeholder="Academic Level">
                  <option value={""} selected disabled hidden>
                    Academic Level
                  </option>
                  {levels.map((level, index) => {
                    return <option key={index}>{level}</option>;
                  })}
                </select>
                {showError && errors.academiclevel ? (
                  <p className="error">{errors.academiclevel}</p>
                ) : (
                  ""
                )}
              </Stack>
            </Col>
            <Col sm={12} lg={6}>
              <Stack gap={3}>
                <label htmlFor="papertype" className="postlabel">
                  Paper Type
                  <span style={{ color: "red" }}>&#42;</span>
                </label>
                <select
                  value={values.papertype}
                  name="papertype"
                  id="papertype"
                  onChange={handleChange}
                  className={
                    showError && errors.papertype ? "input_error" : ""
                  }>
                  <option value={""} selected disabled hidden>
                    Paper Type (If Presentation CPP calculated As slides)
                  </option>
                  {papertypes.map((type, index) => {
                    return <option key={index}>{type}</option>;
                  })}
                </select>
                {showError && errors.papertype ? (
                  <p className="error">{errors.papertype}</p>
                ) : (
                  ""
                )}
              </Stack>
            </Col>
          </Row>
          <Row>
            <Col sm={12} lg={6}>
              <Stack gap={3}>
                <label htmlFor="subject" className="postlabel">
                  Subject/Field<span style={{ color: "red" }}>&#42;</span>
                </label>
                <input
                  list="subject-list"
                  id="subject"
                  name="subject"
                  value={values.subject}
                  onChange={handleChange}
                  className={
                    errors.subject && touched.subject ? "input_error" : ""
                  }
                />
                <datalist
                  id="subject-list"
                  className={showError && errors.subject ? "input_error" : ""}>
                  {subjects.map((mysubject, index) => {
                    return <option key={index}>{mysubject}</option>;
                  })}
                </datalist>
                {showError && errors.subject ? (
                  <p className="error">{errors.subject}</p>
                ) : (
                  ""
                )}
              </Stack>
            </Col>
            <Col sm={12} lg={6}>
              <Stack gap={3}>
                <label htmlFor="format" className="postlabel">
                  Format<span style={{ color: "red" }}>&#42;</span>
                </label>
                <select
                  value={values.format}
                  name="format"
                  id="format"
                  onChange={handleChange}
                  className={showError && errors.format ? "input_error" : ""}>
                  <option value={""} selected disabled hidden>
                    Format
                  </option>
                  {formats.map((myformat, index) => {
                    return <option key={index}>{myformat}</option>;
                  })}
                </select>
                {showError && errors.format ? (
                  <p className="error">{errors.format}</p>
                ) : (
                  ""
                )}
              </Stack>
            </Col>
          </Row>
          <Row>
            <Col lg={6} sm={12}>
              <Stack gap={3}>
                <label htmlFor="numberofpages" className="postlabel">
                  No of Pages/Slides (Min CPP Kshs 250/page Kshs 150/slide)
                  <span style={{ color: "red" }}>&#42;</span>
                </label>
                {values.numberofpages && values.papertype !== "Presentation" ? (
                  <span
                    style={{
                      marginBottom: "-15px",
                      marginTop: "-15px",
                      fontSize: "small",
                    }}>
                    {`Aprox ${values.numberofpages * 250} Words Double Space ${
                      values.numberofpages * 500
                    } Words Single Space`}
                  </span>
                ) : (
                  ""
                )}

                <input
                  type="number"
                  id="numberofpages"
                  value={values.numberofpages}
                  onChange={handleChange}
                  className={
                    errors.numberofpages && showError ? "input_error" : ""
                  }
                />
                {showError && errors.numberofpages ? (
                  <p className="error">{errors.numberofpages}</p>
                ) : (
                  ""
                )}
              </Stack>
            </Col>
            <Col lg={6} sm={12}>
              <Stack gap={3}>
                <label htmlFor="spacing" className="postlabel">
                  Spacing<span style={{ color: "red" }}>&#42;</span>
                </label>
                <select
                  value={values.spacing}
                  name="spacing"
                  id="spacing"
                  onChange={handleChange}
                  className={errors.spacing && showError ? "input_error" : ""}>
                  <option value={""} selected disabled>
                    Spacing
                  </option>
                  {projectspacing.map((myspacing, index) => {
                    return <option key={index}>{myspacing}</option>;
                  })}
                </select>
                {showError && errors.spacing ? (
                  <p className="error">{errors.spacing}</p>
                ) : (
                  ""
                )}
              </Stack>
            </Col>
          </Row>
          <Row>
            <Col lg={6} sm={12}>
              <Stack gap={3}>
                <label htmlFor="deadline" className="postlabel">
                  Order Deadline
                  <span style={{ color: "red" }}>&#42;</span>
                </label>
                <input
                  type="datetime-local"
                  id="deadline"
                  value={values.deadline}
                  // min={new Date().toISOString().split(".")[0]}
                  onChange={handleChange}
                  className={errors.deadline && showError ? "input_error" : ""}
                />
                {showError && errors.deadline ? (
                  <p className="error">{errors.deadline}</p>
                ) : (
                  ""
                )}
              </Stack>
            </Col>
            <Col lg={6} sm={12}>
              <Stack gap={3}>
                <label htmlFor="sourcecount" className="postlabel">
                  Sources/References Count
                  <span style={{ color: "red" }}>&#42;</span>
                </label>
                <input
                  type="number"
                  id="sourcecount"
                  value={values.sourcecount}
                  onChange={handleChange}
                  className={
                    errors.sourcecount && showError ? "input_error" : ""
                  }
                />
                {showError && errors.sourcecount ? (
                  <p className="error">{errors.sourcecount}</p>
                ) : (
                  ""
                )}
              </Stack>
            </Col>
          </Row>
          <Row>
            <Col lg={6} sm={12}>
              <Stack gap={3}>
                <label htmlFor="bidlow" className="postlabel">
                  Lowest Budget {country === "Kenya" ? <>(Kshs)</> : <>(USD)</>}
                  <span style={{ color: "red" }}>&#42;</span>
                </label>
                <input
                  type="number"
                  id="bidlow"
                  disabled={values.numberofpages}
                  value={values.bidlow}
                  onChange={handleChange}
                  className={errors.bidlow && showError ? "input_error" : ""}
                />
                {showError && errors.bidlow ? (
                  <p className="error">{errors.bidlow}</p>
                ) : (
                  ""
                )}
              </Stack>
            </Col>
            <Col lg={6} sm={12}>
              <Stack gap={3}>
                <label htmlFor="bidhigh" className="postlabel">
                  Highest Budget{" "}
                  {country === "Kenya" ? <>(Kshs)</> : <>(USD)</>}
                  <span style={{ color: "red" }}>&#42;</span>
                </label>
                <input
                  type="number"
                  id="bidhigh"
                  value={values.bidhigh}
                  onChange={handleChange}
                  className={errors.bidhigh && showError ? "input_error" : ""}
                />
                {showError && errors.bidhigh ? (
                  <p className="error">{errors.bidhigh}</p>
                ) : (
                  ""
                )}
              </Stack>
            </Col>
          </Row>
          <Row>
            <Col lg={6} sm={12}>
              <Stack gap={3}>
                <label htmlFor="essaylanguage" className="postlabel">
                  Language<span style={{ color: "red" }}>&#42;</span>
                </label>
                <select
                  value={values.essaylanguage}
                  id="essaylanguage"
                  name="essaylanguage"
                  onChange={handleChange}
                  className={
                    errors.essaylanguage && showError ? "input_error" : ""
                  }>
                  <option value={""} selected disabled hidden>
                    Language
                  </option>
                  {availablelanguages.map((language, index) => {
                    return <option key={index}>{language}</option>;
                  })}
                </select>
                {showError && errors.essaylanguage ? (
                  <p className="error">{errors.essaylanguage}</p>
                ) : (
                  ""
                )}
              </Stack>
            </Col>
            <Col lg={6} sm={12}>
              <Stack gap={3}>
                <label
                  htmlFor="project_type"
                  className="postlabel"
                  style={values.bidhigh ? { opacity: 1 } : { opacity: 0.5 }}>
                  Order Type (Public/Private) Default: Public
                </label>
                <select
                  disabled={!values.bidhigh}
                  value={values.project_type}
                  id="project_type"
                  name="project_type"
                  onChange={handleChange}
                  className={
                    errors.project_type && showError ? "input_error" : ""
                  }>
                  <option value={""} selected disabled hidden>
                    Type Of Order
                  </option>
                  <option value="Public">Public</option>
                  <option value="Private">Private</option>
                </select>
                {showError && errors.project_type ? (
                  <p className="error">{errors.project_type}</p>
                ) : (
                  ""
                )}
              </Stack>
              <Modal
                show={nowriters}
                onHide={() => {
                  setNowriters(false);
                }}
                centered
                backdrop="static"
                keyboard={false}>
                <Modal.Header closeButton>
                  <Modal.Title>
                    <img
                      src={warning}
                      alt="warning"
                      style={{ width: "30px", height: "30px" }}
                    />
                    No Writers
                  </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                  You do Not have Personal Writers. You can only post public
                  Projects To Add personal Writers click on:{" "}
                  <Link to="/employer/freelancers">Add Writers</Link>
                </Modal.Body>
              </Modal>
              <Modal
                show={privateorder}
                onHide={() => {
                  setPrivateorder(false);
                  if (nofunds) {
                    setFieldValue("award_type", "Bid");
                  }
                }}
                aria-labelledby="contained-modal-title-vcenter"
                centered
                backdrop="static"
                keyboard={false}>
                <Modal.Header closeButton>
                  <Modal.Title>Choose Order Award Method</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                  <Stack>
                    <span>
                      <label
                        style={{ fontWeight: "bold", marginTop: "5px" }}
                        className="postlabel">
                        Award Method
                      </label>
                    </span>
                    <Stack direction="row">
                      <Stack
                        direction="horizontal"
                        gap={2}
                        className="d-flex align-items-center">
                        <label htmlFor="bid_order" className="postlabel">
                          <b>Private Bid Project</b>
                        </label>
                        <input
                          type="radio"
                          onClick={() => {
                            setPrivateorder(false);
                          }}
                          onChange={handleChange}
                          id="bid_order"
                          value="Bid"
                          name="award_type"
                          className="radio"
                        />
                        {errors.award_type && touched.award_type ? (
                          <p className="error">{errors.award_type}</p>
                        ) : (
                          ""
                        )}
                      </Stack>
                      <Stack direction="horizontal" gap={2}>
                        <label htmlFor="take_order" className="postlabel">
                          <b>Private Take Project</b>
                        </label>
                        <input
                          type="radio"
                          onClick={() => {
                            setPrivateorder(false);
                          }}
                          onChange={handleChange}
                          id="take_order"
                          value="Take"
                          name="award_type"
                          className="radio"
                        />
                        {errors.award_type && touched.award_type ? (
                          <p className="error">{errors.award_type}</p>
                        ) : (
                          ""
                        )}
                      </Stack>
                    </Stack>
                  </Stack>
                </Modal.Body>
              </Modal>
              <Modal
                centered
                backdrop="static"
                keyboard={false}
                show={nofunds}
                onHide={() => {
                  setFieldValue("award_type", "Bid");
                  setNofunds(false);
                }}>
                <Modal.Header closeButton>
                  <Modal.Title>
                    <IoWarningOutline size={20} />
                    Insufficient Funds in Wallet
                  </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                  To Post A Private Take Project you should have Funds in your
                  wallet Equal or Exceeding your Highest Bid Amount. <br />
                  <b>Kshs {values.bidhigh}.00</b>
                  <br />
                  Click here to go to wallet and deposit Funds{" "}
                  <Link to="/employer/transactions">Wallet</Link> <br />
                  Alternatively if you choose to continue to post order it will
                  be transformed to a <b>Private Bid Project</b>
                </Modal.Body>
              </Modal>
            </Col>
          </Row>
          <Row>
            <Col lg={6} sm={12}>
              <Stack gap={2}>
                <h6 style={{ color: "#006a78" }}>
                  Paylater Available to Private Bid Projects & Trusted Employers
                </h6>
                <Stack direction="horizontal" gap={3}>
                  <label
                    htmlFor="paylater"
                    className="postlabel"
                    style={
                      user.employer_profile.trusted &&
                      values.project_type === "Private" &&
                      values.award_type === "Bid"
                        ? { opacity: 1 }
                        : { opacity: 0.5 }
                    }>
                    <b>Pay Later</b>
                  </label>
                  <input
                    data-tooltip-id="trustedemployer"
                    disabled={
                      !user.employer_profile.trusted ||
                      values.project_type === "Public" ||
                      values.award_type === "Take"
                    }
                    type="checkbox"
                    className="radio"
                    id="paylater"
                    name="trusted"
                    value={true}
                    onChange={handleChange}
                  />
                </Stack>

                {showError && errors.trusted ? (
                  <p className="error">{errors.trusted}</p>
                ) : (
                  ""
                )}
              </Stack>
              {!user.employer_profile.trusted && (
                <Tooltip
                  content="Complete 10 Projects and get the Trusted Employer Badge to unlock this feature."
                  id="trustedemployer"
                  variant="success"
                />
              )}
            </Col>
            <Col lg={6} sm={12}>
              <label
                style={
                  values.trusted
                    ? { opacity: 1, color: "#006a78" }
                    : { opacity: 0.5, color: "#006a78" }
                }
                htmlFor="payment_date"
                className="form-label">
                <b>Payment Date</b>
              </label>
              <input
                disabled={!values.trusted}
                type="date"
                className="form-control"
                id="date"
                name="payment_date"
                onChange={handleChange}
              />
              {errors.payment_date ? (
                <p className="error">{errors.payment_date}</p>
              ) : (
                ""
              )}
            </Col>
          </Row>
        </Col>
      );
    case 3:
      return (
        <Col>
          <Row>
            <Col>
              <Table>
                <thead>
                  <tr>
                    <th colSpan={4}>
                      <h5 style={{ textAlign: "center" }}>Order Summary</h5>
                    </th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td style={{ color: "#006a78" }}>
                      <b>Project Title</b>
                    </td>
                    <td colSpan={3}>{values.title}</td>
                  </tr>
                  <tr>
                    <td style={{ color: "#006a78" }}>
                      <b>Academic Level</b>
                    </td>
                    <td>{values.academiclevel}</td>
                    <td style={{ color: "#006a78" }}>
                      <b>Paper Type</b>
                    </td>
                    <td>{values.papertype}</td>
                  </tr>

                  <tr>
                    <td style={{ color: "#006a78" }}>
                      <b>Subject</b>
                    </td>
                    <td>{values.subject}</td>
                    <td style={{ color: "#006a78" }}>
                      <b>Format</b>
                    </td>
                    <td>{values.format}</td>
                  </tr>

                  <tr>
                    <td style={{ color: "#006a78" }}>
                      <b>Number of Pages</b>
                    </td>
                    <td>{values.numberofpages}</td>
                    <td style={{ color: "#006a78" }}>
                      <b>Spacing</b>
                    </td>
                    <td>{values.spacing}</td>
                  </tr>
                  <tr>
                    <td style={{ color: "#006a78" }}>
                      <b>Deadline</b>
                    </td>
                    <td>
                      {moment(values.deadline).format("MMM Do YYYY HH:MM A")}
                    </td>
                    <td style={{ color: "#006a78" }}>
                      <b>Language</b>
                    </td>
                    <td>{values.essaylanguage}</td>
                  </tr>
                  <tr>
                    <td style={{ color: "#006a78" }}>
                      <b>Project Type</b>
                    </td>
                    <td>
                      {values.project_type === "Private" ? (
                        <>
                          {values.project_type}/{values.award_type}
                        </>
                      ) : (
                        <>{values.project_type}</>
                      )}
                    </td>
                    <td style={{ color: "#006a78" }}>
                      <b>Sources/Reference Count</b>
                    </td>
                    <td>{values.sourcecount}</td>
                  </tr>
                  <tr>
                    <td style={{ color: "#006a78" }}>
                      <b>Lowest Budget</b>
                    </td>
                    <td>Kshs {values.bidlow}</td>
                    <td style={{ color: "#006a78" }}>
                      <b>Highest Budget</b>
                    </td>
                    <td>Kshs {values.bidhigh}</td>
                  </tr>
                </tbody>
              </Table>
            </Col>
          </Row>
        </Col>
      );

    default:
      break;
  }
}

export default RenderPostProject;
