import { useFormik } from "formik";
import React, { useEffect, useState } from "react";
import {
  Col,
  Stack,
  Row,
  Image,
  Spinner,
  Card,
  ProgressBar,
} from "react-bootstrap";
import { connect } from "react-redux";
import { get_user } from "../../actions/myuser";
import { HiOutlineUserCircle } from "react-icons/hi";
import * as yup from "yup";
import {
  PROFILE_IMAGE_SUPPORTED_FORMATS,
  PROFILE_IMAGE_FILE_SIZE,
} from "../../assets/projectassets";
import axios from "axios";
import { Link } from "react-router-dom";
import parse from "html-react-parser";
import level from "../../images/level-up.png";
import rating from "../../images/rating.png";
import document from "../../images/document.png";
import disputed from "../../images/disputed.png";
import review from "../../images/review.png";
import Busy from "../global/Busy";
import moment from "moment";
import {
  FacebookShareButton,
  TwitterShareButton,
  WhatsappShareButton,
  FacebookIcon,
  XIcon,
  WhatsappIcon,
} from "react-share";

function WriterProfile({ user, get_user }) {
  console.log(user);
  const [subject,setSubject] = useState([])
  const [loading, setLoading] = useState(true);
  const [state, setState] = useState({
    imageloading: false,
    filesloading: false,
  });
  const [image, setImage] = useState(null);
  useEffect(() => {
    get_user().then(() => {
      setLoading(false);
    });
  }, []);

  const profileValidation = yup.object().shape({
    profile_pic: yup
      .mixed()
      .required("You need to select a Profile Image")
      .test(
        "fileSize",
        "File Size is too large Max size 2MB",
        (value) => value && value.size <= PROFILE_IMAGE_FILE_SIZE
      )
      .test(
        "fileType",
        "Unsupported File Format",
        (value) => value && PROFILE_IMAGE_SUPPORTED_FORMATS.includes(value.type)
      ),
  });
  const imageurl = `${process.env.REACT_APP_API_URL}/accounts/update_user`;
  const uploadProfileImage = async (image) => {
    const config = {
      headers: {
        Authorization: `JWT ${localStorage.getItem("access")}`,
      },
    };
    try {
      const res = await axios.patch(imageurl, image, config);
      return res.data;
    } catch (err) {
      console.log(err);
    }
  };

  function onSubmit(values, actions) {
    console.log(values.usersubjects);
    for (let value in values) {
      if (value === "profile_pic") {
        setState({ ...state, imageloading: true });
        let imageData = new FormData();
        imageData.append(value, values[value]);
        uploadProfileImage(imageData).then(() => {
          get_user().then(() => {
            setState({ ...state, imageloading: false });
            actions.resetForm();
          });
        });
      } else {
      }
    }
  }

  const { errors, values, actions, handleSubmit, handleChange, setFieldValue } =
    useFormik({
      initialValues: {
        profile_pic: "",
      },
      validationSchema: profileValidation,
      onSubmit,
    });
  
  console.log(user)

  return (
    <Col className="mainarea">
      {loading ? (
        <Busy />
      ) : (
        <div>
          <div className=" pagetitle mt-2">
            {user ? <h1 className="title">{user.role}</h1> : ""}
          </div>
          <div className="contents">
            <Row>
              <Col lg={5} sm={12} md={5} className="mb-2 ">
                <Card
                  style={{ height: "95vh", overflowX: "none" }}
                  className="p-3 mt-1 mb-1 smallprofile">
                  <Stack
                    direction="horizontal"
                    gap={2}
                    className="d-flex justify-content-center align-items-center">
                    Share My Profile:
                    <WhatsappShareButton
                      title={`Writer Profile \nID${user.id} ${user.first_name} ${user.last_name}
                  \nExpertise: ${user.writer_profile.level} 
                  \nRating: ${user.writer_profile.rating} Rating Count: ${user.writer_profile.ratingcount}
                  \nCompleted: ${user.writer_profile.completedcount}\n`}
                      url="https://essayshost.com">
                      <WhatsappIcon size={20} round={true} />
                    </WhatsappShareButton>
                    <TwitterShareButton
                      title={`Writer Profile  \nID${user.id} ${user.first_name} ${user.last_name}
                  \nExpertise: ${user.writer_profile.level} 
                  \nRating: ${user.writer_profile.rating} Rating Count: ${user.writer_profile.ratingcount}
                  \nCompleted: ${user.writer_profile.completedcount}\n`}
                      url="https://essayshost.com">
                      <XIcon size={20} round={true} />
                    </TwitterShareButton>
                    <FacebookShareButton
                      hashtag={`Writer Profile \nID${user.id} ${user.first_name} ${user.last_name}
                  \nExpertise: ${user.writer_profile.level} 
                  \nRating: ${user.writer_profile.rating} Rating Count: ${user.writer_profile.ratingcount}
                  \nCompleted: ${user.writer_profile.completedcount}\n`}
                      url="https://essayshost.com">
                      <FacebookIcon size={20} round={true} />
                    </FacebookShareButton>
                  </Stack>
                  <Stack
                    gap={2}
                    className="d-flex justify-content-center align-items-center">
                    <h5 className="text-center mt-3">
                      {user.first_name} {user.last_name}
                    </h5>
                    {user.profile_pic && !image ? (
                      <Image
                        src={process.env.REACT_APP_API_URL + user.profile_pic}
                        className="profile_image"
                      />
                    ) : !image ? (
                      <HiOutlineUserCircle className="profile_image_placeholder" />
                    ) : (
                      image && <Image src={image} className="profile_image" />
                    )}
                    <form
                      onSubmit={handleSubmit}
                      encType="multipart/formdata"
                      value={values.profile_pic}>
                      <Stack gap={2}>
                        <label
                          htmlFor="profile_pic"
                          style={{
                            cursor: "pointer",
                            textAlign: "center",
                            fontWeight: "bold",
                            marginTop: "1vh",
                            marginBottom: "1vh",
                            color: "green",
                          }}>
                          Select Image
                        </label>
                        <input
                          type="file"
                          id="profile_pic"
                          name="profile_pic"
                          onChange={(event) => {
                            if (event.currentTarget.files[0]) {
                              const image = event.currentTarget.files[0];
                              setFieldValue("profile_pic", image);
                              setImage(URL.createObjectURL(image));
                            } else {
                              setFieldValue("profile_pic", null);
                            }
                          }}
                          on
                        />
                        {errors.profile_pic ? (
                          <p className="error">{errors.profile_pic}</p>
                        ) : (
                          ""
                        )}
                        <button
                          type="submit"
                          className="btn btn-outline-success btn-sm"
                          disabled={state.imageloading}>
                          {state.imageloading ? (
                            <>
                              <Spinner as="span" animation="border" size="sm" />{" "}
                              <span>Saving...</span>
                            </>
                          ) : (
                            <span>Save</span>
                          )}
                        </button>
                      </Stack>
                    </form>
                    <h6>{user.email}</h6>
                    <h6>Phone No: 0{user.phone_no}</h6>
                    <h6>
                      Joined: {moment(user.joined).format("Do MMM YYYY")}{" "}
                    </h6>

                    <div>
                      <Stack
                        gap={1}
                        className="d-flex justify-content-center align-items-center">
                        {user.writer_profile.level === "Novice" ? (
                          <>
                            <h6 style={{ color: "green" }}>
                              Progress to Intermediate Level
                            </h6>
                            <p>
                              Complete {10 - user.writer_profile.completedcount}{" "}
                              Projects
                            </p>
                            <ProgressBar
                              variant="success"
                              className="w-100"
                              now={user.writer_profile.completedcount * 10}
                            />
                          </>
                        ) : user.writer_profile.level === "Intermediate" ? (
                          <>
                            <h6 style={{ color: "green" }}>
                              Progress to Expert Level
                            </h6>
                            <p>
                              Complete {50 - user.writer_profile.completedcount}{" "}
                              Orders
                            </p>
                            <ProgressBar
                              variant="success"
                              className="w-100"
                              now={user.writer_profile.completedcount * 2}
                            />
                          </>
                        ) : (
                          <>
                            <h6 style={{ color: "green" }}>
                              Progress to Master Level
                            </h6>
                            <p>
                              Complete{" "}
                              {100 - user.writer_profile.completedcount} Orders
                            </p>
                            <ProgressBar
                              variant="success"
                              className="w-100"
                              now={user.writer_profile.completedcount}
                            />
                          </>
                        )}
                      </Stack>
                    </div>
                    <div className="d-flex justify-content-around">
                      <Link
                        to={"/writer/updatephoneno"}
                        className="btn btn-outline-success btn-sm ">
                        Update Phone No
                      </Link>
                      <Link
                        to="/writer/updateprofile"
                        className="btn btn-outline-success btn-sm">
                        Update My Profile
                      </Link>
                    </div>
                  </Stack>
                </Card>
              </Col>
              <Col lg={7} sm={12} md={7} style={{ height: "inherit" }}>
                <Row>
                  <Col lg={6} sm={12} md={6}>
                    <div className="profilecard">
                      <Stack gap={2}>
                        <h5 className="text-center">Level</h5>

                        <h6>
                          <img
                            src={level}
                            alt="rating"
                            className="profilecardimage"
                          />
                          {user.writer_profile.level}
                        </h6>
                      </Stack>
                    </div>
                  </Col>
                  <Col lg={6} sm={12} md={6}>
                    <div className="profilecard ">
                      <Stack gap={2}>
                        <h5 className="text-center">Rating</h5>
                        <h6>
                          <img
                            src={rating}
                            alt="rating"
                            className="profilecardimage"
                          />
                          {user.writer_profile.rating}
                        </h6>
                      </Stack>
                    </div>
                  </Col>
                </Row>
                <Row className="p-0">
                  <Col lg={6} sm={12} md={6}>
                    <div className="profilecard">
                      <Stack gap={2}>
                        <h5 className="text-center">Completed</h5>
                        <h6>
                          <img
                            src={document}
                            alt="completed"
                            className="profilecardimage"
                          />
                          {user.writer_profile.completedcount}
                        </h6>
                      </Stack>
                    </div>
                  </Col>
                  <Col lg={6} sm={12} md={6}>
                    <div className="profilecard ">
                      <Stack gap={2}>
                        <h5 className="text-center">Disputed</h5>
                        <h6>
                          <img
                            src={disputed}
                            alt="disputed"
                            className="profilecardimage"
                          />
                          {user.writer_profile.disputecount}
                        </h6>
                      </Stack>
                    </div>
                  </Col>
                </Row>
                <Row className="p-0">
                  <Col lg={6} sm={12} md={6}>
                    <div className="profilecard">
                      <Stack gap={2}>
                        <h5 className="text-center">Revised</h5>
                        <h6>
                          <img
                            src={review}
                            alt="review"
                            className="profilecardimage"
                          />
                          {user.writer_profile.revisioncount}
                        </h6>
                      </Stack>
                    </div>
                  </Col>
                  <Col lg={6} sm={12} md={6}>
                    <div className="profilecard">
                      <div>
                        <h5 className="text-center">Academic Fields</h5>
                        {user.writer_profile.subjects !== "None" ? (
                          <ol>
                            {JSON.parse(
                              user.writer_profile.subjects.replace(/'/g, '"')
                            ).map((subjectString, index) => {
                              return (
                                <li key={index} style={{ fontSize: "small" }}>
                                  {subjectString.subject}
                                </li>
                              );
                            })}
                          </ol>
                        ) : (
                          <p>You have not Updated your Fields of Expertise</p>
                        )}
                      </div>
                    </div>
                  </Col>
                </Row>
              </Col>
            </Row>
            <Row>
              <Col lg={12} sm={12} md={12} className="mb-3">
                <Card className="smallprofile">
                  <Stack className="d-flex justify-content-center p-4">
                    <h5 className="text-center">My Bio</h5>

                    {user.writer_profile.about !== "None" ? (
                      <p>{parse(user.writer_profile.about)}</p>
                    ) : (
                      <p className="text-center">
                        You have not updated your Bio
                      </p>
                    )}
                  </Stack>
                </Card>
              </Col>
            </Row>
          </div>
        </div>
      )}
    </Col>
  );
}

const mapStateToProps = (profile) => ({ user: profile.current_user.user });

export default connect(mapStateToProps, { get_user })(WriterProfile);
