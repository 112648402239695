import React, { useState, useEffect } from "react";
import {
  Accordion,
  Col,
  Modal,
  Row,
  Spinner,
  ToastContainer,
  Toast,
} from "react-bootstrap";
import { HiOutlineUserCircle } from "react-icons/hi";
import { useNavigate } from "react-router-dom";
import Busy from "../global/Busy";
import { BiSolidStar } from "react-icons/bi";
import Empty from "../global/Empty";
import getData from "../../customHooks/getData";
import moment from "moment";
import { connect } from "react-redux";
import axios from "axios";
import { Link } from "react-router-dom";
import ReconnectingWebSocket from "reconnecting-websocket";
import { useSelector } from "react-redux";

function MyWriters({ user }) {
  const [state, setState] = useState({
    currentPage: 1,
    postPerPage: 10,
    freelancers: [],

    loading: true,
  });

  const [myprojects, setMyProjects] = useState([]);
  const [totalUnpaid, setTotalUnpaid] = useState(0);
  const [writerId, setWriterId] = useState(0);
  const [writerProjects, setWriterProjects] = useState([]);
  const [freelancerCreds, setFreelancerCreds] = useState("");
  const [writerTotalUnpaid, setWriterTotalUnpaid] = useState(0);
  const [inProgressUnpaid, setInProgressUnpaid] = useState(0);
  const [completedUnpaid, setCompletedUnpaid] = useState(0);
  const [loading, setLoading] = useState(true);
  const [payModal, setPayModal] = useState(false);
  const [writerCompleteUnpaid, setWriterCompleteUnpaid] = useState(0);
  const [insufficientFundsModal, setInsufficientFundsModal] = useState(false);
  const [notification, setNotification] = useState("");
  const [completedProjects, setCompletedProjects] = useState([]);
  const [accordionLoad, setAccordionLoad] = useState(false);
  const [paydata, setPaydata] = useState(null);
  const [paid, setPaid] = useState(false)

  useEffect(() => {
    if (user.id) {
      const paydata = new ReconnectingWebSocket(
        `${process.env.REACT_APP_WEBSOCKET_URL}/ws/make_payment/${user?.id}`
      );
      paydata.addEventListener("open", () => {
        console.log("pay socket open");
      });
      paydata.addEventListener("close", () => {
        console.log("pay socket closed");
      });
      setPaydata(paydata);
    }
  }, []);

  const windowSize = useSelector(state => state.windowSize.innerWidth)
  console.log(windowSize)

  useEffect(() => {
    if (paydata) {
      paydata.addEventListener("message", (event) => {
        const data = JSON.parse(event.data);
        console.log(data)
        if (data.status === "payment_done") {
          getData(url)
            .then((res) => {
              setState({
                ...state,
                freelancers: res,
                loading: false,
              });
              getWriterDetails(writerId);
            })
            .catch((err) => {
              console.log(err);
              setState({
                ...state,
                loading: false,
              });
            });
        }
      });
    }
  }, [paydata]);

  const handlePay = async () => {
    setLoading(true);
    const body = {
      writer_id: writerId,
      amount: writerCompleteUnpaid,
    };
   ;
    const config = {
      headers: {
        Authorization: `JWT ${localStorage.getItem("access")}`,
        Accept: "application/json",
      },
    };
    try {
      const res = await axios.post(
        `${process.env.REACT_APP_API_URL}/accounts/pay_writer_trusted_projects`,
        body,
        config
      );
       getData(url)
         .then((res) => {
           setState({
             ...state,
             freelancers: res,
             loading: false,
           });
           getWriterDetails(writerId);
         })
         .catch((err) => {
           console.log(err);
           setState({
             ...state,
             loading: false,
           });
         });
      setNotification(res.data.message);
      setPayModal(false);
      setPaid(true)  
      setLoading(false);
      return res;
    } catch (error) {
      console.log(error);
      setLoading(false);
    }
  };

  let [currentFreelancer, setCurrentFreelancer] = useState([]);
  let pageNumber = [];

  const url = `${process.env.REACT_APP_API_URL}/writersadmin/get_writers`;
  const navigate = useNavigate();


  useEffect(() => {
    getData(url)
      .then((res) => {
        setState({
          ...state,
          freelancers: res,
          loading: false,
        });
      })
      .catch((err) => {
        console.log(err);
        setState({
          ...state,
          loading: false,
        });
      });
  }, []);

  useEffect(() => {
    getData(
      `${process.env.REACT_APP_API_URL}/accounts/employer_trusted_assigned`
    )
      .then((res) => {
        setMyProjects(res);
        const totalUnpaid = res.reduce((total, project) => {
          return total + project.amount;
        }, 0);
        setTotalUnpaid(totalUnpaid);
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);

  console.log(myprojects);
  useEffect(() => {
    if (!state.loading) {
      let indexOfLastFreelancer = state.currentPage * state.postPerPage;
      let indexOfFirstFreelancer = indexOfLastFreelancer - state.postPerPage;
      currentFreelancer = state.freelancers.slice(
        indexOfFirstFreelancer,
        indexOfLastFreelancer
      );

      setCurrentFreelancer(currentFreelancer);
    }
  }, [state.currentPage, state.loading]);

  for (
    let i = 1;
    i <= Math.ceil(state.freelancers.length / state.postPerPage);
    i++
  ) {
    pageNumber.push(i);
  }

  useEffect(() => {
    if (freelancerCreds !== undefined) {
      const freelancers = state.freelancers.filter((freelancer) => {
        return (
          freelancer.first_name
            .toLowerCase()
            .includes(freelancerCreds.toLowerCase()) ||
          freelancer.last_name
            .toLowerCase()
            .includes(freelancerCreds.toLowerCase())
        );
      });
      setCurrentFreelancer(freelancers);
    }
  }, [freelancerCreds]);

  const setPage = (num) => {
    setState({ ...state, currentPage: num });
  };

  const checkCompletedTotal = async (writerid) => {
    const writerProjects = myprojects.filter((project) => {
      return project.writer.id === writerId;
    });
    const completedProjects = writerProjects.filter((project) => {
      return (
        // project.completed == true
        project.completed === true && new Date(project.paymentdate) < new Date()
      );
    });

    console.log(completedProjects);
    const writertotalUnpaid = completedProjects.reduce((total, project) => {
      return total + project.amount;
    }, 0);
    setPayModal(true);
    setCompletedProjects(completedProjects);
    setWriterCompleteUnpaid(writertotalUnpaid);
  };

  const getWriterDetails = async (writerId) => {
    setLoading(true);
    setWriterId(writerId);
    const writerProjects = myprojects.filter((project) => {
      return project.writer.id === writerId;
    });
    const completedProjects = writerProjects.filter((project) => {
      return project.completed === true;
    });

    const inProgress = writerProjects.filter((project) => {
      return project.completed === false;
    });
    const inProgressUnpaid = inProgress.reduce((total, project) => {
      return total + project.amount;
    }, 0);
    const completedUnpaid = completedProjects.reduce((total, project) => {
      return total + project.amount;
    }, 0);
    const writertotalUnpaid = writerProjects.reduce((total, project) => {
      return total + project.amount;
    }, 0);
    setWriterProjects(writerProjects);
    setWriterTotalUnpaid(writertotalUnpaid);
    setInProgressUnpaid(inProgressUnpaid);
    setCompletedUnpaid(completedUnpaid);
    setLoading(false);
    setAccordionLoad(false);
  };
  console.log(writerId);
  console.log(writerProjects);

  return (
    <Col className="mainarea">
      {state.loading ? (
        <Busy />
      ) : state.freelancers.length > 0 ? (
        <div>
          <ToastContainer
            position="top-end"
            className="p-3"
            style={{ zIndex: 99999 }}>
            <Toast
              delay={3000}
              autohide
              className={
                notification === "Payment done successfully"
                  ? "bg-success text-white"
                  : "bg-danger text-white"
              }
              show={notification}
              onClose={() => {
                setNotification("");
              }}>
              <Toast.Body>{notification}</Toast.Body>
            </Toast>
          </ToastContainer>
          <Row
            className="mt-1 mb-1 pt-2"
            style={{ backgroundColor: "#b8e4bf" }}>
            <Col
              lg={4}
              sm={12}
              className="d-flex justify-content-center align-items-center">
              <input
                type="text"
                placeholder="Search by name"
                className="searchbar"
                onChange={(e) => {
                  setFreelancerCreds(e.target.value);
                }}
              />
            </Col>
            <Col
              lg={4}
              sm={12}
              className="d-flex justify-content-center align-items-center">
              <h6>Unpaid Orders: {myprojects.length} </h6>
            </Col>
            <Col
              lg={4}
              sm={12}
              className="d-flex justify-content-center align-items-center">
              <h6 style={{ color: "#093b03", verticalAlign: "-0.5em" }}>
                Unpaid Amount: Kshs {totalUnpaid}
              </h6>
            </Col>
          </Row>
          <Accordion>
            <Accordion.Item>
              <Accordion.Header className="accordionheader">
                <table>
                  <thead>
                    <tr>
                      <th width={10}>ID</th>
                      <th width={35}>WRITER</th>
                      <th width={25}>RATING</th>
                      <th width={15}>LEVEL</th>
                      <th width={10}>DONE</th>
                    </tr>
                  </thead>
                </table>
              </Accordion.Header>
            </Accordion.Item>

            {currentFreelancer.map((freelancer) => {
              return (
                freelancer.writer_profile !== null && (
                  <Accordion.Item
                    key={freelancer.id}
                    eventKey={freelancer.id}
                    onClick={() => {
                      setAccordionLoad(true);
                      getWriterDetails(freelancer.id);
                    }}>
                    <Accordion.Header>
                      <table>
                        <tbody>
                          <tr>
                            <td width="10%">
                              <b style={{ color: "green" }}>#{freelancer.id}</b>
                            </td>
                            <td width="35%">
                              {freelancer.profile_pic !== null ? (
                                <img
                                  src={
                                   
                                    freelancer.profile_pic
                                  }
                                  className="project_image"
                                  alt="freelancer"
                                />
                              ) : (
                                <HiOutlineUserCircle className="project_no_image" />
                              )}{" "}
                              {freelancer.first_name} {freelancer.last_name}
                            </td>
                            {freelancer.writer_profile !== null ? (
                              <>
                                <td width="25%">
                                  <span>
                                    <b className="boldrating">
                                      {freelancer.writer_profile.rating}
                                      <BiSolidStar
                                        style={{
                                          color: "gold",
                                          verticalAlign: "-3px",
                                        }}
                                        className="ratingstar"
                                      />{" "}
                                    </b>
                                  </span>{" "}
                                  ({freelancer.writer_profile.ratingcount}{" "}
                                  Reviews)
                                </td>
                                <td width="15%">
                                  {freelancer.writer_profile.level}
                                </td>
                                <td width="10%">
                                  {freelancer.writer_profile.completedcount}
                                </td>
                              </>
                            ) : (
                              <>
                                <td width="10%">0/0</td>
                                <td width="15%">Junior</td>
                                <td width="15%">0</td>
                                <td width="15%">0</td>
                              </>
                            )}
                          </tr>
                        </tbody>
                      </table>
                    </Accordion.Header>
                    <Accordion.Body>
                      {accordionLoad ? (
                        <div className="d-flex justify-content-center align-items-center">
                          <Spinner as="span" variant="success" size="sm" />
                        </div>
                      ) : (
                        <>
                          {writerProjects.length > 0 ? (
                            <div>
                              <h5 className="text-center">
                                {writerProjects.length} Trusted Projects
                              </h5>
                              <hr />
                              <table>
                                <thead>
                                  <tr className="border-bottom">
                                    <th width="10%">ID</th>
                                    <th width="15%">Subject</th>
                                    <th width="25%">Payment Date</th>
                                    <th width="25%">Status</th>
                                    <th width="25%">Amount</th>
                                  </tr>
                                </thead>
                                <tbody>
                                  {writerProjects.map((project) => {
                                    return (
                                      <tr>
                                        <td style={{ color: "green" }}>
                                          <b>#{project.id}</b>
                                        </td>
                                        <td>{project.subject}</td>
                                        <td>
                                          {new Date(project.paymentdate) >
                                          new Date() ? (
                                            moment(project.paymentdate).format(
                                              "MMM Do YYYY"
                                            )
                                          ) : (
                                            <b style={{ color: "green" }}>
                                              Due:{" "}
                                              {moment(
                                                project.paymentdate
                                              ).format("MMM Do YY")}
                                            </b>
                                          )}
                                        </td>
                                        <td>
                                          {project.completed ? (
                                            <b style={{ color: "green" }}>
                                              Completed
                                            </b>
                                          ) : (
                                            "In Progress"
                                          )}
                                        </td>
                                        <td>Kshs {project.amount}</td>
                                      </tr>
                                    );
                                  })}
                                  <tr
                                    className="border-top"
                                    style={{ color: "#006a78" }}>
                                    <td
                                      colSpan="3"
                                      className="text-center"></td>
                                    <td>Total In Progress</td>
                                    <td>
                                      <b>Kshs {inProgressUnpaid}</b>
                                    </td>
                                  </tr>
                                  <tr style={{ color: "green" }}>
                                    <td
                                      colSpan="3"
                                      className="text-center"></td>
                                    <td>Total Completed</td>
                                    <td>
                                      <b>Kshs {completedUnpaid}</b>
                                    </td>
                                  </tr>
                                  <tr style={{ color: "blue" }}>
                                    <td
                                      colSpan="3"
                                      className="text-center"></td>
                                    <td>Total Unpaid</td>
                                    <td>
                                      <b>Kshs {totalUnpaid}</b>
                                    </td>
                                  </tr>
                                </tbody>
                              </table>
                              <div className="d-flex justify-content-center">
                                <button
                                  className="btn btn-success btn-sm"
                                  onClick={() => {
                                    checkCompletedTotal(writerId);
                                  }}>
                                  Make Payment
                                </button>
                              </div>
                            </div>
                          ) : (
                            <p>No Unpaid Orders</p>
                          )}
                        </>
                      )}
                      <hr />
                      <div>
                        <button
                          className="btn btn-outline-info btn-sm"
                          onClick={() => {
                            localStorage.setItem(
                              "writer",
                              JSON.stringify(freelancer)
                            );
                            navigate("/employer/writer-info");
                          }}>
                          Writers Bio
                        </button>
                      </div>
                    </Accordion.Body>
                  </Accordion.Item>
                )
              );
            })}
          </Accordion>
          <div className="paginationcontainer d-flex justify-content-center">
            {pageNumber.map((num) => {
              return (
                <span
                  onClick={() => {
                    setPage(num);
                  }}
                  className={
                    num === state.currentPage
                      ? "text-light bg-success paginationitem"
                      : "text-dark bg-light paginationitem"
                  }>
                  {num}
                </span>
              );
            })}
          </div>
          <Modal show={payModal} onHide={() => setPayModal(false)} centered>
            <Modal.Header closeButton>
              <Modal.Title>Pay Trusted Projects</Modal.Title>
            </Modal.Header>
            <Modal.Body>
              {writerCompleteUnpaid ? (
                <>
                  <p>
                    Make payment of Kshs {writerCompleteUnpaid} to Completed Due
                    Projects:
                  </p>
                  <table>
                    <thead>
                      <tr>
                        <th>Id</th>
                        <th>Amount</th>
                        <th>Due Date</th>
                      </tr>
                    </thead>
                    <tbody>
                      {completedProjects.map((project) => {
                        return (
                          <tr>
                            <td style={{ color: "green" }}>#{project.id}</td>
                            <td>Kshs {project.amount}</td>
                            <td>
                              {moment(project.paymentdate).format("MMM Do YY")}
                            </td>
                          </tr>
                        );
                      })}
                    </tbody>
                  </table>
                </>
              ) : (
                <ul>
                  <li>No completed projects</li>
                  <li>No Projects are Past their due Payment Date</li>
                </ul>
              )}
            </Modal.Body>
            {writerCompleteUnpaid > 0 && (
              <Modal.Footer className="d-flex justify-content-center">
                <button
                  disabled={loading || paid}
                  className="btn btn-outline-success btn-sm"
                  onClick={() => {
                    if (writerCompleteUnpaid > user.user_wallet.cash) {
                      setPayModal(false);
                      setInsufficientFundsModal(true);
                    } else {
                      handlePay();
                    }
                  }}>
                  {loading ? "Processing..." : "Pay Now"}
                </button>
              </Modal.Footer>
            )}
          </Modal>
          <Modal
            show={insufficientFundsModal}
            onHide={() => setInsufficientFundsModal(false)}
            centered>
            <Modal.Header closeButton>
              <Modal.Title>Insufficient Funds</Modal.Title>
            </Modal.Header>
            <Modal.Body>
              You need to add at least{" "}
              <b style={{ color: "green" }}>
                Kshs {writerCompleteUnpaid - user.user_wallet.cash}
              </b>{" "}
              to your wallet to make this payment.
              <br />
              Please add funds from the wallet page and try again. <br />
              On Wallet page &rarr; Wallet Balance click Deposit to add funds
              <br />
              Click here to go to:{" "}
              <Link to="/employer/transactions">Wallet</Link>
            </Modal.Body>
          </Modal>
        </div>
      ) : (
        <Empty message={"You Do not have Personal Writers"} />
      )}
    </Col>
  );
}

const mapStateToProps = (state) => {
  return {
    user: state.current_user.user,
  };
};

export default connect(mapStateToProps)(MyWriters);
