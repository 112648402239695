import React from "react";
import { Col, Stack, Tabs, Tab } from "react-bootstrap";
import EmployerTransactions from "./EmployerTransactions"
import Finance from "./Finance"


function Wallet() {

  return (
    <Col className="mainarea">
      <Stack gap={1}>
        <div className="pagetitle">
          <h1 className="title">Wallet</h1>
        </div>
        <div>
          <Tabs
            variant="pills"
            pill bg="success"
            defaultActiveKey="finance"
            justify
            className="tabs"

          >
            <Tab eventKey="finance" title="Wallets">
              <Finance />
            </Tab>
            <Tab eventKey="transaction" title="Transactions" >
              <EmployerTransactions />
            </Tab>
          </Tabs>
        </div>
      </Stack>
    </Col>
  );
}

export default Wallet;
