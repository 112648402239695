import React from "react";
import { Accordion, Col } from "react-bootstrap";

function WriterFaqs() {
  return (
    <Col className="mainarea">
      <div className="pagetitle">
        <h1 className="title">Frequently Asked Questions</h1>
      </div>
      <div className="contents">
        <Accordion>
          <Accordion.Item eventKey="0">
            <Accordion.Header>
              <h6>Profile Creation</h6>
            </Accordion.Header>
            <Accordion.Body>
              <ul>
                <li>
                  Complete your writer profile with information about your
                  skills, expertise, and writing experience.
                </li>
              </ul>
            </Accordion.Body>
          </Accordion.Item>
          <Accordion.Item eventKey="1">
            <Accordion.Header>
              <h6>Explore Orders</h6>
            </Accordion.Header>
            <Accordion.Body>
              <ul>
                <li>
                  Browse through the list of available writing Orders by
                  clicking on the available tab on the sidebar.
                </li>
                <li>
                  Each project will provide detailed information such as project
                  scope, requirements, and budget.
                </li>
              </ul>
            </Accordion.Body>
          </Accordion.Item>
          <Accordion.Item eventKey="2">
            <Accordion.Header>
              <h6>Project Type (Visibility)</h6>
            </Accordion.Header>
            <Accordion.Body>
              <ol>
                <li>
                  <b>Public Orders</b>
                  <ul>
                    <li>
                      Public Orders are visible to all writers/freelancers
                    </li>
                  </ul>
                </li>
                <li>
                  <b> Private Bid Orders</b>

                  <ul>
                    <li>
                      {" "}
                      <b>You Must have an Employer to access these Orders</b>
                    </li>
                    <li>
                      You Only have Access to your Employers' Private Orders
                    </li>
                    <li>
                      Only Writers of a specific Employer can bid on these
                      Orders
                    </li>
                  </ul>
                </li>
                <li>
                  <b>Private Take Orders</b>

                  <ul>
                    <li>
                      <b>You Must have an Employer to Access these Orders</b>
                    </li>
                    <li>
                      You Only have Access to your Employers' Private Orders
                    </li>
                    <li>You can take this Project without bidding on it</li>
                  </ul>
                </li>
              </ol>
            </Accordion.Body>
          </Accordion.Item>
          <Accordion.Item eventKey="3">
            <Accordion.Header>
              <h6>Project Type (Payment)</h6>
            </Accordion.Header>
            <Accordion.Body>
              <ol>
                <li>
                  <b>Escrowed Orders</b>
                  <ul>
                    <li>
                      These are all Orders which Cash will be loaded and
                      Escrowed on the Platform before awarding to you
                    </li>
                    <li>
                      Essay Host guarantees that you will receive Payments for
                      these Orders
                    </li>
                  </ul>
                </li>
                <li>
                  <b>PayLater Trusted Orders</b>
                  <ul>
                    <li>
                      These are those Orders Which an Employer chooses to pay at
                      a Later Date
                    </li>
                    <li>
                      Essays Host does not guarantee the Payment of these Orders
                    </li>
                    <li>
                      You Must be a writer for the Employer to have access to
                      these Orders
                    </li>
                    <li>
                      You Should Ensure that you have Established trust with the
                      Employer Before handling these Orders
                    </li>
                  </ul>
                </li>
              </ol>
            </Accordion.Body>
          </Accordion.Item>
          <Accordion.Item eventKey="4">
            <Accordion.Header>
              <h6>Place a Bid</h6>
            </Accordion.Header>
            <Accordion.Body>
              <ul>
                <li>
                  Select a project that aligns with your skills and interests.
                </li>
                <li>
                  Open the Project and at the bottom you find the bid button
                  click on it
                </li>
                <li>
                  Place a bid by specifying your proposed fee, proposed timeline
                  for completion, and any additional information that sets you
                  apart from other writers.
                </li>
              </ul>
            </Accordion.Body>
          </Accordion.Item>
          <Accordion.Item eventKey="5">
            <Accordion.Header>
              <h6>Wait for Award</h6>
            </Accordion.Header>
            <Accordion.Body>
              <ul>
                <li>
                  Once you've submitted your bid, wait for the employer to
                  review and award the Project.
                </li>
                <li>
                  Employers may choose to communicate with you for further
                  clarification or negotiation via the platforms chat.
                </li>
              </ul>
            </Accordion.Body>
          </Accordion.Item>
          <Accordion.Item eventKey="6">
            <Accordion.Header>
              <h6>Start Writing</h6>
            </Accordion.Header>
            <Accordion.Body>
              <ul>
                <li>
                  Upon approval, commence work on the project according to the
                  provided guidelines.
                </li>
                <li>
                  Regularly communicate with the employer to ensure alignment
                  with expectations.
                </li>
              </ul>
            </Accordion.Body>
          </Accordion.Item>
          <Accordion.Item eventKey="7">
            <Accordion.Header>
              <h6>Submit Files and Complete</h6>
            </Accordion.Header>
            <Accordion.Body>
              <ul>
                <li>
                  Submit the completed work through the platform
                  <ol>
                    <li>Go to In Progress </li>
                    <li>
                      Open the project for which you want to submit the files
                    </li>
                    <li>Click on upload files</li>
                    <li>You will be redirected to upload files page</li>
                    <li>Upload the files and click on submit</li>
                    <li>
                      Once you submit the files go back to In progress and click
                      on the Mark Complete button
                    </li>
                    <li>
                      This will notify the Employer that you have completed the
                      Project hence he should release your Escrowed pay to your
                      wallet
                    </li>
                  </ol>
                </li>

                <li>
                  Ensure that the work meets the project requirements and
                  adheres to any deadlines
                </li>
                <li>
                  Mark the Project as completed to notify the employer of
                  completion so they can release payment.
                </li>
              </ul>
            </Accordion.Body>
          </Accordion.Item>
          <Accordion.Item eventKey="8">
            <Accordion.Header>
              <h6>Get Paid</h6>
            </Accordion.Header>
            <Accordion.Body>
              <ul>
                <li>
                  Upon completion and approval by the employer, the funds will
                  be released to your Essay Host Wallet from where you can
                  withdraw to your M-Pesa account securely.
                </li>
                <li>
                  Maintain open communication with the employer throughout the
                  process.
                </li>
              </ul>
            </Accordion.Body>
          </Accordion.Item>
          <Accordion.Item eventKey="15">
            <Accordion.Header>
              <h6>Essay's Host Wallet</h6>
            </Accordion.Header>
            <Accordion.Body>
              <ul>
                <li>
                  <b>Escrow Wallet</b>
                  <ol>
                    <li>
                      Holds funds for an order in progress, on revision or in
                      dispute
                    </li>
                    <li>
                      Funds in Escrow Wallet cannot be withdrawn by either
                      Writer or Employer
                    </li>
                  </ol>
                </li>
                <li>
                  <b> Unpaid amount</b>
                  <ol>
                    <li>
                      This are all amount that are pending payment for trusted
                      Projects
                    </li>
                    <li>
                      The employer does not Escrow funds for trusted orders.{" "}
                    </li>
                    <li>
                      Ensure you trust an employer before you decide to tackle
                      trusted Orders
                    </li>
                  </ol>
                </li>
                <li>
                  <b> Wallet Balance</b>
                  <ol>
                    <li>
                      This are the funds which have been released for which you
                      can withdraw
                    </li>
                    <li>The minimum withdrawal balance is Kshs 50</li>
                  </ol>
                </li>
                <li>
                  <b>Total Earnings</b>
                  <ol>
                    <li>This are all your earnings on the platform</li>
                  </ol>
                </li>
              </ul>
            </Accordion.Body>
          </Accordion.Item>
          <Accordion.Item eventKey="9">
            <Accordion.Header>
              <h6>
                {" "}
                If I mark a Project as Complete and the Employer does not
                release the funds
              </h6>
            </Accordion.Header>
            <Accordion.Body>
              <ul>
                <li>
                  48 hours after marking a project as completed and no dispute
                  is raised by employer Essay Host Admin will release the cash
                  to your wallet.
                </li>
              </ul>
            </Accordion.Body>
          </Accordion.Item>
          <Accordion.Item eventKey="10">
            <Accordion.Header>
              <h6>Justifications for Refunds and Disputes</h6>
            </Accordion.Header>
            <Accordion.Body>
              <ol>
                <li>
                  Plagiarism: Substantial or unoriginal content that violates
                  the employer's guidelines and expectations.
                </li>

                <li>
                  Late Delivery: Failure to meet the agreed-upon deadline for
                  project completion.
                </li>
                <li>
                  Incomplete Work: Submission of work that does not fulfill the
                  project's requirements or is significantly lacking.
                </li>
                <li>
                  Quality Issues: Poor quality of writing that does not meet the
                  employer's standards or expectations.
                </li>
                <li>
                  Communication Problems: Lack of communication or
                  unresponsiveness from the writer during the project.
                </li>
                <li>
                  Scope Deviation: Failure to adhere to the initially
                  agreed-upon scope of the project.
                </li>
                <li>
                  Missed Revisions: Not addressing requested revisions or
                  modifications as outlined by the employer.
                </li>
                <li>
                  Payment Discrepancies: Disagreements over the agreed-upon
                  payment amount or payment schedule.
                </li>
              </ol>
            </Accordion.Body>
          </Accordion.Item>
          <Accordion.Item eventKey="11">
            <Accordion.Header>
              <h6>Dispute Resolution</h6>
            </Accordion.Header>
            <Accordion.Body>
              <ul>
                <li>
                  In case of a dispute, the employer and writer will attempt to
                  resolve the issue between themselves by providing evidence.
                </li>
                <li>
                  The employer and writer will have the option to communicate
                  through the platform's chat.
                </li>
                <li>
                  Only the Writer or Admin can resolve a dispute put forward by
                  an Employer
                </li>
              </ul>
            </Accordion.Body>
          </Accordion.Item>
          <Accordion.Item eventKey="12">
            <Accordion.Header>
              <h6>Writer Accepts and Refunds</h6>
            </Accordion.Header>
            <Accordion.Body>
              <ul>
                <li>
                  In case of a dispute, the writer has the option to accept the
                  dispute and agree to refund the employer.
                </li>
              </ul>
            </Accordion.Body>
          </Accordion.Item>
          <Accordion.Item eventKey="13">
            <Accordion.Header>
              <h6>Admin Resolution</h6>
            </Accordion.Header>
            <Accordion.Body>
              <ul>
                <li>
                  In case of a dispute, the admin will review the project and
                  make a decision on the best course of action.
                </li>
                <li>
                  If the writer and employer cannot come to an agreement or if
                  the dispute is complex, the platform admin will step in to
                  assess the situation.
                </li>
                <li>
                  The admin may choose to refund the employer or release the
                  funds to the writer.
                </li>
              </ul>
            </Accordion.Body>
          </Accordion.Item>
          <Accordion.Item eventKey="14">
            <Accordion.Header>
              <h6>Communication</h6>
            </Accordion.Header>
            <Accordion.Body>
              <ul>
                <li>
                  You can communicate to the employer through the platform's
                  chat.
                </li>
                <li>
                  Sharing of personal information is strictly Prohibited on the
                  platform.
                </li>
                <li>
                  Chats are based on Orders. Chat is only available once a bid
                  has been placed and the Employer initiates the chat.
                </li>
                <li>
                  Incase the employer awarded you a project and did not initiate
                  a chat you can initiate a chat from the awarded project.
                </li>
                <li>
                  You can communicate to the admin through email
                  info@essayshost.com
                </li>
              </ul>
            </Accordion.Body>
          </Accordion.Item>
        </Accordion>
      </div>
    </Col>
  );
}

export default WriterFaqs;
