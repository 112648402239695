import React, { useState, useEffect, useRef } from "react";
import { Card, Col, Row, Placeholder, Stack} from "react-bootstrap";
import getData from "../../customHooks/getData";
import { HiOutlineUserCircle } from "react-icons/hi";
import { useNavigate } from "react-router-dom";
import Busy from "../global/Busy";
import { BiSolidStar } from "react-icons/bi";


import { IoIosSearch } from "react-icons/io";
import axios from "axios";


function Freelancers() {
  const navigate = useNavigate()
  const [state, setState] = useState({
    currentPage: 1,
    postPerPage: 20,
    freelancers: [],
    loading: true,
  });
  const [currentPage, setCurrentPage] = useState(1)
  const [loadingMore, setLoadingMore] = useState(false)
  const [complete, setComplete] = useState(false)
  
  const containerRef = useRef(null);

  const url = `${process.env.REACT_APP_API_URL}/accounts/freelancers/${currentPage}`;


  useEffect(() => {
    getData(url)
      .then((res) => {
        console.log(res)
        setCurrentPage((page) => page + 1);
        const freelancers = res.filter((freelancer) => {
         return freelancer.writer_profile !== null;
        })
        console.log(freelancers)
        setState({...state, freelancers: freelancers , loading: false});
      })
      .catch((err) => {
        console.log(err);
       
      });
  }, []);

  const searchWriter = async (body) => {
    setLoadingMore(true)
    const url = `${process.env.REACT_APP_API_URL}/writersadmin/search`;
    const config = {
      headers: {
        Authorization: `JWT ${localStorage.getItem("access")}`,
        "Content-Type": "application/json",
        Accept: "application/json",
      },
    }
    try {
      const res = await axios.post(url, body, config)
      console.log(res)
      return res.data
    }
    catch (err) {
      console.log(err)
      setLoadingMore(false)
    }
  }

  const placeholderlist = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20]
   const [filter, setFilter] = useState({
     filtermode: "username",
     filtervalue: "",
   });

  const handleScroll = () => {
    console.log("handle Scroll run")
    const container = containerRef.current;
    const positionFromBottom =
      container.scrollHeight - container.scrollTop - container.clientHeight;

      if (
        positionFromBottom <= 100 && !loadingMore && !complete
      ) {
        // User has scrolled to the bottom, load more data
        console.log("scrolled to bottom");
          setCurrentPage((page) => page + 1);
          setLoadingMore(true);
        setTimeout(() => {
          getData(
            `${process.env.REACT_APP_API_URL}/accounts/freelancers/${currentPage}`
          )
            .then((res) => {
              console.log(res);
              console.log(res.length);
              const freelancers = res.filter((freelancer) => {
                return freelancer.writer_profile !== null;
              });
              setState((prevState) => ({
                freelancers: [...prevState.freelancers, ...freelancers],
                loading: false,
              }));
              setLoadingMore(false);
              if (res.length === 0) {
                setComplete(true);
              }
            })
            .catch((err) => {
              console.log(err);
            });
         },1000)
        
      }
  };
  

  const handleFilterChange = (event) => {
    setFilter({...filter, filtermode: event.target.value })
  };

  const handleChange = (event) => {
    setFilter({...filter, filtervalue: event.target.value})
  }
 
  console.log(filter)

  useEffect(() => {
    if (filter.filtermode === 'level') {
      setFilter({...filter, filtervalue:"Novice"})
    }
    else if (filter.filtermode === 'rating') {
      setFilter({...filter, filtervalue: '1'})
    }
    else {
      setFilter({...filter, filtervalue: ""})
    }
  }, [filter.filtermode])


  return (
    <Col ref={containerRef} onScroll={handleScroll}>
      {state.loading ? (
        <Busy />
      ) : (
        <>
          <div className="pagetitle">
            <h1 className="title">Writers</h1>
          </div>
          <div>
            <Row fluid className="mt-1 mb-1 pt-2 d-flex">
              <Col
                lg={6}
                md={6}
                sm={12}
                className="d-flex justify-content-start align-items-center">
                <Stack direction="horizontal" gap={2}>
                  <h6>Filter: </h6>
                  <select
                    style={{ border: "none" }}
                    value={filter.filtermode}
                    onChange={handleFilterChange}>
                    <option value="username">Writer Name</option>
                    <option value="level">Level</option>
                    <option value="rating">Rating</option>
                  </select>
                  {filter.filtermode === "username" && (
                    <input
                      type="text"
                      value={filter.filtervalue}
                      onChange={handleChange}
                      placeholder="Writer's Name"
                    />
                  )}
                  {filter.filtermode === "level" && (
                    <select value={filter.filtervalue} onChange={handleChange}>
                      <option value="Novice">Novice</option>
                      <option value="Intermediate">Intermediate</option>
                      <option value="Expert">Expert</option>
                    </select>
                  )}
                  {filter.filtermode === "rating" && (
                    <select value={filter.filtervalue} onChange={handleChange}>
                      {[5, 4, 3, 2, 1].map((rating) => (
                        <option key={rating} value={rating}>
                          {rating >= 5
                            ? `Best Rating ${rating}`
                            : `Greater or Equal ${rating}`}
                        </option>
                      ))}
                    </select>
                  )}
                  <div className="d-flex justify-content-start align-items-center pb-2">
                    <button
                      className="btn btn-success btn-sm"
                      onClick={() => {
                        searchWriter(filter)
                          .then((res) => {
                            console.log(res);
                            const freelancers = res.filter((freelancer) => {
                              return freelancer.writer_profile !== null;
                            });
                            setState({
                              ...state,
                              freelancers: freelancers,
                            });
                            setLoadingMore(false);
                          })
                          .catch((err) => {
                            console.log(err);
                          });
                      }}
                      disabled={!filter.filtervalue || state.loading}>
                      <IoIosSearch />
                    </button>
                  </div>
                </Stack>
              </Col>
            </Row>
            <Row className="contents">
              {loadingMore
                ? placeholderlist.map((listitem, index) => {
                    return (
                      <Col key={index} lg={3} sm={6} md={4}>
                        <Card className="mb-2">
                          <Card.Body>
                            <Placeholder as={Card.Title} animation="glow">
                              <Placeholder xs={6} />
                            </Placeholder>
                            <Placeholder as={Card.Text} animation="glow">
                              <Placeholder xs={4} />
                              <Placeholder xs={4} />
                              <Placeholder xs={8} />
                            </Placeholder>
                            <div className="d-flex justify-content-center">
                              <Placeholder.Button
                                variant="primary btn-sm"
                                xs={3}
                              />
                            </div>
                          </Card.Body>
                        </Card>
                      </Col>
                    );
                  })
                : state.freelancers.map((freelancer, index) => {
                    return (
                      <Col key={index} lg={3} sm={6} md={4}>
                        <Card className="mb-2">
                          <Card.Body>
                            <Card.Title>
                              {" "}
                              <Stack direction="horizontal" gap={2}>
                                <div className="d-flex justify-content-center align-items-center mb-2">
                                  {freelancer.profile_pic !== null ? (
                                    <img
                                      src={freelancer.profile_pic}
                                      className="freelancer_image"
                                      alt="freelancer"
                                    />
                                  ) : (
                                    <HiOutlineUserCircle className="freelancer_no_image" />
                                  )}{" "}
                                </div>
                                <Stack className="mt-2">
                                  <h6 style={{ color: "green" }}>
                                    {" "}
                                    {freelancer.first_name}{" "}
                                    {freelancer.last_name}
                                  </h6>
                                  <p>ID#{freelancer.id}</p>
                                </Stack>
                              </Stack>
                            </Card.Title>
                            <Card.Text>
                              <span>
                                <div className="rating d-flex justify-content-start">
                                  <b style={{ color: "green" }}>
                                    {freelancer.writer_profile.rating}
                                  </b>{" "}
                                  {[1, 2, 3, 4, 5].map((index) => (
                                    <div className="rating">
                                      <BiSolidStar
                                        data-tooltip-id={index}
                                        className={
                                          index <=
                                          freelancer.writer_profile.rating
                                            ? "star starselected"
                                            : "star"
                                        }
                                        size={17}
                                        key={index}
                                      />
                                    </div>
                                  ))}
                                  ({freelancer.writer_profile.ratingcount}{" "}
                                  Ratings){" "}
                                </div>
                              </span>{" "}
                              <b style={{ color: "green" }}>Level:</b>{" "}
                              {freelancer.writer_profile.level}
                              <br />
                              <h6 style={{ color: "#006a78" }}>
                                Skilled In
                              </h6>{" "}
                              {freelancer.writer_profile !== null &&
                              freelancer.writer_profile.subjects !== "None" ? (
                                JSON.parse(
                                  freelancer.writer_profile.subjects.replace(
                                    /'/g,
                                    '"'
                                  )
                                ).map((subjectString, index) => {
                                  return (
                                    <span key={index} className="subjecttag">
                                      {subjectString.subject}
                                      {", "}
                                    </span>
                                  );
                                })
                              ) : (
                                <p>
                                  Writer has not Updated their Fields of
                                  Expertise
                                </p>
                              )}
                            </Card.Text>
                            <div className="d-flex justify-content-center">
                              <button
                                className="btn btn-outline-info btn-sm"
                                onClick={() => {
                                  localStorage.setItem(
                                    "writer",
                                    JSON.stringify(freelancer)
                                  );
                                  navigate("/employer/writer-info");
                                }}>
                                Connect
                              </button>
                            </div>
                          </Card.Body>
                        </Card>
                      </Col>
                    );
                  })}
            </Row>
          </div>
        </>
      )}
    </Col>
  );
}

export default Freelancers;
