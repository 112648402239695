import React, { useState, useEffect } from "react";
import { Col, Accordion } from "react-bootstrap";
import getData from "../../customHooks/getData";
import { Link } from "react-router-dom";

import { useNavigate } from "react-router-dom";
import Empty from "../global/Empty";
import Busy from "../global/Busy";
import Project from "./Project/Project";

function Disputed() {
  const [state, setState] = useState({
    currentpage: 1,
    postsperpage: 10,
    projects: [],
    loading: true,
  });

  const url = `${process.env.REACT_APP_API_URL}/accounts/view_disputed_projects`;
  const navigate = useNavigate();
  useEffect(() => {
    getData(url)
      .then((res) => {
        setState({
          ...state,
          projects: res,
          loading: false,
        });
      })
      .catch((err) => {
        console.log(err);
        navigate("/register/login");
      });
  }, []);

  let currentProjects = [];
  // Implement Page Numbers
  const pageNumbers = [];
  if (!state.loading) {
    const indexOfLastProject = state.currentpage * state.postsperpage;
    const indexOfFirstProject = indexOfLastProject - state.postsperpage;
    currentProjects = state.projects.slice(
      indexOfFirstProject,
      indexOfLastProject
    );

    // Depending on the length of the projects available implement the page numbers
    for (
      let i = 1;
      i <= Math.ceil(state.projects.length / state.postsperpage);
      i++
    ) {
      pageNumbers.push(i);
    }
  }

  const setPage = (num) => {
    setState({ ...state, currentpage: num });
  };
  return (
    <div >
      {state.loading ? (
        <Busy />
      ) : (
        <>
          <div className="pagetitle">
            <h1 className="title">Disputed Orders {state.projects.length}</h1>
          </div>

          <Accordion>
            <Accordion.Item eventKey="0">
              <Accordion.Header className="accordionheader">
                <table>
                  <col style={{ width: "20%" }} />
                  <col style={{ width: "40%" }} />
                  <col style={{ width: "40%" }} />
                  <thead>
                    <th> ID</th>
                    <th>FIELD</th>
                    <th>BUDGET </th>
                  </thead>
                </table>
              </Accordion.Header>
            </Accordion.Item>

            {state.projects.length === 0 ? (
              <Empty message="No Disputed Projects" />
            ) : (
              <div className="contentarea">
                {currentProjects.map((project) => {
                  //get today's date and time
                  var now = new Date().getTime();
                  //convert the json date to milliseconds

                  var deadline = new Date(`${project.deadline}`).getTime();

                  var remainingtime = deadline - now;

                  // convert the milliseconds to days hours and minutes
                  function daysminhours(milliseconds) {
                    let seconds = Math.floor(milliseconds / 1000);
                    let minutes = Math.floor(seconds / 60);
                    let hours = Math.floor(minutes / 60);
                    let days = Math.floor(hours / 24);

                    seconds = seconds % 60;
                    // 👇️ if seconds are greater than 30, round minutes up (optional)
                    minutes = seconds >= 30 ? minutes + 1 : minutes;

                    minutes = minutes % 60;

                    // 👇️ If you don't want to roll hours over, e.g. 24 to 00
                    // 👇️ comment (or remove) the line below
                    // commenting next line gets you `24:00:00` instead of `00:00:00`
                    // or `36:15:31` instead of `12:15:31`, etc.
                    hours = hours % 24;
                    if (days > 0) {
                      return `${days}dys: ${hours}hrs : ${minutes}mins`;
                    } else if (hours > 0) {
                      return ` ${hours}hrs : ${minutes}mins`;
                    } else {
                      return `${minutes}mins`;
                    }
                  }
                  return (
                    <Accordion.Item eventKey={project.id} key={project.id}>
                      <Project project={project}>
                        <div className="d-flex justify-content-around">
                          <Link
                            to="/employer/disputeview"
                            className="btn btn-outline-success btn-sm"
                            onClick={() => {
                              localStorage.setItem(
                                "disputeProject",
                                JSON.stringify(project)
                              );
                            }}>
                            View Dispute
                          </Link>
                        </div>
                      </Project>
                    </Accordion.Item>
                  );
                })}
              </div>
            )}
          </Accordion>
          <div className="paginationcontainer d-flex justify-content-center">
            {pageNumbers.map((pageNumber, index) => (
              <span
                key={index}
                className={
                  pageNumber === state.currentpage
                    ? "text-light bg-success paginationitem"
                    : "text-dark bg-light paginationitem"
                }
                onClick={() => {
                  setPage(pageNumber);
                }}>
                {pageNumber}
              </span>
            ))}
          </div>
        </>
      )}
    </div>
  );
}

export default Disputed;
