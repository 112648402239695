import axios from "axios";
import React, { useEffect, useState } from "react";
import { Accordion, Col } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import Empty from "../global/Empty";
import Busy from "../global/Busy";

import WriterProject from "./writerProject/WriterProject";

export function WriterCompleted() {
  const [projects, setProjects] = useState({
    currentPage: 1,
    myProjects: [],
    loading: true,
    postsPerPage: 15,
  });
  const navigate = useNavigate();

  const get_completed = async () => {
    const config = {
      headers: {
        Authorization: `JWT ${localStorage.getItem("access")}`,
        "Content-Type": "application/json",
        Accept: "application/json",
      },
    };
    try {
      const res = await axios.get(
        `${process.env.REACT_APP_API_URL}/accounts/completedprojects`,
        config
      );
      console.log(res);
      setProjects({ ...projects, myProjects: res.data, loading: false });
    } catch (err) {
      navigate("/register/login");
      console.log(err);
    }
  };

  useEffect(() => {
    get_completed();
    const interval = setInterval(() => {
      get_completed()
        .then((res) => {
          console.log(res);
        })
        .catch((err) => {
          console.log(err);
        });
    }, 10000);
    return () => clearInterval(interval);
  }, []);

  const indexLastProject = projects.currentPage * projects.postsPerPage;
  const indexFirstProject = indexLastProject - projects.postsPerPage;
  const displayProjects = projects.myProjects.slice(
    indexFirstProject,
    indexLastProject
  );

  const pageNum = [];

  for (
    let i = 1;
    i <= Math.ceil(projects.myProjects.length / projects.postsPerPage);
    i++
  ) {
    pageNum.push(i);
  }
  const setPage = (number) => {
    setProjects({ ...projects, currentPage: number });
  };
  console.log(displayProjects);
  return (
    <Col className="mainarea">
      {projects.loading ? (
        <Busy />
      ) : (
        <>
          <div className="pagetitle">
            <h1 className="title">Orders {projects.myProjects.length}</h1>
          </div>
          <Accordion>
            <Accordion.Item eventKey="0">
              <Accordion.Header className="accordionheader">
                <table>
                  <col style={{ width: "20%" }} />
                  <col style={{ width: "40%" }} />
                  <col style={{ width: "40%" }} />
                  <thead>
                    <th> ID</th>
                    <th>FIELD</th>
                    <th>BUDGET </th>
                  </thead>
                </table>
              </Accordion.Header>
            </Accordion.Item>
            {projects.myProjects.length === 0 && (
              <Empty message="No Completed Orders" />
              )}
              <div  className="contentarea">
            {displayProjects.map((project) => {
              return (
                <Accordion.Item eventKey={project.id} key={project.id}>
                  <WriterProject project={project} />
                </Accordion.Item>
              );
            })}
                </div>
          </Accordion>
        </>
      )}

      <div className="paginationcontainer d-flex justify-content-center">
        {pageNum.map((num) => {
          return (
            <span
              onClick={() => {
                setPage(num);
              }}
              className={
                num === projects.currentPage
                  ? "text-light bg-success paginationitem"
                  : "text-dark bg-light paginationitem"
              }>
              {num}
            </span>
          );
        })}
      </div>
    </Col>
  );
}
