import { connect } from "react-redux";
import React, { useEffect, useState } from "react";
import { Col, Row, Stack, Spinner, Card, ProgressBar } from "react-bootstrap";
import { useFormik } from "formik";
import { HiOutlineUserCircle } from "react-icons/hi";
import { get_user } from "../../actions/myuser";
import * as yup from "yup";
import axios from "axios";
import {
  PROFILE_IMAGE_SUPPORTED_FORMATS,
  PROFILE_IMAGE_FILE_SIZE,
} from "../../assets/projectassets";

import { Link } from "react-router-dom";

import document from "../../images/document.png";
import disputed from "../../images/disputed.png";
import review from "../../images/review.png";

import wallet from "../../images/wallet.png";
import wallet2 from "../../images/wallet2.png";

import Busy from "../global/Busy";
import { VscWorkspaceTrusted } from "react-icons/vsc";
import moment from "moment";
import { useSelector } from "react-redux";

function Profile({ user, get_user }) {
  const country = useSelector((state) => state.userCountry.country)
  const [loading, setLoading] = useState(true);
  const [state, setState] = useState({
    imageloading: false,
  });
  const [image, setImage] = useState(null);
  const uploadProfileImage = async (image) => {
    const config = {
      headers: {
        Authorization: `JWT ${localStorage.getItem("access")}`,
        Accept: "application/json",
      },
    };
    try {
      const res = await axios.patch(
        `${process.env.REACT_APP_API_URL}/accounts/update_user`,
        image,
        config
      );
      return res.data;
    } catch (err) {
      console.log(err);
    }
  };

  useEffect(() => {
    get_user().then(() => {
      setLoading(false);
    });
  }, []);

  console.log(user.profile_pic)

  const profileValidation = yup.object().shape({
    profile_pic: yup
      .mixed()
      .required("You need to select a profile image")
      .test(
        "fileSize",
        "File size is too large. Max 2MB",
        (value) => value && value.size <= PROFILE_IMAGE_FILE_SIZE
      )
      .test(
        "fileType",
        "Unsupported file type",
        (value) => value && PROFILE_IMAGE_SUPPORTED_FORMATS.includes(value.type)
      ),
  });

  const onSubmit = (values) => {
    for (let value in values) {
      if (value === "profile_pic") {
        setState({ ...state, imageloading: true });
        let imageData = new FormData();
        imageData.append(value, values[value]);
        uploadProfileImage(imageData)
          .then(() => {
            get_user().then(() => {
              setState({ ...state, imageloading: false });
            });
          })
          .catch((err) => {
            setState({ ...state, imageloading: false });
            console.log(err);
          });
      }
    }
  };

  const { values, errors, handleChange, handleSubmit, setFieldValue } =
    useFormik({
      initialValues: {
        phone_no: "",
        profile_pic: "",
      },
      validationSchema: profileValidation,
      onSubmit,
    });

  return (
    <Col className="mainarea">
      {loading ? (
        <Busy />
      ) : (
        <div>
          <div className="pagetitle">
            {user ? <h1 className="title">{user.role}</h1> : ""}
          </div>

          <Row className="contents">
            <Col lg={5} sm={12} md={5}>
              <Card
                style={{ height: "95vh" }}
                className="p-3 mt-1 mb-1 smallprofile">
                {user ? (
                  user.profile_pic ? (
                    <Stack
                      gap={2}
                      className="d-flex justify-content-center align-items-center">
                      <h5 className="text-center">
                        {user.first_name} {user.last_name}
                      </h5>
                      {!image ? (
                        <img src={user.profile_pic} className="profile_image" />
                      ) : (
                        <img src={image} className="profile_image" />
                      )}

                      <form
                        encType="multipart/formdata"
                        onSubmit={handleSubmit}>
                        <Stack gap={2}>
                          <label
                            htmlFor="profile_pic"
                            style={{
                              cursor: "pointer",
                              textAlign: "center",
                              fontWeight: "bold",
                              marginTop: "1vh",
                              marginBottom: "1vh",
                              color: "green",
                            }}>
                            Select Profile Image
                          </label>
                          <input
                            type="file"
                            id="profile_pic"
                            name="profile_pic"
                            accept="image/*"
                            onChange={(event) => {
                              setFieldValue(
                                "profile_pic",
                                event.currentTarget.files[0]
                              );
                              setImage(
                                URL.createObjectURL(
                                  event.currentTarget.files[0]
                                )
                              );
                            }}
                          />
                          {errors.profile_pic ? (
                            <p className="error">{errors.profile_pic}</p>
                          ) : (
                            ""
                          )}
                          <div className="d-flex justify-content-center">
                            <button
                              className="btn btn-outline-success btn-sm"
                              type="submit">
                              {state.imageloading ? (
                                <>
                                  <Spinner
                                    as="span"
                                    animation="border"
                                    size="sm"
                                  />{" "}
                                  <span>Saving...</span>
                                </>
                              ) : (
                                <span>Save</span>
                              )}
                            </button>
                          </div>
                        </Stack>
                      </form>
                      <h6>Email: {user.email}</h6>
                      <h6>Phone: 0{user.phone_no}</h6>
                      <h6>
                        Joined: {moment(user.joined).format("Do MMM YYYY")}{" "}
                      </h6>
                      <div>
                        {user.employer_profile.trusted ? (
                          <Stack className="d-flex justify-content-center align-items-center">
                            <h6>Trusted</h6>
                            <VscWorkspaceTrusted size={30} color="green" />
                          </Stack>
                        ) : (
                          <Stack
                            gap={1}
                            className="d-flex justify-content-center align-items-center">
                            <h6 style={{ color: "green" }}>
                              Progress to Trusted
                            </h6>
                            <p>
                              Complete{" "}
                              <b>
                                {10 - user.employer_profile.completedcount}{" "}
                              </b>
                              Projects
                            </p>
                            <ProgressBar
                              variant="success"
                              className="w-100"
                              now={user.employer_profile.completedcount * 10}
                            />
                          </Stack>
                        )}
                      </div>

                      <div className="d-flex justify-content-center">
                        <Link
                          to="/employer/updatephoneno"
                          className="btn btn-outline-success btn-sm">
                          Update Phone_no
                        </Link>
                      </div>
                    </Stack>
                  ) : (
                    <Stack
                      gap={2}
                      className="d-flex justify-content-center align-items-center">
                      <h5 className="text-center">
                        {user.first_name} {user.last_name}
                      </h5>

                      <h6>Email: {user.email}</h6>
                      <h6>Phone: 0{user.phone_no}</h6>
                      <div>
                        {user.employer_profile.trusted ? (
                          <Stack className="d-flex justify-content-center align-items-center">
                            <h6>Trusted</h6>
                            <VscWorkspaceTrusted size={30} color="green" />
                          </Stack>
                        ) : (
                          <Stack
                            gap={1}
                            className="d-flex justify-content-center align-items-center">
                            <h6 style={{ color: "green" }}>
                              Progress to Trusted
                            </h6>
                            <p>
                              Complete{" "}
                              <b>
                                {10 - user.employer_profile.completedcount}{" "}
                              </b>
                              Projects
                            </p>
                            <ProgressBar
                              variant="success"
                              className="w-100"
                              now={user.employer_profile.completedcount * 10}
                            />
                          </Stack>
                        )}
                      </div>
                      {!image ? (
                        <HiOutlineUserCircle className="profile_image_placeholder" />
                      ) : (
                        <img src={image} className="profile_image" />
                      )}

                      <form
                        encType="multipart/formdata"
                        onSubmit={handleSubmit}>
                        <Stack gap={1}>
                          <label
                            htmlFor="profile_pic"
                            style={{
                              cursor: "pointer",
                              textAlign: "center",
                              fontWeight: "bold",
                              marginTop: "1vh",
                              marginBottom: "1vh",
                              color: "green",
                            }}>
                            Select Profile Pic
                          </label>
                          <input
                            type="file"
                            id="profile_pic"
                            name="profile_pic"
                            onChange={(event) => {
                              setFieldValue(
                                "profile_pic",
                                event.currentTarget.files[0]
                              );
                              setImage(
                                URL.createObjectURL(
                                  event.currentTarget.files[0]
                                )
                              );
                            }}
                          />
                          {errors.profile_pic ? (
                            <p className="error">{errors.profile_pic}</p>
                          ) : (
                            ""
                          )}
                          <div className="d-flex justify-content-center">
                            <button
                              type="submit"
                              className="btn btn-outline-success btn-sm">
                              {state.imageloading ? (
                                <>
                                  <Spinner
                                    as="span"
                                    animation="border"
                                    size="sm"
                                  />{" "}
                                  <span>Saving...</span>
                                </>
                              ) : (
                                <span>Save</span>
                              )}
                            </button>
                          </div>
                        </Stack>
                      </form>

                      <div className="d-flex justify-content-center">
                        <Link
                          to="/employer/updatephoneno"
                          className="btn btn-outline-success btn-sm">
                          Update Phone_no
                        </Link>
                      </div>
                    </Stack>
                  )
                ) : (
                  ""
                )}
              </Card>
            </Col>
            <Col lg={7} sm={12} md={7} style={{ height: "inherit" }}>
              <Row>
                <Col lg={6} sm={12} md={6}>
                  <div className="profilecard">
                    <Stack gap={2}>
                      <h5>Completed </h5>
                      <h6>
                        <img
                          src={document}
                          alt="agreement"
                          className="profilecardimage"
                        />{" "}
                        {user.employer_profile.completedcount}
                      </h6>
                    </Stack>
                  </div>
                </Col>

                <Col lg={6} sm={12} md={6}>
                  <div className="profilecard">
                    <Stack gap={2}>
                      <h5>Revisions</h5>
                      <h6>
                        <img
                          src={review}
                          alt="agreement"
                          className="profilecardimage"
                        />{" "}
                        {user.employer_profile.revisioncount}
                      </h6>
                    </Stack>
                  </div>
                </Col>
                <Col lg={6} sm={12} md={6}>
                  <div className="profilecard">
                    <Stack gap={2}>
                      <h5> All Disputes </h5>
                      <h6>
                        <img
                          src={disputed}
                          alt="agreement"
                          className="profilecardimage"
                        />{" "}
                        {user.employer_profile.disputecount}
                      </h6>
                    </Stack>
                  </div>
                </Col>
                <Col lg={6} sm={12} md={6}>
                  <div className="profilecard">
                    <Stack gap={2}>
                      <h5>Expenditure </h5>
                      <h6>
                        <img
                          src={wallet}
                          alt="agreement"
                          className="profilecardimage"
                        />
                        {country === "Kenya" ? <>Kshs </> : <>USD </>}
                        {user.total_amount}
                      </h6>
                    </Stack>
                  </div>
                </Col>
                <Col lg={6} sm={12} md={6}>
                  <div className="profilecard">
                    <Stack gap={2}>
                      <h5>Escrow </h5>
                      <h6>
                        <img
                          src={wallet}
                          alt="agreement"
                          className="profilecardimage"
                        />{" "}
                        Kshs {user.employer_profile.cash}.00
                      </h6>
                    </Stack>
                  </div>
                </Col>
                <Col lg={6} sm={12} md={6}>
                  <div className="profilecard">
                    <Stack gap={2}>
                      <h5>Wallet </h5>
                      <h6>
                        <img
                          src={wallet2}
                          alt="agreement"
                          className="profilecardimage"
                        />{" "}
                        {country === "Kenya" ? <>Kshs </> : <>USD </>}{" "}
                        {user.user_wallet.cash}
                      </h6>
                    </Stack>
                  </div>
                </Col>
              </Row>
            </Col>
          </Row>
        </div>
      )}
    </Col>
  );
}

const mapStateToProps = (state) => ({
  user: state.current_user.user,
});

export default connect(mapStateToProps, { get_user })(Profile);
