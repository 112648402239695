import React from "react";
import { Col , Stack, Tab, Tabs} from "react-bootstrap";
import Transactions from "./Transactions";
import WriterFinance from "./WriterFinance";

export const WriterWallet = () => {
  return (
    <Col className="mainarea" >
      <Stack gap={1}>
        <div className="pagetitle">
        <h1 className="title">Wallet</h1>
        </div>
        <div>
        <Tabs
      variant="pills"
      pill bg="success"
      defaultActiveKey="finance"
      justify
      className="tabs"
    
    >
      <Tab eventKey="finance" title="Wallets">
        <WriterFinance/>
      </Tab>
      <Tab eventKey="transaction" title="Transactions" >
        <Transactions/>
      </Tab> 
    </Tabs>
        </div>
        </Stack>
    </Col>
  );
};
