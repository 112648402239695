import React, { useEffect, useState } from "react";
import axios from "axios";
import { Row, Col, Container, Card } from "react-bootstrap";
import Transactions from "./dashComponents/Transactions";
import Admins from "./dashComponents/Admins";
import Projects from "./dashComponents/Projects";
import Busy from "../global/Busy";

function ManagerDash() {
  const usersurl = `${process.env.REACT_APP_API_URL}/siteadmin/users`;
  const projectsurl = `${process.env.REACT_APP_API_URL}/siteadmin/all_projects`;
  const [writers, setWriters] = useState(null);
  const [employers, setEmployers] = useState(null);
  const [admins, setAdmins] = useState(null);
  const [onliners, setOnliners] = useState(null);
  const [earnings, setEarnings] = useState(null);

  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);

  const [projects, setProjects] = useState(null);
  const [targetDate, setTargetDate] = useState(null);
  const [loading, setLoading] = useState(true);

  console.log(projects);

  console.log(startDate, endDate);

  const getData = async (url) => {
    const config = {
      headers: {
        Authorization: `JWT ${localStorage.getItem("access")}`,
        "Content-Type": "application/json",
        Accept: "application/json",
      },
    };

    try {
      const res = await axios.get(url, config);
      const data = res.data;
      console.log(data);
      return data;
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    getData(usersurl)
      .then((res) => {
        setLoading(false);
        const writer = res.filter((user) => {
          return user.role === "Writer";
        });
        setWriters(writer);
        const employer = res.filter((user) => {
          return user.role === "Employer";
        });
        setEmployers(employer);
        const admins = res.filter((user) => {
          return user.role === "Admin";
        });
        setAdmins(admins);

        const onliners = res.filter((user) => {
          return user.online === true;
        });
        setOnliners(onliners);
      })
      .catch((err) => console.log(err));
    getData(projectsurl).then((res) => setProjects(res));
  }, []);

  const items = [
    {
      name: "Users",
      total: writers?.length + employers?.length + admins?.length,
      color: "blue",
      bgcolor: "lightblue",
    },
    {
      name: "Employers",
      total: employers?.length,
      color: "purple",
      bgcolor: "lightpurple",
    },
    {
      name: "Writers",
      total: writers?.length,
      color: "orange",
      bgcolor: "lightorange",
    },
    {
      name: "Admins",
      total: admins?.length,
      color: "green",
      bgcolor: "lightgreen",
    },
    {
      name: "Projects",
      total: projects?.length,
      color: "purple",
      bgcolor: "lightblue",
    },
    {
      name: "Online",
      total: onliners?.length,
      color: "red",
      bgcolor: "lightyellow",
    },
  ];
  return loading ? (
    <Busy />
  ) : (
    <Container fluid>
      <Row className="page-header-row">
        <Col lg={12} md={12} xs={12} className="m-0 page-header">
          <div>
            <div className="d-flex justify-content-between">
              <div>
                <h3 className=" text-white">Essays' Host Admin</h3>
              </div>
              <div></div>
            </div>
          </div>
        </Col>
        {items?.map((item, index) => {
          return (
            <Col xl={2} lg={2} md={4} xs={12} key={index}>
              <Card
                style={{ backgroundColor: item.bgcolor, color: item.color }}
                className="custom-card text-center p-3 d-flex align-items-start ">
                <h6>{item.name}</h6>
                <h4>{item.total}</h4>
              </Card>
            </Col>
          );
        })}
      </Row>
      <Row className="custom-row">
        <Transactions />
      </Row>
      <Row className="mt-6 ">
        <Col>
          <Projects />
        </Col>
        {/* card  */}
      </Row>
      <Row className="mt-6">
        <Col>
          <Admins />
        </Col>
      </Row>
    </Container>
  );
}

export default ManagerDash;
