import React, { useState } from "react";

import { Modal, Stack, Spinner } from "react-bootstrap";
import { useFormik } from "formik";
import * as yup from "yup"
import axios from "axios";
import creditcards from "../../images/creditcards.png"



const CardPayment = ({ showPayment, setShowPayment, cardtitle, setIframeUrl , setCardError}) => {
   
  const currencies = ["KES", "USD", "EUR"]
  const [submitting, setSubmitting] = useState(false)

  const {errors, values, touched, handleChange, handleSubmit, setFieldValue, resetForm} = useFormik({
    initialValues: {
  
      amount: "",
      currency: "",

    },
    validationSchema: yup.object().shape({
 
      amount: yup.number().min(1, "Amount should be a Positive Number").required("Amount is required"),
      currency: yup.string().required("Currency is required"),


    }),
    onSubmit: (values) => {
      placeOrder(values)
      .then((res) => {
        setShowPayment(false)
        resetForm()
      })
      .catch((err) => {
        console.log(err)
      })
    }
  })

  const placeOrder =  async (body) => {
      setSubmitting(true)
      const config = {
        headers: {
          "Content-Type": "application/json",
          Authorization: `JWT ${localStorage.getItem("access")}`,
        },
      };
      
      try {
        const url = `${process.env.REACT_APP_API_URL}/accounts/card_payment`
        const response = await axios.post(url, body, config)
        console.log(response.data)
        if (response?.data.error) {
          setCardError(true)
          setSubmitting(false)
        }
        setIframeUrl(response.data.redirect_url)
        setSubmitting(false)
      }
      catch (error) {
        
        setCardError(true)
        setSubmitting(false)
      }
  }
  
  return (
    <Modal
      show={showPayment}
      onHide={() => {
        setShowPayment(false);
      }}
      centered
      backdrop="static"
      classfirst_name="d-flex justify-content-center p-2"
      keyboard={false}>
      <Modal.Header closeButton   className="d-flex align-items-center justify-content-between ">
        <Modal.Title>{cardtitle}</Modal.Title>{"   "} <img src={creditcards} style={{height: "5vh", width: "auto", marginBottom: "1vh"}}/>
      </Modal.Header>
      <Modal.Body style={{ padding: "20px" }}>
        <Stack gap={2}>
          <form onSubmit={handleSubmit}>
           
            <Stack direction="horizontal" gap={2}>
              <Stack gap={2}>
                <label htmlFor="currency" className="postlabel">
                  Currency
                </label>
                <select
                  name="currency"
                  id="currency"
                  className="form-control"
                  onChange={handleChange}
                  value={values.currency}>
                  <option value="">Select Currency</option>
                  {currencies.map((currency, index) => (
                    <option key={index} value={currency}>
                      {currency}
                    </option>
                  ))}
                </select>
                {errors.currency && touched.currency ? (
                  <p className="error">{errors.currency}</p>
                ) : (
                  ""
                )}
              </Stack>
              <Stack gap={2}>
                <label htmlFor="amount" className="postlabel">
                  Amount
                </label>
                <input
                  type="number"
                  className="form-control"
                  name="amount"
                  id="amount"
                  onChange={handleChange}
                  placeholder="Enter amount"
                />
                {errors.amount && touched.amount ? (
                  <p className="error">{errors.amount}</p>
                ) : (
                  ""
                )}
              </Stack>
            </Stack>
          

            <div className="d-flex justify-content-center">
              <button type="submit" className="btn btn-success">
                {submitting ? <Spinner animation="border" size="sm" /> : "Submit"}
              </button>
            </div>
          </form>
        </Stack>
      </Modal.Body>
    </Modal>
  );
};

export default CardPayment;
