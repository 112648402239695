import React, {useEffect, useRef, useState} from 'react'
import axios from 'axios';
import { Col, Row , Stack, Spinner} from "react-bootstrap"
import { Tooltip } from 'react-tooltip';
import { BiSend } from 'react-icons/bi';
import ReactTypingEffect from "react-typing-effect"

function AiAssist() {
   
    const [messages, setMessages] = useState([])
  const [message, setMessage] = useState("")
  const [messageList, setMessageList] = useState([])
    const [generating, setGenerating] = useState(false)
     const messagesContainerRef = useRef();
  const [isTypingComplete, setIsTypingComplete] = useState(false);

  const handleTypingComplete = () => {
    setIsTypingComplete(true);
  };
     useEffect(() => {
       // Scroll to the bottom when messages change
       scrollToBottom();
     }, [messages]);
    
     const scrollToBottom = () => {
       // Using vanilla JavaScript to scroll to the bottom
       if (messagesContainerRef.current) {
         messagesContainerRef.current.scrollTop =
           messagesContainerRef.current.scrollHeight;
       }
     };

      const handleKeyDown = (event) => {
        if (event.key === "Enter") {
          event.preventDefault();
          if (message.trim() !== "") {
            makeRequest(message);
            setMessage(""); // Clear the input after submission
          }
        }
      };

    const makeRequest = async (text) => {
      setGenerating(true)
         setMessageList([...messageList, text])
          const options = {
            url: "https://api.phind.com/agent/" ,
            data: {
              user_input: text,
              message_history: [{"role": "user", "content": "Previous message"}],
              requested_model: "Model identifier here",
              anon_user_id: "Anonymous user identifier here",
              challenge: "Numeric challenge value here"
              
            },
          };
        const config = {
          headers: {
            "Content-Type": "application/json;charset=UTF-8",
            Origin: "https://www.phind.com",
          },
        };
        try {
            const res = await axios.post(options.url, options.data, config.headers)
            console.log(res)
            setMessages([...messages, res.data.openai.generated_text])
            setGenerating(false)
          return res
        }
        catch (err) {
            console.log(err)
            return err
        }
   }
  console.log(messages)
    return (
      <Col>
        <div className="pagetitle" id="mymaintitle">
          <h1 className="title">AI Assist</h1>
        </div>
        <Row className="p-3">
          <Col
            ref={messagesContainerRef}
            style={{
              height: "60vh",
              overflowY: "auto",
              backgroundColor: "white",
              padding: "10px",
              borderRadius: "10px",
            }}
            lg={12}
            sm={12}
            xl={12}>
            {generating ? (
              <div
                className="d-flex justify-content-center align-items-center"
                style={{ height: "inherit" }}>
                <Spinner animation='grow' variant="success" />
              </div>
            ) : (
              <ul style={{ listStyleType: "none", marginTop: "15px" }}>
                {messages.map((text, index) => (
                  <>
                    <li style={{ fontSize: "1.2em", fontWeight: " bold" }}>
                      {messageList[index]}
                    </li>
                    <li key={index} className="recieverStyle">
                      <div>
                        {index === messages.length - 1 ? (
                          // Apply typing effect to the last message
                          !isTypingComplete ? (
                            <ReactTypingEffect
                              text={text}
                              speed={60}
                              eraseSpeed={0} // Disable the erasing effect
                              eraseDelay={Infinity} // Ensure the text stays after typing
                              typingDelay={0}
                              onComplete={handleTypingComplete}
                            />
                          ) : (
                            { text }
                          )
                        ) : (
                          // Display other messages normally
                          text.split(/(\d.+)/).map((part, partIndex) => (
                            <React.Fragment key={partIndex}>
                              {partIndex % 2 === 0 ? (
                                part
                              ) : (
                                <span>
                                  <br />
                                  {part}
                                </span>
                              )}
                            </React.Fragment>
                          ))
                        )}
                      </div>
                      <div
                        style={{
                          textAlign: "right",
                          fontSize: "small",
                          marginTop: "10px",
                        }}></div>
                    </li>
                  </>
                ))}
              </ul>)}
          </Col>
          <Col
            style={{
              height: "10vh",
            }}
            lg={12}
            sm={12}
            xl={12}>
            <form
              onSubmit={() => {
                makeRequest(message);
              }}
              style={{
                padding: "10px",
                backgroundColor: "#e5e5ea",
              }}>
              <Stack
                gap={3}
                className="pb-5 ps-5 pe-5 d-flex align-items-center justify-content-center"
                direction="horizontal">
                <textarea
                  disabled={generating}
                  type="text"
                  style={{ borderRadius: "10px" }}
                  className="mt-3"
                  onChange={(event) => setMessage(event.target.value)}
                  onKeyDown={handleKeyDown}
                  value={message}
                  placeholder='Type your Prompt Here!'
                  cols={100}
                />
                <div>
                  <button
                    data-tooltip-id="send"
                    disabled={message === ""}
                    style={{
                      borderRadius: "50%",
                      height: "5vh",
                      width: "5vh",
                    }}
                    type="submit"
                    className="btn btn-success btn-sm d-flex justify-content-center align-items-center">
                    <BiSend />
                  </button>
                  <Tooltip content="Send" id="send" variant="success" />
                </div>
              </Stack>
            </form>
          </Col>
        </Row>
      </Col>
    );
}

export default AiAssist