
import React from "react";
import { Row, Col, Container, Stack, Carousel, Card } from "react-bootstrap";
import Footer from "../components/Footer";
import hifive from "../images/hifive.png";
import creditcard from "../images/creditcard.png";
import freepost from "../images/freepost.png";
import customer from "../images/customer.png";
import chat from "../images/bubble-chat.png";
import money from "../images/money-bag.png";
import notebook from "../images/upload.png";
import man1 from "../images/man1.jpg";
import man2 from "../images/man2.jpg";
import woman1 from "../images/woman.avif";
import { connect } from "react-redux";
import whatsapp from "../images/WhatsApp_icon.png.webp";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { Tooltip } from "react-tooltip";
import onlaptop from "../images/onlaptop.jpg";

function Home({ authenticated }) {
  const country = useSelector((state) => state.userCountry.country);

  return (
    <Container fluid>
      <Row>
        <Stack className="p-0 m-0">
          <Row
            className="p-0 m-0 justify-content-center"
            style={{ position: "relative" }}>
            <Col
              className="main_image_container m-0 p-0"
              lg={12}
              sm={12}
              md={12}>
              <Row className="d-flex h-100 p-5">
                <Col
                  className="d-flex h-100 align-items-center p-5"
                  lg={6}
                  md={6}
                  sm={12}>
                  <Row>
                    <Col
                      lg={12}
                      sm={12}
                      md={12}
                      style={{ height: "70vh" }}
                      className="carouselhead d-flex align-items-center p-5">
                      <Carousel
                        indicators={false}
                        controls={false}
                        className="d-flex align-items-center">
                        <Carousel.Item interval={5000}>
                          <h1 className="homeheading">
                            Compelling{" "}
                            <span className="highlight">
                              Content for your Success
                            </span>
                          </h1>
                          <p className="homep">
                            Leverage Professional and{" "}
                            <span className="highlight">
                              Proficient Writers and AI and Plag Checkers
                            </span>{" "}
                            on our Platform
                          </p>
                        </Carousel.Item>
                        <Carousel.Item interval={5000}>
                          <h1 className="homeheading">
                            Manage Your{" "}
                            <span className="highlight">
                              Orders and Team of Writers
                            </span>
                          </h1>
                          <p className="homep">
                            Know who is handling which order who is paid among
                            many other Features
                          </p>
                        </Carousel.Item>
                        <Carousel.Item interval={5000}>
                          <h1 className="homeheading">
                            AI and Human Writers{" "}
                            <span className="highlight">
                              the Best of Both Worlds
                            </span>
                          </h1>
                          <p className="homep">
                            Choose whether you need a human, AI Assist or both
                          </p>
                        </Carousel.Item>
                      </Carousel>
                    </Col>
                    <Col lg={12} sm={12} md={12}>
                      <Stack
                        gap={2}
                        direction="horizontal"
                        className="d-flex justify-content-start align-items-end w-100 ps-5">
                        <Link to={"/register"}>
                          <button className="btn btn-lg btn-success accountbutton2">
                            Get Started
                          </button>
                        </Link>
                      </Stack>
                    </Col>
                  </Row>
                </Col>
                <Col lg={6} md={6} sm={0} className="smallnoshow h-100">
                  <Row className="h-100">
                    <Col className="d-flex justify-content-center align-items-center h-100  flex-grow-1 ">
                      <div>
                        <img
                          src={onlaptop}
                          style={{
                            height: "75vh",
                            width: "50vw",
                            borderRadius: "5%",
                          }}
                        />
                      </div>
                    </Col>
                  </Row>
                </Col>
              </Row>
            </Col>
          </Row>

          <Row
            className="d-flex justify-content-center whyessayhost p-5"
            id="aboutus">
            <Col lg={12} sm={12} md={12} className="mt-2 mb-4">
              <h3 className="subheading text-center mb-2 mt-2">
                <span
                  style={{
                    textDecoration: "underline",
                    fontSize: "inherit",
                    fontWeight: "700",
                  }}>
                  Manage
                </span>{" "}
                Writers Efficiently
              </h3>
            </Col>
            <Col
              className="d-flex justify-content-center align-items-center pt-3"
              lg={6}
              sm={12}
              md={6}>
              <div className="platform"></div>
            </Col>
            <Col
              className={
                "d-flex justify-content-center align-items-center flex-column pt-3 ps-4 "
              }
              sm={12}
              md={6}
              lg={6}>
              <Card className="topcard border-white">
                <Card.Body className="d-flex align-items-center">
                  <p className="hometext1">
                    Welcome to our premier Essays and writer management platform
                    that goes beyond the ordinary. Are you a client seeking
                    exceptional services without the fear of scams or
                    unsatisfactory results? Look no further! Our platform not
                    only offers top-notch freelancing expertise but also
                    provides a secure and reliable ecosystem through our unique
                    escrow services and dispute resolutions. Essays Host&trade;{" "}
                    <b>The Project and writer management platform</b>, is an
                    online exchange platform for those in need of managing
                    writers, assistance in, Essays, content writing, Thesis
                    Papers, Dissertations, publications and other works and
                    samples.
                  </p>
                </Card.Body>
              </Card>
            </Col>
          </Row>
          <Row className="d-flex justify-content-center whyessayhost p-5">
            <Col lg={12} sm={12} md={12} className="mt-2 mb-4">
              <h3 className="subheading text-center mb-2 mt-2">
                The{" "}
                <span
                  style={{
                    textDecoration: "underline",
                    fontSize: "inherit",
                    fontWeight: "700",
                  }}>
                  Trusted
                </span>{" "}
                Essay Platform
              </h3>
            </Col>
            <Col
              lg={6}
              sm={12}
              md={6}
              className="d-sm-block d-lg-none d-md-none ">
              <div className="d-flex justify-content-center align-items-center">
                <div className="radiating-circles">
                  <div className="platform1" style={{ zIndex: 100 }}></div>
                </div>
              </div>
            </Col>
            <Col
              className="d-flex justify-content-center align-items-center flex-column pt-3 ps-4"
              sm={12}
              md={6}
              lg={6}>
              <Card className="topcard border-white">
                <Card.Body className="d-flex align-items-center">
                  {" "}
                  <p className="hometext1">
                    Essays Host&trade; is a registered company{" "}
                    {country === "Kenya" ? (
                      <>in Nairobi Kenya</>
                    ) : (
                      <>in 3349 Rainy Day Drive, Cambridge Massachusetts </>
                    )}{" "}
                    a subsidiary of <b>TechZen Group Limited&trade;</b>. What
                    sets us apart? We understand that trust is the cornerstone
                    of any successful collaboration. With our innovative escrow
                    services, you can embark on your writing journey worry-free.
                    How does it work? Once you and your chosen writer agree on
                    the terms, funds are securely held in escrow until you
                    receive the finalized work. This safeguards your investment
                    and ensures that writers are motivated to deliver
                    exceptional results.
                  </p>
                </Card.Body>
              </Card>
            </Col>
            <Col className=" d-lg-block d-sm-none" sm={0} md={6} lg={6}>
              <div className="d-flex justify-content-center align-items-center">
                <div className="radiating-circles">
                  <div className="platform1" style={{ zIndex: 100 }}></div>
                </div>
              </div>
            </Col>
          </Row>
          <Row
            className="justify-content-center p-5 align-items-center"
            id="howitworks">
            <Col lg={10} sm={12} md={10}>
              <h4
                style={{
                  textAlign: "center",
                  marginBottom: "50px",
                }}
                className="subheading1">
                How it Works in 4 easy steps
              </h4>
            </Col>
            <Row className="justify-content-around m-0 ">
              <Col lg={3} sm={12} md={3}>
                <Card className="homecard border-white">
                  <Card.Body>
                    <div className="homeiconsimage mx-auto">
                      <img
                        src={notebook}
                        alt="blueprintanimated"
                        className="homeicons"
                      />
                    </div>
                    <h5 className="subheading2 mb-4">Post an Order</h5>
                    <p className="text-center mt-2 howitworkstext">
                      Post a Public Order for all writers to bid and a Private
                      Order to be handled only by your team of personal writers.
                    </p>
                  </Card.Body>
                </Card>
              </Col>
              <Col lg={3} sm={12} md={3}>
                <Card className="homecard border-white">
                  <Card.Body>
                    <div className="homeiconsimage mx-auto">
                      <img
                        src={customer}
                        alt="notebookanimated"
                        className="homeicons"
                      />
                    </div>
                    <h5 className="subheading2 mb-4">Shortlist Writers</h5>
                    <p className="text-center mt-2 howitworkstext ">
                      Analyze the bids made by prospective writers and award the
                      project to your preffered writer
                    </p>
                  </Card.Body>
                </Card>
              </Col>
              <Col lg={3} sm={12} md={3}>
                <Card className="homecard border-white">
                  <Card.Body>
                    <div className="homeiconsimage mx-auto">
                      <img
                        src={chat}
                        alt="chatanimated"
                        className="homeicons"
                      />
                    </div>
                    <h5 className="subheading2 mb-4">Chat and File Exchange</h5>
                    <p className="text-center mt-2 howitworkstext">
                      Chat with assigned writer on progress when complete the
                      writer uploads the files
                    </p>
                  </Card.Body>
                </Card>
              </Col>
              <Col lg={3} sm={12} md={3}>
                <Card className="homecard border-white">
                  <Card.Body>
                    <div className="homeiconsimage mx-auto">
                      <img
                        src={money}
                        alt="moneyanimated"
                        className="homeicons"
                      />
                    </div>
                    <h5 className="subheading2 mb-4">Pay &#38; Rate</h5>
                    <p className="text-center mt-2 howitworkstext">
                      Pay and rate for the work after only after you are
                      satisfied with it
                    </p>
                  </Card.Body>
                </Card>
              </Col>
            </Row>
          </Row>
          <Row className="mb-5 d-flex justify-content-center">
            <h4 className="subheading text-center ">
              Testmonials from our satisfied clients
            </h4>
            <Col lg={9} md={9} sm={12}>
              <Card.Body>
                <Carousel style={{ height: "50vh" }} indicators={false}>
                  <Carousel.Item interval={6000}>
                    <Card className="carouselcard border-white">
                      <Card.Body>
                        <div className="d-flex justify-content-center mb-2">
                          <img
                            style={{
                              height: "15vh",
                              width: "auto",
                              borderRadius: "50%",
                            }}
                            src={man2}
                            alt="Third slide"
                          />
                        </div>
                        <div className="p-3">
                          <h5 className="text-center text-white">
                            James Hochfield
                          </h5>
                          <h6 className="text-center text-white">
                            Bismarck State College (Bismarck){" "}
                          </h6>
                          <p className="text-center carouseltext">
                            "Working on my project was overwhelming until I
                            found Essays Host. They provided not just writing
                            assistance but also valuable insights that helped
                            shape my project into something I’m genuinely proud
                            of. The team is knowledgeable, professional, and
                            always ready to go the extra mile. Thanks to Essays
                            Host, I was able to complete my project on time and
                            with excellent results. Highly recommend for any
                            academic support!"
                          </p>
                        </div>
                      </Card.Body>
                    </Card>
                  </Carousel.Item>
                  <Carousel.Item interval={6000}>
                    <Card className="carouselcard border-white">
                      <Card.Body>
                        <div className="d-flex justify-content-center mb-2">
                          <img
                            style={{
                              height: "15vh",
                              width: "auto",
                              borderRadius: "50%",
                            }}
                            src={man1}
                            alt="Third slide"
                          />
                        </div>
                        <div className="p-3">
                          <h5 className="text-center text-white">Ai Fang</h5>
                          <h6 className="text-center text-white">
                            Case Western Reserve University (Cleveland)
                          </h6>
                          <p className="text-center carouseltext">
                            "Essays Host was a lifesaver when I was struggling
                            with my thesis. The team provided exceptional
                            guidance and support, helping me to refine my ideas
                            and structure my work effectively. Their attention
                            to detail and commitment to quality made a huge
                            difference, and I couldn’t have completed my thesis
                            without their help. I highly recommend Essays Host
                            to anyone who needs expert assistance with their
                            academic writing!"
                          </p>
                        </div>
                      </Card.Body>
                    </Card>
                  </Carousel.Item>
                  <Carousel.Item interval={6000}>
                    <Card className="carouselcard border-white">
                      <Card.Body>
                        <div className="d-flex justify-content-center mb-2">
                          <img
                            style={{
                              height: "15vh",
                              width: "15vh",
                              borderRadius: "50%",
                            }}
                            src={woman1}
                            alt="Third slide"
                          />
                        </div>
                        <div className="p-3">
                          <h5 className="text-center text-white">Lucy Diaz</h5>
                          <h6 className="text-center text-white">
                            Central Connecticut State University (New Britain){" "}
                          </h6>
                          <p className="text-center carouseltext">
                            "As a student balancing multiple responsibilities, I
                            often found it challenging to keep up with my
                            schoolwork. Essays Host made it possible for me to
                            stay on top of my assignments without sacrificing
                            quality. Their service is reliable, efficient, and
                            always delivers exactly what I need. Whether it's a
                            research paper or a simple essay, I know I can count
                            on them to help me succeed in my studies."
                          </p>
                        </div>
                      </Card.Body>
                    </Card>
                  </Carousel.Item>
                </Carousel>
              </Card.Body>
            </Col>
          </Row>
          {/* <Row className="p-5">
            <Col>
              <Card
               
                className="border-white countcard">
                <Card.Body>
                  <h3 style={{ color: "white" }}>Orders Completed</h3>
                  <h1
                    className="text-end"
                    style={{ color: "white", fontWeight: "bold" }}>
                    509
                  </h1>
                </Card.Body>
              </Card>
            </Col>
            <Col>
              <Card
               
                className="border-white countcard">
                <Card.Body>
                  <h3 style={{ color: "white" }}>Happy Clients</h3>
                  <h1
                    className="text-end"
                    style={{ color: "white", fontWeight: "bold" }}>
                    107
                  </h1>
                </Card.Body>
              </Card>
            </Col>
            <Col>
              <Card
               
                className="border-white countcard">
                <Card.Body>
                  <h3 style={{ color: "white" }}>Writers</h3>
                  <h1
                    className="text-end"
                    style={{ color: "white", fontWeight: "bold" }}>
                    403
                  </h1>
                </Card.Body>
              </Card>
            </Col>
          </Row> */}
          <Row
            id="features"
            className="d-flex justify-content-center align-items-center p-5  mb-2">
            <Row className="justify-content-around">
              <Col
                lg={10}
                md={10}
                sm={12}
                className="d-flex justify-content-center align-items-center">
                <h4
                  style={{ textAlign: "center", marginBottom: "50px" }}
                  className="subheading1">
                  Exclusive to Essays Host
                </h4>
              </Col>
              <Col lg={4} sm={12} md={4}>
                <Card className="bottomcard border-white">
                  <Card.Body>
                    <h5 className="subheading3">
                      Free to Manage Orders and Writers
                    </h5>
                    <div className="featurescont">
                      <img
                        src={creditcard}
                        className="featuresimage"
                        alt="loansimg"
                      />
                    </div>
                    <p className="hometext">
                      Manage writers and Orders with intuitive dashboards, task
                      assignment tools, and real-time communication channels,
                      facilitating seamless coordination and collaboration among
                      employers and writers.
                    </p>
                  </Card.Body>
                </Card>
              </Col>
              <Col lg={4} sm={12} md={4}>
                <Card className="bottomcard border-white">
                  <Card.Body>
                    <h5 className="subheading3">
                      Professional Dispute Resolution
                    </h5>
                    <div className="featurescont">
                      <img
                        src={hifive}
                        className="featuresimage"
                        alt="resolutionimg"
                      />
                    </div>
                    <p className="hometext">
                      Our dedicated dispute resolution team acts as an impartial
                      mediator to facilitate fair and swift resolutions. We
                      value your satisfaction above all else and strive to find
                      mutually beneficial solutions that keep employers and
                      writers content.
                    </p>
                  </Card.Body>
                </Card>
              </Col>
              <Col lg={4} sm={12} md={4}>
                <Card className="bottomcard border-white">
                  <Card.Body>
                    <h5 className="subheading3">
                      Free to Post and Bid on Orders
                    </h5>
                    <div className="featurescont">
                      <img
                        src={freepost}
                        className="featuresimage"
                        alt="postbidimage"
                      />
                    </div>
                    <p className="hometext">
                      You don't pay anything to post Orders manage Writers or
                      bid on projects. All you need is to create an account with
                      Essays host and you have the perfect order and writer
                      management platform.
                    </p>
                  </Card.Body>
                </Card>
              </Col>
            </Row>
            <a href="https://wa.me/message/OMTWAPM2GSRKG1">
              <img
                src={whatsapp}
                alt="whatsapp"
                className="whatsapp"
                data-tooltip-id="contact-support"
                data-tooltip-content="Contact Support"
              />
            </a>
            <Tooltip id="contact-support" />
          </Row>
          <Footer />
        </Stack>
      </Row>
    </Container>
  );
}

const mapStateToProps = (state) => ({
  authenticated: state.auth.isAuthenticated,
});
export default connect(mapStateToProps)(Home);
