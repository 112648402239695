import React, { useEffect, useState } from "react";
import { Row, Col, Card, Modal, Spinner, Stack, Image } from "react-bootstrap";
import { connect } from "react-redux";
import { get_user } from "../../actions/myuser.js";
import * as yup from "yup";
import { useFormik } from "formik";
import axios from "axios";
import { FcOk, FcHighPriority } from "react-icons/fc";
import ReconnectingWebSocket from "reconnecting-websocket";
import warning from "../../images/warning.gif";
import getData from "../../customHooks/getData.js";
import moment from "moment";
import { Link, useLocation } from "react-router-dom";
import mpesa from "../../images/mpesa.png";
import { useSelector } from "react-redux";
import CardPayment from "./CardPayment.js";
import { GiMoneyStack } from "react-icons/gi";
import { GiCash } from "react-icons/gi";



function Finance({ user, get_user }) {
  const [paymentShow, setPaymentShow] = useState(false)
  const [deposit, setDeposit] = React.useState(false);
  const [withdraw, setWithdraw] = React.useState(false);
  const [loading, setLoading] = React.useState(false);
  const [depositModal, setDepositModal] = React.useState(false);
  const [fail, setFail] = React.useState(false);
  const [loan, setLoan] = React.useState(false);
  const [stkPush, setStkPush] = React.useState(false);
  const [depositChoose, setDepositChoose] = useState(false)
  const [iframeUrl, setIframeUrl] = useState('')
  const handleLoan = () => setLoan(true);
  const stkClose = () => setStkPush(false);
  const stkOpen = () => setStkPush(true);
  const handleLoanDone = () => setLoan(false);
  const handleDepositModalClose = () => setDepositModal(false);
  const handleClose2 = () => setWithdraw(false);
  const depositfunc = () => setDepositModal(true);
  const withdrawfunc = () => setWithdraw(true);
  const handleFailDone = () => setFail(false);
  const handleFail = () => setFail(true);
  const handleDeposit = () => setDeposit(true);
  const handleDepositDone = () => setDeposit(false);
  const [socket, setSocket] = useState(null);
  const [successWithdraw, setSuccessWithdraw] = React.useState(false);
  const [failedWithdraw, setFailedWithdraw] = React.useState(false);
  const [withdrawSocket, setWithdrawSocket] = React.useState(null);
  const [insufficient, setInsufficient] = React.useState(false);
  const [serverError, setServerError] = React.useState(false);
  const [withdrawPending, setWithdrawPending] = React.useState(false);
  const country = useSelector((state) => state.userCountry.country)
  const [writerCompleteUnpaid, setWriterCompleteUnpaid] = React.useState(0);
  const [completedProjects, setCompletedProjects] = React.useState([]);
  const [insufficientFundsModal, setInsufficientFundsModal] =
    React.useState(false);
    const phoneRegExp =
      /^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/;
  const location = useLocation();
  const [payStatus, setpayStatus] = React.useState({});
  const [cardPayment, setCardPayment] =React.useState(false)
  

    useEffect(() => {
      // Extract query parameters from the URL
      const queryParams = new URLSearchParams(location.search);
      const orderTrackingId = queryParams.get("OrderTrackingId");
      const orderMerchantReference = queryParams.get("OrderMerchantReference");
        if (orderMerchantReference) {
          const payStatus = new ReconnectingWebSocket(
            `${process.env.REACT_APP_WEBSOCKET_URL}/ws/card_payments/${orderMerchantReference}`
          );
          payStatus.addEventListener("open", () => {
            console.log("Card Pay socket open");
          });
          payStatus.addEventListener("close", () => {
            console.log("Card Pay socket closed");
          });
          payStatus.addEventListener("message", (event) => {
             const data = JSON.parse(event.data);
             console.log("Received message:", data);
             setpayStatus(data);
           });
        }

      // You can now use these parameters in your component
      console.log("OrderTrackingId:", orderTrackingId);
      console.log("OrderMerchantReference:", orderMerchantReference);

      // You can trigger any other actions here, such as updating state or making API calls
    }, []);

 

  const [cardError, setCardError] = useState(false) 

  useEffect(() => {
    getData(
      `${process.env.REACT_APP_API_URL}/accounts/employer_trusted_assigned`
    )
      .then((res) => {
        const projects = res.filter((project) => {
          return (
            project.completed === true &&
            new Date(project.deadline) < new Date()
          );
        });
        setCompletedProjects(projects);
        console.log(projects);
        const totalUnpaid = projects.reduce((total, project) => {
          return total + project.amount;
        }, 0);
        setWriterCompleteUnpaid(totalUnpaid);
      })
      .catch((err) => {
        console.log(err);
        
      });
  }, []);

  useEffect(() => {
    const newsocket = new ReconnectingWebSocket(
      `${process.env.REACT_APP_WEBSOCKET_URL}/ws/mpesa_response/${user?.id}`
    );
    const socket = new ReconnectingWebSocket(
      `${process.env.REACT_APP_WEBSOCKET_URL}/ws/withdrawal_response/${user?.id}`
    );
    setSocket(newsocket);

    setWithdrawSocket(socket);
    socket.addEventListener("open", () =>
      console.log("withdraw Socket connected")
    );
    newsocket.addEventListener("open", () => {
      console.log("WebSocket connected");
    });
    newsocket.addEventListener("close", () => {
      console.log("WebSocket disconnected");
    });
    return () => {
      newsocket.close();
    };
  }, []);

  useEffect(() => {
    if (socket) {
      socket.addEventListener("message", (event) => {
        const data = JSON.parse(event.data);
        if (data.status === "success") {
          get_user();
          handleDeposit();
          stkClose();
        } else if (data.status === "stk_push_sent") {
          stkOpen();
        } else if (data.status === "fail") {
          handleFail();
          stkClose();
        } else if (data.status === "stk_push_failed") {
          handleFail();
          stkClose();
        }
      });
    }
  }, [socket]);

  useEffect(() => {
    if (withdrawSocket) {
      withdrawSocket.addEventListener("message", (e) => {
        const data = JSON.parse(e.data);
        if (data.status === "Withdraw Successful") {
          get_user();
          handleClose2();
          if (withdrawPending) {
            setWithdrawPending(false);
          }
          setSuccessWithdraw(true);
          setLoading(false);
        } else if (data.status === "Insufficient Funds") {
          setInsufficient(true);
          if (withdrawPending) {
            setWithdrawPending(false);
          }
          setLoading(false);
          handleClose2();
        } else if (data.status === "Withdraw Failed") {
          setServerError(true);
          if (withdrawPending) {
            setWithdrawPending(false);
          }
          setLoading(false);
          handleClose2();
        } else if (data.status === "Withdraw Pending") {
          setWithdrawPending(true);
          setLoading(false);
          handleClose2();
        }
      });
    }
  }, [withdrawSocket]);

  const validationSchema = yup.object().shape({
    depositNumber: yup.string()
      .when("otherNumber", {
      is:true,
      then: yup.string().matches(phoneRegExp, "Please Enter a valid Phone Number").required("Deposit number is required"),
   
    }),
    amount: yup
      .number("Invalid Entry")
      .min(country === "Kenya"? 10: 5, `You can Deposit a minimum of ${country === "Kenya" ? <>Kshs 10</>: <>USD 5</>}`)
      .required("Amount is required"),
  });

  const depositCash = async (body) => {
    setLoading(true);
    const config = {
      headers: {
        "Content-Type": "application/json",
        Authorization: `JWT ${localStorage.getItem("access")}`,
        Accept: "application/json",
      },
    };
    try {
      const res = await axios.post(
        `${process.env.REACT_APP_API_URL}/accounts/deposit`,
        body,
        config
      );
      console.log(res.data);
      return res.data;
    } catch (err) {
      console.log(err);
      return err;
    }
  };

  const withdrawCash = async (amount) => {
    setLoading(true);
    const config = {
      headers: {
        "Content-Type": "application/json",
        Authorization: `JWT ${localStorage.getItem("access")}`,
        Accept: "application/json",
      },
    };
    const body = JSON.stringify({ amount });
    try {
      const res = await axios.post(
        `${process.env.REACT_APP_API_URL}/accounts/withdraw`,
        body,
        config
      );
      console.log(res.data);
      return res.data;
    } catch (err) {
      console.log(err);
      return err;
    }
  };

  const { errors, values, handleChange, handleSubmit, setFieldValue, touched } = useFormik({
    initialValues: {
      amount: "",
      depositNumber: "",
      otherNumber:""
     },
    validationSchema: validationSchema,
    onSubmit: (values, { resetForm }) => {
      depositCash(values)
        .then((res) => {
          resetForm();
          handleDepositModalClose();
          setLoading(false);
        })
        .catch((err) => {
          resetForm();
          console.log(err);
          handleDepositModalClose();
          setLoading(false);
        });
    },
  });

  useEffect(() => {
    get_user()
      .then(() => {
        console.log("success");
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);

  return iframeUrl ? (
    <div className="itemscontainerwithtabs">
      <iframe src={iframeUrl} width="100%" height="100%"></iframe>
    </div>
  ) : (
    <Card>
      <Card.Body className="itemscontainerwithtabs mt-1">
        <Row>
          <Col lg={6} sm={12} md={6}>
            <Card className="walletcard">
              <Card.Header as="h6">Escrow Wallet</Card.Header>
              <Card.Body style={{ height: "29vh" }}>
                <h6>Cash in Escrow Cannot be withdrawn</h6>
                <div>
                  <GiMoneyStack className="finance-icon" />
                </div>
                <h5>
                  {country === "Kenya" ? <>Kshs</> : <>USD</>}{" "}
                  {user.employer_profile.cash}
                </h5>
              </Card.Body>
            </Card>
          </Col>
          <Col lg={6} sm={12} md={6}>
            <Card className="walletcard">
              <Card.Header as="h6">Wallet Balance</Card.Header>
              <Card.Body style={{ height: "29vh" }}>
                <h6>Money you deposit to Essay Host and refunded cash </h6>
                <div>
                  <GiMoneyStack className="finance-icon" />
                </div>
                <h5>
                  {country === "Kenya" ? <>Kshs</> : <>USD</>}{" "}
                  {user.user_wallet.cash}.00
                </h5>

                <div className="d-flex justify-content-around">
                  <button
                    className="btn btn-outline-success btn-sm"
                    onClick={() => {
                      setDepositChoose(true);
                    }}>
                    Deposit
                  </button>
                  <button
                    className="btn btn-outline-info btn-sm"
                    onClick={withdrawfunc}>
                    Withdraw
                  </button>
                </div>
              </Card.Body>
            </Card>
          </Col>
        </Row>
        <Row>
          <Col lg={6} sm={12} md={6}>
            <Card className="walletcard">
              <Card.Header as="h6">Unpaid</Card.Header>
              <Card.Body style={{ height: "29vh" }}>
                <div>
                  <h6>Unpaid Amount for completed & Due Pay Later Projects</h6>
                  <div>
                    <GiMoneyStack className="finance-icon" />
                  </div>
                  <h5>
                    {country === "Kenya" ? <>Kshs</> : <>USD</>}{" "}
                    {writerCompleteUnpaid}
                  </h5>
                  {writerCompleteUnpaid > 0 && (
                    <div className="d-flex justify-content-center">
                      <button
                        className="btn btn-outline-success btn-sm"
                        onClick={handleLoan}>
                        Pay
                      </button>
                    </div>
                  )}
                </div>
              </Card.Body>
            </Card>
          </Col>
          <Col lg={6} sm={12} md={6}>
            <Card className="walletcard">
              <Card.Header as="h6">Total Expenditure</Card.Header>
              <Card.Body style={{ height: "29vh" }}>
                <h6>Total amount transacted on Essay Host</h6>
                <div>
                  <GiCash className="finance-icon" />
                </div>
                <h5>
                  {country === "Kenya" ? <>Kshs</> : <>USD</>}{" "}
                  {user.total_amount}
                </h5>
              </Card.Body>
            </Card>
          </Col>
        </Row>
      </Card.Body>
      <CardPayment
        showPayment={paymentShow}
        setShowPayment={setPaymentShow}
        cardtitle={"Deposit Cash"}
        setIframeUrl={setIframeUrl}
        setCardError={setCardError}
      />

      <Modal
        centered
        backdrop="static"
        keyboard={false}
        show={cardPayment}
        onHide={() => {
          setCardPayment(false);
        }}>
        <Modal.Header closeButton>
          <Modal.Title>{payStatus?.description} </Modal.Title>
        </Modal.Header>
      </Modal>
      <Modal
        centered
        backdrop="static"
        keyboard={false}
        show={cardError}
        onHide={() => {
          setCardError(false);
        }}>
        <Modal.Header closeButton>
          <Modal.Title>
            <h6>There was an Error while Processing Your Payment</h6>
          </Modal.Title>
        </Modal.Header>
      </Modal>
      <Modal
        show={depositChoose}
        onHide={() => {
          setDepositChoose(false);
        }}
        centered
        backdrop="static"
        keyboard={false}>
        <Modal.Header closeButton>
          <Modal.Title>Select Deposit Mode</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Stack
            direction="horizontal"
            gap={2}
            className="d-flex justify-content-between">
            <button
              className="btn btn-outline-success"
              onClick={() => {
                setDepositModal(true);
                setDepositChoose(false);
              }}>
              Mobile Money
            </button>
            <button
              className="btn btn-outline-info"
              onClick={() => {
                setPaymentShow(true);
                setDepositChoose(false);
              }}>
              Card Deposit
            </button>
          </Stack>
        </Modal.Body>
      </Modal>

      <Modal
        show={depositModal}
        onHide={handleDepositModalClose}
        centered
        backdrop="static"
        keyboard={false}>
        <Modal.Header closeButton>
          <Modal.Title>
            Deposit Cash{" "}
            <img src={mpesa} alt="mpesaimage" className="mpesaimage" />
          </Modal.Title>{" "}
        </Modal.Header>
        <Modal.Body>
          {!values.otherNumber && <>Deposit from {<b>0{user.phone_no}</b>}</>}
          <Stack direction="horizontal" gap={2}>
            {values.otherNumber ? (
              <>Deposit from my Number</>
            ) : (
              <> Make deposit from another Number </>
            )}
            <br />

            {values.otherNumber ? (
              <button
                className="btn btn-sm btn-outline-info"
                onClick={() => {
                  setFieldValue("otherNumber", false);
                }}>
                {values.otherNumber ? <>My Number</> : <>Other</>}
              </button>
            ) : (
              <button
                className="btn btn-sm btn-outline-info"
                onClick={() => {
                  setFieldValue("otherNumber", true);
                }}>
                {values.otherNumber ? <>My Number</> : <>Other</>}
              </button>
            )}
          </Stack>

          <form onSubmit={handleSubmit} className="mt-2">
            <Stack gap={2}>
              {values.otherNumber && (
                <>
                  <label
                    htmlFor="depositNumber"
                    className="postlabel text-center">
                    Phone Number
                  </label>
                  <input
                    type="number"
                    placeholder="Phone Number"
                    id="depositNumber"
                    name="depositNumber"
                    value={values.depositNumber}
                    onChange={handleChange}
                  />
                  {errors.depositNumber && touched.depositNumber ? (
                    <h6 className="text-danger">{errors.depositNumber}</h6>
                  ) : null}
                </>
              )}
              <label htmlFor="amount" className="postlabel text-center">
                Amount
              </label>
              <input
                id="amount"
                type="number"
                value={values.amount}
                onChange={handleChange}
                placeholder="Kshs"
              />
              {errors.amount && touched.amount ? (
                <h6 className="text-danger">{errors.amount}</h6>
              ) : null}
              <div className="d-flex justify-content-center">
                <button
                  type="submit"
                  className="btn btn-outline-success btn-sm">
                  {loading ? (
                    <span>
                      <Spinner as="span" animation="border" size="sm" />
                      ..Depositing
                    </span>
                  ) : (
                    <span> Deposit</span>
                  )}
                </button>
              </div>
            </Stack>
          </form>
        </Modal.Body>
      </Modal>
      <Modal
        show={withdraw}
        onHide={handleClose2}
        aria-labelledby="contained-modal-title-vcenter"
        centered
        backdrop="static"
        keyboard={false}>
        <Modal.Header closeButton>
          <Modal.Title>
            Withdraw Cash{" "}
            <Image src={mpesa} alt="mpesaimage" className="mpesaimage" />
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {user.user_wallet.cash >= 50 ? (
            <Stack className="d-flex justify-content-center">
              <h6 className="text-center">
                {" "}
                Confirm withdrawal of{" "}
                <b>
                  {country === "Kenya" ? <>Kshs</> : <>USD</>}{" "}
                  {user.user_wallet.cash}.00
                </b>{" "}
                to <b>0{user.phone_no}</b> from your wallet
              </h6>
              <div className="d-flex justify-content-center">
                <button
                  disabled={loading}
                  className="btn btn-outline-success btn-sm"
                  onClick={() => {
                    withdrawCash(user.user_wallet.cash)
                      .then((res) => {
                        get_user();
                        handleClose2();
                      })
                      .catch((err) => {
                        handleClose2();
                        setServerError(true);
                        console.log(err);
                      });
                  }}>
                  {loading ? <span>Withdrawing..</span> : <span>Withdraw</span>}
                </button>
              </div>
            </Stack>
          ) : (
            <h6 className="text-center">
              <img
                src={warning}
                alt="warning"
                style={{ width: "30px", height: "30px" }}
              />{" "}
              You Do not have enough funds in your Wallet to withdraw minimum
              withdrawal amount {country === "Kenya" ? <>Kshs</> : <>USD</>}{" "}
              50.00 <br /> <br /> Wallet Balance{" "}
              {country === "Kenya" ? <>Kshs</> : <>USD</>}{" "}
              {user.user_wallet.cash}.00{" "}
            </h6>
          )}
        </Modal.Body>
      </Modal>

      <Modal
        show={deposit}
        onHide={handleDepositDone}
        centered
        backdrop="static"
        keyboard={false}>
        <Modal.Header closeButton>
          <Modal.Title>
            <FcOk /> Cash Deposit Completed Successfully
          </Modal.Title>
        </Modal.Header>
      </Modal>
      <Modal
        show={insufficient}
        onHide={() => {
          setInsufficient(false);
        }}
        centered>
        <Modal.Header closeButton>
          <Modal.Title>
            <img
              src={warning}
              alt="warning"
              style={{ width: "30px", height: "30px" }}
            />{" "}
            Insufficient Funds in your Wallet Minimum withdrawal Amount{" "}
            {country === "Kenya" ? <>Kshs</> : <>USD</>}
            50.00
          </Modal.Title>
        </Modal.Header>
      </Modal>
      <Modal
        show={fail}
        onHide={handleFailDone}
        centered
        backdrop="static"
        keyboard={false}>
        <Modal.Header closeButton>
          <Modal.Title>
            {" "}
            <img
              src={warning}
              alt="warning"
              style={{ width: "30px", height: "30px" }}
            />
            Deposit Could Not be Completed
          </Modal.Title>
        </Modal.Header>
      </Modal>
      <Modal
        backdrop="static"
        keyboard={false}
        show={successWithdraw}
        onHide={() => setSuccessWithdraw(false)}
        centered>
        <Modal.Header closeButton>
          <Modal.Title>
            <FcOk /> Withdrawal Completed Successfully
          </Modal.Title>
        </Modal.Header>
      </Modal>
      <Modal
        show={failedWithdraw}
        onHide={() => {
          setFailedWithdraw(false);
        }}
        centered>
        <Modal.Header closeButton>
          <Modal.Title>
            {" "}
            <FcHighPriority />
            Error Withdrawal Could Not be Completed
          </Modal.Title>
        </Modal.Header>
      </Modal>
      <Modal
        show={loan}
        onHide={handleLoanDone}
        aria-labelledby="contained-modal-title-vcenter"
        centered
        backdrop="static">
        <Modal.Header closeButton>
          <Modal.Title className="text-center">
            Pay All Unpaid Projects
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {writerCompleteUnpaid ? (
            <>
              <h6>
                Make payment of {country === "Kenya" ? <>Kshs</> : <>USD</>}{" "}
                {writerCompleteUnpaid} to All Completed Due Projects:
              </h6>
              <table>
                <thead>
                  <tr>
                    <th>Id</th>
                    <th>Writer</th>
                    <th>Amount</th>
                    <th>Due Date</th>
                  </tr>
                </thead>
                <tbody>
                  {completedProjects.map((project) => {
                    return (
                      <tr>
                        <td style={{ color: "green" }}>#{project.id}</td>
                        <td>
                          {project.writer.first_name} {project.writer.last_name}
                        </td>
                        <td>
                          {country === "Kenya" ? <>Kshs</> : <>USD</>}{" "}
                          {project.amount}
                        </td>
                        <td>
                          {moment(project.paymentdate).format("MMM Do YY")}
                        </td>
                      </tr>
                    );
                  })}
                </tbody>
              </table>
            </>
          ) : (
            <ul>
              <li>No completed projects</li>
              <li>No Projects are Past their due Payment Date</li>
            </ul>
          )}
        </Modal.Body>
        {writerCompleteUnpaid > 0 && (
          <Modal.Footer>
            <Stack gap={2} className="d-flex justify-content-center">
              {" "}
              <div className="d-flex justify-content-center">
                {" "}
                <button className="btn btn-outline-success btn-sm">
                  Pay Now
                </button>
              </div>
              To make payments to individual Writers click here:{" "}
              <Link to={"/employer/my_writers"}>My Writers</Link>
            </Stack>
          </Modal.Footer>
        )}
      </Modal>
      <Modal
        show={insufficientFundsModal}
        onHide={() => setInsufficientFundsModal(false)}
        centered>
        <Modal.Header closeButton>
          <Modal.Title>Insufficient Funds</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          You need to add at least{" "}
          <b style={{ color: "green" }}>
            {country === "Kenya" ? <>Kshs</> : <>USD</>}{" "}
            {writerCompleteUnpaid - user.user_wallet.cash}
          </b>{" "}
          to your wallet to make this payment.
          <br />
          On Wallet Balance click Deposit to add funds to your wallet.
          <br />
          Otherwise, you can also make paymentd to individual Writers.
          <Link to={"/employer/my_writers"}>My Writers</Link>
          <br />
        </Modal.Body>
      </Modal>
      <Modal
        show={stkPush}
        onHide={stkClose}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered>
        <Modal.Header closeButton>
          <Modal.Title className="text-center">
            An STK push notification has been sent to your phone
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <h6>
            Please Enter your MPESA pin on your phone to complete the
            transaction.
            <br /> A notification will Pop Up indicating whether the transaction
            was successful or not.
          </h6>
        </Modal.Body>
      </Modal>
      <Modal
        show={serverError}
        onHide={() => {
          setServerError(false);
        }}
        centered>
        <Modal.Header closeButton>
          <Modal.Title>
            {" "}
            <FcHighPriority />
            Internal Server Error Please Try Again Later
          </Modal.Title>
        </Modal.Header>
      </Modal>
      <Modal
        show={withdrawPending}
        onHide={() => {
          setWithdrawPending(false);
        }}
        centered>
        <Modal.Header closeButton>
          <Modal.Title>
            {" "}
            <FcHighPriority />
            You have a pending withdrawal request
          </Modal.Title>
        </Modal.Header>
      </Modal>
    </Card>
  );
}

const mapStateToProps = (state) => ({
  user: state.current_user.user,
});

export default connect(mapStateToProps, { get_user })(Finance);
