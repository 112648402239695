import React from "react";
import { Col, Tabs, Tab, Stack } from "react-bootstrap";
import MyWriters from "./MyWriters";
import HireRequests from "./HireRequests";

function Writers() {
  return (
    <Col className="mainarea">
      <div className="pagetitle">
        <h1 className="title">My Writers</h1>
      </div>
      <div>
        <Tabs
          variant="pills"
          pill
          bg="success"
          defaultActiveKey="writers"
          justify
          className="tabs">
          <Tab eventKey="writers" title="Writers">
            <MyWriters />
          </Tab>
          <Tab eventKey="requests" title="Hire Requests Sent">
            <HireRequests />
          </Tab>
        </Tabs>
      </div>
    </Col>
  );
}

export default Writers;
