import axios from "axios";
import React, { useEffect, useState } from "react";
import { Col, Card, Spinner, Modal, Stack, Row, Toast, ToastContainer } from "react-bootstrap";
import { BiArrowBack } from "react-icons/bi";
import { useNavigate } from "react-router-dom";
import { get_user } from "../../actions/myuser";
import { connect } from "react-redux";
import Rating from "../global/Rating";
import * as yup from "yup";
import { useFormik } from "formik";
import { HiOutlineUserCircle } from "react-icons/hi";

function MarkComplete({ get_user }) {
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [modalShow, setModalShow] = useState(false);
  const [showToast, setShowToast] = useState(false);
  const [showToast1, setShowToast1] = React.useState(false);
  const [notification, setNotification] = useState("");
  const [errorModalShow, setErrorModalShow] = useState(false);
   const [writer, setWriter] = useState({});
  const [project, setProject] = useState({
    title: "",
    id: 0,
    writer: {
      id: 0,
    },
  });
  const [feedback, setFeedback] = useState(false);
  const [loadingReview, setLoadingReview] = useState(false);

  const placeReview = async (values) => {
    setLoadingReview(true);
    setShowToast(true);
    const config = {
      headers: {
        Authorization: `JWT ${localStorage.getItem("access")}`,
        Accept: "application/json",
        "Content-Type": "application/json",
      },
    };
    const url = `${process.env.REACT_APP_API_URL}/accounts/review_writer`;
    try {
      const response = await axios.post(
        url,
        {
          review: values.review,
          writer_id: project.writer.id,
          project_id: project.id,
        },
        config
      );
      setLoadingReview(false);
      setNotification("Review Successfully Posted");
      console.log(response.data);
      setFeedback(false);
    } catch (err) {
      setLoadingReview(false);
      setNotification("Error Making Review");
      console.log(err);
    }
  };

  const reviewSchema = yup.object().shape({
    review: yup
      .string()
      .required("Review is required")
      .min(20, "Too short min 20 Characters")
      .max(100, "Too long max 100 characters"),
  });

  const { values, errors, touched, handleChange, handleSubmit } = useFormik({
    initialValues: {
      review: "",
    },
    validationSchema: reviewSchema,
    onSubmit: () => {
      placeReview(values);
    },
  });

  useEffect(() => {
    const project = JSON.parse(localStorage.getItem("completeProject"));
    console.log(project);
    if (project) {
      setProject({
        ...project,
        title: project.title,
        id: project.id,
        writer: {
          id: project.writer.id,
        },
      });
      setWriter(project.writer)
    }
  }, []);

  console.log(writer)

  const complete = async () => {
    setLoading(true);
    const config = {
      headers: {
        "Content-Type": "application/json",
        Authorization: `JWT ${localStorage.getItem("access")}`,
        Accept: "application/json",
      },
    };
    const url = `${process.env.REACT_APP_API_URL}/accounts/completeproject`;
    try {
      const response = await axios.post(
        url,
        { project_id: project.id },
        config
      );
      console.log(response.data);
      setModalShow(true);
    } catch (err) {
      console.log(err);
      setErrorModalShow(true);
    }
  };
  console.log(project);
  return (
    <Col className="mainarea">
      <div className="pagetitle">
        <BiArrowBack onClick={() => navigate(-1)} className="pagenavbutton" />
        <h1 className="title">Complete Order ID: {project.id} </h1>
      </div>
      <ToastContainer
        position="bottom-end"
        className="p-3"
        style={{ zIndex: 99999 }}>
        <Toast
          autohide
          delay={4000}
          className={
            notification === "Review Successfully Posted" ||
            "Writer Successfully Rated"
              ? "bg-success text-white"
              : "bg-danger text-white"
          }
          show={showToast}
          onClose={() => {
            setShowToast(false);
          }}>
          <Toast.Body>{notification}</Toast.Body>
        </Toast>
      </ToastContainer>
      <Card className="itemscontainerwithtabs" style={{ height: "83vh" }}>
        <Card.Body>
          <Row>
            <Col lg={12} sm={12}>
              <h5 className="text-center">{project.title}</h5>
            </Col>
            <Col lg={12} sm={12}>
              <Row>
                <Col
                  className="d-flex justify-content-center align-items-center hometext text-center"
                  lg={12}
                  sm={12}>
                  <p>
                    Before marking an order as complete please make sure that
                    the submitted files from the writer have met all the
                    instructions given while assigning the Project. When you
                    mark a project as Complete the escrowed amount is
                    transffered to the writer who has been handling the project.
                    The writer can now be able to withdraw the cash from their
                    wallet.
                  </p>
                </Col>

                <Col
                  className="d-flex justify-content-center align-items-center"
                  lg={12}
                  md={12}
                  sm={12}>
                  <Stack gap={3}>
                    <h5 className="text-center">Order Handled By</h5>
                    <h6 className="text-center">
                      <span>
                        {writer.profile_pic ? (
                          <img
                            src={
                              process.env.REACT_APP_API_URL + writer.profile_pic
                            }
                            className="project_image"
                            alt="employer_image"
                            style={{ height: "50px", width: "50px" }}
                          />
                        ) : (
                          <HiOutlineUserCircle
                            style={{ height: "50px", width: "50px" }}
                          />
                        )}
                      </span>{" "}
                    </h6>
                    <h6 className="text-center">
                      {" "}
                      {writer.first_name} {writer.last_name}
                    </h6>
                  </Stack>
                </Col>

                <Col lg={6} sm={12}>
                  <Stack className="d-flex justify-content-center align-items-center">
                    <h4>Rate the Writer</h4>
                    <Rating
                      writerid={project?.writer.id}
                      projectid={project?.id}
                      setNotification={setNotification}
                      setShowToast1={setShowToast1}
                    />
                  </Stack>
                </Col>
                <Col lg={6} sm={12}>
                  <Stack className="d-flex justify-content-center align-items-center">
                    <h4>Write A Review</h4>
                    <button
                      className="btn btn-outline-info btn-sm"
                      onClick={(e) => {
                        e.preventDefault();
                        setFeedback(true);
                      }}>
                      Review
                    </button>
                  </Stack>
                </Col>
              </Row>
            </Col>
          </Row>

          <Row>
            <Col className="d-flex justify-content-center align-items-center">
              <button
                className="btn btn-sm btn-outline-success"
                onClick={() => {
                  complete()
                    .then(() => {
                      get_user()
                        .then(() => {
                          setLoading(false);
                        })
                        .catch((err) => {
                          console.log(err);
                        });
                    })
                    .catch((err) => {
                      console.log(err);
                    });
                }}
                disabled={loading}>
                {loading ? (
                  <>
                    <Spinner as="span" animation="border" size="sm" />{" "}
                    <span>Completing...</span>
                  </>
                ) : (
                  <span>Complete Order</span>
                )}
              </button>
            </Col>
          </Row>
        </Card.Body>
      </Card>
      <Modal
        show={modalShow}
        onHide={() => setModalShow(false)}
        centered
        closeButton>
        <Modal.Header closeButton>
          <Modal.Title>Project Successfully Completed</Modal.Title>
        </Modal.Header>
      </Modal>
      <Modal
        show={errorModalShow}
        onHide={() => setErrorModalShow(false)}
        centered
        closeButton>
        <Modal.Header closeButton>
          <Modal.Title>Project Could Not be Completed</Modal.Title>
        </Modal.Header>
      </Modal>
      <Modal
        centered
        show={feedback}
        onHide={() => {
          setFeedback(false);
        }}>
        <Modal.Header closeButton>
          <Modal.Title>
            <h5>Write A Review</h5>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <form onSubmit={handleSubmit}>
            <Stack>
              <textarea
                className="form-control"
                rows="5"
                onChange={handleChange}
                placeholder="review"
                name="review"></textarea>
              {errors.review && touched.review ? (
                <p className="error">{errors.review}</p>
              ) : (
                ""
              )}
              <div className="d-flex justify-content-center">
                <button className="btn btn-outline-info btn-sm" type="submit">
                  {loadingReview ? "...Submitting" : "Submit"}
                </button>
              </div>
            </Stack>
          </form>
        </Modal.Body>
      </Modal>
    </Col>
  );
}

export default connect(null, { get_user })(MarkComplete);
