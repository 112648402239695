import axios from "axios";
import * as actions from "./types"



export const display_projects = async () =>{
  const config = {
    headers: {
      Authorization: `JWT ${localStorage.getItem("access")}`,
      "Content-Type": "application/json",
      Accept: "application/json",
    },
  };
  try {
    const res = await axios.get(
      `${process.env.REACT_APP_API_URL}/accounts/projects`,
      config
    );
   
    return res.data
  } catch (err) {
   
    console.log(err);
  }
};


export const bid = (projectid,bidamount, biddescription) => async (dispatch)=>{
  const config = {
    headers:{
      "Content-Type": "application/json",
      "Authorization": `JWT ${localStorage.getItem("access")}`,
      "Accept": "application/json",
    }
  }
  const body = JSON.stringify({projectid, bidamount, biddescription})

  try{
    const message = "Bid successfully Placed"
    const res = await axios.post(`${process.env.REACT_APP_API_URL}/accounts/bid`, body, config)
    dispatch({
      type: actions.POST_BID_SUCCESS,
      payload: res.data,
    })
    
    return message
  }
  catch(err){
    const message = "Bid Could not be Placed"
    dispatch({
      type: actions.POST_BID_FAIL,
    })
    return message
  }
}
