import React, { useState, useEffect } from "react";
import { Accordion } from "react-bootstrap";
import { Link } from "react-router-dom";
import getData from "../../customHooks/getData";
import { useNavigate } from "react-router-dom";
import Empty from "../global/Empty";
import Busy from "../global/Busy";
import Project from "./Project/Project";

function InRevision() {
  const [state, setState] = useState({
    currentpage: 1,
    postsperpage: 10,
    loading: true,
    projects: [],
  });

  const url = `${process.env.REACT_APP_API_URL}/accounts/list_revision_projects`;
  const navigate = useNavigate();
  useEffect(() => {
    getData(url, navigate)
      .then((res) => {
        setState({
          ...state,
          projects: res,
          loading: false,
        });
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);

  let currentProjects = [];
  // Implement Page Numbers
  const pageNumbers = [];
  console.log(state.loading);
  if (!state.loading) {
    const indexOfLastProject = state.currentpage * state.postsperpage;
    const indexOfFirstProject = indexOfLastProject - state.postsperpage;
    currentProjects = state.projects.slice(
      indexOfFirstProject,
      indexOfLastProject
    );

    // Depending on the length of the projects available implement the page numbers
    for (
      let i = 1;
      i <= Math.ceil(state.projects.length / state.postsperpage);
      i++
    ) {
      pageNumbers.push(i);
    }
  }

  const setPage = (num) => {
    setState({ ...state, currentpage: num });
  };
  return (
    <div>
      {state.loading ? (
        <Busy />
      ) : (
        <>
          <div className="pagetitle">
            <h1 className="title">
              Orders In Revision {state.projects.length}
            </h1>
          </div>
          <div >
            <Accordion>
              <Accordion.Item eventKey="0">
                <Accordion.Header className="accordionheader">
                  <table>
                    <col style={{ width: "20%" }} />
                    <col style={{ width: "40%" }} />
                    <col style={{ width: "40%" }} />
                    <thead>
                      <th> ID</th>
                      <th>FIELD</th>
                      <th>BUDGET </th>
                    </thead>
                  </table>
                </Accordion.Header>
              </Accordion.Item>
              {state.projects.length === 0 ? (
                <Empty message="No Orders in Revision" />
              ) : (
                <div  className="contentarea">
                  {currentProjects.map((project) => {
                    return (
                      <Accordion.Item eventKey={project.id} key={project.id}>
                        <Project project={project}>
                          <div className="d-flex justify-content-around">
                            <Link
                              to="/employer/complete"
                              className="btn btn-outline-success btn-sm"
                              onClick={() => {
                                localStorage.setItem(
                                  "completeProject",
                                  JSON.stringify(project)
                                );
                              }}>
                              Complete
                            </Link>
                            {!project.trusted && (
                              <Link
                                to="/employer/dispute"
                                className="btn btn-outline-danger btn-sm"
                                onClick={() => {
                                  localStorage.setItem(
                                    "disputeProject",
                                    JSON.stringify(project)
                                  );
                                }}>
                                Dispute
                              </Link>
                            )}
                            <Link
                              to="/employer/revisioninstructions"
                              className="btn btn-outline-success btn-sm"
                              onClick={() => {
                                localStorage.setItem(
                                  "revisionProject",
                                  JSON.stringify(project)
                                );
                              }}>
                              View Revision Instructions
                            </Link>
                          </div>
                        </Project>
                      </Accordion.Item>
                    );
                  })}
                </div>
              )}
            </Accordion>
            <div className="paginationcontainer d-flex justify-content-center">
              {pageNumbers.map((pageNumber, index) => (
                <span
                  key={index}
                  className={
                    pageNumber === state.currentpage
                      ? "text-light bg-success paginationitem"
                      : "text-dark bg-light paginationitem"
                  }
                  onClick={() => {
                    setPage(pageNumber);
                  }}>
                  {pageNumber}
                </span>
              ))}
            </div>
          </div>
        </>
      )}
    </div>
  );
}

export default InRevision;
