import React, { useEffect, useState } from "react";
import {
  Col,
  Stack,
  Spinner,
  Card,
  Toast,
  ToastContainer,
} from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import { BiArrowBack } from "react-icons/bi";
import { useFormik } from "formik";
import { FILE_SIZE, SUPPORTED_FORMATS } from "../../assets/projectassets";
import * as yup from "yup";
import { postData } from "../../customHooks/postdata";

function MakeDispute() {
  const [myDispute, setMyDispute] = useState({
    data: null,
    loading: false,
    notification: "",
    posted: false,
  });
  const [showToast, setShowToast] = useState(false);
  const url = `${process.env.REACT_APP_API_URL}/accounts/dispute`;
  function onSubmit(values) {
    setMyDispute({ ...myDispute, loading: true });
    const formdata = new FormData();
    for (const value in values) {
      if (value === "disputefiles") {
        for (let i = 0; i < values[value].length; i++) {
          const files = values[value];
          formdata.append(`file${i}`, files[i]);
        }
      } else {
        formdata.append(value, values[value]);
      }
    }

    postData(url, formdata)
      .then((res) => {
        console.log(res);
        setMyDispute({
          ...myDispute,
          data: res,
          notification: "Dispute Posted Successfully",
          posted: true,
        });
      })
      .catch((err) => {
        console.log(err);
        setMyDispute({
          ...setMyDispute,
          loading: false,
          notification: "Dispute Posting Failed",
          posted: false,
        });
      });
  }
  const [project, setProject] = useState({
    title: "",
    id: 0,
  });
  console.log(project);

  useEffect(() => {
    const project = JSON.parse(localStorage.getItem("disputeProject"));
    if (project) {
      setProject({
        ...project,
        title: project.title,
        id: project.id,
      });
    }
  }, []);

  const disputevalidation = yup.object().shape({
    dispute: yup
      .string()
      .required()
      .min(100, "Minimum of 100 characters to define your dispute")
      .max(500, "Use maximum of 500 characters to define your dispute"),
    disputefiles: yup
      .array()
      .of(
        yup
          .mixed()
          .test(
            "fileSize",
            "File too large MAX 5MB for Each file",
            (value) => value && value.size <= FILE_SIZE
          )
          .test(
            "fileFormat",
            "Unsupported File Format! Supported formats =>(.docx, .jpeg, .jpg, .png, .xlxs, .ppt)",
            (value) => value && SUPPORTED_FORMATS.includes(value.type)
          )
      )
      .max(5, "You can Upload a Maximum of 5 files Per Project"),
  });
  const { values, errors, handleChange, handleSubmit, setFieldValue } =
    useFormik({
      initialValues: {
        projectid: JSON.parse(localStorage.getItem("disputeProject")).id,
        dispute: "",
        disputefiles: [],
      },
      validationSchema: disputevalidation,
      onSubmit,
    });
  const navigate = useNavigate();

  useEffect(() => {
    if (myDispute.notification) {
      setShowToast(true);
    }
  }, [myDispute.notification]);

  return (
    <Col className="mainarea">
      <div className="pagetitle">
        <BiArrowBack onClick={() => navigate(-1)} className="pagenavbutton" />
        <h1 className="title">Dispute Order ID: {project.id}</h1>
      </div>
      <Card className="itemscontainerwithtabs" style={{ height: "83vh" }}>
        <Card.Header as="h5" className="text-center">
          {project.title}
        </Card.Header>
        <Stack gap={3}>
          <Card.Body>
            <ToastContainer
              position="top-end"
              className="p-3"
              style={{ zIndex: 99999 }}>
              <Toast
                delay={3000}
                autohide
                className={
                  myDispute.notification === "Dispute Posted Successfully"
                    ? "bg-success text-white"
                    : "bg-danger text-white"
                }
                show={showToast}
                onClose={() => {
                  setShowToast(false);
                  setMyDispute({
                    ...myDispute,
                    notification: "",
                  });
                }}>
                <Toast.Body>{myDispute.notification}</Toast.Body>
              </Toast>
            </ToastContainer>

            <h6>
              Please state your evidences by using the below description section
              and Post Evidence files Section. Note that you will be able to
              submit the evidences only once and will not be allowed to modify
              the evidences there after, so make sure to include all the
              evidences files if any, preferably in a pdf file format.
            </h6>
            <form onSubmit={handleSubmit} encType="multipart/form-data">
              <Stack gap={3}>
                <label htmlFor="dispute" style={{ color: "green" }}>
                  Give a Brief Description Why You are making the Dispute
                </label>
                <textarea
                  id="dispute"
                  onChange={handleChange}
                  cols={10}
                  rows={7}></textarea>
                {errors.dispute && <p className="error">{errors.dispute}</p>}
                <label htmlFor="files" style={{ color: "green" }}>
                  Post Evidence Files, (eg Turnitin Report)
                </label>
                <input
                  type="file"
                  id="files"
                  onChange={(event) => {
                    const files = [];
                    for (let i = 0; i < event.currentTarget.files.length; i++) {
                      files.push(event.currentTarget.files[i]);
                    }
                    setFieldValue("disputefiles", files);
                  }}
                  multiple
                />
                {errors.disputefiles ? (
                  <p className="error">{errors.disputefiles}</p>
                ) : (
                  ""
                )}
                <div className="d-flex justify-content-center">
                  <button
                    className="btn btn-outline-danger btn-sm"
                    disabled={myDispute.posted || myDispute.loading}>
                    {myDispute.loading ? (
                      <>
                        <Spinner as="span" animation="border" size="sm" />{" "}
                        <span>Posting Dispute...</span>
                      </>
                    ) : (
                      <span>Dispute </span>
                    )}
                  </button>
                </div>
              </Stack>
            </form>
          </Card.Body>
        </Stack>
      </Card>
    </Col>
  );
}

export default MakeDispute;
