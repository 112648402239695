import React, {useState} from "react";
import { Col, Tabs, Tab, Stack } from "react-bootstrap";
import MyEmployers from "./MyEmployers";
import EmploymentRequests from "./EmploymentRequests";

function Employers() {
   const [state, setState] = useState({
     currentPage: 1,
     postPerPage: 10,
     employers: [],
     loading: true,
   });
  return (
    <Col className="mainarea">
      <Stack gap={1}>
        <div className="pagetitle">
          <h1 className="title">My Employers</h1>
        </div>
        <div>
          <Tabs
            variant="pills"
            pill
            bg="success"
            defaultActiveKey="employers"
            justify
            className="tabs">
            <Tab eventKey="employers" title="Employers">
              <MyEmployers state={state} setState={setState} />
            </Tab>
            <Tab eventKey="requests" title="Employment Requests">
              <EmploymentRequests state={state} setState={setState} />
            </Tab>
          </Tabs>
        </div>
      </Stack>
    </Col>
  );
}

export default Employers;
