import React from "react";
import { Spinner, Row, Col } from "react-bootstrap";

function Busy() {
  return (
    
      <Col lg={12} sm={12} md={12}
        className="loader d-flex justify-content-center align-items-center"
        style={{ height: "95vh", width: "100%" }}>
        <Spinner animation="grow" variant="success" />
      </Col>
  
  );
}

export default Busy;
