import React, { useEffect, useState } from "react";
import { Col, Row, Card } from "react-bootstrap";
import file from "../../images/file.png";
import agreement from "../../images/agreement.png";
import document from "../../images/document.png";
import disputed from "../../images/disputed.png";
import review from "../../images/review.png";
import revision from "../../images/revision.png";
import wallet from "../../images/wallet.png";
import wallet2 from "../../images/wallet2.png";
import loan from "../../images/loan.png";
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import { get_user } from "../../actions/myuser.js";
import getData from "../../customHooks/getData.js";
import { useSelector } from "react-redux";

function EmployerDashboard({ employer, get_user }) {
    const country = useSelector((state) => state.userCountry.country);
  const [writerCompleteUnpaid, setWriterCompleteUnpaid] = useState(0);
  useEffect(() => {
    get_user()
      .then(() => {
        console.log("success");
      })
      .catch((err) => {
        console.log(err);
      });
    const interval = setInterval(() => {
      get_user();
    }, 60000);

    return () => clearInterval(interval);
  }, []);

  useEffect(() => {
    getData(
      `${process.env.REACT_APP_API_URL}/accounts/employer_trusted_assigned`
    )
      .then((res) => {
        const projects = res.filter((project) => {
          return (
            project.completed === true &&
            new Date(project.deadline) < new Date()
          );
        });

        console.log(projects);
        const totalUnpaid = projects.reduce((total, project) => {
          return total + project.amount;
        }, 0);
        setWriterCompleteUnpaid(totalUnpaid);
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);

  return (
    <div>
      <Row className="cardcontainer">
        <Col md={4} sm={6} xs={6} className="m-0 p-0">
          <Card className="mycard">
            <Link to={"/employer/my_projects"}>
              <h5 className="card_text">Unassigned </h5>
              <h4 className="d-flex justify-content-around align-items-center cardvalues">
                <img src={file} alt="documentsgif" className="cardicon" />
                {employer.employer_profile.unassigned}{" "}
              </h4>
            </Link>
          </Card>
        </Col>
        <Col md={4} sm={6} xs={6} className="m-0 p-0">
          <Card className="mycard">
            <Link to={"/employer/inprogress"}>
              <h5 className="card_text"> In Progress</h5>
              <h4 className="d-flex justify-content-around align-items-center cardvalues">
                <img src={agreement} alt="writingpng" className="cardicon" />
                {employer.employer_profile.inprogress}
              </h4>
            </Link>
          </Card>
        </Col>
        <Col md={4} sm={6} xs={6} className="m-0 p-0">
          <Card className="mycard">
            <Link to={"/employer/completed"}>
              <h5 className="card_text">Completed </h5>
              <h4 className="d-flex justify-content-around align-items-center cardvalues">
                <img src={document} className="cardicon" alt="documents" />
                {employer.employer_profile.completedcount}
              </h4>
            </Link>
          </Card>
        </Col>
        <Col md={4} sm={6} xs={6} className="m-0 p-0">
          <Card className="mycard">
            <Link to={"/employer/disputed"}>
              <h5 className="card_text">In Dispute</h5>
              <h4 className="d-flex justify-content-around align-items-center cardvalues">
                <img src={disputed} className="cardicon" alt="disputed" />
                {employer.employer_profile.indispute}
              </h4>
            </Link>
          </Card>
        </Col>
        <Col md={4} sm={6} xs={6} className="m-0 p-0">
          <Card className="mycard">
            <Link to={"/employer/profile"}>
              <h5 className="card_text">All Disputes</h5>
              <h4 className="d-flex justify-content-around align-items-center cardvalues">
                <img src={revision} className="cardicon" alt="revision" />
                {employer.employer_profile.disputecount}
              </h4>
            </Link>
          </Card>
        </Col>
        <Col md={4} sm={6} xs={6} className="m-0 p-0">
          <Card className="mycard">
            <Link to={"/employer/inrevision"}>
              <h5 className="card_text">In Revision</h5>
              <h4 className="d-flex justify-content-around align-items-center cardvalues">
                <img src={review} className="cardicon" alt="review" />
                {employer.employer_profile.inrevision}
              </h4>
            </Link>
          </Card>
        </Col>
        <Col md={4} sm={6} xs={6} className="m-0 p-0">
          <Card className="mycard">
            <Link to={"/employer/transactions"}>
              <h5 className="card_text">Escrowed Amount</h5>
              <h4 className="d-flex justify-content-around align-items-center cardvalues">
                <img src={wallet} className="cardicon" alt="wallet" />
                {country === "Kenya" ? <>Kshs</> : <>USD</>}{" "}
                {employer.employer_profile.cash}.00
              </h4>
            </Link>
          </Card>
        </Col>
        <Col md={4} sm={6} xs={6} className="m-0 p-0">
          <Card className="mycard">
            <Link to={"/employer/transactions"}>
              <h5 className="card_text">Total Expenditure</h5>

              <h4 className="d-flex justify-content-around align-items-center cardvalues">
                <img src={wallet2} className="cardicon" alt="wallet" />
                {country === "Kenya" ? <>Kshs</> : <>USD</>}{" "}
                {employer.total_amount}.00
              </h4>
            </Link>
          </Card>
        </Col>
        <Col md={4} sm={6} xs={6} className="m-0 p-0">
          <Card className="mycard">
            <Link to={"/employer/transactions"}>
              <h5 className="card_text">Unpaid Amount</h5>

              <h4 className="d-flex justify-content-around align-items-center cardvalues">
                <img src={loan} className="cardicon" alt="wallet" />
                {country === "Kenya" ? <>Kshs</> : <>USD</>}{" "}
                {writerCompleteUnpaid}.00
              </h4>
            </Link>
          </Card>
        </Col>
      </Row>
    </div>
  );
}

const mapStateToProps = (state) => ({
  employer: state.current_user.user,
});

export default connect(mapStateToProps, { get_user })(EmployerDashboard);
